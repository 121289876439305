import React from 'react'
import useAdCardData from '../../../hooks/useAdCardData'

const UserAdCard = ({ onCardLog, LiButton, user, company, truncateText, getSectionName, visibleSection, getCompanyById }) => {

    const { data, adId, loading } = useAdCardData(visibleSection)

    if (data && Object.keys(data).length) {
        return (
            <>
                <div className="user-info-sidebar rj mt12 cp df jcc py0-5rem px0-5rem aic fdc mt0-5rem bg38 bo45 bss bw1px br8px"
                    style={{ width: "94%", paddingLeft: '0.5rem' }}
                >
                    <div className="px0-5rem">
                        <div className="df w100 jcsb mt0-5rem">
                            <div className="df fdc w70">
                                <div className="df pr">
                                    <img
                                        className="br100"
                                        style={{ width: '40px', height: '40px', border: '1px solid #15D757' }}
                                        src={data?.profile ? data?.profile : require('../../../../images/profile-photo.png')}
                                        alt="alloctaed-Advisor-Image"
                                    />
                                    {
                                        (data && data.linkedIn) && <div className="pt0-5rem pb0-5rem df fdc ml0-5rem jcc" style={{ marginBottom: "7px" }}>
                                            <LiButton link={data.linkedIn} withoutLog={true} />
                                        </div>
                                    }

                                </div>
                                <div onClick={() => onCardLog(company, user, data, 'title', { visibleSection, adId, isDefault: data?.isDefault })} className="w90 df fdc">
                                    <div className="mt0-5rem fw500 c15" style={{ fontSize: '11px', textTransform: 'capitalize' }}>
                                        {`${data?.firstName ?? ""} ${data?.lastName ?? ""}`}
                                    </div>
                                    <div className="fw500 c51 op80" style={{ fontSize: '9px', textTransform: 'capitalize' }}>Chief Executive Officer</div>
                                </div>
                            </div>
                            {
                                data?.company['logo'] && <img className="bg1 br100 ofcov" style={{ width: '44px', height: '40px' }}
                                    src={data?.company['logo']}
                                    alt="company_logo"
                                    onClick={() => getCompanyById(typeof data?.companyId === "string" ? data?.companyId : data?.companyId?._id)}
                                />
                            }

                        </div>
                        <div className="fw500 px0-5rem mt0-5rem mb0-5rem" style={{ paddingLeft: '0px', fontSize: '11px', width: "100%" }}>
                            <div className="fw500" style={{ fontSize: '11px', width: "100%" }}>
                                <p className='c14' style={{ fontSize: '10px' }}>
                                    {truncateText(`Need to chat about your ${getSectionName(company, visibleSection)} requirements?  No obligation call...`, 100)}
                                </p>

                                {
                                    data?.phone ? <p className="mt0-5rem c14" onClick={() => onCardLog(company, user, data, 'phoneNumber', { visibleSection, adId })}>
                                        <span className="fw300 c14">Tel:</span>{" "}
                                        <a
                                            className='c14'
                                            href={`tel:${data?.phone}`} >
                                            {data?.phone}
                                        </a>
                                    </p> : null
                                }
                                {
                                    data?.email ? <p className='c14' onClick={() => onCardLog(company, user, data, "email", { visibleSection, adId })}>
                                        <span className="fw300 c14">Email:</span>{" "}
                                        <a className='c14' href={`mailto:${data?.email ?? ""}`}
                                        >
                                            {truncateText(data?.email, 30) ?? ""}
                                        </a>
                                    </p> : null
                                }

                            </div>
                        </div >
                    </div>
                </div>
            </>
        )
    } else {
        return (<></>)
    }
}

export default UserAdCard