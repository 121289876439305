import React from 'react'
import ModalWrapper from '../../common/Modal/ModalWrapper'
import Button from '../../common/Button'

const DeleteFileConfirmModal = ({ deleteFile, onConfirm, onCancel, modalType }) => {
    return (
        <>
            <ModalWrapper modalType={modalType}>
                <div className={"df aic jcc"}>
                    <div
                        className={""}
                        style={{
                            backgroundColor: "rgba(252, 119, 133, 0.2)",
                            padding: "20px",
                            borderRadius: "50px",
                            height: "110px",
                            width: "110px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                        {/* <Delete /> */}
                        <img src={require("../../../../images/dataRepo/delete.svg")} alt="deleteIcon"
                            style={{
                                height: "70px",
                                width: "70px",
                            }}
                        />
                    </div>
                </div>
                <h1 className="c15 fs1-75rem fw600 tac mt1-5rem">Delete File</h1>
                <p className="fs1rem fw400 c3 mb1rem tac">{deleteFile && deleteFile.originalname}</p>
                <h2 className="c15 fs1-25rem fs1rem-sm fw400 op70 w100 tac mb1rem">
                    Are you sure you want to permanently delete the file?
                    <br></br>
                    <b>This action can’t be undone.</b>
                </h2>

                <div
                    className="tac"
                >
                    <Button
                        btnType="warning"
                        onClick={onConfirm}
                        customClassName="miw200px mla mra"
                        text={"Delete"}
                    />
                </div>

                <div onClick={onCancel} className='w100 tac mt0-5rem'>
                    <span className='fs14px cp' style={{ color: "#15131380" }}>Cancel</span>
                </div>

            </ModalWrapper>
        </>
    )
}

export default DeleteFileConfirmModal