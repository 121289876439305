import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFileReport, setOpenRModal } from '../../../reducers/filesAllocationReducer'
import { Modal as RModal } from 'rsuite';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import Button from '../../common/Button';
import { setDeduperFiles, setFileManageSection, setUnallocatedFiles } from '../../../reducers/filesReducer';

const OpenRModalComponent = () => {

    const dispatch = useDispatch()
    const { company, tab, rModalData, openRModal, fileReport } = useSelector(state => state.fileAllocation)
    const { unallocatedFiles, deduperReports } = useSelector(state => state.files)
    const { selectedCompany } = useSelector(state => state.company)
    const [selectedQuestion, setSelectedQuestion] = useState(null)
    const [forwardData, setForwardData] = useState(null)
    const [curQno, setCurQno] = useState(null)
    const Chevron = ({ className = "" }) => {
        return (
            <svg className={className} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
        )
    };

    const handleClose = () => {
        setSelectedQuestion(null)
        setForwardData(null)
        setCurQno(null)
        dispatch(setOpenRModal(false))
    }

    const handleConfirm = () => {
        if (rModalData?.from && rModalData?.from === "fileReport" && rModalData?.actionType === "move") {
            dispatch(setFileReport(forwardData))
        } else if (rModalData?.from && rModalData?.from === "deduper") {
            dispatch(setDeduperFiles(forwardData))
        } else if (rModalData?.from && rModalData?.from === "section") {
            dispatch(setFileManageSection(forwardData))
        } else {
            dispatch(setUnallocatedFiles(forwardData))
        }
        handleClose()
    }

    // const moveFile = async () => {
    //     const cut = rModalData.actionType === "move" ? true : false;
    //     const file = rModalData.fileData

    //     if (curQno == selectedQuestion || !file) return;
    //     if (selectedQuestion) {
    //         const res = await postRequest('/company/moveFile', { fileReportId: fileReport._id, file: file, unallocated: curQno === "DXXX" ? true : false, companyId: selectedCompany._id, isCut: cut, qNo: selectedQuestion, tab, oldQno: curQno, isDeduper: false }, true);
    //         if (!res || res['code'] != 200) return;
    //         let _targetSection = { ...targetSection }
    //         if (cut) {
    //             delete _targetSection[file.key]
    //             dispatch(setPreviousFileDeleteData(file));
    //         }
    //         dispatch(setTargetSection(_targetSection));

    //         showMessage(res['message']);
    //     } else {
    //         showMessage('Please select target section');
    //     }
    // };

    const cloneAndModifyDeduperResult = (deduperResult, targetKey, qNo, name) => {
        const clonedDeduperResult = JSON.parse(JSON.stringify(deduperResult)); // Deep clone to avoid modifying original

        for (const key in clonedDeduperResult) {
            if (Object.prototype.hasOwnProperty.call(clonedDeduperResult, key)) {
                const data = clonedDeduperResult[key];

                if (Array.isArray(data)) {
                    // Process array-based keys
                    const updatedEntries = data
                        .map((entry) => entry.qNo === qNo ? { ...entry, qNo, name } : null)
                        .filter(entry => entry !== null);

                    if (updatedEntries.length > 0) {
                        clonedDeduperResult[targetKey] = updatedEntries;
                    }
                } else if (typeof data === "object" && data !== null) {
                    // Process nested object structures
                    for (const subKey in data) {
                        if (Object.prototype.hasOwnProperty.call(data, subKey)) {
                            const nestedEntries = Object.values(data[subKey])
                                .map((entry) => entry.qNo === qNo ? { ...entry, qNo, name } : null)
                                .filter(entry => entry !== null);

                            if (nestedEntries.length > 0) {
                                clonedDeduperResult[targetKey] = nestedEntries;
                            }
                        }
                    }
                }
            }
        }

        return clonedDeduperResult;
    };


    const selectTargetSection = async (fileKey, qNo, name, from, index) => {
        setSelectedQuestion(qNo)
        if (from && from === "fileReport" && rModalData?.actionType === "move") {
            console.log(fileReport.importResult[fileKey].section[0], "fileReport.importResult[fileKey].section[0]")
            setCurQno(fileReport.importResult[fileKey].section[0])
            setForwardData({
                ...fileReport,
                importResult: {
                    ...fileReport.importResult,
                    [fileKey]: {
                        ...fileReport.importResult[fileKey],
                        section: [qNo],
                        originalSection: [qNo]
                    }
                }
            })
        }
        if (from && from === "unallocated" && rModalData?.actionType === "reallocate") {
            setForwardData([...unallocatedFiles].reduce((acc, current, _index) => {
                if (`${_index}` === `${rModalData.index}`) {
                    acc.push({
                        ...current,
                        sectionNo: qNo,
                        name: name
                    })
                } else {
                    acc.push(current)
                }
                return acc
            }, []))
        }
        if (from && from === "deduper") {
            const selectedDeuper = deduperReports && deduperReports.selectedDeuper && deduperReports.selectedDeuper.length ? [...deduperReports.selectedDeuper] : [];

            const modifiedDeduperResult = {
                ...deduperReports.deduperResult,
                [rModalData.key]: deduperReports.deduperResult[rModalData.key].map((item, index) => {
                    if (index === rModalData.index) {
                        selectedDeuper.push({
                            ...item,
                            curQno: item.qNo, qNo,
                            isModified: true,
                            actionType: rModalData.actionType,
                            key: item.file.key,
                            index: rModalData.index
                        });

                        return {
                            ...item,
                            ...(rModalData?.actionType === "move" ? {
                                qNo,
                                isModified: true,
                                name: name,
                                actionType: rModalData.actionType
                            } : {}),
                        };
                    }
                    return item;
                })
            };

            setForwardData({
                ...deduperReports,
                deduperResult: rModalData?.actionType === "copy" ? cloneAndModifyDeduperResult(deduperReports.deduperResult, rModalData.fileData.key, qNo, name) : modifiedDeduperResult,
                selectedDeuper
            });
        }

        if (from && from === "section") {
            const selectedFile = [...rModalData.section.files].filter((_f, _i) => _i === rModalData.index)
            const sectionData = {
                ...rModalData.section,
                files: rModalData.actionType === "move"
                    ? [...rModalData.section.files].filter((_f, _i) => _i !== rModalData.index)
                    : qNo === (rModalData.section.qNo ?? rModalData.section.qNo) ? [...rModalData.section.files, ...selectedFile] : rModalData.section.files
            }

            setForwardData({
                sectionData,
                selectFile: selectedFile,
                actionType: rModalData.actionType,
                qNo
            })
        }
    };

    const getSectionDropDown = (section, fileKey, from, index) => {
        const qNo = section.sectionNo || section.no; // Section number
        const name = section.sectionName || section.name; // Section name

        if (section.subFolders && !qNo.includes(".")) {
            // Main section with dropdown (only for top-level sections)
            return (
                <AccordionItem key={qNo}>
                    {({ open }) => (
                        <div className={"lightBorder mb0-5rem w100 jcsb cp "}>
                            {/* Dropdown Header */}
                            <AccordionHeader
                                className="df aic jcsb bg1 w100 dropdown-section-header px2rem"
                            >
                                <h3 className="c15 fw400 tal">
                                    {qNo}. {name}
                                </h3>
                                <Chevron
                                    className={`w2rem h2rem c3 td300 ml0-5rem ${!open ? "tr90deg" : "tr270deg"
                                        }`}
                                />
                            </AccordionHeader>

                            {/* Dropdown Body */}
                            <AccordionBody>
                                <div>
                                    {Object.keys(section.subFolders).map((key) => {
                                        const subFolder = section.subFolders[key];
                                        return getSectionDropDown(
                                            subFolder,
                                            fileKey,
                                            from,
                                            index
                                        );
                                    })}
                                </div>
                            </AccordionBody>
                        </div>
                    )}
                </AccordionItem>
            );
        } else if (section.subFolders) {
            return (
                <div key={`subfolder-${qNo}`}>
                    <div onClick={() => selectTargetSection(fileKey, qNo, name, from, index)} className="rs-dropdown-item">
                        {qNo}. {name}
                    </div>
                    <div style={{ paddingLeft: '36px' }}>
                        {Object.keys(section.subFolders).map((key) => {
                            const subFolder = section.subFolders[key];
                            return getSectionDropDown(
                                subFolder,
                                fileKey,
                                from,
                                index
                            );
                        })}
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    key={qNo}
                    className={`rs-dropdown-item ${(selectedQuestion && qNo === selectedQuestion) ? 'selected' : ''}`}
                    onClick={() => selectTargetSection(fileKey, qNo, name, from, index)}
                >
                    {qNo}. {name}
                </div>
            );
        }
    };

    const getModuleDropdown = (module, key, from, index) => {
        if (module?.sections?.length == 1) {
            const singleSection = module?.sections[0];
            if (singleSection.subFolders) {
                return <>
                    {Object.values(singleSection.subFolders)
                        .map(section => {
                            return getSectionDropDown(section, key, from, index);
                        })
                    }
                </>
            }
        }

        return <>
            {module?.sections && Object.values(module?.sections).map(section => {
                return (
                    getSectionDropDown(section, key, from, index)
                )
            })}
        </>
    };

    if ((company && company['modules']) || (selectedCompany && selectedCompany['modules'])) {
        return (
            <>
                <RModal overflow={true} open={openRModal} onClose={handleClose} style={{ zIndex: '2000' }}>
                    <RModal.Header>
                        <div className='df aic jcfs'>
                            {
                                ["move", "reallocate"].includes(rModalData.actionType) ? <>
                                    <img
                                        src={require("../../../../images/dataRepo/send.svg")}
                                        alt="moveFileIcon"
                                        style={{ width: '40px', height: '40px' }}
                                    />
                                    <span className={"c15 fw600 ml0-5rem fs20px"}>{"reallocate" === rModalData.actionType ? "Reallocate File" : "Move File"}</span>
                                </> : <>
                                    <img
                                        src={require("../../../../images/dataRepo/copy.svg")}
                                        alt="moveFileImg"
                                        style={{ width: '40px', height: '40px' }}
                                    />
                                    <span className={"c15 fw600 ml0-5rem fs20px"}>Copy File</span>
                                </>
                            }

                        </div>
                        <p className='c3'>{rModalData.fileName}</p>
                    </RModal.Header>
                    <RModal.Body
                        style={{
                            maxHeight: "550px",
                            paddingRight: "16px"
                        }}
                        className={"lightScrollbar"}
                    >
                        <Accordion>
                            {(() => {
                                let module = company?.modules[tab] ?? selectedCompany?.modules[tab];
                                if (module) module = JSON.parse(JSON.stringify(module));
                                return getModuleDropdown(module, rModalData.key, rModalData.from, rModalData.index);
                            })()}
                        </Accordion>
                    </RModal.Body>
                    <div className={"w100 tac"}>
                        <Button
                            text={rModalData.actionType === "copy" ? "Copy File" : "Move File"}
                            btnType={"warning"}
                            onClick={handleConfirm}
                            customClassName={"mla mra c1"}
                            disabled={selectedQuestion === null}
                        />
                    </div>
                </RModal>
            </>
        )
    } else {
        return (<></>)
    }
}

export default OpenRModalComponent