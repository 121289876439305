import { Link } from "react-router-dom";
import Modal from '@mui/material/Modal';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { toggleModal } from "../reducers/modalReducer";


export default function TakeTheTest({ readyFor }) {
	const dispatch = useDispatch();
	const user = useSelector(state => state.user);
	const modal = useSelector(state => state.modal);

	const [test, setTest] = useState();
	const array = readyFor ? [readyFor] : ["Investment", "Growth", "Exit"];

	useEffect(() => {
		let test = window.location.href.split('#')[1];
		if (test && !test.includes('fit-report')) {
			if (array.length > 0 && array.filter(item => item == test)) welcome2MinuteTest(test);
		}
	}, []);

	const handleLetsGo = () => {
		localStorage.setItem('testType', test.toLowerCase());
		dispatch(toggleModal({ open: 'addCompany' }));
	};

	const welcome2MinuteTest = async (test) => {
		await dispatch(toggleModal({ open: 'welcome2MinuteTest' }));
		setTest(test);
	};

	return (
		<div id="takeTheTest" className="bg-gradient c1">
			<div className="container py5rem">
				<div className={"df jcc gg2rem ftcr" + array.length}>
					{array.map((test, idx) => <div key={idx} className="h100 df fdc jcsb aic">
						<h2 className="fs1-5rem mb1rem tac lh1">How {test} ready are you?</h2>
						<p className="tac fs1-25rem mb1rem">
							Take the <b>FREE</b> 2-minute Diligentsia test and let's see!<br />
						</p>
						<Link to={`#${test}`} onClick={() => welcome2MinuteTest(test)} className="shadow fs1-25rem cp td250 bg5 c6 c1-hover bg3-hover fw600 br5px py0-5rem px1rem">
							To {test} Ready Test
						</Link>
					</div>)}
				</div>
				<div className="mt3rem df fdc jcc maw500px mxa">
					<h4 className="fs1-25rem fs1-5rem-sm mb2rem tac lh1">What You Get</h4>
					{["Answer a number of bespoke multiple choice questions", "A report indicating how ready your company is, including risk score",
						"Recommendations for improvement", "An AI-enhanced set of KPIs specific to your business"].map((text, idx) => (
							<div key={idx} className="df aic mb1rem fs1rem">
								<svg className="h2rem fds ass f4 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
									<path className="fa-primary" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z" />
								</svg>
								<span className="w100">{text}</span>
							</div>
						))}
					<Link onClick={() => localStorage.removeItem('registerAs')} to="/register" className="mt2rem tac c4-hover td250 fs1rem cp">Skip test, just sign me up please</Link>
				</div>
			</div>

			{/* Welcome 2 Minute Test */}
			{/* <Modal id="welcome2MinuteTestModal" open={modal['open'] == 'welcome2MinuteTest'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic">
				<div className="mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw800px pr pt5rem br5px pr">
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<img className="h2rem pa t0 l0 m1rem" src={require(`../../images/logo.svg`)} alt="TODO" />
					<h1 className="text-gradient fw600 pb1rem">Welcome to your FREE 2-minute test</h1>
					<div className="tac c2">
						<p>Hello, {user['firstName']}! 🎉</p>
						<p>Welcome aboard the Diligentsia 2-Minute Test rollercoaster! 🚀​</p>
						<p>Prepare yourself for a thrilling ride designed to propel your business towards success. Here's how to unlock the ultimate experience:​ </p>
						<p>💼 <b>Real Company Name</b>: The first key to this adventure is authenticity. Use your real company name to enable our AI to navigate through the cosmos of data and deliver insights tailored just for you.​</p>
						<p>⏱️ <b>2-Minute Test</b>: Brace yourself for a whirlwind of 29 multiple-choice questions spanning across various facets of your business. This won't just test your mettle, but also illuminate the path to progress!​</p>
						<p>📊 <b>Test Report</b>: As you cross the finish line, a tailored Test Report awaits you. This treasure maps out your business's readiness for its {test} ready test.​</p>
						<p>🎁 <b>Bonus FREE KPI Report</b>: But that's not all! You'll also receive a bonus KPI Report, suggesting 10 key performance indicators handpicked for your unique business activities.​</p>
					
						<p>🔐 Your answers are securely stored in our vault, maintaining utmost <b>confidentiality</b>. Your data is your treasure, and we don't sell treasures!​</p>
						<p>So, strap in and let's rocket towards success! 🚀🌟"​</p>
					</div>
					<div className="df jcc"><button onClick={() => handleLetsGo()} className="shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fw600 usn cp ttu w40 bsbb tac df jcc aic mt2rem">Let’s Go!</button></div>
				</div>
			</Modal> */}
		</div>
	);
}
