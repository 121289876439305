import Modal from '@mui/material/Modal';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ChecklistSetup from "./checklistSetup.js";
import LogoPermission from "./logoPermission.js";
import SectionCreation from "./sectionCreation.js";
import QuestionCreation from "./questionCreation.js";
import ChecklistFeature from "./checklistFeature.js";
import ChecklistActivation from "./checklistActivation.js";
import { toggleModal } from "../../reducers/modalReducer.js";
import { getRequest, postRequest, showMessage } from '../../config.js';


export default function CreateChecklist({ }) {

    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const modal = useSelector(state => state.modal);

    let [stepTab, setStepTab] = useState(1);
    let [moduleId, setModuleId] = useState();
    let [sections, setSections] = useState([]);
    let [companyId, setCompanyId] = useState();
    let [isDisabled, setIsDisabled] = useState(true);
    let [checklistObj, setChecklistObj] = useState({});
    let [allCompaines, setAllCompaines] = useState([]);
    let [isAIChecklist, setIsAIChecklist] = useState(true);
    let tabs = { 1: 'Checklist Setup', 2: 'Choose Checklist Features', 3: 'Section Creation', 4: 'Question Creation', 5: 'Logo Upload & Permissions', 6: 'Checklist Activation' };

    const popupMessage = {
        1: {
            'generic': 'Creating your checklist',
        },
        2: {
            'manual': 'Setting up checklist features',
            'automatic': 'Generating checklist sections',
        },
        3: {
            'manual': 'Adding checklist sections',
            'automatic': 'Generating checklist questions',
        },
        4: {
            'generic': 'Updating checklist questions',
        },
        5: {
            'generic': 'Updating checklist logo & permissions',
        },
        6: {
            'generic': 'Updating checklist activation',
        },
    }

    useEffect(() => {
        getChecklistData();
        if (location?.search) companyId = (location.search.split('=')[1]).split(';')[0];
        if (companyId) setCompanyId(companyId);
    }, []);

    const getChecklistData = async () => {
        if (location?.hash) moduleId = location.hash.split('#')[1];
        if (moduleId) {
            setModuleId(moduleId);
            const res = await getRequest(`/checklist/get/${moduleId}`);
            // console.log("*********getChecklistData::", res);
            if (res['code'] == 200) setChecklistData(res['body']);
        }
    };

    const setChecklistData = async (data) => {
        if (data?.['sections'] && data['sections'].length > 0) setSections(data['sections']);
        if (data?.['allCompaines']) {
            if (companyId) {
                let company = data?.['allCompaines'].filter(({ _id }) => _id == companyId);
                let companies = data?.['allCompaines'].filter(({ _id }) => _id != companyId);
                allCompaines = company.concat(companies);
                setAllCompaines(allCompaines);
            }
            else setAllCompaines(data?.['allCompaines']);
        }
        if (data?.['module']) setChecklistObj(data['module']);
        setIsAIChecklist(data?.['module']?.['genType'] == 'automatic' ? true : false);
        dispatch(toggleModal({ open: '' }));
        if (data['module']['step']) setTimeout(() => setStepTab(data['module']['step']), 100);
    };

    const handleCreateChecklist = async (data) => {
        dispatch(toggleModal({ open: 'checklistPopup' }));
        // if (checklistObj['step'] == 3 && checklistObj['sections'].length > 2) stepTab = 1;
        if (checklistObj['step'] == 3) checklistObj['sections'] = data;
        const res = await postRequest(`/checklist/create/${stepTab}`, checklistObj);
        if (res?.['code'] != 200) dispatch(toggleModal({ open: '' }));
        if (res?.['code'] == 200) {
            let checklistId = res['body']?.['module']?.['_id'];
            if (checklistId) {
                let path = location.href.split('?')[1];
                if (location.href.includes('#')) window.location.href = `/createChecklist?${path}`
                else window.location.href = `/createChecklist?${path}#${checklistId}`
                if (res['code'] == 200) getChecklistData();
                else dispatch(toggleModal({ open: '' }));
            }
        }
    };

    const handleSidebar = async (idx) => {
        if (checklistObj?.['step'] >= (idx + 1)) setStepTab(idx + 1);
        else showMessage('Please fill required fields and click (Ok, next) button');
    };

    return (
        <>
            <div className='checklist-builder-page mt8rem'>
                <div className='top-header-area df aic w100 jcc mb1rem px5rem bsbb'><h2 className='c15 fs1-75rem lh2'><span className='c4'>AnyList </span>Checklist Builder</h2></div>
                <div className='checklist-setup df fww w100 bsbb h100'>
                    <div className='w80 left-setup-area bsbb py2rem px5rem pr' style={{ border: '1px solid #82828234', borderLeft: 'none', borderBottom: 'none' }}>
                        <div className='heading-setup df aic w100 mb1rem fww'>
                            {stepTab > 1 &&
                                <span onClick={() => setStepTab(stepTab - 1)}>
                                    <svg className='cp mr1rem' width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.2786 0.40705C7.40543 0.279703 7.55715 0.178129 7.7249 0.10825C7.89264 0.0383717 8.07306 0.00158847 8.25563 4.92302e-05C8.43819 -0.0014881 8.61924 0.0322472 8.78821 0.0992886C8.95719 0.16633 9.1107 0.265335 9.2398 0.390524C9.36889 0.515715 9.47098 0.664585 9.54012 0.828446C9.60925 0.992308 9.64404 1.16788 9.64245 1.34492C9.64087 1.52196 9.60294 1.69692 9.53088 1.85959C9.45882 2.02226 9.35407 2.16939 9.22275 2.29239L4.6951 6.68305L20.6251 6.68305C20.9897 6.68305 21.3394 6.82353 21.5973 7.07358C21.8551 7.32362 22 7.66276 22 8.01638C22 8.37001 21.8551 8.70914 21.5973 8.95919C21.3394 9.20924 20.9897 9.34972 20.6251 9.34972L4.6951 9.34972L9.22275 13.7404C9.47321 13.9919 9.61179 14.3287 9.60866 14.6783C9.60553 15.0278 9.46093 15.3623 9.20601 15.6095C8.95108 15.8567 8.60623 15.9969 8.24573 15.9999C7.88523 16.003 7.53792 15.8686 7.2786 15.6257L0.403938 8.95905C0.275896 8.8352 0.174307 8.68806 0.104993 8.52607C0.0356788 8.36409 -3.41367e-07 8.19043 -3.49033e-07 8.01505C-3.56699e-07 7.83967 0.0356787 7.66602 0.104993 7.50403C0.174307 7.34204 0.275896 7.19491 0.403938 7.07105L7.2786 0.40705Z" fill="#151313" />
                                    </svg>
                                </span>
                            }
                            <div className='steps c15 fs1-25rem mr1rem'>
                                <span>{stepTab}</span> / <span>6</span>
                            </div>
                            <h3 className='c15 fs2rem lh2'>{tabs[stepTab]}</h3>
                            {(stepTab > 1 && checklistObj?.['moduleName']) && <h5 className='fs1-25rem fw400 mt0-5rem c3 db w100 '>{checklistObj['moduleName']} <span className='fs1rem c15 fsi ttc' style={{ color: '#15131330' }}>({checklistObj['genType']})</span></h5>}
                        </div>
                        {/* Checklist Setup */}
                        {tabs[stepTab] == 'Checklist Setup' && <>
                            <ChecklistSetup isDisabled={isDisabled} setIsDisabled={setIsDisabled} isAIChecklist={isAIChecklist} setIsAIChecklist={setIsAIChecklist} checklistObj={checklistObj} setChecklistObj={setChecklistObj} handleCreateChecklist={handleCreateChecklist} />
                        </>}
                        {/* Choose Checklist Features */}
                        {tabs[stepTab] == 'Choose Checklist Features' && <>
                            <ChecklistFeature stepTab={stepTab} allCompaines={allCompaines} isAIChecklist={isAIChecklist} setIsAIChecklist={setIsAIChecklist} checklistObj={checklistObj} setChecklistObj={setChecklistObj} handleCreateChecklist={handleCreateChecklist} />
                        </>}
                        {/* Section Creation */}
                        {tabs[stepTab] == 'Section Creation' && <>
                            <SectionCreation isAIChecklist={isAIChecklist} setIsAIChecklist={setIsAIChecklist} sectionsData={sections} checklistObj={checklistObj} setChecklistObj={setChecklistObj} handleCreateChecklist={handleCreateChecklist} />
                        </>}
                        {/* Question Creation */}
                        {tabs[stepTab] == 'Question Creation' && <>
                            <QuestionCreation sections={sections} setSections={setSections} checklistObj={checklistObj} handleCreateChecklist={handleCreateChecklist} getChecklistData={getChecklistData} />
                        </>}
                        {/* Logo Upload & Permissions */}
                        {tabs[stepTab] == 'Logo Upload & Permissions' && <>
                            <LogoPermission allCompaines={allCompaines} checklistObj={checklistObj} setChecklistObj={setChecklistObj} handleCreateChecklist={handleCreateChecklist} />
                        </>}
                        {/* Checklist Activation */}
                        {tabs[stepTab] == 'Checklist Activation' && <>
                            <ChecklistActivation checklistObj={checklistObj} setChecklistObj={setChecklistObj} handleCreateChecklist={handleCreateChecklist} />
                        </>}
                    </div>
                    <div className='w20 right-step-area py2rem pl2rem pr1rem bsbb pr' style={{ border: '1px solid #82828234', borderRight: 'none', borderBottom: 'none', backgroundColor: '#F9F9FF' }}>
                        <div className='top-area h40vh'>
                            {isAIChecklist
                                ? <>
                                    {['Choose Checklist Features', 'Section Creation', 'Logo Upload & Permissions', 'Question Creation', 'Checklist Activation'].includes(tabs[stepTab]) &&
                                        <div className='pr mt1rem'>
                                            <div className='df aic pr' style={{ marginRight: '-34px', marginLeft: '-71px' }}>
                                                {tabs[stepTab] == 'Choose Checklist Features' && <img className='w100' src={require('../../../images/aisidebar-1.png')} alt="" />}
                                                {tabs[stepTab] == 'Section Creation' && <img className='w100' src={require('../../../images/aisidebar-3.png')} alt="" />}
                                                {tabs[stepTab] == 'Logo Upload & Permissions' && <img className='w100' src={require('../../../images/aisidebar-4.png')} alt="" />}
                                                {tabs[stepTab] == 'Question Creation' && <img className='w100' src={require('../../../images/aisidebar-6.png')} alt="" />}
                                                {tabs[stepTab] == 'Checklist Activation' && <img className='w100' src={require('../../../images/aisidebar-8.png')} alt="" />}
                                            </div>
                                        </div>
                                    }</>
                                : <>
                                    {['Choose Checklist Features', 'Section Creation', 'Logo Upload & Permissions', 'Question Creation',].includes(tabs[stepTab]) &&
                                        <div className='pr mt3rem'>
                                            <div className='df aic pr'>
                                                <i className='icon mr1rem pr' style={{ top: '5px' }}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="12" cy="12" r="12" transform="matrix(1 0 0 -1 0 24)" fill="#59B8FD" />
                                                        <path d="M10.8064 9.79219C10.8126 9.38281 11.147 9.05469 11.5564 9.05469C11.9657 9.05469 12.3001 9.38594 12.3064 9.79219L12.5564 16.5297C12.572 16.7859 12.4782 17.0359 12.297 17.2297C12.1032 17.4359 11.8345 17.5547 11.5564 17.5547C11.2782 17.5547 11.0095 17.4359 10.8157 17.2297C10.6345 17.0359 10.5407 16.7859 10.5564 16.5297L10.8064 9.79219Z" fill="white" />
                                                        <path d="M11.5547 5.55469C12.107 5.55469 12.5547 6.0024 12.5547 6.55469C12.5547 7.10697 12.107 7.55469 11.5547 7.55469C11.0024 7.55469 10.5547 7.10697 10.5547 6.55469C10.5547 6.0024 11.0024 5.55469 11.5547 5.55469Z" fill="white" />
                                                    </svg>
                                                </i>
                                                {tabs[stepTab] == 'Choose Checklist Features' && <p className='c15 fs1rem'>Expand fields to fully view<br />details.</p>}
                                                {tabs[stepTab] == 'Logo Upload & Permissions' && <p className='c15 fs1rem'>Please use high quality logo</p>}
                                                {(tabs[stepTab] == 'Section Creation' || tabs[stepTab] == 'Question Creation') && <p className='c15 fs1rem'>Expand fields to fully view details. Overwrite to add your questions.</p>}
                                            </div>
                                            {tabs[stepTab] == 'Choose Checklist Features' &&
                                                <span className='bottom-area ml1rem'>
                                                    <svg width="28" height="45" viewBox="0 0 28 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M26.5 37V38H27.5V37H26.5ZM0.292892 36.2929C-0.0976315 36.6834 -0.0976315 37.3166 0.292892 37.7071L6.65685 44.0711C7.04738 44.4616 7.68054 44.4616 8.07107 44.0711C8.46159 43.6805 8.46159 43.0474 8.07107 42.6569L2.41421 37L8.07107 31.3431C8.46159 30.9526 8.46159 30.3195 8.07107 29.9289C7.68054 29.5384 7.04738 29.5384 6.65685 29.9289L0.292892 36.2929ZM25.5 0L25.5 37H27.5L27.5 0H25.5ZM26.5 36H1V38H26.5V36Z" fill="#777676" />
                                                    </svg>
                                                </span>
                                            }
                                        </div>
                                    }</>}
                        </div>
                        <div className='bottom-checklist pb2rem h50vh df fww ' style={{ width: '85%', alignContent: 'flex-end' }}>
                            {/* Add class active and complete */}
                            {Object.values(tabs).map((item, idx) => {
                                return <button onClick={() => handleSidebar(idx)} className={`df aic checklist-btn bo20 bw1px bss br15px bg0 w100 tal mb0-5rem bsbb cp ${stepTab == (idx + 1) && ' active'}`}>
                                    {(item == 'Checklist Activation' && checklistObj['isActive'])
                                        ? <img className="number-area bo20 bw2px bss tac br100 w2rem h2rem pr" src={require('../../../images/confetti.png')} alt="" />
                                        : <>{checklistObj?.['step'] > (idx + 1)
                                            ? <img className="number-area bo20 bw2px bss tac br100 w2rem h2rem pr" src={require('../../../images/step-completed-icon.png')} alt="" />
                                            : <i className='number-area bo20 bw2px bss tac br100 w2rem h2rem pr'>{idx + 1}</i>
                                        }</>
                                    } {item}
                                </button>
                            })}
                        </div>
                    </div>
                </div>
            </div>

            {/* Checklist Popup */}
            <Modal id="checklistPopup" open={modal['open'] == 'checklistPopup'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw  df jcc aic advisor-dashboard-seemore">
                <div className="poppins-font-style mah100 bg1 shadow w100 py2rem px4rem df fdc m1rem pr br10px pr" style={{ outline: 'none', paddingBottom: '2rem', maxWidth: '750px' }}>
                    <div className='top-logo pa t0 r0 mr1rem mt1rem'><img className='w4rem' src={require('../../../images/favicon.png')} alt="" /></div>
                    <div className='content-area creaing-modal pr mb3rem'>
                        <div className='df jcc'><img className="w10rem" src={require('../../../images/wizard.gif')} alt="" /></div>
                        <div className='text-box w70 df jcc tac fdc mla mra'>
                            <h3 className='fs1-5rem c15 mb1rem'>{popupMessage?.[stepTab]?.[checklistObj?.['genType']] || popupMessage?.[stepTab]?.['generic']}</h3>
                            <div className="my1rem tac">
                                <div className="checklist-progress"><div className={`checklist-progress-bar ${(isAIChecklist && stepTab == 2) && ` sections-progress-bar`} ${(isAIChecklist && stepTab == 3) && ` questions-progress-bar`}`}></div></div>
                            </div>
                            <p className='c15 fs1rem mt1rem'>This may take couple of minutes</p>
                        </div>
                        {(isAIChecklist && stepTab == 3) &&
                            <div className='img-box pa' style={{ bottom: '10px', right: '-400px' }}>
                                <img className="" src={require(`../../../images/checklist-popup-step3.png`)} alt="" />
                            </div>
                        }
                    </div>
                    <svg className="pa cp" onClick={() => dispatch(toggleModal({ open: '' }))} style={{ left: '50%', transform: 'translateX(-50%)', bottom: '-80px' }} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.8" x="4" y="4" width="52" height="52" rx="26" fill="#151313" />
                        <path d="M48.5626 11.4375C44.8913 7.76665 40.214 5.26685 35.1221 4.2542C30.0302 3.24156 24.7523 3.76155 19.9559 5.74843C15.1595 7.73531 11.06 11.0998 8.17569 15.4166C5.29142 19.7333 3.75195 24.8084 3.75195 30C3.75195 35.1917 5.29142 40.2667 8.17569 44.5835C11.06 48.9002 15.1595 52.2647 19.9559 54.2516C24.7523 56.2385 30.0302 56.7585 35.1221 55.7458C40.214 54.7332 44.8913 52.2334 48.5626 48.5625C51.0004 46.1249 52.9342 43.231 54.2535 40.0461C55.5729 36.8611 56.252 33.4474 56.252 30C56.252 26.5526 55.5729 23.1389 54.2535 19.954C52.9342 16.769 51.0004 13.8751 48.5626 11.4375ZM39.3751 36.6375C39.5624 36.8129 39.7117 37.0249 39.8138 37.2603C39.9159 37.4958 39.9686 37.7496 39.9686 38.0063C39.9686 38.2629 39.9159 38.5168 39.8138 38.7522C39.7117 38.9876 39.5624 39.1996 39.3751 39.375C39.1999 39.5488 38.9921 39.6863 38.7636 39.7796C38.5352 39.8729 38.2906 39.9202 38.0438 39.9188C37.5519 39.9167 37.0805 39.7214 36.7313 39.375L30.0001 32.6438L23.3626 39.375C23.0133 39.7214 22.5419 39.9167 22.0501 39.9188C21.8033 39.9202 21.5587 39.8729 21.3302 39.7796C21.1018 39.6863 20.894 39.5488 20.7188 39.375C20.3696 39.0237 20.1736 38.5485 20.1736 38.0531C20.1736 37.5578 20.3696 37.0826 20.7188 36.7313L27.3563 30L20.7188 23.3625C20.4116 23.0038 20.2511 22.5424 20.2694 22.0705C20.2876 21.5986 20.4832 21.151 20.8171 20.8171C21.1511 20.4832 21.5987 20.2875 22.0706 20.2693C22.5425 20.2511 23.0039 20.4116 23.3626 20.7188L30.0001 27.3563L36.6376 20.7188C36.8111 20.539 37.0184 20.3952 37.2476 20.2956C37.4768 20.196 37.7233 20.1424 37.9732 20.1381C38.223 20.1337 38.4713 20.1786 38.7038 20.2702C38.9363 20.3618 39.1484 20.4983 39.3282 20.6719C39.5079 20.8455 39.6517 21.0528 39.7514 21.2819C39.851 21.5111 39.9045 21.7576 39.9089 22.0075C39.9132 22.2573 39.8683 22.5056 39.7767 22.7381C39.6851 22.9706 39.5486 23.1828 39.3751 23.3625L32.6438 30L39.3751 36.6375Z" fill="white" />
                    </svg>
                </div>
            </Modal>
        </>
    )
}