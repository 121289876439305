
import moment from "moment";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Switch from '@mui/material/Switch';
import { useEffect, useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';


export default function BenchmarkReport({ user, company }) {

	let [benchmark, setBenchmark] = useState(false);
	let [selectedModule, setSelectedModule] = useState('repository');

	const downloadPDF = async () => {
		const input = document.getElementById('rootElementId');
		html2canvas(input)
			.then((canvas) => {
				const imgData = canvas.toDataURL('image/png');
				const imgWidth = 600;
				const pageHeight = 841.88976378;
				const imgHeight = (canvas.height * imgWidth) / canvas.width;
				let heightLeft = imgHeight;
				let position = 0;
				heightLeft -= pageHeight;
				const pdf = new jsPDF({ orientation: 'p', unit: 'pt', format: [600, 841.88976378] });
				pdf.addImage(canvas, 'JPEG', 0, position, imgWidth, imgHeight, '', 'FAST');
				while (heightLeft >= 0) {
					position = heightLeft - imgHeight;
					pdf.addPage();
					pdf.addImage(canvas, 'JPEG', 0, position, imgWidth, imgHeight, '', 'FAST');
					heightLeft -= pageHeight;
				}
				pdf.save(`Benchmark-Report.pdf`);
			});
	};

	const benchmarkReportTable = () => {

		let module = {};
		let moduleRisk, moduleCompleteness;
		let moduleRistPrecentage, moduleCompletenessPrecentage;
		if (benchmark) module = company?.['myBenchmark']?.['modules']?.[selectedModule];
		else module = company?.['overallBenchmark']?.['modules']?.[selectedModule];

		if (module) {
			moduleRisk = module['stats']['riskPercent'];
			moduleCompleteness = module['stats']['completionPercent'];

			moduleRistPrecentage = moduleRisk + 100 / 2;
			moduleCompletenessPrecentage = moduleCompleteness + 100 / 2;
		}

		return (
			<table id="table" className="table-company-log w100">
				<thead className="ps t0">
					<tr>
						<th>Sections</th>
						<th className="ttc">{selectedModule.replaceAll('-', ' ')}</th>
					</tr>
				</thead>
				<tbody>
					{module ? <>
						{module['sections'] && Object.keys(module['sections']).map(section => {
							let risk = module['sections'][section]['riskPercent'];
							let completeness = module['sections'][section]['completionPercent'];

							let ristPrecentage = risk + 100 / 2;
							let completenessPrecentage = completeness + 100 / 2;

							return <tr>
								<td>{section}</td>
								<td>
									<div className="df jcse">
										<div className="progress-box-vertical">
											<div className="progress progress-bar-vertical">
												<div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${completenessPrecentage}%` }}>
													<span className="precentage-block"><i>{completeness.toFixed(0)}</i>
														<span className="fs0-75rem mb0-5rem arrow-right">
															{completenessPrecentage < 35 ? 'Below Average'
																: completenessPrecentage > 70 ? 'Above Average'
																	: 'Average'}
														</span>
													</span>
												</div>
											</div>
											<span style={{ marginBottom: '0', fontSize: '8px', bottom: '-7px' }} className="text-center-area">Completeness</span>
										</div>
										{selectedModule !== 'repository' &&
											<div className="progress-box-vertical style-two">
												<div className="progress progress-bar-vertical style-two">
													<div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${ristPrecentage}%` }}>
														<span className="precentage-block"><i>{risk.toFixed(0)}</i>
															<span className="fs0-75rem mb0-5rem arrow-right">
																{ristPrecentage < 35 ? 'Below Average'
																	: ristPrecentage > 70 ? 'Above Average'
																		: 'Average'}
															</span>
														</span>
													</div>
												</div>
												<span style={{ marginBottom: '0', fontSize: '8px', bottom: '-7px' }} className="text-center-area">Risk</span>
											</div>
										}
									</div>
								</td>
							</tr>
						})}
						<tr>
							<td>Average</td>
							<td><div className="df jcse">
								<div className="progress-box-vertical">
									<div className="progress progress-bar-vertical">
										<div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${moduleCompletenessPrecentage}%` }}>
											<span className="precentage-block"><i>{moduleCompleteness.toFixed(0)}</i>
												<span className="fs0-75rem mb0-5rem arrow-right">
													{moduleCompletenessPrecentage < 35 ? 'Below Average'
														: moduleCompletenessPrecentage > 70 ? 'Above Average'
															: 'Average'}
												</span>
											</span>
										</div>
									</div>
									<span style={{ marginBottom: '0', fontSize: '8px', bottom: '-7px' }} className="text-center-area">Completeness</span>
								</div>
								{selectedModule !== 'repository' &&
									<div className="progress-box-vertical style-two">
										<div className="progress progress-bar-vertical style-two">
											<div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${moduleRistPrecentage}%` }}>
												<span className="precentage-block"><i>{moduleRisk.toFixed(0)}</i>
													<span className="fs0-75rem mb0-5rem arrow-right">
														{moduleRistPrecentage < 35 ? 'Below Average'
															: moduleRistPrecentage > 70 ? 'Above Average'
																: 'Average'}
													</span>
												</span>
											</div>
										</div>
										<span style={{ marginBottom: '0', fontSize: '8px', bottom: '-7px' }} className="text-center-area">Risk</span>
									</div>}
							</div></td>
						</tr>
					</>
						: <tr className="pa fs1-25rem fw600 mt1rem" style={{ left: '35%' }}>None of your other companies are eligible for benchmark</tr>
					}
				</tbody>
			</table>
		)
	};

	return (
		<div className="container bg1 py2rem mih70vh company-audit-page">
			<div id="rootElementId">
				<div className="report-start">
					<div className="logo-report mb1rem df jcsb aic w100">
						<img className="h2rem" src={require(`../../../../images/Dg-logo.png`)} alt="TODO" />
						<button onClick={() => downloadPDF()} className="h100 my0-5rem shadow bg3 bg4-hover c1 td250 br5px px1rem fs0-75rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
							<svg className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path className="fa-primary" d="M424.1 320.1l-88 88C334.4 411.5 328.5 416 320 416s-14.4-4.467-16.97-7.031l-88-88c-9.375-9.375-9.375-24.56 0-33.94s24.56-9.375 33.94 0L296 334.1V184C296 170.8 306.8 160 320 160s24 10.75 24 24v150.1l47.03-47.03c9.375-9.375 24.56-9.375 33.94 0S434.3 311.6 424.1 320.1z" /><path className="fa-secondary" d="M537.6 226.6C541.7 215.9 544 204.2 544 192c0-53-43-96-96-96c-19.69 0-38.09 6-53.31 16.2C367 64.2 315.3 32 256 32C167.6 32 96 103.6 96 192c0 2.703 .1094 5.445 .2031 8.133C40.2 219.8 0 273.2 0 336C0 415.5 64.5 480 144 480H512c70.69 0 128-57.3 128-128C640 290.1 596 238.4 537.6 226.6zM424.1 320.1l-88 88C334.4 411.5 328.5 416 320 416s-14.4-4.467-16.97-7.031l-88-88c-9.375-9.375-9.375-24.56 0-33.94s24.56-9.375 33.94 0L296 334.1V184C296 170.8 306.8 160 320 160s24 10.75 24 24v150.1l47.03-47.03c9.375-9.375 24.56-9.375 33.94 0S434.3 311.6 424.1 320.1z" /></svg>
							Download PDF
						</button>
					</div>
					<div className="dg gtcr2a gg2rem mb1-3rem">
						<div className="h100 df fdc jcsb">
							<div>
								<p className="ass fs1rem mb0-5rem">Company Name</p>
								<h2 className="fs1rem fw700 lh1 ttu usn c6">{company['cName']}</h2>
								<p className="ass fs1rem mt0-5rem mb0-5rem">Test Type</p>
								<h2 className="fs1rem fw700 lh1 ttu usn c6">{company['testType']}</h2>
							</div>
						</div>
						<div>
							<ul className="right-list">
								<li>Subscription <span>{((company?.['creatorId']?.['createdBy']?.['role'] == 'Advisor' || company?.['creatorId']?.['createdBy']?.['role'] == 'Investor') && company['subscription'] == 'Pro')
									? 'Portfolio PRO'
									: <>{company['subscription']?.toUpperCase()}</>}
								</span></li>
								<li>Company Reg <span>{company['ukRegNo']}</span></li>
								<li>Issue Date <span>{moment(company['updatedAt']).format("MMM Do YYYY")}</span></li>
								<li>Generated By <span>{company['creatorId']['email']}</span></li>
							</ul>
						</div>
					</div>
					<div className="heading-area mb2rem tac df fdc jcc aic">
						<h2 className="w200 c6 lh2 fs1-25rem" style={{ borderBottom: '3px solid #002d63' }}>Benchmarking Report</h2>
					</div>
				</div>
				<Dropdown className="dropdown-add-group w100">
					<Dropdown.Toggle variant="success" id="dropdown-basic" className='dropdown-btn cp pl5rem shadow bg5 w95 br5px p1rem pr'>
						{(selectedModule.replaceAll('-', ' '))}
						<span className="icon-right pa r0 mr1rem">
							<i className="icon">
								<svg className="ml0-5rem w1rem h1rem f6 f6-xl" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-secondary" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z" />
								</svg>
							</i>
						</span>
						<svg className="center-vertically f3 pa l0 ml1rem w2rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
							<path className="fa-primary" d="M608 352h-96c-17.62 0-32 14.38-32 32v96c0 17.62 14.38 32 32 32h96c17.62 0 32-14.38 32-32v-96C640 366.4 625.6 352 608 352zM128 352H32c-17.62 0-32 14.38-32 32v96c0 17.62 14.38 32 32 32h96c17.62 0 32-14.38 32-32v-96C160 366.4 145.6 352 128 352zM368 352h-96c-17.62 0-32 14.38-32 32v96c0 17.62 14.38 32 32 32h96c17.62 0 32-14.38 32-32v-96C400 366.4 385.6 352 368 352zM384 0H256C238.4 0 224 14.38 224 32v96c0 17.62 14.38 32 32 32h128c17.62 0 32-14.38 32-32V32C416 14.38 401.6 0 384 0z" />
							<path className="fa-secondary" d="M584 262.4V352h-48V272h-192V352h-48V272h-192V352h-48V262.4C56 241.3 73.25 224 94.38 224H296V160h48v64h201.6C566.8 224 584 241.3 584 262.4z" />
						</svg>
					</Dropdown.Toggle>
					<Dropdown.Menu className="dropdown-content cp shadow fww">
						{Object.keys(company?.['overallBenchmark']?.['modules']).map(module => {
							return <Dropdown.Item onClick={() => setSelectedModule(module)}>
								<div className="item fs1rem m0-5rem mb1rem cp bg5">
									<div className="deta-dropdown-company df jcsb aic">
										<h4 className='fs1-25rem ttc'>{module.replaceAll('-', ' ')}</h4>
									</div>
								</div>
							</Dropdown.Item>
						})}
					</Dropdown.Menu>
				</Dropdown>

				<div className="df fdc jcc tac">
					<Switch className="" defaultChecked={benchmark} onChange={(e) => setBenchmark(e.target.checked)} />
					<label className="fs0-75rem fw600" >{benchmark ? 'Client Portfolio Benchmark' : 'Overall Benchmark'}</label>
				</div>

				{/* Benchmark Report Table */}
				{benchmarkReportTable()}
			</div>
		</div>
	);
};
