import React from 'react'
import ModalWrapper from '../../common/Modal/ModalWrapper'
import FileManager from "../../../../images/dataRepo/deduper.svg"

const deduperReportWaiting = ({
    modalType,
    onClickDeduperAuditLog,
    company
}) => {

    return (
        <>
            <ModalWrapper
                modalType={modalType}
                modalCardClasses={"maw1000px"}
            >
                <div className='df jcsb pr'>
                    <div className='df jcfs aic'>
                        <h2 className="c15 fw600 tal df aic mb0-5rem fs1-25em">
                            <FileManager className="mr1rem" /> Deduper
                        </h2>
                    </div>
                    <button
                        onClick={onClickDeduperAuditLog}
                        className='cp tac c1 bg5 c15 br20px px1-5rem py0-5rem fw500'>
                        Log
                    </button>
                </div>

                <h1 className="c15 fw600 tac mb1rem fs1-5em">{company.cName}</h1>
                <h2 className="c4 fs1-25rem-sm ttu w100 tac mb2rem">Diligentsia DeDuper Assistant</h2>

                <div className="tac">
                    <p className='c15'><b>Deduper Magic:</b> We're now summoning the power of AI to checking duplication of files! <br />Please stay with us during this magical incantation…</p>
                    <img className="h9rem" src={require(`../../../../images/wizard.gif`)} alt="" />
                </div>

            </ModalWrapper>

        </>
    )
}

export default deduperReportWaiting