import React, { useEffect } from 'react'
import ModalWrapper from '../../common/Modal/ModalWrapper'
import FileManager from "../../../../images/dataRepo/file_manager.svg"
import { toggleModal } from '../../../reducers/modalReducer';
import { AppMode } from '../../../env';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Delete from "../../../../images/dataRepo/delete.svg"
import Copy from "../../../../images/dataRepo/copy.svg"
import Downloads from "../../../../images/dataRepo/downloads.svg"
import Send from "../../../../images/dataRepo/send.svg"
import { setFileManageSection } from '../../../reducers/filesReducer';
import { postRequest, showMessage } from '../../../config';

const ManageFilesModal = ({
    modalType,
    section,
    company,
    setSection,
    getViewableURL,
    handleEditDispatch,
    setDeleteFileQuestionId,
    setDeleteFile,
    tab,
    targetSection,
    reloadModule
}) => {

    const dispatch = useDispatch()
    const { fileManageSection } = useSelector((state) => state.files)

    const moveFile = async ({ file, qNo, curQno, isCut, sectionName }) => {
        const res = await postRequest('/company/moveFile', { file, unallocated: false, companyId: company._id, isCut, qNo, tab, oldQno: curQno, isDeduper: false, key: file.key, sectionName });
        showMessage(res['message']);
        reloadModule(tab, false, false, true)
    };

    useEffect(() => {
        if (Object.keys(fileManageSection).length) {
            setSection(fileManageSection.sectionData)
            dispatch(setFileManageSection({}))
            moveFile({ file: fileManageSection.selectFile[0], qNo: fileManageSection.qNo, curQno: fileManageSection.sectionData.sectionNo ?? fileManageSection.sectionData.no, isCut: fileManageSection.actionType === "move" ? true : false, sectionName: (fileManageSection.sectionData.name ?? fileManageSection.sectionData.sectionName) })
        }
    }, [fileManageSection])


    const getFileHTML = (sec, file, index) => {
        let fileColor = file.originator == 'filing-assistant' ? 'filing-assistant-file' : file.originator == 'sharepoint' ? 'sharepoint-file' : file.originator == 'dropbox' ? 'dropbox-file' : file.originator == 'gdrive' ? 'gdrive-file' : file.bucket == `repository-diligentsia${AppMode === "production" ? "-prod" : ""}` ? "upload-file" : 'prepopulated-file';
        const key = file.key;

        return <React.Fragment key={`${index}-aja-${new Date().getTime()}`}>
            <div className="df jcsb aic w100 pr1rem pt0-5rem td250 bsbb">
                <div
                    onClick={() => getViewableURL(file.url)}
                    className={`mr1rem pl2rem cp tdu fs1rem pr fw600 bg1 ${fileColor} fileManageFileName w100`}
                >
                    {file.title}
                </div>

                <div className="mr1rem w100">
                    <div className="rs-nav rs-nav-default">
                        <div
                            className="rs-dropdown-toggle rs-nav-item c2 fsi"
                        >
                            {sec['sectionNo'] ? `${sec['sectionNo']} ${sec['sectionName']}` : (sec['no'] ? `${sec['no']} ${sec['name']}` : 'Please select section')}
                        </div>
                    </div>
                </div>

                <div className="df aic w100">
                    <Send
                        data-tooltip={"Cut"}
                        className="mr1rem cp"
                        onClick={() => handleEditDispatch({ key: key, fileName: file.title, from: "section", actionType: "move", fileData: file, index, section: sec })}
                    />

                    <Copy
                        data-tooltip={"Copy"}
                        className="mr1rem cp"
                        onClick={() => handleEditDispatch({ key: key, fileName: file.title, from: "section", actionType: "copy", fileData: file, index, section: sec })}
                    />

                    <Link className="df" target="_blank" to={file.url}>
                        <Downloads data-tooltip={"Download"} className={`f3 cp mr1rem ${tab == 'repository' ? '' : 'mla'}`} />
                    </Link>

                    <Delete className="cp" data-tooltip={"Delete"} onClick={e => { e.preventDefault(); setDeleteFileQuestionId(file.questionId); setDeleteFile(file); dispatch(toggleModal({ open: 'deleteFile', from: 'manageFiles' })) }} />
                </div>
            </div>
        </React.Fragment>
    };

    return (
        <>
            <ModalWrapper
                modalType={modalType}
                modalCardClasses={"maw1000px"}
            >
                <h2 className="c15 fw600 tal df aic mb0-5rem fs1-25em">
                    <FileManager className="mr1rem" /> File Manager
                </h2>
                <div className='df jcc'>
                    <h2 className="c3 fs1rem-sm w100 fw400 tal mb2rem">
                        {section && (section.sectionNo || section.no)} - {section && (section.sectionName || section.name)}
                    </h2>
                </div>
                <ul className="list-file-manger">
                    <li className="bg1 fw400 c2 upload-file">Uploaded Directly</li>
                    <li className="bg1 fw400 c2 prepopulated-file">Companies House</li>
                    <li className="bg1 fw400 c2 filing-assistant-file">Filing Assistant</li>
                    <li className="bg1 fw400 c2 sharepoint-file">Sharepoint</li>
                    <li className="bg1 fw400 c2 dropbox-file">Dropbox</li>
                    <li className="bg1 fw400 c2 gdrive-file">Drive</li>
                </ul>
                <div className='devider'></div>
                <div className="dg mb2rem fs1rem lightScrollbar" style={{ maxHeight: "300px", overflowY: 'scroll' }}>
                    <div className="df jcsb aic w100 pr1rem mt1rem pt0-5rem td250 bsbb">
                        <div className='w100'>
                            <p className='c37 fs12px op60'>File Name</p>
                        </div>
                        <div className='w100'>
                            <p className='c37 fs12px op60' style={{ marginLeft: '22px' }}>Section</p>
                        </div>
                        <div className='w100'>
                            <p className='c37 fs12px op60' style={{ marginLeft: '12px' }}>Actions</p>
                        </div>
                    </div>
                    {section && section.files && section.files.length > 0 && section.files.map((file, index) => { return getFileHTML(section, file, index) })}
                </div>
                {(section && (!section?.files || section?.files.length == 0)) && (
                    <div className='df fdc jcc aic m2rem'>
                        <img src={require("../../../../images/dataRepo/empty-inbox.svg")} alt={"fileManagement"} />
                        <p className="fs1rem fw600 mt1rem tac c15 op80">No files found</p>
                    </div>
                )}
            </ModalWrapper>

        </>
    )
}

export default ManageFilesModal