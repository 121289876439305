import { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from "react-redux";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import { toggleModal } from "../../reducers/modalReducer";
import { postRequest, showMessage } from '../../config';

export default function QuestionCreation({ handleCreateChecklist, checklistObj, sections }) {

    const svg = {
        'question': <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.65515 3.27344H16.4278C20.2366 3.27344 23.3242 6.36109 23.3242 10.1699V13.5562C23.3242 15.5649 22.4647 17.3726 21.0942 18.633V22.2411C21.0942 22.9156 20.2787 23.2534 19.8018 22.7765L17.4074 20.3821C17.0873 20.4277 16.7604 20.4526 16.4277 20.4526H9.65506C5.84625 20.4526 2.7586 17.365 2.7586 13.5562V10.1699C2.75869 6.36109 5.84635 3.27344 9.65515 3.27344Z" fill="url(#paint0_linear_4328_472)" />
            <path d="M9.65515 3.27344H16.4278C20.2366 3.27344 23.3242 6.36109 23.3242 10.1699V13.5562C23.3242 15.5649 22.4647 17.3726 21.0942 18.633V22.2411C21.0942 22.9156 20.2787 23.2534 19.8018 22.7765L17.4074 20.3821C17.0873 20.4277 16.7604 20.4526 16.4277 20.4526H9.65506C5.84625 20.4526 2.7586 17.365 2.7586 13.5562V10.1699C2.75869 6.36109 5.84635 3.27344 9.65515 3.27344Z" fill="url(#paint1_linear_4328_472)" />
            <path d="M12.6264 14.1054H13.4622C14.1929 14.1054 14.7852 13.513 14.7852 12.7824V8.99877C14.7852 8.26806 14.1928 7.67578 13.4622 7.67578H12.6264C11.8957 7.67578 11.3035 8.26815 11.3035 8.99877V12.7824C11.3035 13.513 11.8957 14.1054 12.6264 14.1054Z" fill="url(#paint2_linear_4328_472)" />
            <path d="M2.97206 15.2689L10.8972 7.34375V12.437C10.8972 13.5823 11.8234 14.5119 12.9686 14.516L15.1836 14.524L9.26593 20.4416C6.2208 20.2726 3.70277 18.1287 2.97206 15.2689Z" fill="url(#paint3_linear_4328_472)" />
            <path d="M13.6115 15.8823V15.2021C14.9486 15.038 15.9219 14.2521 15.9219 12.4694V9.36164C15.9219 7.33263 14.667 6.59375 13.0485 6.59375C11.43 6.59375 10.1635 7.33263 10.1635 9.36164V12.4694C10.1635 14.2521 11.1487 15.0379 12.4856 15.2021V15.8823C12.4856 16.2576 12.767 16.4454 13.0484 16.4454C13.3298 16.4454 13.6115 16.2576 13.6115 15.8823ZM11.9931 9.36164C11.9931 8.56417 12.3918 8.18888 13.0486 8.18888C13.7054 8.18888 14.0924 8.56417 14.0924 9.36164V12.4694C14.0924 13.009 13.9282 13.349 13.6116 13.525V13.0089C13.6116 12.6219 13.3301 12.4577 13.0486 12.4577C12.7671 12.4577 12.4858 12.6219 12.4858 13.0089V13.525C12.1808 13.349 11.9932 13.0089 11.9932 12.4694V9.36164H11.9931Z" fill="url(#paint4_linear_4328_472)" />
            <path d="M10.7079 13.6797L17.4102 20.3819C17.0899 20.4275 16.7631 20.4523 16.4302 20.4523H9.65764C5.89033 20.4523 2.83062 17.4311 2.76424 13.6797H10.7079Z" fill="url(#paint5_linear_4328_472)" />
            <defs>
                <linearGradient id="paint0_linear_4328_472" x1="19.7395" y1="7.12208" x2="10.7318" y2="16.1298" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#62DBFB" />
                    <stop offset="0.1912" stop-color="#57D5FA" />
                    <stop offset="0.5232" stop-color="#3BC5F7" />
                    <stop offset="0.954" stop-color="#0DABF2" />
                    <stop offset="1" stop-color="#08A9F1" />
                </linearGradient>
                <linearGradient id="paint1_linear_4328_472" x1="19.7395" y1="7.12208" x2="10.7318" y2="16.1298" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#62DBFB" />
                    <stop offset="0.1912" stop-color="#57D5FA" />
                    <stop offset="0.5232" stop-color="#3BC5F7" />
                    <stop offset="0.954" stop-color="#0DABF2" />
                    <stop offset="1" stop-color="#08A9F1" />
                </linearGradient>
                <linearGradient id="paint2_linear_4328_472" x1="11.9848" y1="11.1625" x2="14.6077" y2="10.4894" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0593FC" stop-opacity="0" />
                    <stop offset="0.6831" stop-color="#0389FC" stop-opacity="0.683" />
                    <stop offset="1" stop-color="#0182FC" />
                </linearGradient>
                <linearGradient id="paint3_linear_4328_472" x1="5.65592" y1="16.068" x2="13.873" y2="12.0291" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0593FC" stop-opacity="0" />
                    <stop offset="0.6831" stop-color="#0389FC" stop-opacity="0.683" />
                    <stop offset="1" stop-color="#0182FC" />
                </linearGradient>
                <linearGradient id="paint4_linear_4328_472" x1="17.9313" y1="6.04214" x2="10.5819" y2="13.3915" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EAF9FA" />
                    <stop offset="1" stop-color="#B3DAFE" />
                </linearGradient>
                <linearGradient id="paint5_linear_4328_472" x1="10.0873" y1="17.6442" x2="10.0873" y2="21.0327" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0593FC" stop-opacity="0" />
                    <stop offset="0.6831" stop-color="#0389FC" stop-opacity="0.683" />
                    <stop offset="1" stop-color="#0182FC" />
                </linearGradient>
            </defs>
        </svg>,
        'risk': <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.0826 22.9981H5.91517C3.74799 22.9981 2.33844 20.7174 3.30763 18.7791L10.3913 4.61155C11.4657 2.46282 14.532 2.46282 15.6064 4.61155L22.6901 18.779C23.6594 20.7174 22.2498 22.9981 20.0826 22.9981Z" fill="url(#paint0_linear_4328_484)" />
            <path d="M20.0826 22.9981H5.91517C3.74799 22.9981 2.33844 20.7174 3.30763 18.7791L10.3913 4.61155C11.4657 2.46282 14.532 2.46282 15.6064 4.61155L22.6901 18.779C23.6594 20.7174 22.2498 22.9981 20.0826 22.9981Z" fill="url(#paint1_linear_4328_484)" />
            <path opacity="0.4" d="M21.9911 19.1305L14.9074 4.96303C14.5387 4.22548 13.8262 3.78516 13.0016 3.78516C12.177 3.78516 11.4646 4.22548 11.0958 4.96303L4.01206 19.1305C3.67938 19.7959 3.71424 20.5709 4.10533 21.2036C4.49641 21.8364 5.17403 22.2142 5.91788 22.2142H20.0854C20.8292 22.2142 21.5068 21.8364 21.8979 21.2036C22.289 20.5709 22.3238 19.7959 21.9911 19.1305ZM21.2306 20.7912C20.9835 21.191 20.5554 21.4297 20.0854 21.4297H5.91788C5.4479 21.4297 5.01977 21.191 4.7727 20.7912C4.5256 20.3914 4.50357 19.9017 4.71378 19.4814L11.7975 5.31391C12.034 4.84097 12.4728 4.56972 13.0016 4.56972C13.5304 4.56972 13.9692 4.84097 14.2057 5.31391L21.2894 19.4814C21.4996 19.9017 21.4776 20.3914 21.2306 20.7912Z" fill="url(#paint2_linear_4328_484)" />
            <path d="M20.0842 22.9982H18.9686L12.5201 16.5498C12.3295 16.4564 12.2337 16.2952 12.2337 16.0649C12.2337 15.1949 12.198 14.5743 12.1266 12.6051C12.0552 10.6363 12.0195 10.2319 12.0195 9.36185C12.0195 9.08411 12.1141 8.86995 12.3031 8.71893C12.4918 8.56752 12.7366 8.49219 13.037 8.49219C13.3375 8.49219 13.5584 8.57065 13.6988 8.72796C13.7259 8.75777 19.6044 14.6343 21.6336 16.6627L22.6919 18.7794C23.6613 20.7176 22.2515 22.9982 20.0842 22.9982Z" fill="url(#paint3_linear_4328_484)" />
            <path d="M18.5065 22.9968H15.5888L12.244 19.6519C12.0216 19.4558 11.9102 19.2271 11.9102 18.9654C11.9102 18.6928 12.0216 18.461 12.244 18.2704C12.4664 18.0797 12.7366 17.9844 13.0544 17.9844C13.3341 17.9844 13.5761 18.0797 13.7801 18.2704L18.5065 22.9968Z" fill="url(#paint4_linear_4328_484)" />
            <path d="M11.9102 18.9668C11.9102 18.6942 12.0214 18.4627 12.2437 18.272C12.4661 18.0814 12.7364 17.9858 13.0542 17.9858C13.334 17.9858 13.5758 18.0814 13.7798 18.272C13.9838 18.4627 14.0861 18.6942 14.0861 18.9668C14.0861 19.2285 13.9839 19.4573 13.7798 19.6535C13.5758 19.8497 13.3339 19.9478 13.0542 19.9478C12.7364 19.9478 12.4661 19.8496 12.2437 19.6535C12.0214 19.4573 11.9102 19.2285 11.9102 18.9668ZM12.0172 9.36197C12.0172 9.08435 12.1116 8.86991 12.3006 8.71885C12.4895 8.5678 12.7342 8.49219 13.0347 8.49219C13.3351 8.49219 13.5558 8.57084 13.6966 8.728C13.8373 8.88515 13.9078 9.09642 13.9078 9.36197C13.9078 10.2321 13.8927 10.6363 13.8632 12.6054C13.8334 14.5746 13.8187 15.1951 13.8187 16.0649C13.8187 16.2464 13.7337 16.3884 13.5639 16.491C13.3939 16.5935 13.2175 16.6449 13.0347 16.6449C12.4991 16.6449 12.2313 16.4517 12.2313 16.0649C12.2313 15.1951 12.1955 14.5746 12.1242 12.6054C12.0528 10.6362 12.0172 10.2321 12.0172 9.36197Z" fill="white" />
            <defs>
                <linearGradient id="paint0_linear_4328_484" x1="8.01357" y1="11.5532" x2="30.8345" y2="34.3742" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFB92D" />
                    <stop offset="1" stop-color="#F59500" />
                </linearGradient>
                <linearGradient id="paint1_linear_4328_484" x1="11.043" y1="14.5826" x2="17.9221" y2="21.4613" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFB92D" />
                    <stop offset="1" stop-color="#F59500" />
                </linearGradient>
                <linearGradient id="paint2_linear_4328_484" x1="3.78514" y1="12.9999" x2="22.2177" y2="12.9999" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFF465" />
                    <stop offset="1" stop-color="#FFE600" />
                </linearGradient>
                <linearGradient id="paint3_linear_4328_484" x1="19.5323" y1="19.0831" x2="8.38856" y2="7.93939" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#BE3F45" stop-opacity="0" />
                    <stop offset="1" stop-color="#BE3F45" />
                </linearGradient>
                <linearGradient id="paint4_linear_4328_484" x1="15.9216" y1="21.8729" x2="12.2866" y2="18.2374" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#BE3F45" stop-opacity="0" />
                    <stop offset="1" stop-color="#BE3F45" />
                </linearGradient>
            </defs>
        </svg>,
        'recommendation': <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4332_1464)">
                <path d="M20.0781 20.309C19.7727 19.4909 18.8578 19.0751 18.0404 19.3827L14.712 20.6343C13.7823 20.9842 12.8032 21.1454 11.8281 21.1233L12.6412 24.1989C13.8773 24.0917 15.1036 23.8222 16.2866 23.3859L19.1524 22.3284C19.9684 22.0276 20.3826 21.1237 20.0781 20.309Z" fill="url(#paint0_linear_4332_1464)" />
                <path d="M22.1231 20.036C21.7701 19.2561 20.8486 18.9126 20.0708 19.2709L16.9034 20.7287C15.6706 21.2965 14.3198 21.5143 12.9926 21.3804L11.7617 24.4855C14.0921 24.6879 16.4573 24.2951 18.6255 23.3138L21.3539 22.0784C22.1309 21.7268 22.475 20.8126 22.1231 20.036Z" fill="url(#paint1_linear_4332_1464)" />
                <path d="M23.5444 20.0951C23.134 19.3243 22.1722 19.0326 21.4025 19.4452L18.2679 21.1242C17.0479 21.7781 15.6849 22.0836 14.3231 22.0302L13.2695 25.2629C15.6585 25.3235 18.0444 24.777 20.1912 23.6445L22.8927 22.2188C23.662 21.8132 23.9536 20.8626 23.5444 20.0951Z" fill="url(#paint2_linear_4332_1464)" />
                <path d="M12.9968 0C12.5455 0 12.1797 0.365835 12.1797 0.817114V2.51624C12.1797 2.96752 12.5455 3.33335 12.9968 3.33335C13.4481 3.33335 13.8139 2.96752 13.8139 2.51624V0.817114C13.8139 0.365835 13.4481 0 12.9968 0Z" fill="url(#paint3_linear_4332_1464)" />
                <path d="M24.8717 11.875H23.1726C22.7213 11.875 22.3555 12.2408 22.3555 12.6921C22.3555 13.1434 22.7213 13.5092 23.1726 13.5092H24.8717C25.323 13.5092 25.6888 13.1434 25.6888 12.6921C25.6888 12.2408 25.323 11.875 24.8717 11.875Z" fill="url(#paint4_linear_4332_1464)" />
                <path d="M2.82483 11.875H1.12571C0.674429 11.875 0.308594 12.2408 0.308594 12.6921C0.308594 13.1434 0.674429 13.5092 1.12571 13.5092H2.82483C3.27611 13.5092 3.64195 13.1434 3.64195 12.6921C3.6419 12.2408 3.27606 11.875 2.82483 11.875Z" fill="url(#paint5_linear_4332_1464)" />
                <path d="M5.18004 3.71588C4.86096 3.39679 4.34356 3.39679 4.02447 3.71588C3.70538 4.03496 3.70538 4.55231 4.02447 4.8714L5.22593 6.07285C5.54501 6.39194 6.06236 6.39194 6.3815 6.07285C6.70058 5.75377 6.70058 5.23642 6.3815 4.91728L5.18004 3.71588Z" fill="url(#paint6_linear_4332_1464)" />
                <path d="M21.9713 3.71588C21.6522 3.39679 21.1349 3.39679 20.8158 3.71588L19.6143 4.91733C19.2952 5.23642 19.2952 5.75377 19.6143 6.0729C19.9334 6.39199 20.4507 6.39199 20.7699 6.0729L21.9713 4.87145C22.2904 4.55236 22.2904 4.03496 21.9713 3.71588Z" fill="url(#paint7_linear_4332_1464)" />
                <path d="M6.6799 6.3713C3.19003 9.86117 3.19003 15.5194 6.6799 19.0092C10.1698 22.4991 15.828 22.4991 19.3178 19.0092C22.8077 15.5194 22.8077 9.86117 19.3178 6.3713C15.828 2.88144 10.1698 2.88144 6.6799 6.3713Z" fill="url(#paint8_linear_4332_1464)" />
                <path d="M12.9985 5.96484C9.28434 5.96484 6.27344 8.97575 6.27344 12.6899C6.27344 16.4041 9.28434 19.415 12.9985 19.415C16.7127 19.415 19.7236 16.4041 19.7236 12.6899C19.7236 8.97575 16.7127 5.96484 12.9985 5.96484Z" fill="url(#paint9_linear_4332_1464)" />
                <path d="M6.67988 19.0065C10.1697 22.4963 15.828 22.4963 19.3178 19.0065C21.0628 17.2615 21.9352 14.9745 21.9352 12.6875H4.0625C4.0625 14.9745 4.93498 17.2615 6.67988 19.0065Z" fill="url(#paint10_linear_4332_1464)" />
                <path d="M19.7204 12.884L17.8912 11.0549C17.6976 10.8154 17.416 10.6396 17.0649 10.5886L16.2742 10.4737C16.2431 10.4428 14.0664 8.275 14.0664 8.275C13.4901 7.56244 12.313 7.64631 11.8785 8.52679L11.1269 10.0495C11.0484 10.2086 10.8966 10.3189 10.721 10.3443L9.04068 10.5885C7.96652 10.7446 7.53762 12.0646 8.31487 12.8223C8.31487 12.8223 9.61263 14.1179 9.62318 14.129C9.68344 14.2357 9.70704 14.3609 9.68583 14.4847L9.39876 16.1583C9.312 16.6643 9.51765 17.1132 9.85998 17.3927L11.7701 19.3029C12.1683 19.3763 12.5787 19.4151 12.9982 19.4151C16.6475 19.4151 19.6176 16.5084 19.7204 12.884Z" fill="url(#paint11_linear_4332_1464)" />
                <path d="M14.1724 8.47218L14.9239 9.99486C15.0024 10.154 15.1543 10.2642 15.3298 10.2897L17.0102 10.5339C18.0844 10.69 18.5133 12.01 17.736 12.7677L16.5201 13.9529C16.393 14.0768 16.3351 14.2552 16.365 14.4301L16.6521 16.1036C16.8355 17.1735 15.7126 17.9892 14.7519 17.4842L13.2489 16.694C13.0919 16.6115 12.9042 16.6115 12.7472 16.694L11.2442 17.4842C10.2835 17.9893 9.1606 17.1735 9.34408 16.1036L9.63115 14.4301C9.66112 14.2552 9.60316 14.0768 9.47612 13.9529L8.26018 12.7677C7.48294 12.01 7.91183 10.69 8.98599 10.5339L10.6664 10.2897C10.8419 10.2642 10.9937 10.154 11.0723 9.99486L11.8238 8.47218C12.3041 7.49886 13.6921 7.49886 14.1724 8.47218Z" fill="white" />
                <path d="M14.7518 17.4861L13.2489 16.6959C13.0919 16.6133 12.9043 16.6133 12.7473 16.6959L11.2444 17.4861C10.2836 17.9912 9.1606 17.1755 9.34403 16.1054L9.63125 14.4321C9.66108 14.2571 9.60326 14.0786 9.47602 13.9548L8.26013 12.7696C7.84225 12.3622 7.77307 11.7923 7.96537 11.3281L16.3697 14.2263C16.3551 14.293 16.3531 14.3629 16.3649 14.4321L16.6521 16.1054C16.8356 17.1755 15.7126 17.9912 14.7518 17.4861Z" fill="white" />
                <path d="M13.2508 16.694L14.7538 17.4842C15.7146 17.9893 16.8374 17.1735 16.654 16.1036L16.3669 14.4301C16.337 14.2552 16.3949 14.0768 16.522 13.9529L17.7379 12.7677C18.5152 12.01 18.0863 10.69 17.0121 10.5339L15.3317 10.2897C15.1562 10.2642 15.0044 10.154 14.9258 9.99486L14.1743 8.47218C13.9342 7.98552 13.4671 7.74219 13 7.74219V16.6321C13.0861 16.6321 13.1723 16.6527 13.2508 16.694Z" fill="white" />
                <path d="M11.2444 17.4861L12.7473 16.6959C12.9044 16.6133 13.0919 16.6133 13.2489 16.6959L14.7518 17.4861C15.7126 17.9912 16.8356 17.1755 16.6522 16.1054L16.365 14.4321C16.3351 14.2571 16.3929 14.0786 16.5202 13.9548L17.7361 12.7696C18.154 12.3622 18.2231 11.7923 18.0308 11.3281L9.62653 14.2263C9.64111 14.293 9.64315 14.3629 9.63132 14.4321L9.3441 16.1054C9.16057 17.1755 10.2836 17.9912 11.2444 17.4861Z" fill="white" />
                <path d="M17.0971 14.9922L15.8347 16.2546C14.8147 17.2745 13.4314 17.8475 11.989 17.8475H10.7734V21.348C13.7318 22.1058 17.0026 21.3275 19.3185 19.0115C19.6237 18.7063 19.9017 18.3841 20.1535 18.0486L17.0971 14.9922Z" fill="url(#paint12_linear_4332_1464)" />
                <path d="M23.1622 19.5813L21.8579 20.558C21.8006 20.5982 21.7429 20.6376 21.6847 20.6763C20.1189 21.7196 18.2276 22.1545 16.3547 21.974C14.7884 21.8231 14.1616 21.7128 12.1443 20.6237C11.7925 20.4338 11.744 20.0538 12.0358 19.7805L12.1157 19.7056C13.6803 19.0767 15.1821 18.3051 16.6027 17.4013C17.4184 16.8824 17.6411 15.7899 17.0981 14.99C16.6039 14.262 15.6403 14.0274 14.8646 14.4429C13.5328 15.1562 12.0658 15.591 10.5542 15.7157C10.2654 15.7395 9.97491 15.7317 9.68671 15.7017C8.77875 15.6071 6.58048 15.3936 5.84427 15.4606C5.83749 15.4612 5.83066 15.4618 5.82383 15.4625C3.89672 15.6408 2.31821 17.0688 1.94182 18.9674L1.91944 19.0804C1.43278 21.5328 3.0273 23.9158 5.48022 24.4021L9.71587 25.2417L12.3838 25.7447C16.5715 26.4999 20.8881 25.5677 24.3806 23.1538L25.2282 22.5225C26.0447 21.9585 26.2459 20.8411 25.678 20.0264C25.108 19.2088 23.9782 19.0091 23.1622 19.5813Z" fill="url(#paint13_linear_4332_1464)" />
                <path d="M3.80144 16.1797C2.85952 16.8102 2.17327 17.799 1.94182 18.9663L1.91944 19.0793C1.43278 21.5317 3.0273 23.9147 5.48022 24.401L9.71588 25.2407L12.3838 25.7437C12.7649 25.8124 13.1469 25.8667 13.5294 25.9077L3.80144 16.1797Z" fill="url(#paint14_linear_4332_1464)" />
                <path d="M17.0989 14.99C16.6046 14.262 15.641 14.0274 14.8654 14.4429C13.3963 15.2297 11.7629 15.6776 10.0865 15.7444C10.0613 15.7454 10.0362 15.7465 10.011 15.7478C7.17758 15.8896 6.89143 19.966 9.67695 20.5039C9.87715 20.5425 10.0842 20.5226 10.2734 20.4466L12.1165 19.7057C13.681 19.0767 15.183 18.3051 16.6035 17.4013C17.4191 16.8824 17.6419 15.7899 17.0989 14.99Z" fill="url(#paint15_linear_4332_1464)" />
                <path d="M2.69457 24.7566H1.10735C0.495784 24.7566 0 24.2609 0 23.6493V16.2402C0 15.6286 0.495784 15.1328 1.10735 15.1328H2.69457C3.30613 15.1328 3.80191 15.6286 3.80191 16.2402V23.6493C3.80191 24.2609 3.30618 24.7566 2.69457 24.7566Z" fill="url(#paint16_linear_4332_1464)" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_4332_1464" x1="15.764" y1="19.4036" x2="16.0639" y2="20.8796" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFEEE6" />
                    <stop offset="1" stop-color="#FFB09E" />
                </linearGradient>
                <linearGradient id="paint1_linear_4332_1464" x1="16.2827" y1="19.5834" x2="16.702" y2="21.3933" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFEEE6" />
                    <stop offset="1" stop-color="#FFB09E" />
                </linearGradient>
                <linearGradient id="paint2_linear_4332_1464" x1="17.5333" y1="20.7331" x2="18.0831" y2="22.0342" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFEEE6" />
                    <stop offset="1" stop-color="#FFB09E" />
                </linearGradient>
                <linearGradient id="paint3_linear_4332_1464" x1="12.1864" y1="3.5" x2="10.5612" y2="1.53682" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FEF0AE" />
                    <stop offset="1" stop-color="#FAC600" />
                </linearGradient>
                <linearGradient id="paint4_linear_4332_1464" x1="22.498" y1="12.502" x2="24.6324" y2="14.045" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FEF0AE" />
                    <stop offset="1" stop-color="#FAC600" />
                </linearGradient>
                <linearGradient id="paint5_linear_4332_1464" x1="3.49805" y1="11.9689" x2="1.4124" y2="10.461" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FEF0AE" />
                    <stop offset="1" stop-color="#FAC600" />
                </linearGradient>
                <linearGradient id="paint6_linear_4332_1464" x1="6.11026" y1="6.05077" x2="3.70104" y2="3.73927" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FEF0AE" />
                    <stop offset="1" stop-color="#FAC600" />
                </linearGradient>
                <linearGradient id="paint7_linear_4332_1464" x1="19.248" y1="5.74902" x2="21.4114" y2="3.65934" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FEF0AE" />
                    <stop offset="1" stop-color="#FAC600" />
                </linearGradient>
                <linearGradient id="paint8_linear_4332_1464" x1="4" y1="11.5" x2="23.7975" y2="18.6796" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FEF0AE" />
                    <stop offset="1" stop-color="#FAC600" />
                </linearGradient>
                <linearGradient id="paint9_linear_4332_1464" x1="17.3019" y1="18.1751" x2="5.87424" y2="7.21116" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FEF0AE" />
                    <stop offset="1" stop-color="#FAC600" />
                </linearGradient>
                <linearGradient id="paint10_linear_4332_1464" x1="15.5175" y1="17.7627" x2="17.4198" y2="20.1175" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FE9738" stop-opacity="0" />
                    <stop offset="1" stop-color="#FE9738" />
                </linearGradient>
                <linearGradient id="paint11_linear_4332_1464" x1="17.5" y1="19" x2="10.4578" y2="7.4007" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#BE3F45" stop-opacity="0" />
                    <stop offset="1" stop-color="#BE3F45" />
                </linearGradient>
                <linearGradient id="paint12_linear_4332_1464" x1="15.4635" y1="18.1901" x2="15.4635" y2="21.3788" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FE9738" stop-opacity="0" />
                    <stop offset="1" stop-color="#FE9738" />
                </linearGradient>
                <linearGradient id="paint13_linear_4332_1464" x1="12.0588" y1="15.5361" x2="16.5197" y2="28.04" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFEEE6" />
                    <stop offset="1" stop-color="#FFB09E" />
                </linearGradient>
                <linearGradient id="paint14_linear_4332_1464" x1="9.09172" y1="21.0437" x2="1.43228" y2="21.0437" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFB09E" stop-opacity="0" />
                    <stop offset="1" stop-color="#EC806B" />
                </linearGradient>
                <linearGradient id="paint15_linear_4332_1464" x1="12.511" y1="17.1585" x2="15.3498" y2="19.9972" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFB09E" stop-opacity="0" />
                    <stop offset="1" stop-color="#EC806B" />
                </linearGradient>
                <linearGradient id="paint16_linear_4332_1464" x1="0.518622" y1="19.9447" x2="3.59591" y2="19.9447" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#B3DAFE" />
                    <stop offset="1" stop-color="#0182FC" />
                </linearGradient>
                <clipPath id="clip0_4332_1464">
                    <rect width="26" height="26" fill="white" />
                </clipPath>
            </defs>
        </svg>,
        'tool-tip': <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.0954 7.59459C19.0954 3.39534 15.6873 -0.00792367 11.4862 1.38565e-05C7.32805 0.00786511 3.97026 3.31889 3.90715 7.47656C3.86673 10.1385 5.19613 12.4926 7.23603 13.8795C7.24414 13.885 7.24902 13.8941 7.24902 13.904C7.6515 14.1551 7.8961 14.5961 7.8961 15.0705V16.7815H15.1055V15.055C15.1055 14.5891 15.3416 14.1547 15.7326 13.9013L15.7526 13.8883C17.7696 12.523 19.0954 10.2136 19.0954 7.59459Z" fill="url(#paint0_linear_4332_1505)" />
            <path d="M11.5034 12.8115C14.3444 12.8115 16.6474 10.5084 16.6474 7.66747C16.6474 4.8265 14.3444 2.52344 11.5034 2.52344C8.66244 2.52344 6.35938 4.8265 6.35938 7.66747C6.35938 10.5084 8.66244 12.8115 11.5034 12.8115Z" fill="url(#paint1_linear_4332_1505)" />
            <path d="M18.6955 10.0334L13.9165 5.25445C13.8453 5.12309 13.7329 5.03125 13.5718 5.03125H9.51909C9.20948 5.03125 9.07812 5.36898 9.07812 5.67855C9.07812 5.96628 9.18213 6.22322 9.37022 6.31169L10.8136 7.78363V11.4386C10.8136 11.5865 10.9026 11.6978 11.0365 11.7728L15.107 15.8433V15.0539C15.107 14.5879 15.343 14.1536 15.7341 13.9001L15.7541 13.8872C17.1136 12.9669 18.1584 11.6173 18.6955 10.0334Z" fill="url(#paint2_linear_4332_1505)" />
            <path d="M13.5288 4.99219H9.47612C9.16651 4.99219 9.03516 5.32992 9.03516 5.63948C9.03516 5.99598 9.19464 6.30555 9.47612 6.30555H10.7707V11.3996C10.7707 11.6998 11.1366 11.8499 11.5025 11.8499C11.8684 11.8499 12.2342 11.6998 12.2342 11.3996V6.30555H13.5289C13.8103 6.30555 13.9698 5.99598 13.9698 5.63948C13.9697 5.32988 13.8384 4.99219 13.5288 4.99219Z" fill="url(#paint3_linear_4332_1505)" />
            <path d="M21.8055 6.92578H20.1917C19.8076 6.92578 19.4961 7.2372 19.4961 7.62139C19.4961 8.00554 19.8075 8.31701 20.1917 8.31701H21.8055C22.1896 8.31701 22.5011 8.00559 22.5011 7.62139C22.5011 7.2372 22.1897 6.92578 21.8055 6.92578Z" fill="url(#paint4_linear_4332_1505)" />
            <path d="M3.505 7.62139C3.505 7.23724 3.19358 6.92578 2.80939 6.92578H1.19561C0.811419 6.92578 0.5 7.2372 0.5 7.62139C0.5 8.00554 0.811419 8.31701 1.19561 8.31701H2.80939C3.19354 8.31696 3.505 8.00554 3.505 7.62139Z" fill="url(#paint5_linear_4332_1505)" />
            <path d="M19.7422 11.485C19.4705 11.2133 19.0301 11.2133 18.7584 11.485C18.4868 11.7566 18.4868 12.1971 18.7584 12.4687L19.8995 13.6098C20.1712 13.8815 20.6116 13.8815 20.8833 13.6098C21.1549 13.3382 21.1549 12.8977 20.8833 12.6261L19.7422 11.485Z" fill="url(#paint6_linear_4332_1505)" />
            <path d="M3.10153 1.63342C2.82989 1.36178 2.38944 1.36178 2.1178 1.63342C1.84615 1.90507 1.84615 2.34551 2.1178 2.61716L3.2589 3.75826C3.53055 4.02991 3.97099 4.02991 4.24264 3.75826C4.51428 3.48662 4.51428 3.04617 4.24264 2.77453L3.10153 1.63342Z" fill="url(#paint7_linear_4332_1505)" />
            <path d="M4.24264 11.485C3.97099 11.2133 3.53055 11.2133 3.2589 11.485L2.1178 12.6261C1.84615 12.8977 1.84615 13.3382 2.1178 13.6098C2.38944 13.8815 2.82989 13.8815 3.10153 13.6098L4.24264 12.4687C4.51428 12.197 4.51428 11.7566 4.24264 11.485Z" fill="url(#paint8_linear_4332_1505)" />
            <path d="M18.7584 3.75826C19.0301 4.02991 19.4705 4.02991 19.7422 3.75826L20.8833 2.61716C21.1549 2.34551 21.1549 1.90507 20.8833 1.63342C20.6116 1.36178 20.1712 1.36178 19.8995 1.63342L18.7584 2.77453C18.4868 3.04617 18.4868 3.48662 18.7584 3.75826Z" fill="url(#paint9_linear_4332_1505)" />
            <path d="M15.1038 15.4497L12.4735 12.8194C12.2449 12.5502 11.9041 12.3789 11.5232 12.3789C10.8351 12.3789 10.2773 12.9367 10.2773 13.6248C10.2773 13.6696 10.2795 13.7141 10.2842 13.7576C10.3188 14.0842 10.4801 14.3732 10.7178 14.5751L12.9239 16.7812H15.1038V15.4497Z" fill="url(#paint10_linear_4332_1505)" />
            <path d="M11.4998 14.8434C12.1879 14.8434 12.7458 14.2856 12.7458 13.5975C12.7458 12.9094 12.1879 12.3516 11.4998 12.3516C10.8117 12.3516 10.2539 12.9094 10.2539 13.5975C10.2539 14.2856 10.8117 14.8434 11.4998 14.8434Z" fill="url(#paint11_linear_4332_1505)" />
            <path d="M4.42578 10.3516C4.98797 11.7927 5.97705 13.0202 7.23834 13.8778C7.24645 13.8833 7.25133 13.8924 7.25133 13.9022C7.65381 14.1534 7.89841 14.5943 7.89841 15.0687V16.7797H15.1078V15.0533C15.1078 14.5873 15.3439 14.153 15.7349 13.8995L15.7549 13.8865C17.0224 13.0286 18.0166 11.7977 18.5807 10.3516H4.42578V10.3516Z" fill="url(#paint12_linear_4332_1505)" />
            <path d="M14.6805 15.5156H8.31754C7.65243 15.5156 7.11328 16.0548 7.11328 16.7199C7.11328 17.385 7.65247 17.9241 8.31754 17.9241H14.6805C15.3456 17.9241 15.8848 17.385 15.8848 16.7199C15.8848 16.0548 15.3456 15.5156 14.6805 15.5156Z" fill="url(#paint13_linear_4332_1505)" />
            <path d="M7.97656 19.457C8.60604 20.9614 9.94697 22.0002 11.4996 22.0002C13.0522 22.0002 14.3931 20.9614 15.0226 19.457H7.97656Z" fill="url(#paint14_linear_4332_1505)" />
            <path d="M7.97656 19.457C8.60604 20.9614 9.94697 22.0002 11.4996 22.0002C13.0522 22.0002 14.3931 20.9614 15.0226 19.457H7.97656Z" fill="url(#paint15_linear_4332_1505)" />
            <path d="M14.6805 17.9219H8.31754C7.65243 17.9219 7.11328 18.4611 7.11328 19.1261C7.11328 19.7913 7.65247 20.3304 8.31754 20.3304H14.6805C15.3456 20.3304 15.8848 19.7912 15.8848 19.1261C15.8848 18.461 15.3456 17.9219 14.6805 17.9219Z" fill="url(#paint16_linear_4332_1505)" />
            <path d="M14.6805 15.5156H8.31754C7.65243 15.5156 7.11328 16.0548 7.11328 16.7199C7.11328 17.385 7.65247 17.9241 8.31754 17.9241H14.6805C15.3456 17.9241 15.8848 17.385 15.8848 16.7199C15.8848 16.0548 15.3456 15.5156 14.6805 15.5156Z" fill="url(#paint17_linear_4332_1505)" />
            <path d="M14.6806 17.9219L11.5121 17.8047L8.31754 17.9219C7.65243 17.9219 7.11328 18.4611 7.11328 19.1262C7.11328 19.7913 7.65247 20.3304 8.31754 20.3304H14.6806C15.3457 20.3304 15.8848 19.7912 15.8848 19.1262C15.8849 18.461 15.3457 17.9219 14.6806 17.9219Z" fill="url(#paint18_linear_4332_1505)" />
            <defs>
                <linearGradient id="paint0_linear_4332_1505" x1="5.93971" y1="3.05907" x2="15.0821" y2="12.2015" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F7E07D" />
                    <stop offset="1" stop-color="#E69642" />
                </linearGradient>
                <linearGradient id="paint1_linear_4332_1505" x1="9.58814" y1="5.75216" x2="17.3877" y2="13.5517" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F7E07D" />
                    <stop offset="1" stop-color="#E69642" />
                </linearGradient>
                <linearGradient id="paint2_linear_4332_1505" x1="17.8051" y1="13.8419" x2="5.67629" y2="1.71302" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D52C1C" stop-opacity="0" />
                    <stop offset="0.28" stop-color="#D12C1E" stop-opacity="0.28" />
                    <stop offset="0.5735" stop-color="#C42C23" stop-opacity="0.574" />
                    <stop offset="0.8721" stop-color="#AF2B2C" stop-opacity="0.872" />
                    <stop offset="1" stop-color="#A42B31" />
                </linearGradient>
                <linearGradient id="paint3_linear_4332_1505" x1="9.55614" y1="5.5966" x2="14.2863" y2="10.3268" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#E38523" />
                    <stop offset="1" stop-color="#C4237C" />
                </linearGradient>
                <linearGradient id="paint4_linear_4332_1505" x1="18.5774" y1="6.71341" x2="23.2577" y2="8.46855" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F7E07D" />
                    <stop offset="1" stop-color="#E69642" />
                </linearGradient>
                <linearGradient id="paint5_linear_4332_1505" x1="-0.418726" y1="6.71341" x2="4.26166" y2="8.46855" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F7E07D" />
                    <stop offset="1" stop-color="#E69642" />
                </linearGradient>
                <linearGradient id="paint6_linear_4332_1505" x1="17.4346" y1="11.6526" x2="22.0474" y2="13.3824" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F7E07D" />
                    <stop offset="1" stop-color="#E69642" />
                </linearGradient>
                <linearGradient id="paint7_linear_4332_1505" x1="0.793997" y1="1.80097" x2="5.40674" y2="3.53075" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F7E07D" />
                    <stop offset="1" stop-color="#E69642" />
                </linearGradient>
                <linearGradient id="paint8_linear_4332_1505" x1="1.46243" y1="11.9032" x2="4.78304" y2="13.1485" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F7E07D" />
                    <stop offset="1" stop-color="#E69642" />
                </linearGradient>
                <linearGradient id="paint9_linear_4332_1505" x1="18.1031" y1="2.05165" x2="21.4237" y2="3.29689" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F7E07D" />
                    <stop offset="1" stop-color="#E69642" />
                </linearGradient>
                <linearGradient id="paint10_linear_4332_1505" x1="15.1922" y1="17.2937" x2="11.3785" y2="13.48" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D52C1C" stop-opacity="0" />
                    <stop offset="0.28" stop-color="#D12C1E" stop-opacity="0.28" />
                    <stop offset="0.5735" stop-color="#C42C23" stop-opacity="0.574" />
                    <stop offset="0.8721" stop-color="#AF2B2C" stop-opacity="0.872" />
                    <stop offset="1" stop-color="#A42B31" />
                </linearGradient>
                <linearGradient id="paint11_linear_4332_1505" x1="10.8794" y1="12.977" x2="12.0204" y2="14.118" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#E38523" />
                    <stop offset="1" stop-color="#C4237C" />
                </linearGradient>
                <linearGradient id="paint12_linear_4332_1505" x1="11.5033" y1="12.2928" x2="11.5033" y2="15.8093" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D52C1C" stop-opacity="0" />
                    <stop offset="0.28" stop-color="#D12C1E" stop-opacity="0.28" />
                    <stop offset="0.5735" stop-color="#C42C23" stop-opacity="0.574" />
                    <stop offset="0.8721" stop-color="#AF2B2C" stop-opacity="0.872" />
                    <stop offset="1" stop-color="#A42B31" />
                </linearGradient>
                <linearGradient id="paint13_linear_4332_1505" x1="11.499" y1="16.221" x2="11.499" y2="17.6877" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EEF4FF" />
                    <stop offset="1" stop-color="#CFE7FD" />
                </linearGradient>
                <linearGradient id="paint14_linear_4332_1505" x1="8.50191" y1="20.7286" x2="13.9964" y2="20.7286" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#5A5A5A" />
                    <stop offset="1" stop-color="#464646" />
                </linearGradient>
                <linearGradient id="paint15_linear_4332_1505" x1="11.4996" y1="21.6335" x2="11.4996" y2="20.7058" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#464646" stop-opacity="0" />
                    <stop offset="1" stop-color="#202020" />
                </linearGradient>
                <linearGradient id="paint16_linear_4332_1505" x1="11.499" y1="18.2893" x2="11.499" y2="20.017" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EEF4FF" />
                    <stop offset="1" stop-color="#CFE7FD" />
                </linearGradient>
                <linearGradient id="paint17_linear_4332_1505" x1="11.499" y1="16.7818" x2="11.499" y2="18.2916" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#8AAADC" stop-opacity="0" />
                    <stop offset="1" stop-color="#8AAADC" />
                </linearGradient>
                <linearGradient id="paint18_linear_4332_1505" x1="11.4991" y1="19.2079" x2="11.4991" y2="20.2268" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#8AAADC" stop-opacity="0" />
                    <stop offset="1" stop-color="#8AAADC" />
                </linearGradient>
            </defs>
        </svg>,
        'toolbox-offer': <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4332_1543)">
                <path d="M3.80078 7.40625H22.2021V11.1521H3.80078V7.40625Z" fill="url(#paint0_linear_4332_1543)" />
                <path d="M3.80078 7.40625H8.22629V11.1521H3.80078V7.40625Z" fill="url(#paint1_linear_4332_1543)" />
                <path d="M8.82812 7.40625H13.2536V11.1521H8.82812V7.40625Z" fill="url(#paint2_linear_4332_1543)" />
                <path d="M3.76817 21.3742H1.64453L2.86663 7.38367C2.90798 6.91021 3.3044 6.54688 3.77969 6.54688C4.30994 6.54688 4.72956 6.9955 4.69418 7.52453L3.76817 21.3742Z" fill="url(#paint3_linear_4332_1543)" />
                <path d="M24.3525 21.3741H22.2288L21.3029 7.52453C21.2675 6.99545 21.6871 6.54688 22.2174 6.54688C22.6926 6.54688 23.0891 6.91016 23.1304 7.38367L24.3525 21.3741Z" fill="url(#paint4_linear_4332_1543)" />
                <path d="M4.50912 10.2969L3.76786 21.3764H1.64453L2.61214 10.2969H4.50912Z" fill="url(#paint5_linear_4332_1543)" />
                <path d="M24.3529 21.3764H22.2295L21.4883 10.2969H23.3853L24.3529 21.3764Z" fill="url(#paint6_linear_4332_1543)" />
                <path d="M6.69531 4.71875H9.51886V12.889H6.69531V4.71875Z" fill="url(#paint7_linear_4332_1543)" />
                <path d="M6.69531 4.71875H9.51886V8.12873H6.69531V4.71875Z" fill="url(#paint8_linear_4332_1543)" />
                <path d="M2.91016 1.96875H6.59517V4.30987H2.91016V1.96875Z" fill="url(#paint9_linear_4332_1543)" />
                <path d="M2.91016 1.96875H6.59517V4.30987H2.91016V1.96875Z" fill="url(#paint10_linear_4332_1543)" />
                <path d="M3.10584 5.24269H1.71C1.46672 5.24269 1.26953 5.0455 1.26953 4.80222V1.47172C1.26953 1.22844 1.46672 1.03125 1.71 1.03125H3.10584C3.34912 1.03125 3.54632 1.22844 3.54632 1.47172V4.80217C3.54637 5.04545 3.34912 5.24269 3.10584 5.24269Z" fill="url(#paint11_linear_4332_1543)" />
                <path d="M12.8378 5.24269H5.45548C4.9731 5.24269 4.58203 4.85162 4.58203 4.36924V1.9047C4.58203 1.42232 4.9731 1.03125 5.45548 1.03125H9.98519C11.9359 1.03125 13.5172 2.61261 13.5172 4.56327C13.5173 4.93849 13.2131 5.24269 12.8378 5.24269Z" fill="url(#paint12_linear_4332_1543)" />
                <path d="M1.86719 5.24264H3.10693C3.35021 5.24264 3.5474 5.04545 3.5474 4.80217V1.47172C3.5474 1.22844 3.35021 1.03125 3.10693 1.03125H1.86719V5.24264Z" fill="url(#paint13_linear_4332_1543)" />
                <path d="M8.11278 21.7772C6.56818 21.7772 5.33777 20.485 5.41347 18.9422L5.82259 10.6056C5.849 10.0674 6.29309 9.64453 6.83195 9.64453H9.39356C9.93243 9.64453 10.3765 10.0674 10.4029 10.6056L10.812 18.9422C10.8877 20.4849 9.65734 21.7772 8.11278 21.7772Z" fill="url(#paint14_linear_4332_1543)" />
                <path d="M10.812 18.9441L10.4318 11.1953H5.8753L5.79144 11.2417L5.41347 18.9441C5.33777 20.4868 6.56818 21.7791 8.11278 21.7791C9.65734 21.7791 10.8877 20.4868 10.812 18.9441Z" fill="url(#paint15_linear_4332_1543)" />
                <path d="M5.51966 16.7773L5.41347 18.9416C5.33777 20.4843 6.56818 21.7766 8.11278 21.7766C9.65739 21.7766 10.8878 20.4844 10.8121 18.9416L10.7059 16.7773H5.51966Z" fill="url(#paint16_linear_4332_1543)" />
                <path d="M11.1066 11.8645C11.4597 14.3914 11.4728 16.9449 11.1448 19.4889C11.0347 20.343 9.81787 20.401 9.62134 19.5625C9.39784 18.609 9.24531 17.4929 9.19423 16.2961C9.11959 14.5468 9.27544 12.9505 9.58902 11.7707C9.80635 10.9532 10.9895 11.0267 11.1066 11.8645Z" fill="url(#paint17_linear_4332_1543)" />
                <path d="M10.1081 17.2038C10.0364 17.2759 9.99183 17.3747 9.99219 17.4845C9.99244 17.703 10.1703 17.8803 10.3893 17.8798L11.3042 17.8783C11.3238 17.6142 11.3391 17.3495 11.3504 17.0859L10.3881 17.0872C10.2782 17.0875 10.1796 17.1324 10.1081 17.2038Z" fill="url(#paint18_linear_4332_1543)" />
                <path d="M10.1078 15.4815C10.0363 15.5529 9.99203 15.6522 9.99219 15.7614C9.99265 15.9805 10.1703 16.1572 10.3888 16.157L11.3796 16.1557C11.3826 15.8915 11.3822 15.6274 11.3778 15.3633L10.3877 15.3649C10.2784 15.365 10.1795 15.4093 10.1078 15.4815Z" fill="url(#paint19_linear_4332_1543)" />
                <path d="M10.1038 13.7626C10.032 13.8347 9.98798 13.9333 9.98828 14.043C9.98854 14.2616 10.1658 14.4391 10.3849 14.4386L11.3465 14.4371C11.3341 14.1727 11.3176 13.9084 11.2969 13.6445L10.3836 13.646C10.2739 13.6463 10.1752 13.6911 10.1038 13.7626Z" fill="url(#paint20_linear_4332_1543)" />
                <path d="M5.11868 11.8645C4.76549 14.3914 4.75244 16.9449 5.08044 19.4889C5.19056 20.343 6.40736 20.401 6.60389 19.5625C6.82739 18.609 6.97992 17.4929 7.03101 16.2961C7.10564 14.5468 6.94979 12.9505 6.63621 11.7707C6.41888 10.9532 5.23578 11.0267 5.11868 11.8645Z" fill="url(#paint21_linear_4332_1543)" />
                <path d="M6.11333 17.2038C6.18506 17.2759 6.22962 17.3747 6.22926 17.4845C6.22901 17.703 6.05119 17.8803 5.83218 17.8798L4.91723 17.8783C4.89765 17.6142 4.88236 17.3495 4.87109 17.0859L5.8334 17.0872C5.94321 17.0875 6.04186 17.1324 6.11333 17.2038Z" fill="url(#paint22_linear_4332_1543)" />
                <path d="M6.11747 15.4815C6.18894 15.5529 6.23324 15.6522 6.23309 15.7614C6.23263 15.9805 6.05502 16.1572 5.83646 16.157L4.84571 16.1557C4.8427 15.8915 4.84306 15.6274 4.84744 15.3633L5.83753 15.3649C5.94684 15.365 6.04574 15.4093 6.11747 15.4815Z" fill="url(#paint23_linear_4332_1543)" />
                <path d="M6.12171 13.7626C6.19344 13.8347 6.23748 13.9333 6.23718 14.043C6.23692 14.2616 6.05961 14.4391 5.84055 14.4386L4.87891 14.4371C4.89135 14.1727 4.90776 13.9084 4.92851 13.6445L5.84177 13.646C5.95158 13.6463 6.05023 13.6911 6.12171 13.7626Z" fill="url(#paint24_linear_4332_1543)" />
                <path d="M4.58203 2.69531V4.37134C4.58203 4.85372 4.9731 5.24479 5.45548 5.24479H12.8378C13.213 5.24479 13.5172 4.94059 13.5172 4.56538C13.5172 3.87841 13.3204 3.23774 12.9812 2.69531H4.58203Z" fill="url(#paint25_linear_4332_1543)" />
                <path d="M16.4628 1.92083L13.185 9.19145C12.4381 10.8483 13.6499 12.7239 15.4673 12.7239H20.2118C22.0292 12.7239 23.241 10.8483 22.494 9.19145L19.2164 1.92083C18.6817 0.734723 16.9975 0.734723 16.4628 1.92083Z" fill="url(#paint26_linear_4332_1543)" />
                <path d="M13.185 9.19238C12.4381 10.8492 13.6499 12.7248 15.4674 12.7248H20.2118C22.0293 12.7248 23.2411 10.8492 22.4941 9.19238L21.733 7.50391H13.9462L13.185 9.19238Z" fill="url(#paint27_linear_4332_1543)" />
                <path d="M20.2121 12.7246C21.6147 12.7246 22.6563 11.6075 22.7155 10.3359H17.8398V12.7246H20.2121Z" fill="url(#paint28_linear_4332_1543)" />
                <path d="M18.5362 15.2403V11.0337C18.5362 10.6483 18.2238 10.3359 17.8384 10.3359C17.453 10.3359 17.1406 10.6483 17.1406 11.0337V15.2403H18.5362Z" fill="url(#paint29_linear_4332_1543)" />
                <path d="M18.5362 15.2403V11.0337C18.5362 10.6483 18.2238 10.3359 17.8384 10.3359C17.453 10.3359 17.1406 10.6483 17.1406 11.0337V15.2403H18.5362Z" fill="url(#paint30_linear_4332_1543)" />
                <path d="M19.1155 13.9688H16.561C15.9048 13.9688 15.4947 14.6793 15.823 15.2475C16.3114 16.0926 16.4344 17.1003 16.1637 18.0382L15.3664 20.8008C15.2251 21.2905 15.5926 21.779 16.1022 21.779H19.5743C20.0839 21.779 20.4513 21.2905 20.31 20.8008L19.5127 18.0382C19.2421 17.1003 19.3651 16.0927 19.8534 15.2475C20.1818 14.6793 19.7718 13.9688 19.1155 13.9688Z" fill="url(#paint31_linear_4332_1543)" />
                <path d="M19.1155 13.9688H16.561C15.9048 13.9688 15.4947 14.6793 15.823 15.2475C16.3114 16.0926 16.4344 17.1003 16.1637 18.0382L15.3664 20.8008C15.2251 21.2905 15.5926 21.779 16.1022 21.779H19.5743C20.0839 21.779 20.4513 21.2905 20.31 20.8008L19.5127 18.0382C19.2421 17.1003 19.3651 16.0927 19.8534 15.2475C20.1818 14.6793 19.7718 13.9688 19.1155 13.9688Z" fill="url(#paint32_linear_4332_1543)" />
                <path d="M20.31 20.8006L19.5127 18.0379C19.4435 17.7981 19.4002 17.5537 19.382 17.3086H16.2944C16.2762 17.5537 16.233 17.7981 16.1637 18.0379L15.3664 20.8006C15.2251 21.2903 15.5926 21.7788 16.1022 21.7788H19.5743C20.0839 21.7787 20.4513 21.2902 20.31 20.8006Z" fill="url(#paint33_linear_4332_1543)" />
                <path d="M26 17.5936V23.1306C26 24.1445 25.1781 24.9664 24.1642 24.9664H23.6727C23.4284 24.9664 23.1901 24.9043 22.9691 24.8002C22.7975 24.7194 22.6056 24.6743 22.4033 24.6743H21.3689C21.1668 24.6743 20.9749 24.7193 20.8032 24.8002C20.5822 24.9043 20.3438 24.9664 20.0996 24.9664H17.7487C17.5045 24.9664 17.2661 24.9043 17.0452 24.8002C16.8735 24.7193 16.6816 24.6743 16.4794 24.6743H15.4445C15.2423 24.6743 15.0504 24.7193 14.8787 24.8002C14.6577 24.9043 14.4194 24.9664 14.1751 24.9664H11.8248C11.5806 24.9664 11.3422 24.9043 11.1212 24.8002C10.9495 24.7194 10.7577 24.6743 10.5554 24.6743H9.52054C9.31835 24.6743 9.12646 24.7193 8.95476 24.8002C8.73376 24.9043 8.49542 24.9664 8.25123 24.9664H5.90037C5.65612 24.9664 5.41779 24.9043 5.19679 24.8002C5.02509 24.7194 4.83325 24.6743 4.63101 24.6743H3.59667C3.39443 24.6743 3.20254 24.7193 3.03089 24.8002C2.80989 24.9043 2.57155 24.9664 2.32731 24.9664H1.8358C0.821906 24.9664 0 24.1445 0 23.1306V17.5941C0 16.58 0.822161 15.7578 1.83631 15.7578H24.1642C25.1781 15.7578 26 16.5797 26 17.5936Z" fill="url(#paint34_linear_4332_1543)" />
                <path d="M18.0308 21.4563H7.97053C7.19287 21.4563 6.5625 20.8259 6.5625 20.0483V18.7283C6.5625 17.9507 7.19292 17.3203 7.97053 17.3203H18.0307C18.8084 17.3203 19.4388 17.9507 19.4388 18.7283V20.0483C19.4388 20.8259 18.8084 21.4563 18.0308 21.4563Z" fill="url(#paint35_linear_4332_1543)" />
                <path d="M5.96221 24.2373V24.9659H5.90048C5.65623 24.9659 5.4179 24.9037 5.1969 24.7997C5.02519 24.7189 4.83335 24.6737 4.63112 24.6737H3.59672C3.39448 24.6737 3.20259 24.7188 3.03094 24.7997C2.80994 24.9037 2.57161 24.9659 2.32736 24.9659H2.26562V24.2373C2.26562 23.5022 2.86159 22.9062 3.59672 22.9062H4.63112C5.36625 22.9062 5.96221 23.5022 5.96221 24.2373Z" fill="url(#paint36_linear_4332_1543)" />
                <path d="M11.8885 24.2373V24.9659H11.8267C11.5825 24.9659 11.3441 24.9037 11.1231 24.7997C10.9514 24.7189 10.7596 24.6737 10.5574 24.6737H9.52245C9.32026 24.6737 9.12837 24.7188 8.95667 24.7997C8.73567 24.9037 8.49734 24.9659 8.25314 24.9659H8.19141V24.2373C8.19141 23.5022 8.78737 22.9062 9.5225 22.9062H10.5574C11.2925 22.9062 11.8885 23.5022 11.8885 24.2373Z" fill="url(#paint37_linear_4332_1543)" />
                <path d="M17.8103 24.2373V24.9659H17.7486C17.5043 24.9659 17.266 24.9037 17.0451 24.7997C16.8734 24.7188 16.6815 24.6737 16.4793 24.6737H15.4444C15.2422 24.6737 15.0503 24.7188 14.8786 24.7997C14.6576 24.9037 14.4193 24.9659 14.175 24.9659H14.1133V24.2373C14.1133 23.5022 14.7092 22.9062 15.4444 22.9062H16.4793C17.2144 22.9062 17.8103 23.5022 17.8103 24.2373Z" fill="url(#paint38_linear_4332_1543)" />
                <path d="M23.7357 24.2373V24.9659H23.6739C23.4297 24.9659 23.1913 24.9037 22.9703 24.7997C22.7987 24.7189 22.6068 24.6737 22.4046 24.6737H21.3702C21.168 24.6737 20.9761 24.7188 20.8044 24.7997C20.5834 24.9037 20.345 24.9659 20.1008 24.9659H20.0391V24.2373C20.0391 23.5022 20.635 22.9062 21.3702 22.9062H22.4046C23.1397 22.9062 23.7357 23.5022 23.7357 24.2373Z" fill="url(#paint39_linear_4332_1543)" />
                <path d="M0 18.7852V23.1306C0 24.1445 0.821906 24.9664 1.8358 24.9664H2.32731C2.57155 24.9664 2.80989 24.9043 3.03089 24.8002C3.20259 24.7194 3.39448 24.6743 3.59667 24.6743H4.63106C4.83325 24.6743 5.02514 24.7194 5.19684 24.8002C5.41779 24.9043 5.65617 24.9664 5.90042 24.9664H8.25128C8.49553 24.9664 8.73386 24.9043 8.95481 24.8002C9.12651 24.7194 9.3184 24.6743 9.52059 24.6743H10.5555C10.7577 24.6743 10.9496 24.7194 11.1213 24.8002C11.3423 24.9043 11.5806 24.9664 11.8249 24.9664H14.1752C14.4194 24.9664 14.6578 24.9043 14.8788 24.8002C15.0504 24.7194 15.2423 24.6743 15.4446 24.6743H16.4795C16.6817 24.6743 16.8735 24.7194 17.0452 24.8002C17.2662 24.9043 17.5046 24.9664 17.7488 24.9664H20.0997C20.3439 24.9664 20.5823 24.9043 20.8033 24.8002C20.9749 24.7194 21.1668 24.6743 21.369 24.6743H22.4034C22.6057 24.6743 22.7975 24.7194 22.9692 24.8002C23.1902 24.9043 23.4286 24.9664 23.6728 24.9664H24.1643C25.1782 24.9664 26.0001 24.1445 26.0001 23.1306V18.7852H0Z" fill="url(#paint40_linear_4332_1543)" />
                <path d="M15.2799 19.0487H10.7197C10.4905 19.0487 10.3047 18.8629 10.3047 18.6337C10.3047 18.4045 10.4905 18.2188 10.7197 18.2188H15.2799C15.5091 18.2188 15.6948 18.4046 15.6948 18.6337C15.6948 18.8629 15.5091 19.0487 15.2799 19.0487Z" fill="url(#paint41_linear_4332_1543)" />
                <path d="M14.2385 20.5487H11.7587C11.5295 20.5487 11.3438 20.3629 11.3438 20.1337C11.3438 19.9045 11.5296 19.7188 11.7587 19.7188H14.2385C14.4677 19.7188 14.6534 19.9046 14.6534 20.1337C14.6534 20.3629 14.4676 20.5487 14.2385 20.5487Z" fill="url(#paint42_linear_4332_1543)" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_4332_1543" x1="13.0014" y1="8.12049" x2="13.0014" y2="12.5081" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#60B7FF" />
                    <stop offset="1" stop-color="#2740B0" />
                </linearGradient>
                <linearGradient id="paint1_linear_4332_1543" x1="6.32299" y1="9.27917" x2="4.58965" y2="9.27917" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#1F3596" stop-opacity="0" />
                    <stop offset="0.4068" stop-color="#1D3290" stop-opacity="0.407" />
                    <stop offset="0.9508" stop-color="#192B7F" stop-opacity="0.951" />
                    <stop offset="1" stop-color="#192A7D" />
                </linearGradient>
                <linearGradient id="paint2_linear_4332_1543" x1="11.3843" y1="9.27917" x2="9.03923" y2="9.27917" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#1F3596" stop-opacity="0" />
                    <stop offset="0.4068" stop-color="#1D3290" stop-opacity="0.407" />
                    <stop offset="0.9508" stop-color="#192B7F" stop-opacity="0.951" />
                    <stop offset="1" stop-color="#192A7D" />
                </linearGradient>
                <linearGradient id="paint3_linear_4332_1543" x1="1.09695" y1="13.26" x2="5.17757" y2="14.9356" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#60B7FF" />
                    <stop offset="1" stop-color="#2740B0" />
                </linearGradient>
                <linearGradient id="paint4_linear_4332_1543" x1="19.8784" y1="12.9605" x2="25.2681" y2="15.1736" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#60B7FF" />
                    <stop offset="1" stop-color="#2740B0" />
                </linearGradient>
                <linearGradient id="paint5_linear_4332_1543" x1="3.07683" y1="13.5936" x2="3.07683" y2="16.1107" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#1F3596" stop-opacity="0" />
                    <stop offset="0.4068" stop-color="#1D3290" stop-opacity="0.407" />
                    <stop offset="0.9508" stop-color="#192B7F" stop-opacity="0.951" />
                    <stop offset="1" stop-color="#192A7D" />
                </linearGradient>
                <linearGradient id="paint6_linear_4332_1543" x1="22.9206" y1="13.5936" x2="22.9206" y2="16.1107" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#1F3596" stop-opacity="0" />
                    <stop offset="0.4068" stop-color="#1D3290" stop-opacity="0.407" />
                    <stop offset="0.9508" stop-color="#192B7F" stop-opacity="0.951" />
                    <stop offset="1" stop-color="#192A7D" />
                </linearGradient>
                <linearGradient id="paint7_linear_4332_1543" x1="7.01533" y1="8.81061" x2="9.32743" y2="8.81061" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EAF9FA" />
                    <stop offset="1" stop-color="#B3DAFE" />
                </linearGradient>
                <linearGradient id="paint8_linear_4332_1543" x1="8.04815" y1="6.83419" x2="8.42729" y2="4.61346" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#B3DAFE" stop-opacity="0" />
                    <stop offset="0.1128" stop-color="#A9D6FC" stop-opacity="0.113" />
                    <stop offset="0.3072" stop-color="#8DCAF4" stop-opacity="0.307" />
                    <stop offset="0.5596" stop-color="#60B7E8" stop-opacity="0.56" />
                    <stop offset="0.8571" stop-color="#229ED8" stop-opacity="0.857" />
                    <stop offset="1" stop-color="#0290CF" />
                </linearGradient>
                <linearGradient id="paint9_linear_4332_1543" x1="4.75264" y1="2.29637" x2="4.75264" y2="3.9118" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EAF9FA" />
                    <stop offset="1" stop-color="#B3DAFE" />
                </linearGradient>
                <linearGradient id="paint10_linear_4332_1543" x1="4.26646" y1="3.14659" x2="3.16088" y2="3.14659" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#B3DAFE" stop-opacity="0" />
                    <stop offset="0.1128" stop-color="#A9D6FC" stop-opacity="0.113" />
                    <stop offset="0.3072" stop-color="#8DCAF4" stop-opacity="0.307" />
                    <stop offset="0.5596" stop-color="#60B7E8" stop-opacity="0.56" />
                    <stop offset="0.8571" stop-color="#229ED8" stop-opacity="0.857" />
                    <stop offset="1" stop-color="#0290CF" />
                </linearGradient>
                <linearGradient id="paint11_linear_4332_1543" x1="1.63721" y1="3.14426" x2="3.44333" y2="3.14426" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EAF9FA" />
                    <stop offset="1" stop-color="#B3DAFE" />
                </linearGradient>
                <linearGradient id="paint12_linear_4332_1543" x1="7.91299" y1="2.1703" x2="10.3142" y2="6.23261" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EAF9FA" />
                    <stop offset="1" stop-color="#B3DAFE" />
                </linearGradient>
                <linearGradient id="paint13_linear_4332_1543" x1="2.38316" y1="3.14421" x2="3.89867" y2="3.14417" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#B3DAFE" stop-opacity="0" />
                    <stop offset="0.1128" stop-color="#A9D6FC" stop-opacity="0.113" />
                    <stop offset="0.3072" stop-color="#8DCAF4" stop-opacity="0.307" />
                    <stop offset="0.5596" stop-color="#60B7E8" stop-opacity="0.56" />
                    <stop offset="0.8571" stop-color="#229ED8" stop-opacity="0.857" />
                    <stop offset="1" stop-color="#0290CF" />
                </linearGradient>
                <linearGradient id="paint14_linear_4332_1543" x1="7.4281" y1="15.7166" x2="10.6623" y2="15.7166" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FEE45A" />
                    <stop offset="1" stop-color="#FEA613" />
                </linearGradient>
                <linearGradient id="paint15_linear_4332_1543" x1="9.77775" y1="14.1967" x2="7.78951" y2="16.2359" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FEA613" stop-opacity="0" />
                    <stop offset="1" stop-color="#E94444" />
                </linearGradient>
                <linearGradient id="paint16_linear_4332_1543" x1="8.12265" y1="19.7044" x2="8.12268" y2="22.4308" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FEA613" stop-opacity="0" />
                    <stop offset="1" stop-color="#E94444" />
                </linearGradient>
                <linearGradient id="paint17_linear_4332_1543" x1="9.62498" y1="15.6856" x2="11.2803" y2="15.6636" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#5A5A5A" />
                    <stop offset="1" stop-color="#444444" />
                </linearGradient>
                <linearGradient id="paint18_linear_4332_1543" x1="10.6731" y1="17.1445" x2="10.7037" y2="17.8344" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#5A5A5A" />
                    <stop offset="1" stop-color="#444444" />
                </linearGradient>
                <linearGradient id="paint19_linear_4332_1543" x1="10.6971" y1="15.3878" x2="10.7019" y2="16.2969" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#5A5A5A" />
                    <stop offset="1" stop-color="#444444" />
                </linearGradient>
                <linearGradient id="paint20_linear_4332_1543" x1="10.7055" y1="13.7357" x2="10.6709" y2="14.5467" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#5A5A5A" />
                    <stop offset="1" stop-color="#444444" />
                </linearGradient>
                <linearGradient id="paint21_linear_4332_1543" x1="5.40676" y1="15.6841" x2="6.68878" y2="15.6841" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#5A5A5A" />
                    <stop offset="1" stop-color="#444444" />
                </linearGradient>
                <linearGradient id="paint22_linear_4332_1543" x1="5.53209" y1="17.1875" x2="5.50151" y2="17.8775" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#5A5A5A" />
                    <stop offset="1" stop-color="#444444" />
                </linearGradient>
                <linearGradient id="paint23_linear_4332_1543" x1="5.51193" y1="15.4309" x2="5.50713" y2="16.3399" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#5A5A5A" />
                    <stop offset="1" stop-color="#444444" />
                </linearGradient>
                <linearGradient id="paint24_linear_4332_1543" x1="5.5037" y1="13.7787" x2="5.5383" y2="14.5898" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#5A5A5A" />
                    <stop offset="1" stop-color="#444444" />
                </linearGradient>
                <linearGradient id="paint25_linear_4332_1543" x1="9.05941" y1="4.08972" x2="9.05941" y2="6.11469" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#B3DAFE" stop-opacity="0" />
                    <stop offset="0.1128" stop-color="#A9D6FC" stop-opacity="0.113" />
                    <stop offset="0.3072" stop-color="#8DCAF4" stop-opacity="0.307" />
                    <stop offset="0.5596" stop-color="#60B7E8" stop-opacity="0.56" />
                    <stop offset="0.8571" stop-color="#229ED8" stop-opacity="0.857" />
                    <stop offset="1" stop-color="#0290CF" />
                </linearGradient>
                <linearGradient id="paint26_linear_4332_1543" x1="15.8663" y1="6.29429" x2="18.8763" y2="9.30427" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EAF9FA" />
                    <stop offset="1" stop-color="#B3DAFE" />
                </linearGradient>
                <linearGradient id="paint27_linear_4332_1543" x1="17.8396" y1="10.2369" x2="17.8396" y2="13.3978" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#B3DAFE" stop-opacity="0" />
                    <stop offset="0.1128" stop-color="#A9D6FC" stop-opacity="0.113" />
                    <stop offset="0.3072" stop-color="#8DCAF4" stop-opacity="0.307" />
                    <stop offset="0.5596" stop-color="#60B7E8" stop-opacity="0.56" />
                    <stop offset="0.8571" stop-color="#229ED8" stop-opacity="0.857" />
                    <stop offset="1" stop-color="#0290CF" />
                </linearGradient>
                <linearGradient id="paint28_linear_4332_1543" x1="20.7769" y1="11.5356" x2="17.0986" y2="11.5356" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#B3DAFE" stop-opacity="0" />
                    <stop offset="0.1128" stop-color="#A9D6FC" stop-opacity="0.113" />
                    <stop offset="0.3072" stop-color="#8DCAF4" stop-opacity="0.307" />
                    <stop offset="0.5596" stop-color="#60B7E8" stop-opacity="0.56" />
                    <stop offset="0.8571" stop-color="#229ED8" stop-opacity="0.857" />
                    <stop offset="1" stop-color="#0290CF" />
                </linearGradient>
                <linearGradient id="paint29_linear_4332_1543" x1="17.1241" y1="12.7932" x2="18.5799" y2="12.7932" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EAF9FA" />
                    <stop offset="1" stop-color="#B3DAFE" />
                </linearGradient>
                <linearGradient id="paint30_linear_4332_1543" x1="17.8472" y1="12.9943" x2="17.8472" y2="14.4501" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#B3DAFE" stop-opacity="0" />
                    <stop offset="0.1128" stop-color="#A9D6FC" stop-opacity="0.113" />
                    <stop offset="0.3072" stop-color="#8DCAF4" stop-opacity="0.307" />
                    <stop offset="0.5596" stop-color="#60B7E8" stop-opacity="0.56" />
                    <stop offset="0.8571" stop-color="#229ED8" stop-opacity="0.857" />
                    <stop offset="1" stop-color="#0290CF" />
                </linearGradient>
                <linearGradient id="paint31_linear_4332_1543" x1="16.5267" y1="17.8738" x2="19.7154" y2="17.8738" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FD4755" />
                    <stop offset="1" stop-color="#A72B2B" />
                </linearGradient>
                <linearGradient id="paint32_linear_4332_1543" x1="17.8383" y1="14.8358" x2="17.8383" y2="15.9112" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#A72B2B" stop-opacity="0" />
                    <stop offset="1" stop-color="#42210B" />
                </linearGradient>
                <linearGradient id="paint33_linear_4332_1543" x1="17.847" y1="20.1302" x2="17.847" y2="22.0753" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#A72B2B" stop-opacity="0" />
                    <stop offset="1" stop-color="#42210B" />
                </linearGradient>
                <linearGradient id="paint34_linear_4332_1543" x1="11.1386" y1="15.2008" x2="15.6248" y2="27.64" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#60B7FF" />
                    <stop offset="1" stop-color="#2740B0" />
                </linearGradient>
                <linearGradient id="paint35_linear_4332_1543" x1="13.0007" y1="21.9223" x2="13.0007" y2="14.5215" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#60B7FF" />
                    <stop offset="1" stop-color="#2740B0" />
                </linearGradient>
                <linearGradient id="paint36_linear_4332_1543" x1="4.11392" y1="25.1979" x2="4.11392" y2="21.5126" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#60B7FF" />
                    <stop offset="1" stop-color="#2740B0" />
                </linearGradient>
                <linearGradient id="paint37_linear_4332_1543" x1="10.0399" y1="25.1979" x2="10.0399" y2="21.5126" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#60B7FF" />
                    <stop offset="1" stop-color="#2740B0" />
                </linearGradient>
                <linearGradient id="paint38_linear_4332_1543" x1="15.9618" y1="25.1979" x2="15.9618" y2="21.5126" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#60B7FF" />
                    <stop offset="1" stop-color="#2740B0" />
                </linearGradient>
                <linearGradient id="paint39_linear_4332_1543" x1="21.8874" y1="25.1979" x2="21.8874" y2="21.5126" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#60B7FF" />
                    <stop offset="1" stop-color="#2740B0" />
                </linearGradient>
                <linearGradient id="paint40_linear_4332_1543" x1="13" y1="21.666" x2="13" y2="25.5408" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#1F3596" stop-opacity="0" />
                    <stop offset="0.4068" stop-color="#1D3290" stop-opacity="0.407" />
                    <stop offset="0.9508" stop-color="#192B7F" stop-opacity="0.951" />
                    <stop offset="1" stop-color="#192A7D" />
                </linearGradient>
                <linearGradient id="paint41_linear_4332_1543" x1="12.6849" y1="18.1389" x2="14.4692" y2="20.9428" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FEE45A" />
                    <stop offset="1" stop-color="#FEA613" />
                </linearGradient>
                <linearGradient id="paint42_linear_4332_1543" x1="12.0039" y1="18.5707" x2="13.7883" y2="21.3746" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FEE45A" />
                    <stop offset="1" stop-color="#FEA613" />
                </linearGradient>
                <clipPath id="clip0_4332_1543">
                    <rect width="26" height="26" fill="white" />
                </clipPath>
            </defs>
        </svg>,
    }

    const dispatch = useDispatch();
    const modal = useSelector(state => state.modal);

    let [sectionAccordion, setSectionAccordion] = useState('');
    let [questionAccordion, setQuestionAccordion] = useState('');
    // let [deleteFile, setDeleteFile] = useState({ file: '', questionId: '', sectionIndex: '', questionIndex: '' });

    function getQuestionKeys(question) {
        let keys = ['qDescription']; // 'commentPoints', 'filePoints', 'completionScore'], //TODO: maybe use later
        if (question['questionType'].includes('F')) keys.push('templateFiles');
        if (['esg', 'software-development', 'directors-questionnaire', 'warranty-disclosure', 'repository'].includes(question['type'])) keys = keys.concat(['topTip', 'toolboxOffer']);
        if (['esg', 'software-development', 'business-audit-investment', 'business-audit-growth', 'business-audit-exit'].includes(question['type'])) keys = keys.concat(['riskDescription', 'recommendDescription']);
        else if (question['questionType'].includes('YN') && question['type'] != 'repository') keys = keys.concat(['ifYes', 'riskIfYes', 'ifNo', 'riskIfNo']);

        if (question['questionType'].includes('1to5')) keys = keys.concat(['if1', 'riskIf1', 'if2', 'riskIf2', 'if3', 'riskIf3', 'if4', 'riskIf4', 'if5', 'riskIf5']);
        if (question['questionType'].includes('D/ND')) keys = keys.concat(['ifD', 'riskIfD', 'ifNS', 'riskIfNS', 'ifND', 'riskIfND']);

        return [...keys];
    };

    const saveQuestion = async (e, question, sectionIndex, questionIndex) => {
        let attachment = [];
        if (e.target.files) for (const file of e.target.files) attachment.push(file);

        let res = await postRequest(`/question/update`, { attachment, questionId: question['_id'], [e.target.name]: e.target.value });
        if (!res || res['code'] != 200) return;
        if (res['code'] == '200') {
            sections[sectionIndex]['questions'][questionIndex] = res['body'];
            setQuestionAccordion('');
            setQuestionAccordion(question['qNo']);
        }
        showMessage(res['message']);
    };

    const confirmDeleteFile = async (deleteFile) => {
        const res = await postRequest('/repository/deleteTemplate', { file: deleteFile['file'], questionId: deleteFile['questionId'] });
        if (!res || res['code'] != 200) return;
        if (res['code'] == '200') {
            sections[deleteFile['sectionIndex']]['questions'][deleteFile['questionIndex']] = res['body'];
            setQuestionAccordion('');
            setQuestionAccordion(res['body']['qNo']);
        }
        showMessage(res['message']);
    };


    return (
        <>
            <div className='checklist-form-area df fww w100'>
                <div className='checklist-form w100 mb5rem'>
                    {sections.length > 0 && sections.map((section, sectionIndex) => {
                        return (
                            <Accordion expanded={sectionAccordion == section?.['sectionName']} onChange={() => { sectionAccordion == section['sectionName'] ? setSectionAccordion('') : setSectionAccordion(section?.['sectionName']) }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography className='fw500'>Section {sectionIndex + 1}: {section?.['sectionName']}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className='df fdc jcsb'>
                                        {section?.['questions'].length > 0 && section['questions'].map((question, questionIndex) => {
                                            let maxRisk = 5;
                                            let minRisk = -5;
                                            let questionKeys = getQuestionKeys(question);
                                            let riskKeys = questionKeys.filter(k => k.startsWith("riskIf"));

                                            return (
                                                <Accordion expanded={questionAccordion == question?.['qNo']} onChange={() => { questionAccordion == question?.['qNo'] ? setQuestionAccordion('') : setQuestionAccordion(question?.['qNo']) }}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <Typography className='fw400'>
                                                            <div className='df aic w100 pr'>
                                                                <span className='pa c15 fw400'>Ques {questionIndex + 1}:</span>
                                                                <span className={`fw400 ml5rem ${question['qDescription'] ? ' c15' : ' c16'}`}>{question['qDescription'] || 'Your question shows here once added'}</span>
                                                            </div>
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div className='df fdc jcsb'>
                                                            <div className='description-box df fww mt0-5rem'>
                                                                <div className='pr mb1rem w100'>
                                                                    <label className='w100 fw500 c15 df aic' for='qDescription'>
                                                                        <i className='icon pr mr0-5rem' style={{ top: '4px' }}>{svg['question']}</i> Question No. {questionIndex + 1} </label>
                                                                    <input name='qDescription' defaultValue={question?.['qDescription']} onBlur={(e) => saveQuestion(e, question, sectionIndex, questionIndex)} type='text' placeholder='Write question in your own words' className='bss bw2px bo5 w100 br8px p1rem mt0-5rem' />
                                                                </div>
                                                                <div className='pr mb1rem w100'>
                                                                    <label className='w100 fw500 c15 df aic' for='riskDescription'>
                                                                        <i className='icon pr mr0-5rem' style={{ top: '4px' }}>{svg['risk']}</i> Risk </label>
                                                                    <input defaultValue={question?.['riskDescription']} name='riskDescription' onBlur={(e) => saveQuestion(e, question, sectionIndex, questionIndex)} type='text' placeholder='Explain risk if the answer is “No”' className='bss bw2px bo5 w100 br8px p1rem mt0-5rem' />
                                                                </div>
                                                                <div className='pr mb1rem w100'>
                                                                    <label className='w100 fw500 c15 df aic' for='recommendDescription'>
                                                                        <i className='icon pr mr0-5rem' style={{ top: '4px' }}>{svg['recommendation']}</i> Recommendation </label>
                                                                    <input defaultValue={question?.['recommendDescription']} name='recommendDescription' onBlur={(e) => saveQuestion(e, question, sectionIndex, questionIndex)} type='text' placeholder='Yes: Great! Nothing more to do at this time.' className='bss bw2px bo5 w100 br8px p1rem mt0-5rem' />
                                                                </div>
                                                                {checklistObj['riskScoring'] && <>
                                                                    <div className='pr mb1rem w100'>
                                                                        <div className='heading-box df jcc fww'>
                                                                            <h4 className='df aic c15 fs1rem w100 jcc'>
                                                                                <i className='icon mr0-5rem pr' style={{ top: '4px' }}>
                                                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <circle cx="9" cy="9" r="9" transform="matrix(1 0 0 -1 0 18)" fill="#59B8FD" />
                                                                                        <path d="M8.10282 7.34219C8.10751 7.03516 8.35829 6.78906 8.66532 6.78906C8.97236 6.78906 9.22314 7.0375 9.22782 7.34219L9.41532 12.3953C9.42704 12.5875 9.35673 12.775 9.22079 12.9203C9.07548 13.075 8.87392 13.1641 8.66532 13.1641C8.45673 13.1641 8.25517 13.075 8.10986 12.9203C7.97392 12.775 7.90361 12.5875 7.91532 12.3953L8.10282 7.34219Z" fill="white" />
                                                                                        <path d="M8.66406 4.16406C9.07828 4.16406 9.41406 4.49985 9.41406 4.91406C9.41406 5.32828 9.07828 5.66406 8.66406 5.66406C8.24985 5.66406 7.91406 5.32828 7.91406 4.91406C7.91406 4.49985 8.24985 4.16406 8.66406 4.16406Z" fill="white" />
                                                                                    </svg>
                                                                                </i>
                                                                                Assign Risk Scores
                                                                            </h4>
                                                                            <p className='w100 fs0-75rem tac' style={{ color: '#65656580' }}>(Default if no action taken)</p>
                                                                        </div>
                                                                    </div>
                                                                    <>
                                                                        <div className="df jcsb w100">
                                                                            <b>Low Risk</b>
                                                                            <b>High Risk</b>
                                                                        </div>
                                                                        <div className="checklistRiskSlider riskSlider bsbb pr w100 tac">
                                                                            {riskKeys.map((key, idx) => <input key={idx} data-id={key} name={key} onChange={(e) => saveQuestion(e, question, sectionIndex, questionIndex)} type="range" className={key + " pen pa oyv l0 w100"} min={minRisk} max={maxRisk} defaultValue={question[key]} />)}
                                                                        </div>
                                                                        <div className="df jcsb w100 df jcsb w100 mt1-5rem">
                                                                            {Array(Math.abs(minRisk) + Math.abs(maxRisk) + 1).fill(1).map((j, i) => <span key={i} className="w1rem tac">{minRisk + i}</span>)}
                                                                        </div>
                                                                    </>
                                                                </>}
                                                                <div className='pr mb1rem w100'>
                                                                    <label className='w100 fw500 c15 df aic' for='topTip'>
                                                                        <i className='icon pr mr0-5rem' style={{ top: '4px' }}>{svg['tool-tip']}</i> Tool Tip <span className='c16 fw400 ml0-5rem'>(Optional)</span>
                                                                    </label>
                                                                    <input defaultValue={question?.['topTip']} name='topTip' onBlur={(e) => saveQuestion(e, question, sectionIndex, questionIndex)} type='text' placeholder="Yes: Great! Nothing more to do at this time." className='bss bw2px bo5 w100 br8px p1rem mt0-5rem' />
                                                                </div>
                                                                <div className='pr mb1rem w100'>
                                                                    <label className='w100 fw500 c15 df aic' for='toolboxOffer'>
                                                                        <i className='icon pr mr0-5rem' style={{ top: '4px' }}>{svg['toolbox-offer']}</i> Toolbox Offer <span className='c16 fw400 ml0-5rem'>(Optional)</span>
                                                                    </label>
                                                                    <input defaultValue={question?.['toolboxOffer']} name='toolboxOffer' onBlur={(e) => saveQuestion(e, question, sectionIndex, questionIndex)} type='text' placeholder="Please provide toolbox offer for help." className='bss bw2px bo5 w100 br8px p1rem mt0-5rem' />
                                                                </div>
                                                                <div className='pr mb1rem w100'>
                                                                    <label className='w100 fw500 c15 df aic' for='templateFiles'>
                                                                        <i className='icon pr mr0-5rem text-gradient fa fa-file-text' style={{ fontSize: '22px' }} /> Template Files <span className='c16 fw400 ml0-5rem'>(Optional)</span>
                                                                    </label>
                                                                    <label for="profile" className="w100 bsbb bg5 br5px tac p1rem bsd bo3 bw2px cp pr upload-pic-area db mt0-5rem">
                                                                        <span className="pen fs1rem fw500 tac">
                                                                            <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M59.4632 31.0446C58.5439 25.1606 53.4429 20.6445 47.3047 20.6445C44.9955 20.6445 42.7766 21.2834 40.852 22.4751C37.924 17.5543 32.6528 14.4922 26.7969 14.4922C17.7506 14.4922 10.3906 21.8522 10.3906 30.8984C10.3906 30.9545 10.3906 31.0126 10.3927 31.0687C4.57871 32.048 0 37.1168 0 43.2031C0 49.9883 5.65619 55.5078 12.4414 55.5078H57.5586C64.3438 55.5078 70 49.9883 70 43.2031C70 37.0649 65.3472 31.9639 59.4632 31.0446Z" fill="white" />
                                                                                <g clip-path="url(#clip0_4332_1603)">
                                                                                    <path d="M35 56C42.732 56 49 49.732 49 42C49 34.268 42.732 28 35 28C27.268 28 21 34.268 21 42C21 49.732 27.268 56 35 56Z" fill="#4A67FF" />
                                                                                    <path d="M34.9857 49.6555C33.7421 49.6555 32.4987 49.6555 31.2554 49.6555C29.5503 49.6555 28.3198 48.4294 28.3111 46.7248C28.3078 46.1298 28.3078 45.5346 28.3111 44.9392C28.3143 44.3256 28.6862 43.9308 29.255 43.9297C29.8237 43.9286 30.2005 44.3207 30.2043 44.9338C30.2082 45.5468 30.2043 46.1456 30.2043 46.7516C30.2087 47.387 30.5822 47.7573 31.2199 47.7578C33.7388 47.7578 36.2575 47.7578 38.7761 47.7578C39.4143 47.7578 39.7883 47.387 39.7932 46.7521C39.7976 46.1462 39.7932 45.5402 39.7932 44.9343C39.7932 44.3284 40.1711 43.9286 40.7421 43.9297C41.313 43.9308 41.6904 44.3256 41.6854 44.9387C41.6794 45.7136 41.7226 46.4967 41.6253 47.2613C41.447 48.6602 40.2543 49.6478 38.8389 49.6544C37.5576 49.6582 36.2714 49.6555 34.9857 49.6555Z" fill="white" />
                                                                                    <path d="M34.0538 37.6102C33.9094 37.7448 33.8247 37.8186 33.7459 37.8979C33.14 38.5098 32.5373 39.1256 31.9286 39.7354C31.4966 40.1685 30.8945 40.2002 30.504 39.8212C30.1136 39.4423 30.1289 38.8205 30.5538 38.3928C31.7908 37.1481 33.0319 35.9078 34.2769 34.6719C34.7144 34.2344 35.2865 34.2344 35.724 34.6719C36.9774 35.9151 38.2257 37.1636 39.469 38.4174C39.878 38.8303 39.8802 39.451 39.5001 39.8218C39.12 40.1926 38.5158 40.1762 38.0985 39.7622C37.4805 39.147 36.8718 38.5219 36.2583 37.9028C36.179 37.823 36.0942 37.7486 35.9679 37.6294C35.9597 37.8011 35.9498 37.9121 35.9498 38.0231C35.9498 39.9804 35.9525 41.9371 35.9498 43.8944C35.9498 44.6288 35.3034 45.0658 34.6313 44.8044C34.2594 44.6595 34.0576 44.3368 34.0576 43.8643C34.0554 41.9284 34.0554 39.9926 34.0576 38.057L34.0538 37.6102Z" fill="white" />
                                                                                </g>
                                                                                <defs>
                                                                                    <clipPath id="clip0_4332_1603">
                                                                                        <rect width="28" height="28" fill="white" transform="translate(21 28)" />
                                                                                    </clipPath>
                                                                                </defs>
                                                                            </svg>
                                                                        </span>
                                                                        <br />
                                                                        <div><input className="" id="profile" type="file" accept="/*" onChange={(e) => saveQuestion(e, question, sectionIndex, questionIndex)} /></div>
                                                                        <p className='c12 fw400 fsi fs0-75rem'>If you have a template document or spreadsheet you want to offer the user who will complete the template, you can upload it <br /> here. Upload or drag and drop (max 100MB)</p>
                                                                    </label>
                                                                    <div className="df fww mt1rem">
                                                                        {question['templateFiles'] && question['templateFiles'].map(file =>
                                                                            <span className="df jcc aic br25px lh1 bg3 c1 px0-5rem fw600 mr0-5rem mb0-5rem">
                                                                                <Link key={file['originalname'] + file['etag']} className="fw400 py0-5rem pl0-5rem" target="_blank" to={file['location']}>{file['originalname']}</Link>
                                                                                <i className="fa fa-trash pl1rem pr0-5rem" style={{ "cursor": "pointer" }} onClick={() => confirmDeleteFile({ file, questionId: question['_id'], sectionIndex, questionIndex })} />
                                                                            </span>)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                            )
                                        })}
                                        {/* <div className='df aic w100 mb0-5rem pr cp bss bw2px bo5 w100 br25px p1rem bsbb'>
                                            <span className='pa ml0-5rem c15 w2rem h2rem br100 bg3 cp tac lh2' style={{ lineHeight: '40px' }}>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18.3098 11.5825H11.5805V18.3118C11.5805 19.1859 10.8714 19.895 9.9973 19.895C9.1232 19.895 8.41406 19.1859 8.41406 18.3118V11.5825H1.6848C0.810699 11.5825 0.101562 10.8734 0.101562 9.99925C0.101562 9.12515 0.810699 8.41602 1.6848 8.41602H8.41406V1.68675C8.41406 0.812653 9.1232 0.103516 9.9973 0.103516C10.8714 0.103516 11.5805 0.812653 11.5805 1.68675V8.41602H18.3098C19.1839 8.41602 19.893 9.12515 19.893 9.99925C19.893 10.8734 19.1839 11.5825 18.3098 11.5825Z" fill="#FAFAFA" />
                                                </svg>
                                            </span>
                                            <span className='pr c15 ml3rem'>Add Question</span>
                                        </div> */}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                </div>
            </div>
            <div className='bottom-btn-area df aic w100 jcsb mt5rem bsbb'>
                <p className='c15 fs1rem' style={{ color: '#15131350' }}>4/6 Question Creation</p>
                <button onClick={() => handleCreateChecklist()} className='shadow c1 bg4 bg3-hover cp fw400 fs1rem pr br38px w200px h3rem df aic jcc'>
                    Ok, next
                    <i className='icon pr ml0-5rem' style={{ top: '3px' }}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.42954 17.0154H21.1966L16.7035 22.1842C15.413 23.6688 17.3488 25.8957 18.6394 24.4111L22.7314 19.6964L25.463 16.5491C25.9924 15.9365 25.9924 14.9471 25.463 14.3344L18.6394 6.47557C18.3798 6.16869 18.0225 5.99649 17.6502 6.00005C16.4212 6.00023 15.818 7.72225 16.7035 8.70263L21.2073 13.8714H6.35888C4.46611 13.9794 4.60744 17.1238 6.42954 17.0154Z" fill="white" />
                        </svg>
                    </i>
                </button>
            </div>

            {/* Delete File */}
            {/* <Modal open={modal['open'] == 'deleteFile'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw  df jcc aic advisor-dashboard-seemore">
                <div className="poppins-font-style mah100 bg1 shadow w100 py2rem px4rem df fdc m1rem pr br10px pr" style={{ outline: 'none', paddingBottom: '2rem', maxWidth: '750px' }}>
                    <div className='top-logo pa t0 r0 mr1rem mt1rem'><img className='w4rem' src={require('../../../images/favicon.png')} alt="" /></div>
                    <div className='content-area creaing-modal pr my3rem'>
                        <h1 className='text-gradient fs2rem tac lh1 fw600 mb2rem'>DELETE FILE</h1>
                        <p className='c15 fs1rem tac mb0-5rem'>Are you sure you want to delete this file?</p>
                        <p className="fs1-25rem fw500 mb2rem tac ttu">({deleteFile && deleteFile['file']['originalname']})</p>
                        <div className='df jcsa gg1rem'>
                            <span onClick={() => dispatch(toggleModal({ open: '' }))} className='new-shadow cp c14 bg1 fw400 fs1rem pr br38px bw1px bss bsbb w200px h3rem df aic jcc'>Cancel</span>
                            <span onClick={() => confirmDeleteFile()} className='shadow c1 bg4 cp fw400 fs1rem pr br38px px0-5rem w200px h3rem df aic jcc'>Confirm</span>
                        </div>
                    </div>
                    <svg className="pa cp" onClick={() => dispatch(toggleModal({ open: '' }))} style={{ left: '50%', transform: 'translateX(-50%)', bottom: '-80px' }} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.8" x="4" y="4" width="52" height="52" rx="26" fill="#151313" />
                        <path d="M48.5626 11.4375C44.8913 7.76665 40.214 5.26685 35.1221 4.2542C30.0302 3.24156 24.7523 3.76155 19.9559 5.74843C15.1595 7.73531 11.06 11.0998 8.17569 15.4166C5.29142 19.7333 3.75195 24.8084 3.75195 30C3.75195 35.1917 5.29142 40.2667 8.17569 44.5835C11.06 48.9002 15.1595 52.2647 19.9559 54.2516C24.7523 56.2385 30.0302 56.7585 35.1221 55.7458C40.214 54.7332 44.8913 52.2334 48.5626 48.5625C51.0004 46.1249 52.9342 43.231 54.2535 40.0461C55.5729 36.8611 56.252 33.4474 56.252 30C56.252 26.5526 55.5729 23.1389 54.2535 19.954C52.9342 16.769 51.0004 13.8751 48.5626 11.4375ZM39.3751 36.6375C39.5624 36.8129 39.7117 37.0249 39.8138 37.2603C39.9159 37.4958 39.9686 37.7496 39.9686 38.0063C39.9686 38.2629 39.9159 38.5168 39.8138 38.7522C39.7117 38.9876 39.5624 39.1996 39.3751 39.375C39.1999 39.5488 38.9921 39.6863 38.7636 39.7796C38.5352 39.8729 38.2906 39.9202 38.0438 39.9188C37.5519 39.9167 37.0805 39.7214 36.7313 39.375L30.0001 32.6438L23.3626 39.375C23.0133 39.7214 22.5419 39.9167 22.0501 39.9188C21.8033 39.9202 21.5587 39.8729 21.3302 39.7796C21.1018 39.6863 20.894 39.5488 20.7188 39.375C20.3696 39.0237 20.1736 38.5485 20.1736 38.0531C20.1736 37.5578 20.3696 37.0826 20.7188 36.7313L27.3563 30L20.7188 23.3625C20.4116 23.0038 20.2511 22.5424 20.2694 22.0705C20.2876 21.5986 20.4832 21.151 20.8171 20.8171C21.1511 20.4832 21.5987 20.2875 22.0706 20.2693C22.5425 20.2511 23.0039 20.4116 23.3626 20.7188L30.0001 27.3563L36.6376 20.7188C36.8111 20.539 37.0184 20.3952 37.2476 20.2956C37.4768 20.196 37.7233 20.1424 37.9732 20.1381C38.223 20.1337 38.4713 20.1786 38.7038 20.2702C38.9363 20.3618 39.1484 20.4983 39.3282 20.6719C39.5079 20.8455 39.6517 21.0528 39.7514 21.2819C39.851 21.5111 39.9045 21.7576 39.9089 22.0075C39.9132 22.2573 39.8683 22.5056 39.7767 22.7381C39.6851 22.9706 39.5486 23.1828 39.3751 23.3625L32.6438 30L39.3751 36.6375Z" fill="white" />
                    </svg>
                </div>
            </Modal> */}
        </>
    )
}