import React, { Fragment, useEffect } from 'react'
import ModalWrapper from '../../common/Modal/ModalWrapper'
import TrashIcon from '@rsuite/icons/Trash';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../../reducers/modalReducer';
import { Divider } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import Button from '../../common/Button';

const UnallocatedFilesModal = ({ company, tab, onClickUnallocatedBinAuditLog, unallocatedFiles, modalType, handleEditDispatch, setDeleteUnallocatedFile, loading, moveUnallocatedFile, setUnallocatedFiles }) => {

    const dispatch = useDispatch()
    const { rModalData } = useSelector(state => state.fileAllocation)

    useEffect(() => {
        if (rModalData.newQNo) {
            const data = [...unallocatedFiles].reduce((acc, cur) => {
                if (cur.id === rModalData.fileData.id) {
                    acc.push({
                        ...cur,
                        "sectionNo": rModalData.newQNo,
                        "name": rModalData.newQName
                    })
                    moveUnallocatedFile(rModalData.fileData, true, rModalData.newQNo, rModalData.newQName)
                } else {
                    acc.push({ ...cur })
                }
                return acc
            }, [])
            dispatch(setUnallocatedFiles(data))
        }

    }, [rModalData])

    const onClickReallocationComplete = async () => {
        unallocatedFiles.filter((un) => un.sectionNo).map((un) => moveUnallocatedFile(un, true, un.sectionNo, un.name))
    }

    const getUnallocatedFileHTML = (unallocatedFile, index) => {
        const file = unallocatedFile.file;
        const fileUrl = file.location;
        const title = file.originalname;
        const key = file.key;
        let module = company.modules[tab];
        if (module) module = JSON.parse(JSON.stringify(module));
        return <Fragment key={`unallocateFile-${index}`}>
            <tr>
                <td className={"mx1rem fs1rem fw600 bg1"}>
                    <div
                        onClick={() => getViewableURL(fileUrl)}
                        className={`tal fis-td mx1rem fs14px fw600 tdu bg1 lightDot red`} style={{ flex: 1 }}
                    >
                        {title}
                    </div>
                </td>
                <td className="frs-td mx1rem fs1rem fw600 bg1">
                    <div className="df fdr aic jcfs">
                        <div className="rs-nav rs-nav-default">
                            <div
                                onClick={() =>
                                    handleEditDispatch({ key: key, fileName: title, from: "unallocated", actionType: "reallocate", fileData: unallocatedFile, index, module })
                                }
                                className="bg62 br10px fw400 fs13px df aic p0-5rem jcsb c49 fsi cp">
                                {(unallocatedFile.sectionNo && `${unallocatedFile.sectionNo}. ${unallocatedFile['name']}`) || 'Please select section'}
                                <KeyboardArrowDown className='c3' />
                            </div>
                        </div>
                    </div>
                </td>

                <td className="frs-td fs1rem fw600 bg1">
                    <TrashIcon data-tooltip={"Delete"} onClick={e => {
                        e.preventDefault();
                        setDeleteUnallocatedFile(unallocatedFile);
                        dispatch(toggleModal({ open: 'deleteUnallocatedFile', from: 'unallocated' }))
                    }}
                        className="cp f9 h1rem"
                    />
                </td>
            </tr>
        </Fragment>
    };

    return (
        <>
            <ModalWrapper
                modalType={modalType}
                modalCardClasses="maw800px mah80 p2rem"
            >
                <div className='df jcsb pr'>
                    <div className='df jcfs aic'>
                        <img src={require("../../../../images/dataRepo/file.svg")} alt={"fileManagement"} />
                        <h1 className="c15 fw600 fs20px mb0rem ml0-5rem">Unallocated Files Bin</h1>
                    </div>

                    <button
                        onClick={onClickUnallocatedBinAuditLog}
                        className='cp tac c1 bg5 c15 br20px px1-5rem py0-5rem fw500'>
                        Log
                    </button>
                </div>

                <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />

                <div className="dg gg0-5rem mb2rem fs1rem mah400px oya" >
                    <table className="fis bcc">
                        <thead>
                            <tr>
                                <th className="w50 tal fs14px fw400 c37">Unallocated File</th>
                                <th className="w40 tal fs14px fw400 c37">Allocation Section</th>
                                <th className="w10 fs14px fw400 c37">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {unallocatedFiles && unallocatedFiles.length > 0 && unallocatedFiles.map(getUnallocatedFileHTML)}
                        </tbody>
                    </table>
                    {unallocatedFiles && unallocatedFiles.length == 0 ? (
                        <div className='df fdc jcc aic m2rem'>
                            <img src={require("../../../../images/dataRepo/empty-inbox.svg")} alt={"fileManagement"} />
                            <p className="fs1rem fw600 mt1rem tac c15 op80">No files found</p>
                        </div>
                    ) : null}
                </div>
                {
                    unallocatedFiles.filter((un) => un.sectionNo).length ? <div className="df w100 fdr jcc mt2rem">
                        <Button
                            onClick={onClickReallocationComplete}
                            text={"Confirm Allocation"}
                            disabled={loading}
                            btnType='warning'
                            customClassName="maw300px"
                        />
                    </div> : null
                }

            </ModalWrapper>
        </>
    )
}

export default UnallocatedFilesModal