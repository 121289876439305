import React from 'react'
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../../reducers/modalReducer';

const imageMap = {
    emailHeadIcon: require('../../../../images/emailHeadIcon.png'),
    googleValidatior: require('../../../../images/googleValidatior.svg'),
    smsHeadIcon: require('../../../../images/profilePage/sms_auth.svg'),
};

const ModalWrapper = ({
    children,
    girlComponent,
    modalType,
    headerType,
    headerBgIcon,
    heading,
    onCloseHandler,
    modalCardClasses = ""
}) => {

    const modal = useSelector(state => state.modal)
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(toggleModal({ open: '' }))
        if (onCloseHandler) onCloseHandler()
    }

    const isMob = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        return mobileRegex.test(userAgent)
    }

    return (
        <Modal open={modal['open'] === modalType} onClose={handleClose} className='backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic'>
            <>
                <div className={`mah80 oya bg1 shadow w100 bsbb df fdc m1rem maw700px br20px ${modalCardClasses} hideScrollbar`}
                    style={{ padding: headerType ? "0rem" : isMob() ? "2rem 1rem" : "3rem", zIndex: 9 }}>
                    {headerType && ["primary"].includes(headerType)
                        ? <div className='bg-gradient3 p1rem df aic pr' style={{ height: "50px", padding: '1rem 1.5rem' }}>
                            <span className='c1'>{heading}</span>
                            <img className='pa' src={imageMap[headerBgIcon ?? "emailHeadIcon"]} alt="headerBgIcon" style={{ width: '80px', right: "80px", bottom: "0px" }} />
                        </div> : null
                    }
                    <div className='pr' style={{ padding: headerType ? "3rem" : "0rem" }}>{children}</div>
                </div>
                {girlComponent && girlComponent}
                <svg className="pa cp" onClick={handleClose}
                    style={{ left: '50%', transform: 'translateX(-50%)', bottom: '20px' }}
                    width="60"
                    height="60"
                    viewBox="0 0 60 60"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.8" x="4" y="4" width="52" height="52" rx="26" fill="#151313" />
                    <path d="M48.5606 11.4375C44.8894 7.76665 40.2121 5.26685 35.1201 4.2542C30.0282 3.24156 24.7503 3.76155 19.9539 5.74843C15.1575 7.73531 11.058 11.0998 8.17374 15.4166C5.28947 19.7333 3.75 24.8084 3.75 30C3.75 35.1917 5.28947 40.2667 8.17374 44.5835C11.058 48.9002 15.1575 52.2647 19.9539 54.2516C24.7503 56.2385 30.0282 56.7585 35.1201 55.7458C40.2121 54.7332 44.8894 52.2334 48.5606 48.5625C50.9984 46.1249 52.9322 43.231 54.2516 40.0461C55.5709 36.8611 56.25 33.4474 56.25 30C56.25 26.5526 55.5709 23.1389 54.2516 19.954C52.9322 16.769 50.9984 13.8751 48.5606 11.4375ZM39.3731 36.6375C39.5604 36.8129 39.7098 37.0249 39.8119 37.2603C39.914 37.4958 39.9666 37.7496 39.9666 38.0063C39.9666 38.2629 39.914 38.5168 39.8119 38.7522C39.7098 38.9876 39.5604 39.1996 39.3731 39.375C39.1979 39.5488 38.9901 39.6863 38.7617 39.7796C38.5332 39.8729 38.2886 39.9202 38.0419 39.9188C37.55 39.9167 37.0786 39.7214 36.7294 39.375L29.9981 32.6438L23.3606 39.375C23.0114 39.7214 22.54 39.9167 22.0481 39.9188C21.8013 39.9202 21.5567 39.8729 21.3283 39.7796C21.0998 39.6863 20.8921 39.5488 20.7169 39.375C20.3676 39.0237 20.1716 38.5485 20.1716 38.0531C20.1716 37.5578 20.3676 37.0826 20.7169 36.7313L27.3544 30L20.7169 23.3625C20.4097 23.0038 20.2492 22.5424 20.2674 22.0705C20.2856 21.5986 20.4812 21.151 20.8152 20.8171C21.1491 20.4832 21.5967 20.2875 22.0686 20.2693C22.5405 20.2511 23.0019 20.4116 23.3606 20.7188L29.9981 27.3563L36.6356 20.7188C36.8092 20.539 37.0165 20.3952 37.2457 20.2956C37.4748 20.196 37.7214 20.1424 37.9712 20.1381C38.2211 20.1337 38.4693 20.1786 38.7018 20.2702C38.9343 20.3618 39.1465 20.4983 39.3262 20.6719C39.506 20.8455 39.6498 21.0528 39.7494 21.2819C39.849 21.5111 39.9026 21.7576 39.9069 22.0075C39.9113 22.2573 39.8664 22.5056 39.7748 22.7381C39.6832 22.9706 39.5467 23.1828 39.3731 23.3625L32.6419 30L39.3731 36.6375Z" fill="white" />
                </svg>
            </>
        </Modal>
    )
}

export default ModalWrapper