import React, { useState } from 'react'
import moment from 'moment'
import TrashIcon from '@rsuite/icons/Trash';
import EditIcon from '@rsuite/icons/Edit';
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentScore, setLogList, setRModalData, setOpenRModal, setDeleteImportResult, setFileReport, setMatchFileProcessing, updateFileAllocation } from '../../../reducers/filesAllocationReducer';
import { getRequest, postRequest, showMessage } from '../../../config';
import { toggleModal } from '../../../reducers/modalReducer';
import { useLocation } from "react-router-dom";
import { setCompany } from '../../../reducers/companyReducer';
import ModalWrapper from '../../common/Modal/ModalWrapper';
import Button from '../../common/Button';
import { Divider } from '@mui/material';
import Send from "../../../../images/dataRepo/send.svg"
import Search from "../../../../images/dataRepo/search.svg"
import { KeyboardArrowDown } from '@mui/icons-material';

const ImportSummaryModal = ({ modalType }) => {

    // hooks and vars
    const dispatch = useDispatch()
    const location = useLocation();
    const { fileReport, currentScore, reportHistories, profileClass, tab } = useSelector(state => state.fileAllocation)
    const { selectedCompany: company } = useSelector(state => state.company)
    let modal = useSelector(state => state.modal);
    const [moduleContent] = useState({
        'kpi': {
            heading: 'KPI',
            aiEnhanced: true,
            uploadFiles: false,
            subheading: 'Key Performance Indicators'
        }, 'repository': {
            aiEnhanced: false,
            uploadFiles: true,
            heading: 'Repository',
            subheading: 'Securely store all your important documents here'
        }, 'business-audit-investment': {
            aiEnhanced: true,
            uploadFiles: false,
            heading: 'Business Audit',
            subheading: 'Get expert recommendations on how to reduce risk'
        }, 'business-audit-growth': {
            aiEnhanced: true,
            uploadFiles: false,
            heading: 'Business Audit',
            subheading: 'Get expert recommendations on how to reduce risk'
        }, 'business-audit-exit': {
            aiEnhanced: true,
            uploadFiles: false,
            heading: 'Business Audit',
            subheading: 'Get expert recommendations on how to reduce risk'
        }, 'directors-questionnaire': {
            aiEnhanced: false,
            uploadFiles: true,
            heading: 'Directors Questionnaire',
            subheading: ''
        }, 'two-minute-test-exit': {
            aiEnhanced: true,
            uploadFiles: false,
            heading: 'Exit Ready Test',
            subheading: 'See How Exit Ready You Are'
        }, 'two-minute-test-growth': {
            aiEnhanced: true,
            uploadFiles: false,
            heading: 'Growth Ready Test',
            subheading: 'See How Growth Ready You Are'
        }, 'esg': {
            aiEnhanced: false,
            uploadFiles: true,
            heading: 'Environmental, Social & Governmental',
            subheading: 'Get expert recommendations on how to reduce risk'
        }, 'software-development': {
            aiEnhanced: false,
            uploadFiles: true,
            heading: 'Software Development',
            subheading: 'Get expert recommendations on how to reduce risk'
        }, 'warranty-disclosure': {
            aiEnhanced: false,
            uploadFiles: true,
            heading: 'Warranty Disclosure Schedule',
            subheading: 'Get expert recommendations on how to reduce risk'
        }, 'two-minute-test-investment': {
            aiEnhanced: true,
            uploadFiles: false,
            heading: 'Investment Ready Test',
            subheading: 'How Investment Ready Are You'
        }, 'unaudited-certification': {
            sections: [],
            heading: 'Certification',
            subheading: 'This is your current certification',
        }, 'investor-lead': {
            uploadFiles: true,
            heading: 'Investor Lead Introduction',
            subheading: 'Securely store all your important documents here'
        }
    });

    const onClickFileImportAuditLog = async (fileReportId) => {
        const res = await getRequest(`/company/getFileReportLogs/${fileReportId}`);
        setLogList(res['body']);
        dispatch(toggleModal({ open: 'fileReportAuditLog' }))
    };

    const onClickReallocationComplete = async () => {
        const fileReportData = {
            ...fileReport,
            status: 'Complete',
            currentScore: currentScore
        }
        const moduleName = tab && tab !== "" ? tab : location.hash.includes("#") ? location.hash.replace("#", "") : location.hash
        dispatch(setFileReport(fileReportData))
        dispatch(toggleModal({ open: 'fileUploadFilingAssistant' }))
        const resp = await postRequest(`/company/allocateFiles`, { companyId: company._id, tab: moduleName, fileReport: fileReportData }, true);
        if (!resp) return;
        const updatedReport = resp.body['report'];

        for (let i = 0; i < reportHistories.length; i++) {
            if (reportHistories[i]['_id'] == updatedReport['_id']) {
                reportHistories[i] = updatedReport;
                break;
            }
        }
        showMessage(resp['message']);
        dispatch(toggleModal({ open: '' }))

        if (location.pathname.includes(company._id)) {
            const res = await getRequest(`/company/get/${company._id}?moduleName=${moduleName}`);
            if (res['code'] == 200) {
                res['body']['modules']['kpi'] = { type: "kpi", sections: [] };
                for (let module of Object.values(res['body']['modules'])) {
                    let content = module['type'].includes('directors-questionnaire') ? moduleContent['directors-questionnaire'] : moduleContent[module['type']]
                    if (content) {
                        module['heading'] = module['director'] ? `${content['heading']} - ${module['director']['name']} (${module['director']['designation']})` : content['heading'];
                        module['aiEnhanced'] = content['aiEnhanced'];
                        module['subheading'] = content['subHeading'];
                        module['uploadFiles'] = content['uploadFiles'];
                    }
                }
                res['body']['modules']['unaudited-certification'] = moduleContent['unaudited-certification'];
                // if (company?.['modules']) res['body']['modules'] = company['modules'];
                dispatch(setCompany(res['body']))
            }
        }
        dispatch(setMatchFileProcessing(false))
        dispatch(updateFileAllocation({ underProcessingTab: '' }))
    };

    const getSectionNameFromSection = (section, qNo) => {
        if (section.sectionNo == qNo || section.no == qNo)
            return section.sectionName || section.name;

        if (section.subFolders) {
            for (const subFolder of Object.values(section.subFolders)) {
                let result = getSectionNameFromSection(subFolder, qNo);
                if (result != "")
                    return result;
            }
        }

        return "";
    };

    const getSectionName = (module, qNo, file, key) => {
        if (qNo == "DXXX" || qNo == "SXXX") return <div
            onClick={() => handleEditDispatch({ key: key, fileName: file.originalname, from: 'fileReport', actionType: "move", fileData: file, index: "0" })}
            className='bg62 br10px df aic p1rem jcsb fsi'
        >
            <p>Please select section</p>
            <KeyboardArrowDown className='c3' />
        </div>
        const sections = [...module?.sections];
        for (const section of sections) {
            const sectionName = getSectionNameFromSection(section, qNo);
            if (sectionName != "")
                return `${qNo} ${sectionName}`;
        }

        return "Unknown";
    };

    const handleEditDispatch = (action) => {
        dispatch(setRModalData({ ...action }))
        dispatch(setOpenRModal(true))
    }

    function isSubarray(subarray, array) {
        const sortedSubarray = subarray.slice().sort();
        const sortedArray = array.slice().sort();

        let i = 0;
        return sortedArray.some((element) => {
            if (element === sortedSubarray[i]) {
                i++;
            }
            return i === sortedSubarray.length;
        });
    };

    const calcAccuracyScore = (count) => {
        let allocated = 0;
        for (const key of Object.keys(fileReport.importResult)) {
            const currentSection = fileReport.importResult[key]['section'];
            const oldSection = fileReport.importResult[key]['originalSection'];

            if (oldSection.includes("DXXX") || oldSection.includes("SXXX")) continue;

            if (isSubarray(oldSection, currentSection)) {
                allocated++;
            }
        }
        const totalCount = count ?? Object.keys(fileReport.importResult).length;
        dispatch(setCurrentScore(!totalCount ? 0 : Math.round(allocated / totalCount * 100)))
        return !totalCount ? 0 : Math.round(allocated / totalCount * 100);
    };

    const removeSectionFromImport = async (fileKey, index) => {
        console.log("***************HAR-fileReport.importResult", fileKey);

        let sections = [...fileReport.importResult[fileKey]['section']];
        const _fileReport = { ...fileReport };  // Clone the fileReport object
        const qNo = sections[index];

        sections.splice(index, 1);

        // Get the file data
        const file = _fileReport.importResult[fileKey]['file'];

        console.log(fileReport.importResult, "****************fileReport.importResult");

        if (sections.length === 0) {
            const { [fileKey]: removedFile, ...restOfFileReport } = _fileReport.importResult;
            _fileReport.importResult = restOfFileReport;
        } else {
            _fileReport.importResult[fileKey] = {
                ..._fileReport.importResult[fileKey],
                section: sections
            };
        }

        const score = calcAccuracyScore(Object.keys(_fileReport.importResult).length);

        // Send delete request
        const res = await postRequest(`/company/deleteSection`, {
            companyId: company._id,
            fileReportId: _fileReport['_id'],
            fileKey,
            index,
            qNo,
            score,
            file
        });

        dispatch(setFileReport(_fileReport));

        // if (!res) return;

        // if (res['code'] === 200) {
        //     dispatch(setDeleteImportResult({ index: fileKey, qNo }));
        // }
    };


    const addNewSectionToFile = async (fileKey, oldIndex) => {
        const newSection = fileReport.importResult[fileKey]['section'][oldIndex];
        let index = 0;

        if (newSection == '') {
            return;
        }

        let newArray = {
            ...fileReport,
            importResult: {
                ...fileReport.importResult,
                [fileKey]: {
                    ...fileReport.importResult[fileKey],
                    section: [...(fileReport.importResult[fileKey].section || [])],
                    originalSection: [...(fileReport.importResult[fileKey].originalSection || [])]
                }
            }
        };

        if (Array.isArray(fileReport.importResult[fileKey]['section'])) {
            newArray.importResult[fileKey]['section'].push(newSection)
            index = newArray.importResult[fileKey]['section'].length - 1;
        } else {
            newArray.importResult[fileKey]['section'] = [newSection];
        }

        const newScore = calcAccuracyScore()
        const res = await postRequest(`/company/allocateFile`, {
            companyId: company._id, fileReportId: newArray['_id'], fileKey, index, qNo: newSection, score: newScore, file: newArray.importResult[fileKey]['file'],
            isAdd: true,
        });
        dispatch(setFileReport({ ...newArray, currentScore: newScore }))
        if (!res) return;
    };

    const getImportResultEntryHTML = key => {
        const fileName = fileReport.importResult[key].file['originalname'];
        const file = fileReport.importResult[key].file
        const section = fileReport.importResult[key]['section'];
        let module = { ...company.modules[tab || "repository"] };
        if (module) module = JSON.parse(JSON.stringify(module));
        if (Array.isArray(section)) {
            return <>
                {
                    section.map((qNo, index) => {
                        const sectionName = getSectionName(module, qNo, file, key);
                        return <React.Fragment key={`${index + section.length - 1}-section-allocate`}>
                            <tr className={`${index == section.length - 1 ? 'fis-tr' : ''}`}>
                                <td className={`tal fis-td mx1rem fs14px fw600 tdu bg1 lightDot ${index != 0 ? 'vh' : ''} ${qNo == "DXXX" ? 'red' : (fileReport['status'] == 'In Progress' ? 'orange' : 'green')}`}>
                                    {fileName}
                                </td>
                                <td className={`tal mx1rem fs14px fw400 bg1 c15`}>
                                    {sectionName}
                                </td>
                                {fileReport['status'] == 'In Progress' && (
                                    <td className={`mx1rem fs1rem fw600 bg1 tal fis-td fis-td-action`}>
                                        <div className="df fdr jcc aic">
                                            {qNo !== "DXXX" ?
                                                <Send
                                                    data-tooltip={"Cut"}
                                                    className="mr1rem cp"
                                                    onClick={() => handleEditDispatch({ key: key, fileName: file.title, from: 'fileReport', actionType: "move", fileData: file, index })}
                                                /> : null
                                            }
                                            <TrashIcon data-tooltip={"Delete"} onClick={() => removeSectionFromImport(key, index)} className="cp f9 ml1rem" />
                                        </div>
                                    </td>
                                )}
                            </tr>
                        </React.Fragment>
                    })
                }
            </>

        } else {
            const sectionName = getSectionName(module, section, file, key);
            return <>
                <tr className={`${index == section.length - 1 ? 'fis-tr' : ''}`}>
                    <td className={`tal fis-td mx1rem fs1rem fw600 bg1`}>
                        {fileName}
                    </td>
                    <td className={`tal fis-td mx1rem fs1rem fw600 bg1 fis-td`}>
                        {sectionName}
                    </td>
                    <td className={`mx1rem fs1rem fw600 bg1 tal fis-td fis-td-action`}>
                        <EditIcon onClick={handleEditDispatch({ key: key, fileName: fileName })} />
                    </td>
                </tr>
            </>
        }
    };

    if (fileReport && Object.keys(company).length && modal['open'] == modalType) {
        return (
            <>
                <ModalWrapper
                    modalType={modalType}
                    // onCloseHandler={() => dispatch(fetchCompanyData({ ...(tab && { moduleName: tab }), companyId: selectedCompany._id, selectedCompany }));}
                    modalCardClasses="maw800px mah80 p2rem"
                    girlComponent={<div style={{ position: 'relative' }}>
                        <img src={require("../../../../images/dataRepo/fillingassistantGirl.png")} alt="messageLadie" className='animateRight' />
                    </div>}
                >
                    <div className='df jcsb pr'>
                        <div className='df jcfs aic'>
                            <img src={require("../../../../images/dataRepo/file_placement.svg")} alt={"fileManagement"} />
                            <h1 className="c15 fw600 fs20px mb0rem ml0-5rem">Filing Assistant </h1>
                        </div>

                        <button
                            onClick={() => onClickFileImportAuditLog(fileReport['_id'])}
                            className='cp tac c1 bg5 c15 br20px px1-5rem py0-5rem fw500'>
                            Log
                        </button>
                    </div>

                    <h3 className="w100 fw400 fs13px c37 df jcfs aic mt1-5rem">
                        {fileReport?.['lastAllocatedBy'] ? (
                            <>
                                <span className='mr0-5rem'>Reallocated by&nbsp;</span>
                                <p className={`w2rem h2rem jcc aic dib wsn mr0-5rem`}>
                                    {fileReport?.['lastAllocatedBy']?.['profile']
                                        ? <img
                                            src={fileReport?.['lastAllocatedBy']?.['profile']}
                                            className={`w2rem h2rem df jcc aic br100 ${profileClass}`}
                                            data-tooltip={fileReport?.['lastAllocatedBy']?.['firstName'] ? (fileReport?.['lastAllocatedBy']?.['firstName'] + " " + fileReport?.['lastAllocatedBy']?.['lastName']) : "?"}
                                        />
                                        : <img
                                            src={require("../../../../images/profile-photo.png")}
                                            className={`w2rem h2rem df jcc aic br100 ${profileClass} mr0-5rem`}
                                            data-tooltip={fileReport?.['lastAllocatedBy']?.['firstName'] ? (fileReport?.['lastAllocatedBy']?.['firstName'] + " " + fileReport?.['lastAllocatedBy']?.['lastName']) : "?"}
                                        />
                                    }
                                </p>
                                <p
                                    className={`df jcc aic br100 fw500 mr0-5rem`}
                                >
                                    {`${fileReport?.['lastAllocatedBy']?.['firstName'] ? `${fileReport?.['lastAllocatedBy']?.['firstName']} ${fileReport?.['lastAllocatedBy']?.['lastName'] ? fileReport?.['lastAllocatedBy']?.['lastName'] : ""}` : ""}`}
                                </p>
                                &nbsp;{moment(fileReport['lastAllocatedOn']).format('lll')}
                            </>
                        ) : (
                            <>
                                Imported by: &nbsp;
                                <span className={`w2rem h2rem jcc aic dib wsn mr0-5rem`}>
                                    {fileReport?.['importBy']?.['profile']
                                        ? <img
                                            src={fileReport?.['importBy']?.['profile']}
                                            className={`w2rem h2rem df jcc aic br100 ${profileClass} mr0-5rem`}
                                            data-tooltip={fileReport?.['importBy']?.['firstName'] ? (fileReport?.['importBy']?.['firstName'] + " " + (fileReport?.['importBy']?.['lastName'] ? fileReport?.['importBy']?.['lastName'] : "")) : "?"}
                                        />
                                        : <img
                                            src={require("../../../../images/profile-photo.png")}
                                            className={`w2rem h2rem df jcc aic br100 ${profileClass} mr0-5rem`}
                                            data-tooltip={fileReport?.['importBy']?.['firstName'] ? (fileReport?.['importBy']?.['firstName'] + " " + (fileReport?.['importBy']?.['lastName'] ? fileReport?.['importBy']?.['lastName'] : "")) : "?"}
                                        />
                                    }
                                </span>
                                <p
                                    className={`df jcc aic ttc br100 fw600 c36`}
                                >
                                    {`${fileReport?.['importBy']?.['firstName'] ? `${fileReport?.['importBy']?.['firstName']} ${fileReport?.['importBy']?.['lastName'] ? fileReport?.['importBy']?.['lastName'] : ""}` : ""}`}
                                </p>
                                <span className='c36'>
                                    &nbsp;{moment(fileReport['importDate']).format('MMM DD, YYYY, HH:mm A')}
                                </span>
                            </>
                        )}
                    </h3>

                    <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />

                    <div className="dg gg0-5rem mb2rem fs1rem mah400px oya" >
                        <table className="fis bcc">
                            <thead>
                                <tr>
                                    <th className="w100 tal fs14px fw400 c37">Files</th>
                                    <th className="w100 tal fs14px fw400 c37">Allocated Section</th>
                                    {fileReport['status'] == 'In Progress' && (
                                        <th className="w100 fs14px fw400 c37">Actions</th>
                                    )}
                                </tr>
                            </thead>
                            {fileReport.importResult && Object.keys(fileReport.importResult).filter(key => fileReport.importResult[key]['section'].some((element) => !element.includes("DXXX") && !element.includes("SXXX"))).length > 0 && (
                                <tbody>
                                    <tr>
                                        <td>
                                            <h1 className={"c15 fs18px fw500 tal"}>Allocated files</h1>
                                        </td>
                                    </tr>
                                    {fileReport.importResult && Object.keys(fileReport.importResult).filter(key => fileReport.importResult[key]['section'].some((element) => !element.includes("DXXX") && !element.includes("SXXX"))).map(getImportResultEntryHTML)}
                                </tbody>
                            )}
                        </table>

                        {fileReport.importResult && Object.keys(fileReport.importResult).filter(key => fileReport.importResult[key]['section'].some((element) => element.includes("DXXX") || element.includes("SXXX"))).length > 0 && (<>
                            <h1 className={"c15 fs18px fw500 tal mt1rem ml1rem"}>Unallocated Bin: Please reallocate files <span className='c4'>*</span>
                            </h1>
                            <table className="fis bcc">
                                <thead className='dn'>
                                    <tr>
                                        <th className="fis-th w40 bbn"></th>
                                        <th className="fis-th w60 bbn"></th>
                                        {fileReport['status'] == 'In Progress' && (
                                            <th className="fis-th fis-th-action bbn"></th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {fileReport.importResult && Object.keys(fileReport.importResult).filter(key => fileReport.importResult[key]['section'].some((element) => element.includes("DXXX") || element.includes("SXXX"))).map(getImportResultEntryHTML)}
                                </tbody>
                            </table>
                        </>
                        )}
                    </div>

                    <div className="df w100 fdr jcc ">
                        {modal['from'] == 'reportHistory' ? (
                            <Button
                                onClick={() => dispatch(toggleModal({ open: 'fileReportHistory' }))}
                                text={"Back to List"}
                                customClassName="maw300px c1 mr0-5rem"
                            />
                        ) :
                            fileReport['status'] == 'In Progress' && fileReport.importResult && Object.keys(fileReport.importResult).filter(key => fileReport.importResult[key]['section'].some((element) => !element.includes("DXXX") && !element.includes("SXXX"))).length > 0 && (
                                <Button
                                    onClick={onClickReallocationComplete}
                                    text={"Confirm Allocation"}
                                    btnType='warning'
                                    customClassName="maw300px"
                                />

                            )
                        }
                    </div>

                    <div>
                        <div style={{
                            width: "60%",
                            height: "1px",
                            backgroundColor: "#82828256",
                            opacity: "64%",
                            margin: "24px auto"
                        }}></div>
                        <div className='df aic jcc'>
                            <Search className="mr0-5rem" />
                            <p className='fs13px c37 fw400 mr0-5rem'>{fileReport.importResult && (
                                <>
                                    [{Object.keys(fileReport.importResult).length}]imported files = [{Object.keys(fileReport.importResult).filter(key => fileReport.importResult[key]['section'].some((element) => !element.includes("DXXX") && !element.includes("SXXX") && !element.includes("SXXX"))).length}] allocated files +
                                    [{Object.keys(fileReport.importResult).filter(key => fileReport.importResult[key]['section'].some((element) => element.includes("DXXX") || element.includes("SXXX") || element.includes("SXXX"))).length}] unallocated bin files
                                </>
                            )}</p>
                            <p className='fs13px c37 fw400'>Allocation accuracy {currentScore}%</p>
                        </div>
                    </div>

                </ModalWrapper>
            </>
        )
    } else {
        return (<></>)
    }
}

export default ImportSummaryModal