import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { toggleModal } from "../../reducers/modalReducer";

export default function Introduction() {

    const dispatch = useDispatch();

    const modal = useSelector(state => state.modal);

    const handleLetsGo = async () => {
        await localStorage.setItem('isDemoChecklist', true);
        localStorage.removeItem('testType');
        dispatch(toggleModal({ open: 'addCompany' }));
    };

    return (
        <>
            <div className='df fww w100 my2rem'>
                <div class="df w100">
                    <div className="df jcc aic mr1rem"><img className="" src={require(`../../../images/demo-checklist/demo-checklist-wizard.png`)} alt="" /></div>
                    <div class='df fdc'>
                        <p className="fs1rem fw300 w100">Welcome to the demo checklist creation process.</p>
                        <p className="fs1rem fw500 w100">Demo purpose:</p>
                        <p className="fs1rem fw300 w100">To  quickly show you the power of a small part of our platform features​</p>
                    </div>
                    <img className="cp pa" src={require(`../../../images/demo-checklist/intro-ai.png`)} alt="" style={{ top: '0px', left: '62%' }} />
                </div>
                <div class="df jcc w100 mt2rem">
                    <div class='df fdc bg38 p4rem br20px'>
                        <h5 class='fs1-5rem fw400 c15'>How it works?</h5>
                        <img className="" src={require(`../../../images/demo-checklist/intro-how-it-works.png`)} alt="" />
                        <p class='fs1rem c12 fw300'>For the purposes of this demo we have removed a number of additional features available in the full platform</p>
                        <img className="pa w80px" src={require(`../../../images/demo-checklist/5-minute.png`)} alt="" style={{ left: '48%', bottom: '15%' }} />
                    </div>
                </div>
                <div class='w100 df jcc mt3rem'>
                    <button onClick={() => handleLetsGo()} className='df aic jcc cp shadow c1 fw400 fs1rem pr br38px w400px py1rem bg4 bg3-hover'>Let's Go! - It's FREE</button>
                </div>
            </div>
        </>
    )
}