import React from 'react'
import ModalWrapper from '../../common/Modal/ModalWrapper'
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { toggleModal } from '../../../reducers/modalReducer';
import { Divider } from '@mui/material';
import { setFileAllocateCompany } from '../../../reducers/filesAllocationReducer';

const FileReportHistory = ({ modalType, reportHistories, setFileReport, setCloneImportResult, setCurrentScore, company, profileClass }) => {

    const dispatch = useDispatch()

    const getReportStatus = (report) => {
        if (!report) return "-";
        for (let result of Object.values(report['importResult'])) {
            if (result.section.includes("DXXX")) {
                break;
            }
        }

        if (report['status'] == 'In Progress') {
            return <p className="orange">Not yet reviewed</p>;
        } else if (report['status'] == 'Complete') {
            return <p className="green">Reviewed and Accepted</p>;
        } else {
            return "-"
        }
    };

    const onClickReport = (report) => {
        dispatch(setFileReport(report))
        dispatch(setFileAllocateCompany(company))
        setCloneImportResult(JSON.parse(JSON.stringify(report['importResult'])));
        dispatch(setCurrentScore(report['currentScore']))
        dispatch(toggleModal({ open: 'importSummary', from: 'reportHistory' }))
    };


    const getReportHistoryEntryHTML = report => {
        return <>
            <tr className="cp" onClick={() => onClickReport(report)}>
                <td className={`mx1rem fs14px fw500 bg1 c2`}>
                    {moment(report['importDate']).format('lll')}
                </td>
                <td className={`frs-td mx1rem fs14px fw500 bg1 c2`}>
                    {Object.keys(report['importResult']).length}
                </td>
                <td className={`frs-td mx1rem fs14px fw500 bg1`}>
                    <span className={`w2rem h2rem jcc aic br100 bg1 c1 dib wsn`}>

                        {report?.['importBy']?.['profile']
                            ? <img src={report?.['importBy']?.['profile']} className={`w2rem h2rem df jcc aic br100 ${profileClass}`} data-tooltip={report?.['importBy']?.['firstName'] ? (report?.['importBy']?.['firstName'] + " " + report?.['importBy']?.['lastName']) : "?"} />
                            : <span className={`wsn w2rem h2rem df jcc aic br100 ${profileClass}`} data-tooltip={report?.['importBy']?.['firstName'] ? (report?.['importBy']?.['firstName'] + " " + report?.['importBy']?.['lastName']) : "?"}>{report?.['importBy']?.['firstName'] ? (report?.['importBy']?.['firstName'][0] + (report?.['importBy']?.['lastName'] ? report?.['importBy']?.['lastName'][0] : "")) : "?"}</span>
                        }
                    </span>
                </td>
                <td className={`frs-td mx1rem fs14px fw500 bg1 c2`}>
                    {report?.['lastAllocatedBy'] && (
                        <span className={`w2rem h2rem jcc aic br100 dib c2`}>
                            {report?.['lastAllocatedBy']?.['profile']
                                ? <img src={report?.['lastAllocatedBy']?.['profile']} className={`w2rem h2rem df jcc aic br100 ${profileClass}`} data-tooltip={report?.['lastAllocatedBy']?.['firstName'] ? (report?.['lastAllocatedBy']?.['firstName'] + " " + report?.['lastAllocatedBy']?.['lastName']) : "?"} />
                                : <span className={`wsn w2rem h2rem df jcc aic br100 ${profileClass}`} data-tooltip={report?.['lastAllocatedBy']?.['firstName'] ? (report?.['lastAllocatedBy']?.['firstName'] + " " + report?.['lastAllocatedBy']?.['lastName']) : "?"}>{report?.['lastAllocatedBy']?.['firstName'] ? (report?.['lastAllocatedBy']?.['firstName'][0] + (report?.['lastAllocatedBy']?.['lastName'] ? report?.['lastAllocatedBy']?.['lastName'][0] : "")) : "?"}</span>
                            }
                        </span>
                    )}

                </td>
                <td className={`frs-td mx1rem fs14px fw500 bg1 c2`}>
                    {report?.['initialScore']}{'% -> '}{report?.['currentScore']}{'%'}
                </td>
                <td className={`frs-td mx1rem fs14px fw500 bg1`}>
                    {getReportStatus(report)}
                </td>
            </tr>
        </>
    };

    return (
        <>
            <ModalWrapper
                modalType={modalType}
                modalCardClasses="maw1000px mah80 p2rem"
            >

                <div className='df fdc jcfs pr'>
                    <div className='df jcfs aic'>
                        <img src={require("../../../../images/dataRepo/file.svg")} alt={"fileManagement"} />
                        <h1 className="c15 fw600 fs20px mb0rem ml0-5rem">Filing Assistant Manager</h1>
                    </div>
                    <h1 className='c3 fs18px w100 fw400 mt0-5rem tal'>Filing Assistant</h1>
                </div>

                <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />

                <div className="dg gg0-5rem mb2rem fs1rem mah400px oya">
                    <table className="fis bcc frs-table">
                        <thead>
                            <tr>
                                <th className="fis-th frs-th fs14px fw400 c37 tal">Date</th>
                                <th className="fis-th frs-th fs14px fw400 c37">File Count</th>
                                <th className="fis-th frs-th fs14px fw400 c37">Import By</th>
                                <th className="fis-th frs-th fs14px fw400 c37">Reallocated By</th>
                                <th className="fis-th frs-th fs14px fw400 c37">Accuracy Change</th>
                                <th className="fis-th fs14px fw400 c37" style={{ minWidth: '124px' }}>Status</th>
                            </tr>
                        </thead>
                        {reportHistories.length > 0 && (
                            <tbody>
                                {reportHistories.filter(f => f['importResult'] != null && f['importResult'] != undefined).map(getReportHistoryEntryHTML)}
                            </tbody>
                        )}
                    </table>
                    {reportHistories.length == 0 && (
                        <span className="fs1-25rem tac">No file report history</span>
                    )}
                </div>

            </ModalWrapper>
        </>
    )
}

export default FileReportHistory