import React from 'react'
import ModalWrapper from '../../common/Modal/ModalWrapper'
import { toggleModal } from '../../../reducers/modalReducer';
import Button from '../../common/Button';

const WelcomToDashboardModal = ({ modalType, dispatch, company, user }) => {

    return (
        <ModalWrapper
            modalType={modalType}
        >
            <h2 className="c15 fw600 pb1rem">Welcome to your {company?.subscription} Dashboard!</h2>
            <div className="tal">
                <p className='c15 op70 fs12px mb0-5rem'>Hello there, {user.firstName}!</p>
                <p className='c15 op70 fs12px'>Welcome to your Diligentsia {company?.subscription} adventure! To make the most of this thrilling journey and unlock the true value of your subscription, we recommend following these steps:</p>
                <ol className="tal">
                    <li className='c15 op70 fs12px mb0-25rem'>First up, embark on the quest of uploading your key corporate documents to your <b>Repository</b> . This treasure trove will be your safe haven for all things important.</li>
                    <li className='c15 op70 fs12px mb0-25rem'>Next, create a magical link between your Repository and <b>Data Room</b> preferred cloud provider. But don't worry, you won't be alone in this. We'll be your guiding star and show you the way! </li>
                    <li className='c15 op70 fs12px mb0-25rem'>Now that your Repository is all set, it's time to take on your <b>Quick Business Audit</b>. Trust us, this is a battle worth fighting!</li>
                    <li className='c15 op70 fs12px mb0-25rem'>After your victorious battle, it's time to review your spoils! Check out your <b>reports</b> showcasing section completeness and risk.</li>
                    {company?.subscription == 'Pro' && <li className='c15 op70 fs12px mb0-25rem'>With all the hard work done, it's time to bask in the glory of your accomplishment! Obtain your unaudited Diligentsia certificate, a badge of honor for your efforts.</li>}
                    <li className='c15 op70 fs12px mb0-25rem'>And finally, make your triumphant return to the <b>Dashboard</b>. This is your kingdom, where you can access all other modules to continue improving and strengthening your business.</li>
                </ol>
                <p className='c15 op70 fs12px mb0-25rem'>Here's to an incredible journey, {user?.firstName}! Let's conquer this adventure together! </p>
            </div>

            <div
                className='mt2rem w100 fs1rem df jcc'
            >
                <Button
                    btnType="warning"
                    customClassName="w40"
                    text={"Let’s Go!"}
                    onClick={() => dispatch(toggleModal({ open: '' }))}
                />
            </div>

        </ModalWrapper>
    )
}

export default WelcomToDashboardModal