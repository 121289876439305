import moment from "moment";
import React, { useRef } from "react";
import Modal from '@mui/material/Modal';
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { AppMode } from "../../../env";
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

import { useSelector, useDispatch } from "react-redux";
import SidebarIndex from "./SidebarIndex";
import IDeals from "../../../components/svgs/IDeals";
import Dropbox from "../../../components/svgs/Dropbox";
import Datasite from "../../../components/svgs/Datasite";
import { toggleModal } from "../../../reducers/modalReducer";
import SharePoint from "../../../components/svgs/SharePoint";
import SeedLegals from "../../../components/svgs/SeedLegals";
import GoogleDrive from "../../../components/svgs/GoogleDrive";
import { getRequest, postRequest, showMessage } from '../../../config';
import HeadingSubheadingContent from "../../../components/HeadingSubheadingContent";
import { setLogList, setRModalData, setOpenRModal, setTargetSection, updateFileAllocation } from "../../../reducers/filesAllocationReducer";
import { setSyncDataRoomStatus } from "../../../reducers/dataRoomReducer";
import { LockOutlined } from "@mui/icons-material";
import Button from "../../../components/common/Button";
import WelcomToDashboardModal from "../../../components/modals/dataRepository/welcomToDashboardModal";
import ManageFilesModal from "../../../components/modals/dataRepository/manageFilesModal";
import CloseBtn from "../../../../images/dataRepo/closebtn.svg"
import UserDefinedSectionModal from "../../../components/modals/dataRepository/userDefinedSectionModal";
import AuditLogModal from "../../../components/modals/auditLog/auditLogModal";
import Pill from "../../../components/common/Pill";
import DeleteFileConfirmModal from "../../../components/modals/deleteFileConfirm/deleteFileConfirmModal";
import UnallocatedFilesModal from "../../../components/modals/unallocateFiles";
import FileReportHistory from "../../../components/modals/fileReportHistory";
import DeduperReport from "../../../components/modals/deduperReport";
import DeduperReportWaiting from "../../../components/modals/deduperReport/deduperReportWaiting";
import { setDeduperFiles, setUnallocatedFiles } from "../../../reducers/filesReducer";
import 'react-circular-progressbar/dist/styles.css';
import "../../../../styles/rsuite.change.css";


export default function QuesAns({ setIsWorkflowReport, profileClass, advisors, permissionLevel, tab, setTab, sanitiseTab, company, setCompany, companyId, openKPI, toggleReport, enableAIEnhancedReporting, calculateStats, calculateSubFolderStats, getCompletenessAndRiskHTML, aiEnhanced, setAiEnchaned, reloadModule, twoMinuteTestAIReport, getFitInvestorFirms, isTwoMinuteTesHomePage, setOpenTabs, openTabs, companyIndex,
	setCloneImportResult, setCurrentScore, reportHistories, setReportHistories, setFileReport, sortSections, showAlert }) {

	let dispatch = useDispatch();
	const spinner = document.getElementById("e1jygW4piZH1");

	let user = useSelector(state => state.user);
	let modal = useSelector(state => state.modal);
	const { syncing } = useSelector((state) => state.appData);
	let { logList, targetSection, processing } = useSelector(state => state.fileAllocation);
	let { unallocatedFiles, deduperReports: dupCheck } = useSelector(state => state.files);

	let [section, setSection] = useState();
	let [refresh, setRefresh] = useState(false);
	let [logModule, setLogModule] = useState([]);
	let [deleteFile, setDeleteFile] = useState();
	let [isSaving, setIsSaving] = useState(false);
	let [saveTimeout, setSaveTimeout] = useState();
	let [attachment, setAttachment] = useState([]);
	let [visibleSection, setVisibleSection] = useState(null);
	let [loading, setLoading] = useState(false)
	let [isFAMenuVisible, setFAMenuVisible] = useState(false);
	let [newUpgradeSync, setNewUpgradeSync] = useState(false);
	let [deleteFileLoading, setDeleteFileLoading] = useState(false);
	let [deleteFileQuestionId, setDeleteFileQuestionId] = useState();
	let [deleteUnallocatedFile, setDeleteUnallocatedFile] = useState();
	let [questionsAndAnswersToSave, setQuestionsAndAnswersToSave] = useState([]);
	const location = useLocation();

	useEffect(() => {
		if (processing && location.hash && location.hash === "#repository" && showAlert) {
			dispatch(toggleModal({ open: 'repositoryAlert' }))
		}
	}, [processing, location.hash, showAlert])

	let sectionRefs = useRef([]);
	let fileImportRef = useRef(null);

	useEffect(() => {
		const handleScroll = () => {
			const sectionPositions = sectionRefs.current.map(ref => {
				const id = ref?.id?.split('.')[0];
				const top = Math.floor(ref?.getBoundingClientRect().top);
				return { id, top };
			});
			const currentActiveSection = sectionPositions.find(position => position?.['top'] >= 0 && position.top < window.innerHeight);
			if (currentActiveSection) setVisibleSection(currentActiveSection.id);
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	const handleEditDispatch = (action) => {
		dispatch(setRModalData({ ...action }))
		dispatch(setOpenRModal(true))
	}

	const syncDrive = async (sectionId, qNo) => {
		if (syncing) return showMessage("Please wait! Syncing is under process")
		dispatch(setSyncDataRoomStatus({ syncing: true }))
		let body = {};
		if (sectionId) body['sectionId'] = sectionId;
		if (qNo) body['qNo'] = qNo;
		body['tab'] = tab;
		body['newUpgradeSync'] = newUpgradeSync;
		const res = await postRequest(`/company/syncGDriveDataroom/${companyId}`, body, true);
		if (!res) return;
		if (tab) reloadModule(tab, false, false, true);
		// showMessage(res['message']);
		if (newUpgradeSync) {
			setNewUpgradeSync(false);
		}
		window.location.reload();
		dispatch(setSyncDataRoomStatus({ syncing: false }))
	};

	const syncDropbox = async (sectionId, qNo) => {
		if (syncing) return showMessage("Please wait! Syncing is under process")
		dispatch(setSyncDataRoomStatus({ syncing: true }))
		let body = {};
		if (sectionId) body['sectionId'] = sectionId;
		if (qNo) body['qNo'] = qNo;
		body['tab'] = tab;
		// setNewUpgradeSync(true);
		body['newUpgradeSync'] = newUpgradeSync;
		const res = await postRequest(`/company/syncDropBoxDataroom/${companyId}`, body, true);
		if (!res) return;
		if (tab) reloadModule(tab, false, false, true);
		// showMessage(res['message']);
		if (newUpgradeSync) {
			setNewUpgradeSync(false);
			window.location.reload();
		}
		dispatch(setSyncDataRoomStatus({ syncing: false }))
	};

	const syncSharePoint = async (sectionId, qNo) => {
		if (syncing) return showMessage("Please wait! Syncing is under process")
		dispatch(setSyncDataRoomStatus({ syncing: true }))
		let body = {};
		if (sectionId) body['sectionId'] = sectionId;
		if (qNo) body['qNo'] = qNo;
		body['tab'] = tab;
		body['newUpgradeSync'] = newUpgradeSync;
		const res = await postRequest(`/company/syncSharePointDataroom/${companyId}`, body, true);
		if (!res) return;
		if (tab) reloadModule(tab, false, false, true);
		// showMessage(res['message']);
		if (newUpgradeSync) {
			setNewUpgradeSync(false);
			window.location.reload();
		}
		dispatch(setSyncDataRoomStatus({ syncing: false }))
	};

	useEffect(() => {
		if (questionsAndAnswersToSave.length) {
			if (isSaving) setSaveTimeout(setTimeout(() => setQuestionsAndAnswersToSave(questionsAndAnswersToSave => [...questionsAndAnswersToSave]), 1000));
			else save()
		}
	}, [questionsAndAnswersToSave, isSaving]);

	const confirmDeleteFile = async (from) => {
		if (deleteFileLoading) return
		setDeleteFileLoading(true)
		let directorId = undefined;
		if (tab.includes('directors-questionnaire')) {
			const dqs = tab.split('_');
			if (dqs.length > 1) directorId = dqs[1];
		}
		let res = {}
		if (tab.includes('directors-questionnaire'))
			res = await postRequest('/repository/deleteFile', { file: deleteFile, questionId: deleteFileQuestionId, companyId, directorId });
		else res = await postRequest('/repository/deleteFile', { file: deleteFile, questionId: deleteFileQuestionId, companyId });

		if (!res || res['code'] != 200) return;

		if (tab) await reloadModule(tab);
		showMessage(res['message']);

		if (section) {
			section.files = section.files?.filter(f => f.key != deleteFile.key);
			setSection(section);
			setRefresh(!refresh);
		}
		setDeleteFileLoading(false)
		dispatch(toggleModal({ open: from }));
	};

	const confirmDeleteUnallocatedFile = async () => {
		const res = await postRequest('/company/deleteUnallocatedFile', { fileReportId: deleteUnallocatedFile.id, file: deleteUnallocatedFile.file, companyId });
		if (!res || res['code'] != 200) return;

		dispatch(setUnallocatedFiles(unallocatedFiles.filter(f => f.file.key != deleteUnallocatedFile.file.key)));
		showMessage(res['message']);
		dispatch(toggleModal({ open: 'unallocatedFiles' }));
	};

	const onDragOver = e => e.preventDefault();
	const onDrop = (e, question, qNo) => {
		if (!company['uploadedFile'] || !company['syncRequired']) {
			dispatch(setCompany({
				...company,
				uploadedFile: true,
				syncRequired: true
			}));
		}

		e.preventDefault();
		e.target.files = e.dataTransfer.files;
		e.target.querySelector("input").value = null;
		saveAnswer(e, question, qNo);
	};

	const radioButtonChanged = (e, question, qNo, moduleType) => {

		// if (permissionLevel == 'DASHBOARD' || (company['creatorId']['_id'] != user['_id'] && company['creatorId']['createdBy']?.['_id'] != user['_id'] && !company['advisors']?.[user['_id']]?.['modules']?.[qNo] && !company['investors']?.[user['_id']] && !company['leads']?.[user['_id']] && !company['memberPermissions']?.[user['_id']]))
		// 	return showMessage('To access this module please contact your account admin');

		if (company['creatorId']['_id'] != user['_id'] && company['cCategory'] == 'client' && !companyIndex) {
			if (user['role'] == 'Advisor' && !company['advisors']?.[user['_id']]?.['modules']?.[qNo.split('.')[0]]) return showMessage('To access this module please contact your account admin');
			if (user['role'] == 'Investor' && !company['investors']?.[user['_id']]?.['modules']?.[qNo.split('.')[0]]) return showMessage('To access this module please contact your account admin');
			if ((user['role'] == 'Member' && !company?.['memberPermissions']?.[user['_id']]?.[qNo.split('.')[0]])) return showMessage('To access this module please contact your account admin');
		}

		// question = Object.freeze(question);
		question = JSON.parse(JSON.stringify(question));

		const option = e['target']['dataset']['option'];
		const wrapper = e['target'].closest("[data-question-id]")
		//TODO: change to toggleHeight
		for (const showOrHideEl of wrapper.querySelectorAll(".showOrHide"))
			if (option === "Yes" || option == 'Disclosure') {
				showOrHideEl.classList.remove("dn");
				setTimeout(() => showOrHideEl.classList.remove("op0", "h0"), 100);
			} else {
				showOrHideEl.classList.add("op0", "h0");
				setTimeout(() => showOrHideEl.classList.add("dn"), 300);
			}

		// Send only update keys
		if (!question['answer'] || question['answer'] == undefined) question['answer'] = {};
		else question['answer'] = { _id: question['answer']['_id'], status: question['answer']['status'] };

		question['answer']['radioOption'] = option;
		if (option == 'No') question['answer']['status'] = 'Completed';
		else if (option == 'N/A') question['answer']['status'] = 'Not Applicable';
		else if (['T', 'Date', 'DropDown'].includes(question['questionType'])) {
			question['answer']['status'] = 'Completed';
			question['answer']['radioOption'] = 'Yes';
		} else if ((question['questionType'].includes('F')) || (option == 'Disclosure' && moduleType == 'warranty-disclosure')) {
			question['answer']['status'] = question['answer']['status'] ?? 'In Progress';
		} else question['answer']['status'] = 'Completed';
		if (!question['updatedBy']) window.scrollTo({ top: window['scrollY'] + 150, behavior: 'smooth' });

		saveAnswer(e, question, qNo, 'radio');
	};

	const cloneFile = (file) => {
		const clonedFile = new File([file], file['name'], { type: file['type'] });
		return clonedFile;
	};

	const handleFileChange = async (e) => {
		if (e['target'].files.length > 80) {
			return showMessage("Oops! You can upload up to 80 files at a time. Please try again with fewer files.");
		}
		dispatch(toggleModal({ open: 'computerToClarence', percent: 0 }));
		attachment = [];
		let relPaths = [], filesInfo = [];
		setFAMenuVisible(false);
		let percent = 0, filesUploaded = 0;
		const totalFiles = e['target']['files'].length;
		for (const file of e['target'].files) {
			relPaths.push(file.webkitRelativePath);
			const clonedFile = cloneFile(file);
			attachment = [clonedFile];
			const res = await postRequest(`/company/uploadFile`, { attachment });
			if (!res) continue;
			if (!res.body?.['report']) continue;
			filesInfo.push(res['body']['report']);
			filesUploaded = filesUploaded + 1;
			percent = Math.round((filesUploaded / totalFiles) * 100);
			dispatch(toggleModal({ open: 'computerToClarence', percent, fileName: file['name'], totalFiles, filesUploaded }));
		}

		e['target']['value'] = null;
		dispatch(toggleModal({ open: 'allocatingFiles' }));
		const res = await postRequest(`/company/uploadFiles`, { files: filesInfo, companyId, tab, relPaths });
		if (!res) return;
		if (!res['body']?.['report']) return;

		// await reloadModule("repository", true);
		showMessage(res['message']);
		dispatch(setFileReport(res['body']['report']));
		setCloneImportResult(JSON.parse(JSON.stringify(res['body']['report']['importResult'])));
		const allocated = Object.keys(res['body']['report']['importResult']).filter(key => res['body']['report']['importResult'][key]['section'].some((element) => !element.includes("DXXX"))).length;
		// DXXX
		const total = Object.keys(res['body']['report']['importResult']).length;
		e['target']['value'] = '';
		dispatch(toggleModal({ open: 'importSummary', from: '' }))
		dispatch(
			updateFileAllocation({
				fileReport: res.body['report'],
				company,
				currentScore: total == 0 ? 0 : Math.round(allocated / total * 100),
				reportHistories,
				profileClass
			}))
	};

	const sectionNos = ['UD021', 'UD022', 'UD023', 'UD024', 'UD025'];
	const strAdditionalSections = sectionNos.map(sectionNo => {
		const section = company?.["modules"]?.[tab]?.['sections'].find((sec) => sec?.sectionNo === sectionNo)
		if (section && section.type === "Additional Sections" && section['companyId'] && section['sectionName'] !== "") {
			return [sectionNo, section.sectionName, true, true];
		} else {
			return [sectionNo, "", false, true];
		}
	});

	let [additionalSections, setAdditionalSections] = useState(strAdditionalSections);
	const [isSectionNameDuplicate, setIsSectionNameDuplicate] = useState({ indexes: [], name: '' })

	const findDuplicates = (arr) => {
		const map = arr.reduce((acc, [index, name]) => {
			if (name !== "") (acc[name.toLowerCase().trim()] = acc[name.toLowerCase().trim()] || []).push(index);
			return acc;
		}, {});

		for (const [name, indexes] of Object.entries(map)) {
			if (indexes.length > 1) return { indexes, name };
		}

		return { indexes: [], name: '' };
	};

	function editSectionName(strVal, index) {
		let newArray = [...additionalSections];
		newArray[index][1] = strVal;
		setAdditionalSections(newArray);
		const newSetData = findDuplicates(newArray)
		newSetData.indexes.length ? setIsSectionNameDuplicate(newSetData) : setIsSectionNameDuplicate({ indexes: [], name: '' })
	};

	function onEdit(index) {
		let newArray = [...additionalSections];
		if (newArray[index][3] === true && newArray[index][1] === "") return showMessage('Please input additional user-defined sections name!');
		newArray[index][3] = !newArray[index][3];
		setAdditionalSections(newArray);
		const newSetData = findDuplicates(newArray)
		newSetData.indexes.length ? setIsSectionNameDuplicate(newSetData) : setIsSectionNameDuplicate({ indexes: [], name: '' })
	};

	async function onDelete(index) {
		const selectedSection = company["modules"][tab]['sections'].find((sec) => sec.sectionNo === additionalSections[index][0])
		let connectDataroomConfig = 0;
		if (company['gDriveLink']) connectDataroomConfig = 1;
		if (company['dropBoxLink']) connectDataroomConfig = 2;
		if (company['sharePointLink']) connectDataroomConfig = 3;
		try {
			const resp = await postRequest('/repository/delete/customSections', {
				sectionId: selectedSection._id,
				companyId,
				connectDataroomConfig
			})
			if (resp['code'] === 200) {
				let newArray = [...additionalSections];
				newArray[index][2] = false;
				newArray[index][3] = true;
				newArray[index][1] = "";
				setAdditionalSections(newArray);
				const newSetData = findDuplicates(newArray)
				const restIndexes = newSetData.indexes.length ? newSetData.indexes.filter((item) => item !== newArray[index][0]) : []
				newSetData.indexes.length ? setIsSectionNameDuplicate(newSetData) : setIsSectionNameDuplicate({ indexes: restIndexes, name: '' })
				showMessage(resp['message']);
				dispatch(toggleModal({ open: '' }));
				if (tab) reloadModule(tab, true, true);
			}
		} catch (err) { console.log(err) }

	};

	const onSaveAdditionalSections = async () => {
		if (isSectionNameDuplicate.indexes.length && isSectionNameDuplicate.name !== '') return
		let sectionNames = additionalSections.map(section => section[1])
		if (sectionNames.every(val => val === '')) {
			return showMessage("Please enter minimum one section name.");
		}

		let connectDataroomConfig = 0;
		if (company['gDriveLink']) connectDataroomConfig = 1;
		if (company['dropBoxLink']) connectDataroomConfig = 2;
		if (company['sharePointLink']) connectDataroomConfig = 3;
		const additionalQuestion = "Please upload any file related to this section"

		const res = await postRequest(`/repository/addSection/`, { type: 'Additional Sections', sectionNos, sectionNames, companyId, tab, connectDataroomConfig, qDescription: additionalQuestion });

		showMessage(res['message']);
		dispatch(toggleModal({ open: '' }));
		if (tab) reloadModule(tab, false, true);
	};

	const saveAnswer = (e, question, sectionNo, type) => {
		if (permissionLevel == 'DASHBOARD') {
			showMessage('To access this module please contact your account admin');
			return;
		}

		let subAnswers;
		if (question['qNo'] == 'DEPF008.3.1' && question?.['answer']?.['subAnswers']) subAnswers = question['answer']['subAnswers']

		question = JSON.parse(JSON.stringify(question));
		// Send only update keys
		if (type != 'radio' && question['answer']) question['answer'] = { _id: question['answer']['_id'] };

		if (['T', 'F/T', 'Date', 'DropDown', 'Radio', 'Multi'].includes(question['questionType'])) {
			question['answer'].status = 'Completed';
			question['answer'].radioOption = 'Yes';
		}

		clearTimeout(saveTimeout);
		let questionAndAnswerToSave = questionsAndAnswersToSave.find(q => q._id == question['_id']);
		if (!questionAndAnswerToSave) {
			questionAndAnswerToSave = {
				...question,
				sectionNo,
				moduleType: question['type'],
				answer: {
					_id: question.answer?._id,
					fileIndexes: [],
					logs: question.answer?.logs,
					...question.answer
				}
			};
			if (question?.['answer']?.['status']) questionAndAnswerToSave['answer']['status'] = question['answer']['status'];
			questionsAndAnswersToSave.push(questionAndAnswerToSave);
		}

		if (question['qNo'] == 'DEPF008.3.1') {
			if (!questionAndAnswerToSave['answer']['subAnswers']) questionAndAnswerToSave['answer']['subAnswers'] = {};
			if (subAnswers) questionAndAnswerToSave['answer']['subAnswers'] = { ...subAnswers, [e.target.name]: e.target.value };
			else questionAndAnswerToSave['answer']['subAnswers'][e.target.name] = e.target.value;
		}
		else if (question['questionType'] == 'Radio') questionAndAnswerToSave.answer['dropdown'] = e.target.dataset.option;
		else if (question['questionType'] == 'Multi') {
			let answer = '';
			e.target.value.map(value => {
				if (value.length == 0) return;
				if (answer.length > 0) answer = answer + ';' + value;
				else answer = value;
			})
			questionAndAnswerToSave.answer[e.target.name] = answer;
		}
		else if (e.target.dataset && e.target.dataset.option) questionAndAnswerToSave.answer.radioOption = e.target.dataset.option;
		else if (e.target.files) {
			for (const file of e.target.files) {
				let index = attachment.push(file) - 1;
				questionAndAnswerToSave.answer.fileIndexes.push(index);
			}
			//showMessage("Uploading files");
			if (!company['uploadedFile'] || !company['syncRequired']) {
				dispatch(setCompany({
					...company,
					uploadedFile: true,
					syncRequired: true
				}));
			}
			if (question['questionType'] == 'D/ND') questionAndAnswerToSave.answer.radioOption = 'Disclosure';
			else questionAndAnswerToSave.answer.radioOption = 'Yes';
		}
		else if (e.target.type === "checkbox") {
			questionAndAnswerToSave.answer.status = e.target.checked ? "Completed" : "In Progress";
			questionAndAnswerToSave.answer.radioOption = question['questionType'] == 'D/ND' ? 'Disclosure' : 'Yes';
		} else questionAndAnswerToSave.answer[e.target.name] = e.target.value || e.target.checked;

		if (e.target.files) setQuestionsAndAnswersToSave(questionsAndAnswersToSave => [...questionsAndAnswersToSave]);
		else setSaveTimeout(setTimeout(() => setQuestionsAndAnswersToSave(questionsAndAnswersToSave => [...questionsAndAnswersToSave]), 10));
	};

	const save = async () => {
		setIsSaving(true);
		setQuestionsAndAnswersToSave([]);
		let companyClone = JSON.parse(JSON.stringify({ ...company }));
		let currentCount = 0, percent = 0, filesInfo = [];
		const totalFileCount = attachment.length;
		for (const file of attachment) {
			const res = await postRequest(`/question/newUploadFile`, { attachment: [file] }, true);
			if (!res) continue;
			if (!res.body?.['result']) continue;
			currentCount++;
			filesInfo.push(res.body['result']);
			percent = Math.round((currentCount / totalFileCount) * 100);
			dispatch(toggleModal({ open: 'computerToDataRepository', percent, fileName: file['name'], totalFiles: totalFileCount, filesUploaded: currentCount }));
		}

		const res = await postRequest('/question/newSaveAnswers', { files: filesInfo, companyId, questionsAndAnswersToSave });
		dispatch(toggleModal({ open: '' }));
		if (!res || res['code'] != 200) return setIsSaving(false);
		const { answerErrors, savedQuestions } = res['body'];
		for (let question of savedQuestions) {
			let { sectionNo, _id } = question;
			let moduleName = question['moduleType'] || question['type'];
			if (window.location.hash.includes('directors-questionnaire')) moduleName = window.location.hash.replace('#', '');
			let module = companyClone['modules'][moduleName];
			if (answerErrors[_id]) {
				showMessage(answerErrors[_id]);
				continue;
			}

			// This is for expension pack subsections
			if (module?.['expansionPack'])
				for (let key of Object.keys(module.sections[0]['subFolders'])) {
					let section = module.sections[0]['subFolders'][key];
					if (sectionNo.includes(section['sectionNo'])) {
						let questionIndex = section['questions'].findIndex(({ _id }) => _id == question['_id']);
						module.sections[0]['subFolders'][key]['questions'][questionIndex] = question;
					}
				}
			// This question update is for other modules than expansion packs
			else {
				if (module?.sections) for (let section of module?.sections) {
					if (sectionNo.includes(`${section['sectionNo']}`)) {
						let questionIndex = section['questions'].findIndex(({ _id }) => _id == question['_id']);
						// question['answer']['logs'] = section['questions'][questionIndex]?.['answer']?.['logs']; // Keep previous logs with user details
						section['updatedAt'] = Date.now();
						section['questions'][questionIndex] = question;
						if (question['answer']['updatedBy']) section['questions'][questionIndex]['answer']['updatedBy'] = question['answer']['updatedBy'];
					}
				}
			}

			companyClone.modules[moduleName] = module;
		}
		await dispatch(setCompany(calculateStats(companyClone, tab, "check2")));
		//if (!repoStarted) setRepoStarted(!!repositoryClone.answers?.find(a => a.radioOption));
		setAttachment([]); //TODO: only remove successful attachments. undo answers set on UI like radio button checks and files selected if it failed
		//questionsAndAnswersToSave.push(...currentQuestionsAndAnswersToSave); //TODO: add failed ones back
		setIsSaving(false);
		showMessage(res['message']);
		return spinner.classList.add("op0")
	};

	const onClickDeduperAuditLog = async () => {
		const res = await getRequest(`/company/getDeduperReportLog/${companyId}`);
		dispatch(setLogList(res['body']))
		dispatch(toggleModal({ open: 'deduperReportAuditLog' }))
	};

	const onClickUnallocatedBinAuditLog = async () => {
		const res = await getRequest(`/company/getUnallocatedBinLog/${companyId}`);
		dispatch(setLogList(res['body']))
		dispatch(toggleModal({ open: 'unallocatedBinAuditLog' }))
	};

	const moveUnallocatedFile = async (unallocatedFile, cut, qNo, sectionName) => {
		if (qNo) {
			const res = await postRequest('/company/moveFile', { fileReportId: unallocatedFile.id, file: unallocatedFile.file, unallocated: true, companyId, isCut: cut, qNo, tab, sectionName, key: unallocatedFile.file.key });
			if (!res || res['code'] != 200) return;

			await reloadModule("repository", cut ? true : false);
			showMessage(res['message']);
			if (cut) {
				dispatch(setUnallocatedFiles(unallocatedFiles.filter(f => f.file.key != unallocatedFile.file.key)));
				setRefresh(!refresh);
			}
			dispatch(toggleModal({ open: "" }))
		} else {
			showMessage('Please select target section');
		}
	};

	const moveDeduperFile = async (deduperId, curQno, file, cut, qNo, sectionName, key, index) => {
		if (cut && curQno == qNo) return;
		if (qNo) {
			const res = await postRequest('/company/moveDeduperFile', { deduperId, file: file, companyId, isCut: cut, qNo, tab, sectionName, oldQno: curQno, key, index });
			if (!res || res['code'] != 200) return;
			const newReport = res['body'];

			if (tab) await reloadModule(tab, cut ? true : false);
			showMessage(res['message']);
			if (cut) {
				const modules = company.modules[tab];
				for (const section of modules?.sections) {
					const sectionQno = section.no || section.sectionNo
					console.log(section, "section")
					if (sectionQno == curQno) {
						section.files = section.files && section.files.length ? section.files.filter(f => f.key != file.key) : [];
						setSection(section);
						break;
					}
				}
			}

			dispatch(setDeduperFiles(JSON.parse(JSON.stringify(newReport))))
			// setDupCheck();
			let curSection = {};
			for (const dupFiles of Object.values(newReport.deduperResult || {})) {
				if (!Array.isArray(dupFiles)) continue;
				for (const dupFile of dupFiles) {
					if (dupFile?.file?.key) {
						curSection[dupFile.file.key] = {
							qNo: dupFile.qNo,
							name: dupFile.name
						};
					}
				}
			}

			dispatch(setTargetSection(curSection));
			setRefresh(!refresh);
		} else {
			showMessage('Please select target section');
		}
	};

	const onClickFileReportHistory = async () => {
		setFAMenuVisible(false);
		const res = await getRequest(`/company/getFileReportHistory/${companyId}`);
		setReportHistories(res['body']);
		dispatch(toggleModal({ open: 'fileReportHistory' }))
	};

	const showDeduperReport = (report) => {
		// setDupCheck(JSON.parse(JSON.stringify(report)));
		// setDupCheck(report);
		dispatch(setDeduperFiles(report))
		let curSection = {};
		if (report?.deduperResult && typeof report.deduperResult === "object") {
			for (const dupFiles of Object.values(report.deduperResult)) {
				if (!Array.isArray(dupFiles)) continue;

				for (const dupFile of dupFiles) {
					if (dupFile?.file?.key) {
						curSection[dupFile.file.key] = {
							qNo: dupFile.qNo,
							name: dupFile.name
						};
					}
				}
			}
		}
		dispatch(setTargetSection(curSection));
		dispatch(toggleModal({ open: 'duplicationCheckSummary' }));
	};

	const onClickDeduperReportHistory = async () => {
		setFAMenuVisible(false);
		const res = await getRequest(`/company/getDeduperReportHistory/${company['_id']}`);
		if (res['body'] && Array.isArray(res['body']) && res['body'].length > 0) {
			showDeduperReport(res['body'][0]);
		} else {
			showDeduperReport({});
		}
		// setDeduperHistories(res['body']);
		// dispatch(toggleModal({ open: 'deduperReportHistory' }))
	};

	const onClickViewUnallocatedFiles = async () => {
		setFAMenuVisible(false);
		const res = await getRequest(`/company/unallocatedFiles/${companyId}`);
		dispatch(setTargetSection({}));
		dispatch(setUnallocatedFiles(res['body']['unallocated']));
		dispatch(toggleModal({ open: 'unallocatedFiles' }));
	};


	const checkDuplicatedFiles = async () => {
		setFAMenuVisible(false);
		dispatch(toggleModal({ open: 'checkingDuplication' }));
		const res = await postRequest(`/company/checkDuplicate`, { companyId, tab });
		showDeduperReport(res['body']);
	};

	const getViewableURL = (url) => {
		window.open(url, "_blank");
	};

	const getDeduperReportLogsHTML = (logs) => logs.map((log, idx) => {
		if (!log['files']) return <> </>
		else return (
			<div key={idx} className="df my0-5rem">
				{log?.['userId']?.['profile']
					&& <img className={`ass mr0-5rem br100 w2rem h2rem ${profileClass}`} src={log['userId']['profile']} data-tooltip={log?.['userId'].firstName ? (log?.['userId'].firstName + " " + log?.['userId'].lastName) : "Register to show your name here"} />
					|| <span className={`ass w2rem h2rem fw600 mr0-5rem df jcc aic br100 fs0-5rem ${profileClass}`}>{log?.['userId']?.['firstName']?.[0] || "?"}{log?.['userId']?.['lastName']?.[0] || "?"}</span>
				}
				<div className="w100">
					<span className='fs0-75rem c15'><b>{log['message']} by {log?.['userId']?.['firstName']} </b> at <b>{new Date(log.timestamp).toLocaleString()}</b></span>

					{log['files'].length > 0 && <>
						<p className="df fs0-75rem mb0-5rem">Files: {log['files'].map((file, idx) => <div key={idx} onClick={() => getViewableURL(file['location'])} className="db c3-hover fw600 tdu fs0-75rem ml0-5rem cp">{file['originalname']}</div>)}</p>
					</>
					}

					{log?.['change'] &&
						<div className="shadow bg5 br5px p1rem dg gg0-5rem mb0-5rem fs0-75rem">
							<p><b>Comment</b>: {log?.['change']?.comment ? log?.['change']?.comment : '-'}</p>
						</div>
					}
				</div>
			</div>
		)
	});

	const getFileReportLogsHTML = (logs) => logs.map((log, idx) => {
		if (!log['files']) return <> </>
		else return (
			<div key={idx} className="df my0-5rem">
				{log?.['userId']?.['profile']
					&& <img className={`ass mr0-5rem br100 w2rem h2rem ${profileClass}`} src={log['userId']['profile']} data-tooltip={log?.['userId'].firstName ? (log?.['userId'].firstName + " " + log?.['userId'].lastName) : "Register to show your name here"} />
					|| <span className={`ass w2rem h2rem fw600 mr0-5rem df jcc aic br100 fs0-5rem ${profileClass}`}>{log?.['userId']?.['firstName']?.[0] || "?"}{log?.['userId']?.['lastName']?.[0] || "?"}</span>
				}
				<div className="w100">
					<span className='fs0-75rem c15'><b>{log['message']} by {log?.['userId']?.['firstName']} </b> at <b>{new Date(log.timestamp).toLocaleString()}</b></span>

					{log['files'].length > 0 && <>
						<p className="df fs0-75rem mb0-5rem">Files: {log['files'].map((file, idx) => <div key={idx} onClick={() => getViewableURL(file['location'])} className="db c3-hover fw600 tdu fs0-75rem ml0-5rem cp">{file['originalname']}</div>)}</p>
					</>
					}
					{log?.['change'] &&
						<div className="shadow bg5 br5px p1rem dg gg0-5rem mb0-5rem fs0-75rem">
							<p className=""><b>Changed Status</b>: {log?.['change']?.comment ? log?.['change']?.comment : '-'}</p>
							<p><b>Assistant Accuracy</b>: {log?.['change']?.accuracy != undefined ? log?.['change']?.accuracy : '-'}%</p>
							{/* <p><b>Updated Comment</b>: {log?.['change']?.comment ? log?.['change']?.comment : '-'}</p> */}
						</div>
					}
				</div>
			</div>
		)
	});

	const getUnallocatedBinLogsHTML = (logs) => logs.map((log, idx) => {
		if (!log['files']) return <> </>
		else return (
			<div key={idx} className="df my0-5rem">
				{log?.['userId']?.['profile']
					&& <img className={`ass mr0-5rem br100 w2rem h2rem ${profileClass}`} src={log['userId']['profile']} data-tooltip={log?.['userId'].firstName ? (log?.['userId'].firstName + " " + log?.['userId'].lastName) : "Register to show your name here"} />
					|| <span className={`ass w2rem h2rem fw600 mr0-5rem df jcc aic br100 fs0-5rem ${profileClass}`}>{log?.['userId']?.['firstName']?.[0] || "?"}{log?.['userId']?.['lastName']?.[0] || "?"}</span>
				}
				<div className="w100">
					<span className='fs0-75rem c15'><b>{log['message']} by {log?.['userId']?.['firstName']} </b> at <b>{new Date(log.timestamp).toLocaleString()}</b></span>

					{log['files'].length > 0 && <>
						<p className="df fs0-75rem mb0-5rem">Files: {log['files'].map((file, idx) => <div key={idx} onClick={() => getViewableURL(file['location'])} target="_blank" className="db c3-hover fw600 tdu fs0-75rem ml0-5rem cp">{file['originalname']}</div>)}</p>
					</>
					}
					{/* {log?.['change'] &&
						<div className="shadow bg5 br5px p1rem dg gg0-5rem mb0-5rem fs0-75rem">
							<p className=""><b>Changed Status</b>: {log?.['change']?.comment ? log?.['change']?.comment : '-'}</p>
						</div>
					} */}
				</div>
			</div>
		)
	});

	const onClickFileManager = (section) => {
		let curSection = { ...targetSection };
		if (section && section.files && section.files.length > 0) {
			section.files.map(file => {
				curSection[file.key] = { qNo: section.sectionNo || section.no, name: section.sectionName || section.name };
			})
		}
		dispatch(setTargetSection(curSection));
	};

	const answerSaved = (answer) => {
		let answerClass = (answer?.['updatedBy']?.['role'] == 'Advisor' ? 'advisor-profile' : answer?.['updatedBy']?.['role'] == 'Investor' ? 'investor-profile' : 'client-profile')
		return (
			<div className="df my0-5rem">
				{answer?.['updatedBy']?.['profile'] && <img className={`ass mr0-5rem br100 w2rem h2rem ${answerClass}`} src={answer['updatedBy']['profile']} data-tooltip={answer['updatedBy']['firstName'] ? (answer['updatedBy']['firstName'] + " " + answer['updatedBy']['lastName']) : "Register to show your name here"} />
					|| <span className={`shadow ass w2rem h2rem fw600 mr0-5rem df jcc aic br100 fs0-5rem c15 bg16 ${answerClass}`} data-tooltip={answer?.['updatedBy']?.['firstName'] ? (answer['updatedBy']['firstName'] + " " + answer['updatedBy']['lastName']) : "Register to show your name here"}>{answer?.['updatedBy']?.['firstName']?.[0] || "?"}{answer?.['updatedBy']?.['lastName']?.[0] || "?"}</span>}
				<div className="w100">
					<span className='fs0-75rem c15'><b>Answer Saved</b>, <b>{moment(answer['updatedAt']).format('lll')}</b></span>
				</div>
			</div>
		)
	};

	const handleSectionLogs = async (id) => {
		const res = await getRequest(`/question/getLogs/${id}`);
		dispatch(setLogList(res['body']))
		dispatch(toggleModal({ open: 'auditLog' }))
	};

	const openWindow = (url) => {
		if (window.innerWidth <= 640) {
			var a = document.createElement('a');
			a.setAttribute("href", url);
			a.setAttribute("target", "_blank");

			var dispatch = document.createEvent("HTMLEvents");
			dispatch.initEvent("click", true, true);

			a.dispatchEvent(dispatch);
		}
		else {
			var width = window.innerWidth * 0.8;
			var height = width * window.innerHeight / window.innerWidth;
			window.open(url, 'newwindow', 'width=' + width + ', height=' + height + ', top=' + ((window.innerHeight - height) / 2) + ', left=' + ((window.innerWidth - width) / 2));
		}
		return false;
	};

	const getFilesFromQuestions = questions => questions && questions.filter(q => q.answer?.files?.length)
		.reduce((arr, q) => [...arr, ...q.answer.files.map(file => ({
			...file,
			questionId: q._id,
			title: file.originalname,
			url: file.location,
			ext: file.originalname.split(".").slice(-1)[0],
		}))], []);

	const showRiskRecommendation = (key) => {
		return <h5 className="fs1rem fsi mt2rem df aic c15">
			{key.includes("recommend")
				? <><svg className="mr0-5rem" width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M21.0954 10.0946C21.0954 5.89534 17.6873 2.49208 13.4862 2.50001C9.32805 2.50787 5.97026 5.81889 5.90715 9.97656C5.86673 12.6385 7.19613 14.9926 9.23603 16.3795C9.24414 16.385 9.24902 16.3941 9.24902 16.404C9.6515 16.6551 9.8961 17.0961 9.8961 17.5705V19.2815H17.1055V17.555C17.1055 17.0891 17.3416 16.6547 17.7326 16.4013L17.7526 16.3883C19.7696 15.023 21.0954 12.7136 21.0954 10.0946Z" fill="url(#paint0_linear_5984_51730)" />
					<path d="M13.5034 15.3135C16.3444 15.3135 18.6474 13.0104 18.6474 10.1694C18.6474 7.32845 16.3444 5.02539 13.5034 5.02539C10.6624 5.02539 8.35938 7.32845 8.35938 10.1694C8.35938 13.0104 10.6624 15.3135 13.5034 15.3135Z" fill="url(#paint1_linear_5984_51730)" />
					<path d="M20.6955 12.5354L15.9165 7.7564C15.8453 7.62505 15.7329 7.5332 15.5718 7.5332H11.5191C11.2095 7.5332 11.0781 7.87094 11.0781 8.1805C11.0781 8.46824 11.1821 8.72517 11.3702 8.81365L12.8136 10.2856V13.9406C12.8136 14.0885 12.9026 14.1997 13.0365 14.2748L17.107 18.3453V17.5559C17.107 17.0899 17.343 16.6556 17.7341 16.4021L17.7541 16.3891C19.1136 15.4689 20.1584 14.1193 20.6955 12.5354Z" fill="url(#paint2_linear_5984_51730)" />
					<path d="M15.5249 7.49023H11.4722C11.1626 7.49023 11.0312 7.82797 11.0312 8.13753C11.0312 8.49403 11.1907 8.80359 11.4722 8.80359H12.7668V13.8976C12.7668 14.1978 13.1327 14.3479 13.4986 14.3479C13.8645 14.3479 14.2303 14.1978 14.2303 13.8976V8.80359H15.525C15.8064 8.80359 15.9659 8.49403 15.9659 8.13753C15.9658 7.82792 15.8344 7.49023 15.5249 7.49023Z" fill="url(#paint3_linear_5984_51730)" />
					<path d="M23.8094 9.42578H22.1956C21.8115 9.42578 21.5 9.7372 21.5 10.1214C21.5 10.5055 21.8114 10.817 22.1956 10.817H23.8094C24.1935 10.817 24.505 10.5056 24.505 10.1214C24.505 9.7372 24.1936 9.42578 23.8094 9.42578Z" fill="url(#paint4_linear_5984_51730)" />
					<path d="M5.505 10.1214C5.505 9.73724 5.19358 9.42578 4.80939 9.42578H3.19561C2.81142 9.42578 2.5 9.7372 2.5 10.1214C2.5 10.5055 2.81142 10.817 3.19561 10.817H4.80939C5.19354 10.817 5.505 10.5055 5.505 10.1214Z" fill="url(#paint5_linear_5984_51730)" />
					<path d="M21.7422 13.985C21.4705 13.7133 21.0301 13.7133 20.7584 13.985C20.4868 14.2566 20.4868 14.6971 20.7584 14.9687L21.8995 16.1098C22.1712 16.3815 22.6116 16.3815 22.8833 16.1098C23.1549 15.8382 23.1549 15.3977 22.8833 15.1261L21.7422 13.985Z" fill="url(#paint6_linear_5984_51730)" />
					<path d="M5.10153 4.13342C4.82989 3.86178 4.38944 3.86178 4.1178 4.13342C3.84615 4.40507 3.84615 4.84551 4.1178 5.11716L5.2589 6.25826C5.53055 6.52991 5.97099 6.52991 6.24264 6.25826C6.51428 5.98662 6.51428 5.54617 6.24264 5.27453L5.10153 4.13342Z" fill="url(#paint7_linear_5984_51730)" />
					<path d="M6.24264 13.985C5.97099 13.7133 5.53055 13.7133 5.2589 13.985L4.1178 15.1261C3.84615 15.3977 3.84615 15.8382 4.1178 16.1098C4.38944 16.3815 4.82989 16.3815 5.10153 16.1098L6.24264 14.9687C6.51428 14.697 6.51428 14.2566 6.24264 13.985Z" fill="url(#paint8_linear_5984_51730)" />
					<path d="M20.7584 6.25826C21.0301 6.52991 21.4705 6.52991 21.7422 6.25826L22.8833 5.11716C23.1549 4.84551 23.1549 4.40507 22.8833 4.13342C22.6116 3.86178 22.1712 3.86178 21.8995 4.13342L20.7584 5.27453C20.4868 5.54617 20.4868 5.98662 20.7584 6.25826Z" fill="url(#paint9_linear_5984_51730)" />
					<path d="M17.1077 17.9497L14.4774 15.3194C14.2488 15.0502 13.908 14.8789 13.5271 14.8789C12.839 14.8789 12.2812 15.4367 12.2812 16.1248C12.2812 16.1696 12.2834 16.2141 12.2882 16.2576C12.3227 16.5842 12.484 16.8732 12.7217 17.0751L14.9278 19.2812H17.1077V17.9497Z" fill="url(#paint10_linear_5984_51730)" />
					<path d="M13.4959 17.3454C14.184 17.3454 14.7419 16.7876 14.7419 16.0994C14.7419 15.4113 14.184 14.8535 13.4959 14.8535C12.8078 14.8535 12.25 15.4113 12.25 16.0994C12.25 16.7876 12.8078 17.3454 13.4959 17.3454Z" fill="url(#paint11_linear_5984_51730)" />
					<path d="M6.42188 12.8516C6.98406 14.2927 7.97314 15.5202 9.23444 16.3778C9.24255 16.3833 9.24742 16.3924 9.24742 16.4022C9.6499 16.6534 9.8945 17.0943 9.8945 17.5687V19.2797H17.1039V17.5533C17.1039 17.0873 17.3399 16.653 17.731 16.3995L17.751 16.3865C19.0185 15.5286 20.0127 14.2977 20.5768 12.8516H6.42188V12.8516Z" fill="url(#paint12_linear_5984_51730)" />
					<path d="M16.6766 18.0156H10.3136C9.64852 18.0156 9.10938 18.5548 9.10938 19.2199C9.10938 19.885 9.64857 20.4241 10.3136 20.4241H16.6766C17.3417 20.4241 17.8809 19.885 17.8809 19.2199C17.8809 18.5548 17.3417 18.0156 16.6766 18.0156Z" fill="url(#paint13_linear_5984_51730)" />
					<path d="M9.97656 21.957C10.606 23.4614 11.947 24.5002 13.4996 24.5002C15.0522 24.5002 16.3931 23.4614 17.0226 21.957H9.97656Z" fill="url(#paint14_linear_5984_51730)" />
					<path d="M9.97656 21.957C10.606 23.4614 11.947 24.5002 13.4996 24.5002C15.0522 24.5002 16.3931 23.4614 17.0226 21.957H9.97656Z" fill="url(#paint15_linear_5984_51730)" />
					<path d="M16.6766 20.4238H10.3136C9.64852 20.4238 9.10938 20.963 9.10938 21.6281C9.10938 22.2932 9.64857 22.8324 10.3136 22.8324H16.6766C17.3417 22.8324 17.8809 22.2932 17.8809 21.6281C17.8809 20.963 17.3417 20.4238 16.6766 20.4238Z" fill="url(#paint16_linear_5984_51730)" />
					<path d="M16.6766 18.0156H10.3136C9.64852 18.0156 9.10938 18.5548 9.10938 19.2199C9.10938 19.885 9.64857 20.4241 10.3136 20.4241H16.6766C17.3417 20.4241 17.8809 19.885 17.8809 19.2199C17.8809 18.5548 17.3417 18.0156 16.6766 18.0156Z" fill="url(#paint17_linear_5984_51730)" />
					<path d="M16.6766 20.4238L13.5082 20.3066L10.3136 20.4238C9.64852 20.4238 9.10938 20.963 9.10938 21.6281C9.10938 22.2932 9.64857 22.8324 10.3136 22.8324H16.6766C17.3418 22.8324 17.8809 22.2932 17.8809 21.6281C17.8809 20.963 17.3418 20.4238 16.6766 20.4238Z" fill="url(#paint18_linear_5984_51730)" />
					<defs>
						<linearGradient id="paint0_linear_5984_51730" x1="7.93971" y1="5.55907" x2="17.0821" y2="14.7015" gradientUnits="userSpaceOnUse">
							<stop stop-color="#F7E07D" />
							<stop offset="1" stop-color="#E69642" />
						</linearGradient>
						<linearGradient id="paint1_linear_5984_51730" x1="11.5881" y1="8.25411" x2="19.3877" y2="16.0536" gradientUnits="userSpaceOnUse">
							<stop stop-color="#F7E07D" />
							<stop offset="1" stop-color="#E69642" />
						</linearGradient>
						<linearGradient id="paint2_linear_5984_51730" x1="19.8051" y1="16.3438" x2="7.67629" y2="4.21497" gradientUnits="userSpaceOnUse">
							<stop stop-color="#D52C1C" stop-opacity="0" />
							<stop offset="0.28" stop-color="#D12C1E" stop-opacity="0.28" />
							<stop offset="0.5735" stop-color="#C42C23" stop-opacity="0.574" />
							<stop offset="0.8721" stop-color="#AF2B2C" stop-opacity="0.872" />
							<stop offset="1" stop-color="#A42B31" />
						</linearGradient>
						<linearGradient id="paint3_linear_5984_51730" x1="11.5522" y1="8.09465" x2="16.2824" y2="12.8249" gradientUnits="userSpaceOnUse">
							<stop stop-color="#E38523" />
							<stop offset="1" stop-color="#C4237C" />
						</linearGradient>
						<linearGradient id="paint4_linear_5984_51730" x1="20.5813" y1="9.21341" x2="25.2617" y2="10.9686" gradientUnits="userSpaceOnUse">
							<stop stop-color="#F7E07D" />
							<stop offset="1" stop-color="#E69642" />
						</linearGradient>
						<linearGradient id="paint5_linear_5984_51730" x1="1.58127" y1="9.21341" x2="6.26166" y2="10.9686" gradientUnits="userSpaceOnUse">
							<stop stop-color="#F7E07D" />
							<stop offset="1" stop-color="#E69642" />
						</linearGradient>
						<linearGradient id="paint6_linear_5984_51730" x1="19.4346" y1="14.1526" x2="24.0474" y2="15.8824" gradientUnits="userSpaceOnUse">
							<stop stop-color="#F7E07D" />
							<stop offset="1" stop-color="#E69642" />
						</linearGradient>
						<linearGradient id="paint7_linear_5984_51730" x1="2.794" y1="4.30097" x2="7.40674" y2="6.03075" gradientUnits="userSpaceOnUse">
							<stop stop-color="#F7E07D" />
							<stop offset="1" stop-color="#E69642" />
						</linearGradient>
						<linearGradient id="paint8_linear_5984_51730" x1="3.46243" y1="14.4032" x2="6.78304" y2="15.6485" gradientUnits="userSpaceOnUse">
							<stop stop-color="#F7E07D" />
							<stop offset="1" stop-color="#E69642" />
						</linearGradient>
						<linearGradient id="paint9_linear_5984_51730" x1="20.1031" y1="4.55165" x2="23.4237" y2="5.79689" gradientUnits="userSpaceOnUse">
							<stop stop-color="#F7E07D" />
							<stop offset="1" stop-color="#E69642" />
						</linearGradient>
						<linearGradient id="paint10_linear_5984_51730" x1="17.1961" y1="19.7937" x2="13.3824" y2="15.98" gradientUnits="userSpaceOnUse">
							<stop stop-color="#D52C1C" stop-opacity="0" />
							<stop offset="0.28" stop-color="#D12C1E" stop-opacity="0.28" />
							<stop offset="0.5735" stop-color="#C42C23" stop-opacity="0.574" />
							<stop offset="0.8721" stop-color="#AF2B2C" stop-opacity="0.872" />
							<stop offset="1" stop-color="#A42B31" />
						</linearGradient>
						<linearGradient id="paint11_linear_5984_51730" x1="12.8755" y1="15.4789" x2="14.0165" y2="16.62" gradientUnits="userSpaceOnUse">
							<stop stop-color="#E38523" />
							<stop offset="1" stop-color="#C4237C" />
						</linearGradient>
						<linearGradient id="paint12_linear_5984_51730" x1="13.4994" y1="14.7928" x2="13.4994" y2="18.3093" gradientUnits="userSpaceOnUse">
							<stop stop-color="#D52C1C" stop-opacity="0" />
							<stop offset="0.28" stop-color="#D12C1E" stop-opacity="0.28" />
							<stop offset="0.5735" stop-color="#C42C23" stop-opacity="0.574" />
							<stop offset="0.8721" stop-color="#AF2B2C" stop-opacity="0.872" />
							<stop offset="1" stop-color="#A42B31" />
						</linearGradient>
						<linearGradient id="paint13_linear_5984_51730" x1="13.4951" y1="18.721" x2="13.4951" y2="20.1877" gradientUnits="userSpaceOnUse">
							<stop stop-color="#EEF4FF" />
							<stop offset="1" stop-color="#CFE7FD" />
						</linearGradient>
						<linearGradient id="paint14_linear_5984_51730" x1="10.5019" y1="23.2286" x2="15.9964" y2="23.2286" gradientUnits="userSpaceOnUse">
							<stop stop-color="#5A5A5A" />
							<stop offset="1" stop-color="#464646" />
						</linearGradient>
						<linearGradient id="paint15_linear_5984_51730" x1="13.4996" y1="24.1335" x2="13.4996" y2="23.2058" gradientUnits="userSpaceOnUse">
							<stop stop-color="#464646" stop-opacity="0" />
							<stop offset="1" stop-color="#202020" />
						</linearGradient>
						<linearGradient id="paint16_linear_5984_51730" x1="13.4951" y1="20.7913" x2="13.4951" y2="22.519" gradientUnits="userSpaceOnUse">
							<stop stop-color="#EEF4FF" />
							<stop offset="1" stop-color="#CFE7FD" />
						</linearGradient>
						<linearGradient id="paint17_linear_5984_51730" x1="13.4951" y1="19.2818" x2="13.4951" y2="20.7916" gradientUnits="userSpaceOnUse">
							<stop stop-color="#8AAADC" stop-opacity="0" />
							<stop offset="1" stop-color="#8AAADC" />
						</linearGradient>
						<linearGradient id="paint18_linear_5984_51730" x1="13.4952" y1="21.7098" x2="13.4952" y2="22.7287" gradientUnits="userSpaceOnUse">
							<stop stop-color="#8AAADC" stop-opacity="0" />
							<stop offset="1" stop-color="#8AAADC" />
						</linearGradient>
					</defs>
				</svg>
					Recommendation:</>
				: <><svg className="f4 mr0-5rem mah2rem maw2rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path className="fa-primary" d="M256 304c13.25 0 24-10.75 24-24v-128C280 138.8 269.3 128 256 128S232 138.8 232 152v128C232 293.3 242.8 304 256 304zM256 337.1c-17.36 0-31.44 14.08-31.44 31.44C224.6 385.9 238.6 400 256 400s31.44-14.08 31.44-31.44C287.4 351.2 273.4 337.1 256 337.1z" /><path className="fa-secondary" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM232 152C232 138.8 242.8 128 256 128s24 10.75 24 24v128c0 13.25-10.75 24-24 24S232 293.3 232 280V152zM256 400c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 385.9 273.4 400 256 400z" /></svg>
					Risk:</>
			}
		</h5>
	};

	const sortQuestionsByQNo = (questions) => {
		return questions.sort((a, b) => {
			const aParts = a['qNo'].match(/\d+/g).map(Number);
			const bParts = b['qNo'].match(/\d+/g).map(Number);
			for (let i = 0; i < Math.max(aParts.length, bParts.length); i++)
				if ((aParts[i] || 0) !== (bParts[i] || 0)) return (aParts[i] || 0) - (bParts[i] || 0);
			return 0;
		});
	};

	const onAccurateFileName = async () => {
		dispatch(toggleModal({ open: 'accurateFileNames' }));
	};
	const onAccurateFolderName = async () => {
		dispatch(toggleModal({ open: 'accurateFolderNames' }));
	};

	const subFolderModules = ["repository"];
	const getSectionHTML = (module, currentSection) => {

		if (module) module = JSON.parse(JSON.stringify(module));
		if (currentSection) currentSection = JSON.parse(JSON.stringify(currentSection));
		let convert;
		if (module['type'].includes('directors-questionnaire')) convert = currentSection?.subFolders || module?.sections[0]?.subFolders;
		else convert = currentSection?.subFolders || module?.sections;
		return <>
			{convert && Object.values(convert)
				.filter(s => (currentSection?.questions || s?.questions || []).find(q => q.qNo.match(new RegExp((s.sectionNo || s.no) + "((\.[0-9]+)|$)"))))
				.slice().sort(sortSections).map((section, sectionIdx) => {
					if ((module['type'] == 'repository' || module['type'] == `business-audit-${company['testType']}`) && (user['role'] == "Advisor" || user['role'] == "Investor") && (company['advisors'] || company['investors']) && section?.['sectionNo']) {
						if ((!company?.['advisors']?.[user['_id']]?.['modules'][section?.['sectionNo']] && user['role'] == 'Advisor') || !company?.['investors']?.[user['_id']]?.['modules'][section?.['sectionNo']] && user['role'] == 'Investor') return;
					}

					const qNo = section.sectionNo || section.no;
					const sectionId = section['_id'] || section['sectionId'];
					let questions;
					let showSubFolder = false;
					if (module['expansionPack']) {
						showSubFolder = qNo.indexOf('.') < 0;
						questions = qNo.indexOf('.') > 0 ? section['questions'] : [];
						section.stats = section.stats || currentSection?.stats; // Temporary
					} else if (subFolderModules.includes(module.type)) {
						if (currentSection) section.questions = currentSection.questions;
						if (Object.values((section && section?.subFolders) || {})?.find(s => section?.questions?.find(q => q.qNo.match(new RegExp((s.sectionNo || s.no) + "(\.[0-9]+)|$")))))
							showSubFolder = true;

						questions = section.questions && section.questions.filter(q => q.qNo === qNo) || [];
					} else questions = section.questions.filter(q => q.qNo.match(new RegExp(qNo + "(\.[0-9]+)|$")));

					section.files = [];
					if (section && section['files']) section.files = getFilesFromQuestions(questions);
					// Sort all questions.
					questions = sortQuestionsByQNo(questions);

					const jsx = <>
						{questions && questions.length > 0 && questions.map((question, questionIdx) => {
							if (!aiEnhanced && Object.values(question?.['answer']?.['aiResponse'] || {}).length > 0) setAiEnchaned(true);
							let answer = question.answer || {};
							let showFiles = answer['radioOption'] === "Yes" || answer['radioOption'] == 'Disclosure';
							let showToolBox = answer['radioOption'] === "Yes" || answer['radioOption'] == "No" || answer['radioOption'] == "N/A" || answer['radioOption'] == 'Disclosure';

							return (
								<div key={question._id} data-module-type={module.type} data-section-no={question['qNo']} data-question-id={question['_id']} className={!showSubFolder && "bss bw2px bo5 br10px px2rem py1rem mt1rem"}>
									<div className={`df ${(answer?.['updatedBy']) ? 'jcsb' : 'jcfe'}`}>
										{(answer?.['updatedBy']) && <div>{answerSaved(answer)}</div>}
										<div>
											<div className="df gg1rem fs0-75rem">
												{/*showFiles && */((section.sectionNo || section.no) && (company.gDriveLink || company.sharePointLink || company.dropBoxLink) && module['uploadFiles'] && (!tab.includes('directors-questionnaire')) && (answer['files'] && answer['files'].length)) ?
													<span className={((section.editPermission ?? true) ? "" : "op20 pen ") + "mla bg5 bg6-hover c1-hover td250 br50px px1-5rem py0-5rem fw600 cp ttc bsbb tac df jcc aic bss bo31 bw1px c15"}
														onClick={() => (company.gDriveLink && syncDrive(sectionId, (question['qNo']))) || (company.sharePointLink && syncSharePoint(sectionId, (question['qNo']))) || (company.dropBoxLink && syncDropbox(sectionId, (question['qNo'])))}>Sync</span> : ''
												}
												{!isTwoMinuteTesHomePage && answer['logs'] && answer['logs'].length > 0 &&
													<span onClick={() => { setSection({ ...section, questionId: question?.['qNo'] }); handleSectionLogs(answer['_id']); setLogModule(` - ${question['qNo']} ${section['sectionName'] || section['name']}`) }} className="mla bg5 bg6-hover c1-hover td250 br50px px1-5rem py0-5rem fw600 cp ttc bsbb tac df jcc aic bss bo31 bw1px c15">Log</span>
												}
												{showFiles && answer && answer.files && answer?.files?.length > 0 &&
													<span onClick={() => { setSection({ ...section, questionId: question?.['qNo'] }); onClickFileManager(section); dispatch(toggleModal({ open: 'manageFiles' })) }} className="mla bg5 bg6-hover c1-hover td250 br50px px1-5rem py0-5rem fw600 cp ttc bsbb tac df jcc aic bss bo31 bw1px c15">Manage Files ({answer.files.length})</span>
												}
											</div>
										</div>
									</div>
									<div data-module-type={module.type} data-section-no={qNo} data-question-id={question['_id']} className={((question.editPermission ?? true) ? "" : "op20 pen ") + "dg grid-template-columns-three-two gg2rem report-upload-area-saf aifs"}>
										<div className={isTwoMinuteTesHomePage && `df fww aifs`}>
											<div>
												<div className="fs1rem c15 fw500">{question['qNo']}
													<p className="fw400 ml1rem fs14px">{question.qDescription}</p>
												</div>
												{(() => {
													const options = question.questionType === 'yesNo' ? ['Yes', 'No', 'N/A']
														: question.questionType === '1to5' ? [1, 2, 3, 4, 5]
															: question.questionType == 'D/ND' ? ['Disclosure', 'No Disclosure', 'Not Sure', 'N/A']
																: ['Yes', 'No', 'N/A'];
													return (
														<>{module['type'].includes("directors-questionnaire") || module['type'].includes("investor-lead")
															? <>
																{question?.questionType.includes("YN") &&
																	<div className="df aic fww">
																		{options.map(option =>
																			<label key={question._id + option} for={question._id + option} className="py1rem df cp fs0-75rem">
																				<span className="checkbox w30px h30px pr">
																					<input
																						defaultChecked={answer.radioOption == option}
																						data-option={option} id={question._id + option + answer.radioOption}
																						onChange={(e) => radioButtonChanged(e, question, qNo, module.type)}
																						name={question._id}
																						className="bg1 bo4 bw2px bss cp td250 w30px h30px br5px"
																						key={`${question._id}-${option}-${answer.radioOption}`}
																						type="radio"
																					/>
																				</span>
																				<span className="w100 fs0-75rem lh3 ml0-5rem usn mr2rem fw500">{option}</span>
																			</label>)
																		}
																	</div>
																}
																{question?.questionType.includes("DropDown") &&
																	<div className="oyh td250 pr mt1rem">
																		<select value={answer?.dropdown} name="dropdown" className="pl1rem bss bw2px bo5 w90 br5px p1rem" onChange={(e) => saveAnswer(e, question, qNo)}>
																			<option value={answer?.dropdown} selected disabled hidden>{answer?.dropdown}</option>
																			{module['type'].includes("investor-lead")
																				? question['options'] && question['options'].map((option, idx) => <option key={idx} value={option['name'].replaceAll("'", ',')}>{option['name'].replaceAll("'", ',')}</option>)
																				: question['options'] && question['options'].map((option, idx) => <option key={idx} value={option['name']}>{option['name']}</option>)
																			}
																		</select>
																	</div>
																}
																{question?.questionType.includes("Date") &&
																	<div className="oyh td250 pr mt1rem">
																		<input
																			value={answer?.date && (new Date(answer?.date).toISOString().substring(0, 10))}
																			type="date"
																			name="date"
																			className="pl2rem bss bw2px bo5 w90 br5px p1rem"
																			onChange={(e) => saveAnswer(e, question, qNo)}
																			max={new Date().toISOString().substring(0, 10)}
																		/>
																	</div>
																}
																{question?.questionType.includes("Radio") &&
																	<div className="df aic fww">
																		{question['options'].map(option =>
																			<label key={question._id + option['name']} for={question._id + option['name']} className="py1rem df cp fs0-75rem">
																				<span className="checkbox w30px h30px pr">
																					<input defaultChecked={answer['dropdown'] == option['name']} data-option={option['name']} onChange={(e) => saveAnswer(e, question, qNo)} name={question._id} id={question._id + option['name']} className="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br5px" type="radio" key={`${question._id}-${option}-${answer['dropdown']}`} />
																				</span>
																				<span className="w100 fs0-75rem lh3 ml0-5rem usn mr2rem fw500">{option['name']}</span>
																			</label>)
																		}
																	</div>
																}
																{question?.questionType.includes("Multi") &&
																	<div className="multi-dropdown mt1rem">
																		<Select
																			multiple
																			className="w400px"
																			placeholder="Select any options from dropdown"
																			name="dropdown"
																			id="demo-multiple-checkbox"
																			labelId="demo-multiple-checkbox-label"
																			onChange={(e) => saveAnswer(e, question, qNo)}
																			renderValue={(selected) => selected.join(',  ')}
																			value={question['answer']['dropdown'] ? question['answer']['dropdown']?.split(";") : []}>
																			{question['options'].map((option, idx) => (
																				<MenuItem value={option['name']} className="multi-dropdown-list">
																					<Checkbox checked={question['answer']?.['dropdown']?.split(";").indexOf(option['name']) > -1} />
																					<ListItemText primary={option['name']} />
																				</MenuItem>
																			))}
																		</Select>
																	</div>
																}
															</>
															: <div className="df aic fww ml1rem">
																{options.map(option =>
																	<div>
																		<label key={question._id + option} for={question._id + option} className={`df cp fs0-75rem ${question.questionType === '1to5' && (option == 1 || option == 5) ? 'pt2rem pb0-5rem' : 'py1rem'}`}>
																			<span className="checkbox w20px h20px pr">
																				<input
																					defaultChecked={answer.radioOption == option}
																					data-option={option}
																					onChange={(e) => radioButtonChanged(e, question, qNo, module.type)}
																					name={question._id}
																					id={question._id + option + answer.radioOption}
																					className="bg1 bo4 bw1px bss cp td250 w20px h20px br5px" type="radio"
																					key={`${question._id}-${option}-${answer.radioOption}`}
																				/>
																			</span>
																			<span className="w100 fs0-75rem c15 lh3 ml0-5rem usn mr1-5rem fw500">{option}</span>
																		</label>
																		{(question.questionType === '1to5' && option == 1) && <span className="c9 fs0-75rem">Poor</span>}
																		{(question.questionType === '1to5' && option == 5) && <span className="c7 fs0-75rem">Excellent</span>}
																	</div>
																)}
															</div>
														}
														</>
													)
												})()}
												{!([`business-audit-${company['testType']}`, `two-minute-test-${company['testType']}`].includes(module['type'])) && module.stats.completionPercent == 100 && answer.radioOption === "No" &&
													["riskDescription", "recommendDescription"].filter(key => question[key]).map(key => (
														<div key={question._id + key} className={"recommendation"}>
															{showRiskRecommendation(key)}
															{question[key].split(/\n/).map((s, i) => <p key={i} className="mt1rem c15 fs14px">{s}</p>)}
														</div>
													))}
												{module['type'].includes(`business-audit-${company['testType']}`) && answer.radioOption === "No" &&
													["riskDescription", "recommendDescription"].filter(key => answer?.aiResponse && answer?.aiResponse['if' + key]).map(key => (
														<div key={question._id + key} className={"recommendation"}>
															{showRiskRecommendation(key)}
															{answer.aiResponse['if' + key].split(/\n/).map((s, i) => <p key={i} className="mt1rem c15 fs14px">{s}</p>)}
														</div>
													))}
												{module?.['type'] && company?.['testType'] && module['type'] == `two-minute-test-${company['testType']}` && module?.['stats']?.['completionPercent'] == 100 &&
													<>{["Yes", "No", 1, 2, 3, 4, 5].filter(key =>
														(question?.['answer']?.['aiResponse'] ? question['answer']['aiResponse']["if" + key] : question["if" + key])).map(key => (
															<div key={question._id + key} className={"recommendation" + ((answer.radioOption) == key ? "" : " dn")}>
																{showRiskRecommendation('recommend')}
																{question?.['answer']?.['aiResponse']
																	? <>{question['answer']['aiResponse']["if" + key].split(/\n/).map((s, i) => <p key={i} className="mt1rem c15 fs14px">{s}</p>)}</>
																	: <>{question["if" + key].split(/\n/).map((s, i) => <p key={i} className="mt1rem c15 fs14px">{s}</p>)}</>
																}
															</div>
														))}</>
												}
												{question.topTip && <div className={`${isTwoMinuteTesHomePage && (sectionIdx == 0 && questionIdx == 0) && "op60"} fs0-75rem`}>
													<div className={"showOrHide oyh td250 df oxh aifs mb0-5rem mt0-5rem"}>
														{/* <svg className="f3 w20px mr0-5rem"  */}
														<svg className="mr0-5rem" width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M21.0954 10.0946C21.0954 5.89534 17.6873 2.49208 13.4862 2.50001C9.32805 2.50787 5.97026 5.81889 5.90715 9.97656C5.86673 12.6385 7.19613 14.9926 9.23603 16.3795C9.24414 16.385 9.24902 16.3941 9.24902 16.404C9.6515 16.6551 9.8961 17.0961 9.8961 17.5705V19.2815H17.1055V17.555C17.1055 17.0891 17.3416 16.6547 17.7326 16.4013L17.7526 16.3883C19.7696 15.023 21.0954 12.7136 21.0954 10.0946Z" fill="url(#paint0_linear_5984_51730)" />
															<path d="M13.5034 15.3135C16.3444 15.3135 18.6474 13.0104 18.6474 10.1694C18.6474 7.32845 16.3444 5.02539 13.5034 5.02539C10.6624 5.02539 8.35938 7.32845 8.35938 10.1694C8.35938 13.0104 10.6624 15.3135 13.5034 15.3135Z" fill="url(#paint1_linear_5984_51730)" />
															<path d="M20.6955 12.5354L15.9165 7.7564C15.8453 7.62505 15.7329 7.5332 15.5718 7.5332H11.5191C11.2095 7.5332 11.0781 7.87094 11.0781 8.1805C11.0781 8.46824 11.1821 8.72517 11.3702 8.81365L12.8136 10.2856V13.9406C12.8136 14.0885 12.9026 14.1997 13.0365 14.2748L17.107 18.3453V17.5559C17.107 17.0899 17.343 16.6556 17.7341 16.4021L17.7541 16.3891C19.1136 15.4689 20.1584 14.1193 20.6955 12.5354Z" fill="url(#paint2_linear_5984_51730)" />
															<path d="M15.5249 7.49023H11.4722C11.1626 7.49023 11.0312 7.82797 11.0312 8.13753C11.0312 8.49403 11.1907 8.80359 11.4722 8.80359H12.7668V13.8976C12.7668 14.1978 13.1327 14.3479 13.4986 14.3479C13.8645 14.3479 14.2303 14.1978 14.2303 13.8976V8.80359H15.525C15.8064 8.80359 15.9659 8.49403 15.9659 8.13753C15.9658 7.82792 15.8344 7.49023 15.5249 7.49023Z" fill="url(#paint3_linear_5984_51730)" />
															<path d="M23.8094 9.42578H22.1956C21.8115 9.42578 21.5 9.7372 21.5 10.1214C21.5 10.5055 21.8114 10.817 22.1956 10.817H23.8094C24.1935 10.817 24.505 10.5056 24.505 10.1214C24.505 9.7372 24.1936 9.42578 23.8094 9.42578Z" fill="url(#paint4_linear_5984_51730)" />
															<path d="M5.505 10.1214C5.505 9.73724 5.19358 9.42578 4.80939 9.42578H3.19561C2.81142 9.42578 2.5 9.7372 2.5 10.1214C2.5 10.5055 2.81142 10.817 3.19561 10.817H4.80939C5.19354 10.817 5.505 10.5055 5.505 10.1214Z" fill="url(#paint5_linear_5984_51730)" />
															<path d="M21.7422 13.985C21.4705 13.7133 21.0301 13.7133 20.7584 13.985C20.4868 14.2566 20.4868 14.6971 20.7584 14.9687L21.8995 16.1098C22.1712 16.3815 22.6116 16.3815 22.8833 16.1098C23.1549 15.8382 23.1549 15.3977 22.8833 15.1261L21.7422 13.985Z" fill="url(#paint6_linear_5984_51730)" />
															<path d="M5.10153 4.13342C4.82989 3.86178 4.38944 3.86178 4.1178 4.13342C3.84615 4.40507 3.84615 4.84551 4.1178 5.11716L5.2589 6.25826C5.53055 6.52991 5.97099 6.52991 6.24264 6.25826C6.51428 5.98662 6.51428 5.54617 6.24264 5.27453L5.10153 4.13342Z" fill="url(#paint7_linear_5984_51730)" />
															<path d="M6.24264 13.985C5.97099 13.7133 5.53055 13.7133 5.2589 13.985L4.1178 15.1261C3.84615 15.3977 3.84615 15.8382 4.1178 16.1098C4.38944 16.3815 4.82989 16.3815 5.10153 16.1098L6.24264 14.9687C6.51428 14.697 6.51428 14.2566 6.24264 13.985Z" fill="url(#paint8_linear_5984_51730)" />
															<path d="M20.7584 6.25826C21.0301 6.52991 21.4705 6.52991 21.7422 6.25826L22.8833 5.11716C23.1549 4.84551 23.1549 4.40507 22.8833 4.13342C22.6116 3.86178 22.1712 3.86178 21.8995 4.13342L20.7584 5.27453C20.4868 5.54617 20.4868 5.98662 20.7584 6.25826Z" fill="url(#paint9_linear_5984_51730)" />
															<path d="M17.1077 17.9497L14.4774 15.3194C14.2488 15.0502 13.908 14.8789 13.5271 14.8789C12.839 14.8789 12.2812 15.4367 12.2812 16.1248C12.2812 16.1696 12.2834 16.2141 12.2882 16.2576C12.3227 16.5842 12.484 16.8732 12.7217 17.0751L14.9278 19.2812H17.1077V17.9497Z" fill="url(#paint10_linear_5984_51730)" />
															<path d="M13.4959 17.3454C14.184 17.3454 14.7419 16.7876 14.7419 16.0994C14.7419 15.4113 14.184 14.8535 13.4959 14.8535C12.8078 14.8535 12.25 15.4113 12.25 16.0994C12.25 16.7876 12.8078 17.3454 13.4959 17.3454Z" fill="url(#paint11_linear_5984_51730)" />
															<path d="M6.42188 12.8516C6.98406 14.2927 7.97314 15.5202 9.23444 16.3778C9.24255 16.3833 9.24742 16.3924 9.24742 16.4022C9.6499 16.6534 9.8945 17.0943 9.8945 17.5687V19.2797H17.1039V17.5533C17.1039 17.0873 17.3399 16.653 17.731 16.3995L17.751 16.3865C19.0185 15.5286 20.0127 14.2977 20.5768 12.8516H6.42188V12.8516Z" fill="url(#paint12_linear_5984_51730)" />
															<path d="M16.6766 18.0156H10.3136C9.64852 18.0156 9.10938 18.5548 9.10938 19.2199C9.10938 19.885 9.64857 20.4241 10.3136 20.4241H16.6766C17.3417 20.4241 17.8809 19.885 17.8809 19.2199C17.8809 18.5548 17.3417 18.0156 16.6766 18.0156Z" fill="url(#paint13_linear_5984_51730)" />
															<path d="M9.97656 21.957C10.606 23.4614 11.947 24.5002 13.4996 24.5002C15.0522 24.5002 16.3931 23.4614 17.0226 21.957H9.97656Z" fill="url(#paint14_linear_5984_51730)" />
															<path d="M9.97656 21.957C10.606 23.4614 11.947 24.5002 13.4996 24.5002C15.0522 24.5002 16.3931 23.4614 17.0226 21.957H9.97656Z" fill="url(#paint15_linear_5984_51730)" />
															<path d="M16.6766 20.4238H10.3136C9.64852 20.4238 9.10938 20.963 9.10938 21.6281C9.10938 22.2932 9.64857 22.8324 10.3136 22.8324H16.6766C17.3417 22.8324 17.8809 22.2932 17.8809 21.6281C17.8809 20.963 17.3417 20.4238 16.6766 20.4238Z" fill="url(#paint16_linear_5984_51730)" />
															<path d="M16.6766 18.0156H10.3136C9.64852 18.0156 9.10938 18.5548 9.10938 19.2199C9.10938 19.885 9.64857 20.4241 10.3136 20.4241H16.6766C17.3417 20.4241 17.8809 19.885 17.8809 19.2199C17.8809 18.5548 17.3417 18.0156 16.6766 18.0156Z" fill="url(#paint17_linear_5984_51730)" />
															<path d="M16.6766 20.4238L13.5082 20.3066L10.3136 20.4238C9.64852 20.4238 9.10938 20.963 9.10938 21.6281C9.10938 22.2932 9.64857 22.8324 10.3136 22.8324H16.6766C17.3418 22.8324 17.8809 22.2932 17.8809 21.6281C17.8809 20.963 17.3418 20.4238 16.6766 20.4238Z" fill="url(#paint18_linear_5984_51730)" />
															<defs>
																<linearGradient id="paint0_linear_5984_51730" x1="7.93971" y1="5.55907" x2="17.0821" y2="14.7015" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#F7E07D" />
																	<stop offset="1" stop-color="#E69642" />
																</linearGradient>
																<linearGradient id="paint1_linear_5984_51730" x1="11.5881" y1="8.25411" x2="19.3877" y2="16.0536" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#F7E07D" />
																	<stop offset="1" stop-color="#E69642" />
																</linearGradient>
																<linearGradient id="paint2_linear_5984_51730" x1="19.8051" y1="16.3438" x2="7.67629" y2="4.21497" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#D52C1C" stop-opacity="0" />
																	<stop offset="0.28" stop-color="#D12C1E" stop-opacity="0.28" />
																	<stop offset="0.5735" stop-color="#C42C23" stop-opacity="0.574" />
																	<stop offset="0.8721" stop-color="#AF2B2C" stop-opacity="0.872" />
																	<stop offset="1" stop-color="#A42B31" />
																</linearGradient>
																<linearGradient id="paint3_linear_5984_51730" x1="11.5522" y1="8.09465" x2="16.2824" y2="12.8249" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#E38523" />
																	<stop offset="1" stop-color="#C4237C" />
																</linearGradient>
																<linearGradient id="paint4_linear_5984_51730" x1="20.5813" y1="9.21341" x2="25.2617" y2="10.9686" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#F7E07D" />
																	<stop offset="1" stop-color="#E69642" />
																</linearGradient>
																<linearGradient id="paint5_linear_5984_51730" x1="1.58127" y1="9.21341" x2="6.26166" y2="10.9686" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#F7E07D" />
																	<stop offset="1" stop-color="#E69642" />
																</linearGradient>
																<linearGradient id="paint6_linear_5984_51730" x1="19.4346" y1="14.1526" x2="24.0474" y2="15.8824" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#F7E07D" />
																	<stop offset="1" stop-color="#E69642" />
																</linearGradient>
																<linearGradient id="paint7_linear_5984_51730" x1="2.794" y1="4.30097" x2="7.40674" y2="6.03075" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#F7E07D" />
																	<stop offset="1" stop-color="#E69642" />
																</linearGradient>
																<linearGradient id="paint8_linear_5984_51730" x1="3.46243" y1="14.4032" x2="6.78304" y2="15.6485" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#F7E07D" />
																	<stop offset="1" stop-color="#E69642" />
																</linearGradient>
																<linearGradient id="paint9_linear_5984_51730" x1="20.1031" y1="4.55165" x2="23.4237" y2="5.79689" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#F7E07D" />
																	<stop offset="1" stop-color="#E69642" />
																</linearGradient>
																<linearGradient id="paint10_linear_5984_51730" x1="17.1961" y1="19.7937" x2="13.3824" y2="15.98" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#D52C1C" stop-opacity="0" />
																	<stop offset="0.28" stop-color="#D12C1E" stop-opacity="0.28" />
																	<stop offset="0.5735" stop-color="#C42C23" stop-opacity="0.574" />
																	<stop offset="0.8721" stop-color="#AF2B2C" stop-opacity="0.872" />
																	<stop offset="1" stop-color="#A42B31" />
																</linearGradient>
																<linearGradient id="paint11_linear_5984_51730" x1="12.8755" y1="15.4789" x2="14.0165" y2="16.62" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#E38523" />
																	<stop offset="1" stop-color="#C4237C" />
																</linearGradient>
																<linearGradient id="paint12_linear_5984_51730" x1="13.4994" y1="14.7928" x2="13.4994" y2="18.3093" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#D52C1C" stop-opacity="0" />
																	<stop offset="0.28" stop-color="#D12C1E" stop-opacity="0.28" />
																	<stop offset="0.5735" stop-color="#C42C23" stop-opacity="0.574" />
																	<stop offset="0.8721" stop-color="#AF2B2C" stop-opacity="0.872" />
																	<stop offset="1" stop-color="#A42B31" />
																</linearGradient>
																<linearGradient id="paint13_linear_5984_51730" x1="13.4951" y1="18.721" x2="13.4951" y2="20.1877" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#EEF4FF" />
																	<stop offset="1" stop-color="#CFE7FD" />
																</linearGradient>
																<linearGradient id="paint14_linear_5984_51730" x1="10.5019" y1="23.2286" x2="15.9964" y2="23.2286" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#5A5A5A" />
																	<stop offset="1" stop-color="#464646" />
																</linearGradient>
																<linearGradient id="paint15_linear_5984_51730" x1="13.4996" y1="24.1335" x2="13.4996" y2="23.2058" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#464646" stop-opacity="0" />
																	<stop offset="1" stop-color="#202020" />
																</linearGradient>
																<linearGradient id="paint16_linear_5984_51730" x1="13.4951" y1="20.7913" x2="13.4951" y2="22.519" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#EEF4FF" />
																	<stop offset="1" stop-color="#CFE7FD" />
																</linearGradient>
																<linearGradient id="paint17_linear_5984_51730" x1="13.4951" y1="19.2818" x2="13.4951" y2="20.7916" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#8AAADC" stop-opacity="0" />
																	<stop offset="1" stop-color="#8AAADC" />
																</linearGradient>
																<linearGradient id="paint18_linear_5984_51730" x1="13.4952" y1="21.7098" x2="13.4952" y2="22.7287" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#8AAADC" stop-opacity="0" />
																	<stop offset="1" stop-color="#8AAADC" />
																</linearGradient>
															</defs>
														</svg>
														<h4 className={"c15"}>Top Tip</h4>
													</div>
													<p className={"showOrHide oyh td250 oxh w100 c37 mb0-5rem"}>{question.topTip}</p>
												</div>}
												{question['toolboxOffer'] && <div className="fs0-75rem">
													<div className={(showToolBox ? "" : "op0 h0 dn ") + "showOrHide oyh td250 oxh df aifs mb0-5rem"}>
														<svg className="f3 w20px mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path className="fa-primary" d="M512 205.3V320h-128V272C384 263.1 376.9 256 368 256h-32C327.1 256 320 263.1 320 272V320H192V272C192 263.1 184.9 256 176 256h-32C135.1 256 128 263.1 128 272V320H0V205.3c0-8.5 3.375-16.62 9.375-22.62l45.25-45.25C60.63 131.4 68.75 128 77.25 128h357.5c8.5 0 16.62 3.375 22.62 9.375l45.25 45.25C508.6 188.6 512 196.8 512 205.3z" /><path className="fa-secondary" d="M384 320v48c0 8.875-7.125 16-16 16h-32c-8.875 0-16-7.125-16-16V320H192v48C192 376.9 184.9 384 176 384h-32C135.1 384 128 376.9 128 368V320H0v128c0 17.62 14.38 32 32 32h448c17.62 0 32-14.38 32-32v-128H384zM176 80h160V128H384V80C384 53.5 362.5 32 336 32h-160C149.5 32 128 53.5 128 80V128h48V80z" /></svg>
														<h4 className="c15">Toolbox Offer</h4>
													</div>
													<p className={(showToolBox ? "" : "op0 h0 dn ") + "showOrHide oyh td250 oxh w100 mb0-5rem c15"}>{question['toolboxOffer']}</p>
												</div>}
											</div>
											{/* 2-Minute Test Dummy Image   */}

											{question.templateFiles && question.templateFiles.length > 0 && <div className={(showFiles ? "" : "op0 h0 dn ") + "showOrHide df fww mt0-5rem"}>
												{question.templateFiles.map(file => <a key={file.originalname} className="df jcc aic br5px lh1 bg4 c1 px0-5rem fw600 mr0-5rem mb0-5rem" target="_blank" href={file.location}>
													<span className="py0-5rem pl0-5rem">{file.originalname}</span>
												</a>)}
											</div>}
										</div>

										<label for={question._id + "complete"} className="cp mt0-5rem pb1rem">
											<div className="df">
												<span className="checkbox w20px h20px pr">
													<input defaultChecked={answer.status === "Completed"} onChange={(e) => saveAnswer(e, question, qNo)} name="status" id={question._id + "complete"} className="bg5 bo4 bw2px bss cp td250 w20px h20px br20px" type="checkbox" key={`${question._id}-Completed"-${answer.status}`} />
												</span>
												<span className="fw500 w100 fs0-75rem br20 lh3 ml0-5rem usn mr0-5rem c15">Mark as Complete</span>
											</div>
											{
												(answer.status && answer.status !== "") ? <div className="pt0-5rem tar">
													<Pill customClass="py0-25rem mla" text={answer.status} />
												</div> : null
											}

										</label>

										{isTwoMinuteTesHomePage && ((sectionIdx == 0 && questionIdx == 0) ?
											<div className='two-min-invest-image op80'>
												<div className="df aic mb1rem op80">
													<span className="ml0-5rem bg5 td250 br50px px1-5rem py0-5rem fw600 da-cursor bsbb tac df jcc aic bss bo31 bw1px c15 fs0-75rem">Sync</span>
													<span className="ml0-5rem bg5 td250 br50px px1-5rem py0-5rem fw600 da-cursor bsbb tac df jcc aic bss bo31 bw1px c15 fs0-75rem">File Manager</span>
													<span className="ml0-5rem bg5 td250 br50px px1-5rem py0-5rem fw600 da-cursor bsbb tac df jcc aic bss bo31 bw1px c15 fs0-75rem">Log</span>
												</div>
												<div className={"oyh td250"}>
													<label for={"fileUpload" + new Date().getTime()} className="w100 bsbb bg50 br5px df jcc fdc aic px2rem py0-5rem bsd bo3 bw2px da-cursor mb0-5rem">
														<svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M59.4632 31.0446C58.5439 25.1606 53.4429 20.6445 47.3047 20.6445C44.9955 20.6445 42.7766 21.2834 40.852 22.4751C37.924 17.5543 32.6528 14.4922 26.7969 14.4922C17.7506 14.4922 10.3906 21.8522 10.3906 30.8984C10.3906 30.9545 10.3906 31.0126 10.3927 31.0687C4.57871 32.048 0 37.1168 0 43.2031C0 49.9883 5.65619 55.5078 12.4414 55.5078H57.5586C64.3438 55.5078 70 49.9883 70 43.2031C70 37.0649 65.3472 31.9639 59.4632 31.0446Z" fill="white" />
															<g clip-path="url(#clip0_5984_51759)">
																<path d="M35 56C42.732 56 49 49.732 49 42C49 34.268 42.732 28 35 28C27.268 28 21 34.268 21 42C21 49.732 27.268 56 35 56Z" fill="#4A67FF" />
																<path d="M34.9896 49.6555C33.746 49.6555 32.5026 49.6555 31.2593 49.6555C29.5542 49.6555 28.3237 48.4294 28.315 46.7248C28.3117 46.1298 28.3117 45.5346 28.315 44.9392C28.3182 44.3256 28.6901 43.9308 29.2589 43.9297C29.8276 43.9286 30.2044 44.3207 30.2082 44.9338C30.2121 45.5468 30.2082 46.1456 30.2082 46.7516C30.2126 47.387 30.5861 47.7573 31.2238 47.7578C33.7427 47.7578 36.2614 47.7578 38.78 47.7578C39.4182 47.7578 39.7922 47.387 39.7971 46.7521C39.8015 46.1462 39.7971 45.5402 39.7971 44.9343C39.7971 44.3284 40.175 43.9286 40.746 43.9297C41.3169 43.9308 41.6943 44.3256 41.6893 44.9387C41.6833 45.7136 41.7265 46.4967 41.6292 47.2613C41.4509 48.6602 40.2582 49.6478 38.8429 49.6544C37.5615 49.6582 36.2753 49.6555 34.9896 49.6555Z" fill="white" />
																<path d="M34.0577 37.6102C33.9133 37.7448 33.8286 37.8186 33.7498 37.8979C33.1439 38.5098 32.5412 39.1256 31.9325 39.7354C31.5005 40.1685 30.8984 40.2002 30.5079 39.8212C30.1175 39.4423 30.1328 38.8205 30.5577 38.3928C31.7947 37.1481 33.0358 35.9078 34.2808 34.6719C34.7183 34.2344 35.2904 34.2344 35.7279 34.6719C36.9813 35.9151 38.2296 37.1636 39.4729 38.4174C39.8819 38.8303 39.8841 39.451 39.504 39.8218C39.124 40.1926 38.5197 40.1762 38.1024 39.7622C37.4844 39.147 36.8758 38.5219 36.2622 37.9028C36.1829 37.823 36.0981 37.7486 35.9718 37.6294C35.9636 37.8011 35.9537 37.9121 35.9537 38.0231C35.9537 39.9804 35.9565 41.9371 35.9537 43.8944C35.9537 44.6288 35.3073 45.0658 34.6352 44.8044C34.2633 44.6595 34.0615 44.3368 34.0615 43.8643C34.0593 41.9284 34.0593 39.9926 34.0615 38.057L34.0577 37.6102Z" fill="white" />
															</g>
															<defs>
																<clipPath id="clip0_5984_51759">
																	<rect width="28" height="28" fill="white" transform="translate(21 28)" />
																</clipPath>
															</defs>
														</svg>
														<span className="pen fs1rem fw400 c49 tac op80 mt1rem mb1rem fsi">
															Click here or drag and drop files (max 100MB)
														</span>
													</label>
													<div className="oyh td250 pr df aic ">
														<textarea
															rows="1"
															style={{ resize: "vertical" }}
															name="comment"
															type="text"
															disabled
															placeholder="Add notes"
															className="pl3rem bss bw2px bo5 w100 br5px pr1rem py0-75rem"
														>
														</textarea>
														<svg className="pa ml1rem" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
															<g clip-path="url(#clip0_5984_51771)">
																<path d="M14.25 8.25H9.75V3.75C9.75 3.33579 9.41421 3 9 3C8.58579 3 8.25 3.33579 8.25 3.75V8.25H3.75C3.33579 8.25 3 8.58579 3 9C3 9.41421 3.33579 9.75 3.75 9.75H8.25V14.25C8.25 14.6642 8.58579 15 9 15C9.41421 15 9.75 14.6642 9.75 14.25V9.75H14.25C14.6642 9.75 15 9.41421 15 9C15 8.58579 14.6642 8.25 14.25 8.25Z" fill="#4A67FF" />
																<g clip-path="url(#clip1_5984_51771)">
																	<path d="M16.7301 14.3415L4.73098 17.2387C4.09339 17.3926 3.45171 17.0005 3.29776 16.3629L0.400638 4.36379C0.246685 3.7262 0.638767 3.08452 1.27636 2.93057L13.2755 0.0334501C13.9131 -0.120503 14.5548 0.27158 14.7087 0.909168L17.6059 12.9083C17.7598 13.5459 17.3678 14.1876 16.7301 14.3415Z" fill="url(#paint0_linear_5984_51771)" />
																	<path d="M14.3672 14.9115L16.7313 14.3407C17.3688 14.1868 17.7609 13.5451 17.607 12.9075L15.3723 3.65234H14.3672V14.9115Z" fill="url(#paint1_linear_5984_51771)" />
																	<path d="M15.3863 3.72331L14.7068 0.909168C14.5529 0.271579 13.9112 -0.120503 13.2736 0.0334501L1.27447 2.93057C0.856593 3.03147 0.54424 3.34185 0.421875 3.72331H15.3863Z" fill="url(#paint2_linear_5984_51771)" />
																	<path d="M1.85156 10.3629L3.30014 16.3624C3.45409 17 4.09574 17.3921 4.73336 17.2382L16.7325 14.341C17.3701 14.1871 17.7622 13.5454 17.6082 12.9078L16.1597 6.9082L1.85156 10.3629Z" fill="url(#paint3_linear_5984_51771)" />
																	<path d="M11.3983 17.9993H1.36905C0.815741 17.9993 0.367188 17.5508 0.367188 16.9975L0.376011 3.92377C0.376011 3.37046 0.824564 2.92188 1.37787 2.92188H14.4486C15.0019 2.92188 15.4505 3.37043 15.4505 3.92377L15.4417 13.956C15.4417 16.1891 13.6314 17.9993 11.3983 17.9993Z" fill="url(#paint4_linear_5984_51771)" />
																	<path d="M8.50558 4.14062L7.3125 5.33371L10.9873 9.00846C11.5559 8.8328 12.0046 8.38404 12.1803 7.81538L8.50558 4.14062Z" fill="url(#paint5_linear_5984_51771)" />
																	<path d="M7.90614 5.57985C8.37206 5.57985 8.74977 5.20214 8.74977 4.73621C8.74977 4.27029 8.37206 3.89258 7.90614 3.89258C7.44021 3.89258 7.0625 4.27029 7.0625 4.73621C7.0625 5.20214 7.44021 5.57985 7.90614 5.57985Z" fill="url(#paint6_linear_5984_51771)" />
																	<path d="M0.367188 10.6563V16.9965C0.367188 17.5498 0.815741 17.9983 1.36905 17.9983H11.3983C13.6314 17.9983 15.4417 16.1881 15.4417 13.955V10.6562H0.367188V10.6563Z" fill="url(#paint7_linear_5984_51771)" />
																	<path d="M11.3984 17.9984V14.957C11.3984 14.4037 11.847 13.9551 12.4003 13.9551H15.4418C15.4418 16.1882 13.6315 17.9984 11.3984 17.9984Z" fill="url(#paint8_linear_5984_51771)" />
																	<path d="M13.0689 9.20312H7.28104C7.07404 9.20312 6.90625 9.37091 6.90625 9.57791C6.90625 9.78491 7.07404 9.9527 7.28104 9.9527H13.0689C13.2759 9.9527 13.4437 9.78491 13.4437 9.57791C13.4437 9.37091 13.2759 9.20312 13.0689 9.20312Z" fill="white" />
																	<path d="M2.74979 9.9527H4.44885C4.65585 9.9527 4.82364 9.78491 4.82364 9.57791C4.82364 9.37091 4.65585 9.20312 4.44885 9.20312H2.74979C2.54279 9.20312 2.375 9.37091 2.375 9.57791C2.375 9.78491 2.54279 9.9527 2.74979 9.9527Z" fill="white" />
																	<path d="M5.375 9.57791C5.375 9.78491 5.54279 9.9527 5.74979 9.9527H6.17455C6.38155 9.9527 6.54934 9.78491 6.54934 9.57791C6.54934 9.37091 6.38155 9.20312 6.17455 9.20312H5.74979C5.54282 9.20312 5.375 9.37091 5.375 9.57791Z" fill="white" />
																	<path d="M8.53764 10.9688H2.74979C2.54279 10.9688 2.375 11.1365 2.375 11.3435C2.375 11.5505 2.54279 11.7183 2.74979 11.7183H8.53764C8.74464 11.7183 8.91242 11.5505 8.91242 11.3435C8.91242 11.1365 8.74464 10.9688 8.53764 10.9688Z" fill="white" />
																	<path d="M13.066 10.9688H11.367C11.16 10.9688 10.9922 11.1365 10.9922 11.3435C10.9922 11.5505 11.16 11.7183 11.367 11.7183H13.066C13.273 11.7183 13.4408 11.5505 13.4408 11.3435C13.4408 11.1365 13.273 10.9688 13.066 10.9688Z" fill="white" />
																	<path d="M10.073 10.9688H9.64823C9.44123 10.9688 9.27344 11.1365 9.27344 11.3435C9.27344 11.5505 9.44123 11.7183 9.64823 11.7183H10.073C10.28 11.7183 10.4478 11.5505 10.4478 11.3435C10.4478 11.1365 10.28 10.9688 10.073 10.9688Z" fill="white" />
																</g>
															</g>
															<defs>
																<linearGradient id="paint0_linear_5984_51771" x1="4.64913" y1="5.97348" x2="20.2804" y2="15.518" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#D9B1EE" />
																	<stop offset="1" stop-color="#814AA0" />
																</linearGradient>
																<linearGradient id="paint1_linear_5984_51771" x1="16.8003" y1="9.28193" x2="15.1027" y2="9.28193" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#5B3374" stop-opacity="0" />
																	<stop offset="1" stop-color="#5B3374" />
																</linearGradient>
																<linearGradient id="paint2_linear_5984_51771" x1="7.90405" y1="1.33693" x2="7.90405" y2="3.53614" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#5B3374" stop-opacity="0" />
																	<stop offset="1" stop-color="#5B3374" />
																</linearGradient>
																<linearGradient id="paint3_linear_5984_51771" x1="10.1943" y1="13.541" x2="11.0307" y2="17.0005" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#5B3374" stop-opacity="0" />
																	<stop offset="1" stop-color="#5B3374" />
																</linearGradient>
																<linearGradient id="paint4_linear_5984_51771" x1="0.973999" y1="3.52579" x2="14.9207" y2="17.4724" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#FFDD00" />
																	<stop offset="1" stop-color="#FFB000" />
																</linearGradient>
																<linearGradient id="paint5_linear_5984_51771" x1="9.96461" y1="6.7927" x2="5.00606" y2="1.83419" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#FEA613" stop-opacity="0" />
																	<stop offset="1" stop-color="#E94444" />
																</linearGradient>
																<linearGradient id="paint6_linear_5984_51771" x1="7.37934" y1="4.20938" x2="8.24118" y2="5.07123" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#FE93BB" />
																	<stop offset="1" stop-color="#FD3581" />
																</linearGradient>
																<linearGradient id="paint7_linear_5984_51771" x1="7.90442" y1="15.5917" x2="7.90442" y2="19.8029" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#FEA613" stop-opacity="0" />
																	<stop offset="1" stop-color="#E94444" />
																</linearGradient>
																<linearGradient id="paint8_linear_5984_51771" x1="12.3144" y1="14.8711" x2="14.6838" y2="17.2404" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#FFDD00" />
																	<stop offset="1" stop-color="#FFB000" />
																</linearGradient>
																<clipPath id="clip0_5984_51771">
																	<rect width="18" height="18" fill="white" />
																</clipPath>
																<clipPath id="clip1_5984_51771">
																	<rect width="18" height="18" fill="white" />
																</clipPath>
															</defs>
														</svg>
													</div>
												</div>
											</div> : <>
												<div className='two-min-invest-image'>
													<label
														for={"fileUpload" + new Date().getTime()}
														className="w100 bsbb bg50 br5px df jcfs aic px0-5rem py1rem bsd bo3 bw2px da-cursor mb0-5rem op60"
													>
														<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
															<g clip-path="url(#clip0_5984_51832)">
																<path d="M18.75 24.5H5.25C4.01 24.5 3 23.491 3 22.25V11.75C3 10.509 4.01 9.5 5.25 9.5H18.75C19.99 9.5 21 10.509 21 11.75V22.25C21 23.491 19.99 24.5 18.75 24.5ZM5.25 11C4.837 11 4.5 11.336 4.5 11.75V22.25C4.5 22.664 4.837 23 5.25 23H18.75C19.163 23 19.5 22.664 19.5 22.25V11.75C19.5 11.336 19.163 11 18.75 11H5.25Z" fill="#4A67FF" />
																<path d="M17.25 11C16.836 11 16.5 10.664 16.5 10.25V6.5C16.5 4.019 14.481 2 12 2C9.519 2 7.5 4.019 7.5 6.5V10.25C7.5 10.664 7.164 11 6.75 11C6.336 11 6 10.664 6 10.25V6.5C6 3.191 8.691 0.5 12 0.5C15.309 0.5 18 3.191 18 6.5V10.25C18 10.664 17.664 11 17.25 11Z" fill="#4A67FF" />
																<path d="M12 17.5C10.897 17.5 10 16.603 10 15.5C10 14.397 10.897 13.5 12 13.5C13.103 13.5 14 14.397 14 15.5C14 16.603 13.103 17.5 12 17.5ZM12 15C11.725 15 11.5 15.224 11.5 15.5C11.5 15.776 11.725 16 12 16C12.275 16 12.5 15.776 12.5 15.5C12.5 15.224 12.275 15 12 15Z" fill="#4A67FF" />
																<path d="M12 20.5C11.586 20.5 11.25 20.164 11.25 19.75V17C11.25 16.586 11.586 16.25 12 16.25C12.414 16.25 12.75 16.586 12.75 17V19.75C12.75 20.164 12.414 20.5 12 20.5Z" fill="#4A67FF" />
															</g>
															<defs>
																<clipPath id="clip0_5984_51832">
																	<rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
																</clipPath>
															</defs>
														</svg>
														<div className="df fdc jcfs aifs ml1rem">
															<div className="pen fs1rem tal fw500 w100 c3 op80 m0rem fsi"> Register to access more features </div>
															<div className="fs13px tal w100 fsi">{(sectionIdx === 0 && [1, 2].includes(questionIdx)) ? "(No credit card required)" : "Tools Tip, Add files, Sync, File manager, Log"}</div>
														</div>
														<input disabled className="dn" id={"fileUpload" + new Date().getTime()} type="file" multiple />
													</label>
												</div>
											</>)
										}

										{module['uploadFiles'] && <>
											{module['type'].includes("directors-questionnaire") || module['type'].includes("investor-lead")
												? <div>
													{question?.questionType.includes("F") &&
														<div className={(question?.questionType.includes("YN") && (showFiles ? "" : "op0 h0 dn ")) + "showOrHide oyh td250 oxh"}>
															<label for={question._id + "complete"} className="df cp mt0-5rem pb1rem pl3rem">
																<span className="checkbox w30px h30px pr">
																	<input defaultChecked={answer.status === "Completed"} onChange={(e) => saveAnswer(e, question, qNo)} name="status" id={question._id + "complete"} className="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br20px" type="checkbox" key={`${question._id}-Completed"-${answer.status}`} />
																</span>
																<span className="fw500 w100 fs0-75rem lh3 ml0-5rem usn  mr2rem">Mark as Complete</span>
															</label>
															<label onDragOver={onDragOver} onDrop={(e) => onDrop(e, question, qNo)} for={"fileUpload" + question._id} className="w100 bsbb bg50 br5px df jcc fdc aic px2rem py1rem bsd bo3 bw1px cp mb0-5rem">
																<svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M59.4632 31.0446C58.5439 25.1606 53.4429 20.6445 47.3047 20.6445C44.9955 20.6445 42.7766 21.2834 40.852 22.4751C37.924 17.5543 32.6528 14.4922 26.7969 14.4922C17.7506 14.4922 10.3906 21.8522 10.3906 30.8984C10.3906 30.9545 10.3906 31.0126 10.3927 31.0687C4.57871 32.048 0 37.1168 0 43.2031C0 49.9883 5.65619 55.5078 12.4414 55.5078H57.5586C64.3438 55.5078 70 49.9883 70 43.2031C70 37.0649 65.3472 31.9639 59.4632 31.0446Z" fill="white" />
																	<g clip-path="url(#clip0_5984_51759)">
																		<path d="M35 56C42.732 56 49 49.732 49 42C49 34.268 42.732 28 35 28C27.268 28 21 34.268 21 42C21 49.732 27.268 56 35 56Z" fill="#4A67FF" />
																		<path d="M34.9896 49.6555C33.746 49.6555 32.5026 49.6555 31.2593 49.6555C29.5542 49.6555 28.3237 48.4294 28.315 46.7248C28.3117 46.1298 28.3117 45.5346 28.315 44.9392C28.3182 44.3256 28.6901 43.9308 29.2589 43.9297C29.8276 43.9286 30.2044 44.3207 30.2082 44.9338C30.2121 45.5468 30.2082 46.1456 30.2082 46.7516C30.2126 47.387 30.5861 47.7573 31.2238 47.7578C33.7427 47.7578 36.2614 47.7578 38.78 47.7578C39.4182 47.7578 39.7922 47.387 39.7971 46.7521C39.8015 46.1462 39.7971 45.5402 39.7971 44.9343C39.7971 44.3284 40.175 43.9286 40.746 43.9297C41.3169 43.9308 41.6943 44.3256 41.6893 44.9387C41.6833 45.7136 41.7265 46.4967 41.6292 47.2613C41.4509 48.6602 40.2582 49.6478 38.8429 49.6544C37.5615 49.6582 36.2753 49.6555 34.9896 49.6555Z" fill="white" />
																		<path d="M34.0577 37.6102C33.9133 37.7448 33.8286 37.8186 33.7498 37.8979C33.1439 38.5098 32.5412 39.1256 31.9325 39.7354C31.5005 40.1685 30.8984 40.2002 30.5079 39.8212C30.1175 39.4423 30.1328 38.8205 30.5577 38.3928C31.7947 37.1481 33.0358 35.9078 34.2808 34.6719C34.7183 34.2344 35.2904 34.2344 35.7279 34.6719C36.9813 35.9151 38.2296 37.1636 39.4729 38.4174C39.8819 38.8303 39.8841 39.451 39.504 39.8218C39.124 40.1926 38.5197 40.1762 38.1024 39.7622C37.4844 39.147 36.8758 38.5219 36.2622 37.9028C36.1829 37.823 36.0981 37.7486 35.9718 37.6294C35.9636 37.8011 35.9537 37.9121 35.9537 38.0231C35.9537 39.9804 35.9565 41.9371 35.9537 43.8944C35.9537 44.6288 35.3073 45.0658 34.6352 44.8044C34.2633 44.6595 34.0615 44.3368 34.0615 43.8643C34.0593 41.9284 34.0593 39.9926 34.0615 38.057L34.0577 37.6102Z" fill="white" />
																	</g>
																	<defs>
																		<clipPath id="clip0_5984_51759">
																			<rect width="28" height="28" fill="white" transform="translate(21 28)" />
																		</clipPath>
																	</defs>
																</svg>

																<span className="pen fs1rem c49 fw500 tac fsi">Click here or drag and drop files (max 100MB)</span>
																<input onChange={(e) => saveAnswer(e, question, qNo)} className="dn" id={"fileUpload" + question._id} type="file" multiple />
															</label>
															<div className="df fww">
																{answer.files && answer.files.map((file, fileIndex) => {
																	let fileColor = file.originator == 'filing-assistant' ? 'filing-assistant-file' : file.originator == 'sharepoint' ? 'sharepoint-file' : file.originator == 'dropbox' ? 'dropbox-file' : file.originator == 'gdrive' ? 'gdrive-file' : file.bucket == `repository-diligentsia${AppMode === "production" ? "-prod" : ""}` ? "upload-file" : 'prepopulated-file';
																	return (
																		<div key={`${file.originalname}-${fileIndex + 1000}-allocatedFiles`} className={`df jcc aic br5px lh1 px1rem py0-5rem fw600 mr0-5rem mb0-5rem cp pr pageCutBg ${fileColor}`} >
																			<span className="py0-5rem pl0-5rem" onClick={() => getViewableURL(file.location)}>{file.originalname}</span>

																			<CloseBtn onClick={e => { e.preventDefault(); setDeleteFileQuestionId(question._id); setDeleteFile(file); dispatch(toggleModal({ open: 'deleteFile', from: '' })) }} className="mx1rem" />
																		</div>
																	)
																}
																)}
															</div>
														</div>
													}
													{question?.questionType.includes("T") &&
														<>{module['type'].includes("investor-lead") && question['qNo'] == "DEPF008.3.1"
															? <div className="mah40vh oa oyh td250 pr mt1rem">
																{question?.['subQuestions'] && question['subQuestions'].length > 0
																	? <>{question?.['subQuestions'].map(subQuestion => {
																		return <>
																			<p>{subQuestion['subQDescription']}</p>
																			<textarea defaultValue={question?.['answer']?.['subAnswers'] && question?.['answer']?.['subAnswers']?.[subQuestion['subQNo']]} rows="1" style={{ resize: "vertical" }} onBlur={(e) => saveAnswer(e, question, qNo)} name={subQuestion['subQNo']} type="text" className="pl1-5rem bss bw2px bo5 w100 br5px p1rem"
																				placeholder={`Please enter director LinkedIn url here`} ></textarea>
																		</>
																	})}
																	</>
																	: <textarea defaultValue={answer.comment} rows="1" style={{ resize: "vertical" }} onBlur={(e) => saveAnswer(e, question, qNo)} name="comment" type="text" className="pl1-5rem bss bw2px bo5 w100 br5px p1rem"
																		placeholder={`Please enter director LinkedIn url here`} ></textarea>
																}
															</div>
															: <div className={(question?.questionType.includes("YN") && (showFiles ? "" : "op0 h0 dn ")) + "oyh td250 pr mt1rem"}>
																<textarea defaultValue={answer.comment} rows="3" style={{ resize: "vertical" }} onBlur={(e) => saveAnswer(e, question, qNo)} name="comment" type="text" className="pl1-5rem bss bw2px bo5 w100 br5px p1rem"
																	placeholder={`Please enter your answer here`} ></textarea>
															</div>
														}</>
													}
												</div>
												: <div className={(showFiles ? "" : "op0 h0 dn ") + "showOrHide oyh td250 oxh"}>
													<label onDragOver={onDragOver} onDrop={(e) => onDrop(e, question, qNo)} for={"fileUpload" + question._id} className="w100 bsbb bg50 br5px df jcc fdc aic px2rem py1rem bsd bo3 bw2px cp mb0-5rem">
														<svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M59.4632 31.0446C58.5439 25.1606 53.4429 20.6445 47.3047 20.6445C44.9955 20.6445 42.7766 21.2834 40.852 22.4751C37.924 17.5543 32.6528 14.4922 26.7969 14.4922C17.7506 14.4922 10.3906 21.8522 10.3906 30.8984C10.3906 30.9545 10.3906 31.0126 10.3927 31.0687C4.57871 32.048 0 37.1168 0 43.2031C0 49.9883 5.65619 55.5078 12.4414 55.5078H57.5586C64.3438 55.5078 70 49.9883 70 43.2031C70 37.0649 65.3472 31.9639 59.4632 31.0446Z" fill="white" />
															<g clip-path="url(#clip0_5984_51759)">
																<path d="M35 56C42.732 56 49 49.732 49 42C49 34.268 42.732 28 35 28C27.268 28 21 34.268 21 42C21 49.732 27.268 56 35 56Z" fill="#4A67FF" />
																<path d="M34.9896 49.6555C33.746 49.6555 32.5026 49.6555 31.2593 49.6555C29.5542 49.6555 28.3237 48.4294 28.315 46.7248C28.3117 46.1298 28.3117 45.5346 28.315 44.9392C28.3182 44.3256 28.6901 43.9308 29.2589 43.9297C29.8276 43.9286 30.2044 44.3207 30.2082 44.9338C30.2121 45.5468 30.2082 46.1456 30.2082 46.7516C30.2126 47.387 30.5861 47.7573 31.2238 47.7578C33.7427 47.7578 36.2614 47.7578 38.78 47.7578C39.4182 47.7578 39.7922 47.387 39.7971 46.7521C39.8015 46.1462 39.7971 45.5402 39.7971 44.9343C39.7971 44.3284 40.175 43.9286 40.746 43.9297C41.3169 43.9308 41.6943 44.3256 41.6893 44.9387C41.6833 45.7136 41.7265 46.4967 41.6292 47.2613C41.4509 48.6602 40.2582 49.6478 38.8429 49.6544C37.5615 49.6582 36.2753 49.6555 34.9896 49.6555Z" fill="white" />
																<path d="M34.0577 37.6102C33.9133 37.7448 33.8286 37.8186 33.7498 37.8979C33.1439 38.5098 32.5412 39.1256 31.9325 39.7354C31.5005 40.1685 30.8984 40.2002 30.5079 39.8212C30.1175 39.4423 30.1328 38.8205 30.5577 38.3928C31.7947 37.1481 33.0358 35.9078 34.2808 34.6719C34.7183 34.2344 35.2904 34.2344 35.7279 34.6719C36.9813 35.9151 38.2296 37.1636 39.4729 38.4174C39.8819 38.8303 39.8841 39.451 39.504 39.8218C39.124 40.1926 38.5197 40.1762 38.1024 39.7622C37.4844 39.147 36.8758 38.5219 36.2622 37.9028C36.1829 37.823 36.0981 37.7486 35.9718 37.6294C35.9636 37.8011 35.9537 37.9121 35.9537 38.0231C35.9537 39.9804 35.9565 41.9371 35.9537 43.8944C35.9537 44.6288 35.3073 45.0658 34.6352 44.8044C34.2633 44.6595 34.0615 44.3368 34.0615 43.8643C34.0593 41.9284 34.0593 39.9926 34.0615 38.057L34.0577 37.6102Z" fill="white" />
															</g>
															<defs>
																<clipPath id="clip0_5984_51759">
																	<rect width="28" height="28" fill="white" transform="translate(21 28)" />
																</clipPath>
															</defs>
														</svg>
														<span className="pen fs1rem fw500 c49 tac op80 mt1rem mb2rem fsi">
															Click here or drag and drop files (max 100MB)
														</span>
														<input onChange={(e) => saveAnswer(e, question, qNo)} className="dn" id={"fileUpload" + question._id} type="file" multiple />
													</label>
													<div className="df fww">
														{answer.files && answer.files.map(file => {
															let fileColor = file.originator == 'filing-assistant' ? 'filing-assistant-file' : file.originator == 'sharepoint' ? 'sharepoint-file' : file.originator == 'dropbox' ? 'dropbox-file' : file.originator == 'gdrive' ? 'gdrive-file' : file.bucket == `repository-diligentsia${AppMode === "production" ? "-prod" : ""}` ? "upload-file" : 'prepopulated-file';
															return (
																<div key={file.originalname} className={`df jcc aic lh1 px1rem py0-5rem fw600 mr0-5rem mb0-5rem cp pr pageCutBg ${fileColor}`} >
																	<span className="py0-5rem pl0-5rem" onClick={() => getViewableURL(file.location)}>{file.originalname}</span>
																	<CloseBtn onClick={e => {
																		e.preventDefault();
																		setDeleteFileQuestionId(question._id);
																		setDeleteFile(file);
																		dispatch(toggleModal({ open: 'deleteFile', from: '' }))
																	}}
																		className="ml1rem"
																		style={{ minWidth: "18px" }}
																	/>
																</div>)
														})}
													</div>
													<div className="oyh td250 pr df aic ">
														<textarea
															defaultValue={answer.comment}
															rows="1"
															style={{ resize: "vertical" }}
															onBlur={(e) => saveAnswer(e, question, qNo)}
															name="comment"
															type="text"
															placeholder="Add notes"
															className="pl3rem bss bw2px bo5 w100 br5px pr1rem py0-75rem"
														>
														</textarea>
														<svg className="pa ml1rem" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
															<g clip-path="url(#clip0_5984_51771)">
																<path d="M14.25 8.25H9.75V3.75C9.75 3.33579 9.41421 3 9 3C8.58579 3 8.25 3.33579 8.25 3.75V8.25H3.75C3.33579 8.25 3 8.58579 3 9C3 9.41421 3.33579 9.75 3.75 9.75H8.25V14.25C8.25 14.6642 8.58579 15 9 15C9.41421 15 9.75 14.6642 9.75 14.25V9.75H14.25C14.6642 9.75 15 9.41421 15 9C15 8.58579 14.6642 8.25 14.25 8.25Z" fill="#4A67FF" />
																<g clip-path="url(#clip1_5984_51771)">
																	<path d="M16.7301 14.3415L4.73098 17.2387C4.09339 17.3926 3.45171 17.0005 3.29776 16.3629L0.400638 4.36379C0.246685 3.7262 0.638767 3.08452 1.27636 2.93057L13.2755 0.0334501C13.9131 -0.120503 14.5548 0.27158 14.7087 0.909168L17.6059 12.9083C17.7598 13.5459 17.3678 14.1876 16.7301 14.3415Z" fill="url(#paint0_linear_5984_51771)" />
																	<path d="M14.3672 14.9115L16.7313 14.3407C17.3688 14.1868 17.7609 13.5451 17.607 12.9075L15.3723 3.65234H14.3672V14.9115Z" fill="url(#paint1_linear_5984_51771)" />
																	<path d="M15.3863 3.72331L14.7068 0.909168C14.5529 0.271579 13.9112 -0.120503 13.2736 0.0334501L1.27447 2.93057C0.856593 3.03147 0.54424 3.34185 0.421875 3.72331H15.3863Z" fill="url(#paint2_linear_5984_51771)" />
																	<path d="M1.85156 10.3629L3.30014 16.3624C3.45409 17 4.09574 17.3921 4.73336 17.2382L16.7325 14.341C17.3701 14.1871 17.7622 13.5454 17.6082 12.9078L16.1597 6.9082L1.85156 10.3629Z" fill="url(#paint3_linear_5984_51771)" />
																	<path d="M11.3983 17.9993H1.36905C0.815741 17.9993 0.367188 17.5508 0.367188 16.9975L0.376011 3.92377C0.376011 3.37046 0.824564 2.92188 1.37787 2.92188H14.4486C15.0019 2.92188 15.4505 3.37043 15.4505 3.92377L15.4417 13.956C15.4417 16.1891 13.6314 17.9993 11.3983 17.9993Z" fill="url(#paint4_linear_5984_51771)" />
																	<path d="M8.50558 4.14062L7.3125 5.33371L10.9873 9.00846C11.5559 8.8328 12.0046 8.38404 12.1803 7.81538L8.50558 4.14062Z" fill="url(#paint5_linear_5984_51771)" />
																	<path d="M7.90614 5.57985C8.37206 5.57985 8.74977 5.20214 8.74977 4.73621C8.74977 4.27029 8.37206 3.89258 7.90614 3.89258C7.44021 3.89258 7.0625 4.27029 7.0625 4.73621C7.0625 5.20214 7.44021 5.57985 7.90614 5.57985Z" fill="url(#paint6_linear_5984_51771)" />
																	<path d="M0.367188 10.6563V16.9965C0.367188 17.5498 0.815741 17.9983 1.36905 17.9983H11.3983C13.6314 17.9983 15.4417 16.1881 15.4417 13.955V10.6562H0.367188V10.6563Z" fill="url(#paint7_linear_5984_51771)" />
																	<path d="M11.3984 17.9984V14.957C11.3984 14.4037 11.847 13.9551 12.4003 13.9551H15.4418C15.4418 16.1882 13.6315 17.9984 11.3984 17.9984Z" fill="url(#paint8_linear_5984_51771)" />
																	<path d="M13.0689 9.20312H7.28104C7.07404 9.20312 6.90625 9.37091 6.90625 9.57791C6.90625 9.78491 7.07404 9.9527 7.28104 9.9527H13.0689C13.2759 9.9527 13.4437 9.78491 13.4437 9.57791C13.4437 9.37091 13.2759 9.20312 13.0689 9.20312Z" fill="white" />
																	<path d="M2.74979 9.9527H4.44885C4.65585 9.9527 4.82364 9.78491 4.82364 9.57791C4.82364 9.37091 4.65585 9.20312 4.44885 9.20312H2.74979C2.54279 9.20312 2.375 9.37091 2.375 9.57791C2.375 9.78491 2.54279 9.9527 2.74979 9.9527Z" fill="white" />
																	<path d="M5.375 9.57791C5.375 9.78491 5.54279 9.9527 5.74979 9.9527H6.17455C6.38155 9.9527 6.54934 9.78491 6.54934 9.57791C6.54934 9.37091 6.38155 9.20312 6.17455 9.20312H5.74979C5.54282 9.20312 5.375 9.37091 5.375 9.57791Z" fill="white" />
																	<path d="M8.53764 10.9688H2.74979C2.54279 10.9688 2.375 11.1365 2.375 11.3435C2.375 11.5505 2.54279 11.7183 2.74979 11.7183H8.53764C8.74464 11.7183 8.91242 11.5505 8.91242 11.3435C8.91242 11.1365 8.74464 10.9688 8.53764 10.9688Z" fill="white" />
																	<path d="M13.066 10.9688H11.367C11.16 10.9688 10.9922 11.1365 10.9922 11.3435C10.9922 11.5505 11.16 11.7183 11.367 11.7183H13.066C13.273 11.7183 13.4408 11.5505 13.4408 11.3435C13.4408 11.1365 13.273 10.9688 13.066 10.9688Z" fill="white" />
																	<path d="M10.073 10.9688H9.64823C9.44123 10.9688 9.27344 11.1365 9.27344 11.3435C9.27344 11.5505 9.44123 11.7183 9.64823 11.7183H10.073C10.28 11.7183 10.4478 11.5505 10.4478 11.3435C10.4478 11.1365 10.28 10.9688 10.073 10.9688Z" fill="white" />
																</g>
															</g>
															<defs>
																<linearGradient id="paint0_linear_5984_51771" x1="4.64913" y1="5.97348" x2="20.2804" y2="15.518" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#D9B1EE" />
																	<stop offset="1" stop-color="#814AA0" />
																</linearGradient>
																<linearGradient id="paint1_linear_5984_51771" x1="16.8003" y1="9.28193" x2="15.1027" y2="9.28193" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#5B3374" stop-opacity="0" />
																	<stop offset="1" stop-color="#5B3374" />
																</linearGradient>
																<linearGradient id="paint2_linear_5984_51771" x1="7.90405" y1="1.33693" x2="7.90405" y2="3.53614" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#5B3374" stop-opacity="0" />
																	<stop offset="1" stop-color="#5B3374" />
																</linearGradient>
																<linearGradient id="paint3_linear_5984_51771" x1="10.1943" y1="13.541" x2="11.0307" y2="17.0005" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#5B3374" stop-opacity="0" />
																	<stop offset="1" stop-color="#5B3374" />
																</linearGradient>
																<linearGradient id="paint4_linear_5984_51771" x1="0.973999" y1="3.52579" x2="14.9207" y2="17.4724" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#FFDD00" />
																	<stop offset="1" stop-color="#FFB000" />
																</linearGradient>
																<linearGradient id="paint5_linear_5984_51771" x1="9.96461" y1="6.7927" x2="5.00606" y2="1.83419" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#FEA613" stop-opacity="0" />
																	<stop offset="1" stop-color="#E94444" />
																</linearGradient>
																<linearGradient id="paint6_linear_5984_51771" x1="7.37934" y1="4.20938" x2="8.24118" y2="5.07123" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#FE93BB" />
																	<stop offset="1" stop-color="#FD3581" />
																</linearGradient>
																<linearGradient id="paint7_linear_5984_51771" x1="7.90442" y1="15.5917" x2="7.90442" y2="19.8029" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#FEA613" stop-opacity="0" />
																	<stop offset="1" stop-color="#E94444" />
																</linearGradient>
																<linearGradient id="paint8_linear_5984_51771" x1="12.3144" y1="14.8711" x2="14.6838" y2="17.2404" gradientUnits="userSpaceOnUse">
																	<stop stop-color="#FFDD00" />
																	<stop offset="1" stop-color="#FFB000" />
																</linearGradient>
																<clipPath id="clip0_5984_51771">
																	<rect width="18" height="18" fill="white" />
																</clipPath>
																<clipPath id="clip1_5984_51771">
																	<rect width="18" height="18" fill="white" />
																</clipPath>
															</defs>
														</svg>
													</div>
												</div>
											}
										</>}
									</div>
								</div>
							);
						})}
						{showSubFolder && (
							<div className="toggleHeight cp">
								<div className="oh dg" style={{ cursor: "auto" }}>
									<div></div> {/*LEAVE EMPTY DIV HERE*/}

									{getSectionHTML(module, section)}
								</div>
							</div>
						)}
					</>;
					return (
						<div ref={(el) => { if (section['sectionNo']) sectionRefs.current[sectionIdx] = el }} key={`Diligentia-${sectionIdx + 10000000}-questions-${qNo}`} id={qNo}
							className={(section['sectionNo'] && "sectionWrapper ") + " bss bw2px bo31 br10px px1rem py1rem fs1rem"}>
							{/* <div key={qNo} id={qNo} className={(section['sectionNo'] && "sectionWrapper ") + "bss bw2px bo5 br10px px1rem py0-5rem fs1rem"}> */}
							<div className="df mb0-5rem">
								<h3 className="fs1rem mr0-5rem c15">
									<span className="fw400">{section['sectionName'] && `Section ${sectionIdx + 1}: `} </span>{section['sectionName'] || section['name']}
								</h3>
								<span className={(section['sectionNo'] && "sectionTitle") + " fs0-75rem c15"}>{qNo}
									<span className="mx0-5rem"> | </span> {getCompletenessAndRiskHTML(module.type, section.stats, questions)}
								</span>
								{/* {showSubFolder && <>{!module['expansionPack'] && <button className="td250 fs0-75rem pen bg5 c6 br5px fw600 px0-5rem py0-5rem">Hide Subfolders</button>}</>} */}
							</div>
							{module.type === "business-audit" ? (
								<div className="toggleHeight cp">
									<button className="mb0-5rem td250 fs0-75rem pen bg5 c6 br5px fw600 px0-5rem py0-5rem">Show Section</button>
									<div className="oh dg" style={{ cursor: "auto", height: "0px" }}>
										<div></div>
										{jsx}
									</div>
								</div>)
								: <div className="dg">{jsx}</div>}
						</div>
					);
				})
			}
			{((user['role'] == 'Guest' || !user.firstName) && module['type'] == `two-minute-test-${company['testType']}`) &&
				<div className="maw400px mxa my2rem df jcc aic fdc">
					<Button
						btnType="warning"
						text="Next"
						disabled={(module?.['stats']?.['completionPercent'] != 100)}
						customClassName="df jcc aic"
						onClick={() => openKPI()}
					/>
				</div>
			}
		</>
	};

	return (
		<>
			<div className="td250 container bg1 py1rem w100 df">
				<div className="w80 mr1rem dg gg1rem">
					{
						user?.role.toLowerCase() === "guest" ? <HeadingSubheadingContent
							wrapperClass="tac ttc"
							heading={company?.modules[tab]?.heading}
							subheading={company?.modules[tab]?.subheading}
							paragraph={isTwoMinuteTesHomePage ? <>
								Register at the end of test to access more features <span className="fs11px">(No credit card required)</span>
								<br></br>
								<LockOutlined className="c3" />
							</> : <></>}
						/> : null
					}


					{tab !== 'investor-lead' &&
						<div className="dg gtcr5 gtcr5-xl gg0-5rem btn-dataroom-saf pr mah3rem">
							{/* Dummy Buttons for 2-Minute from Home Page */}
							{isTwoMinuteTesHomePage && <>
								{/* 2-Minute Sync/View Data Room Buttons  */}
								<button
									disabled
									data-tooltip='Sync Data room feature is disabled. You must register to access it.'
									className='da-cursor bg3 c1 td250 br50px op50 fs0-75rem py0-75rem fw600 cp bsbb tac df jcc aic mr1rem'
								>
									<span className="df">
										<GoogleDrive className="pen fds w1rem ml0-5rem" />
										<Dropbox className="pen fds w1rem ml0-5rem" />
										<SharePoint className="pen fds w1rem ml0-5rem" />
									</span>
									&nbsp;&nbsp;&nbsp;
									Sync Data room
								</button>
								<button disabled data-tooltip='View Data Room feature is disabled. You must register to access it.' className='da-cursor bg3 c1 td250 br50px op50 fs0-75rem py0-5rem fw600 cp bsbb tac df jcc aic mr1rem' onClick={() => syncDrive()}>
									<span className="df">
										<GoogleDrive className="pen fds w1rem ml0-5rem" />
										<Dropbox className="pen fds w1rem ml0-5rem c1 f1" />
										<SharePoint className="pen fds w1rem ml0-5rem" />
									</span>
									&nbsp;&nbsp;&nbsp;
									View Data Room
								</button>
								{/* 2-Minute Test Button Filling Assistant   */}
								<div></div>
								<div></div>
								<button data-tooltip='Filling Assistant feature is disabled. You must register to access it.' className='da-cursor btn-filing-assistant bg3 op50 c1 td250 br50px px1rem py0-75rem fw600 cp bsbb tac df jcc aic fs0-75rem pr' disabled>
									<img className="pa r0" style={{ top: "-15px" }} src={require("../../../../images/fillingassistanticons.png")} alt="fillingIcons" />
									<LockOutlined style={{ color: "#fff", marginRight: "8px", fontSize: '12px' }} />
									Filing Assistant
								</button>
							</>}
							{tab === "kitemark" ? <>
								<span className="shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttc bsbb tac df jcc aic">Kitemark Audit</span>
								<span className="shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttc bsbb tac df jcc aic">Kitemark Report</span>
								<span className="shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttc bsbb tac df jcc aic">Digital Certificate</span>
								<span className="shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttc bsbb tac df jcc aic">Full Report</span>
								<span className="shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttc bsbb tac df jcc aic">Report by Section</span>
							</>
								: company.modules?.[tab]?.uploadFiles && (() => {
									const [syncLink, driveName] = company.gDriveLink ? [company.gDriveLink, "Google Drive"]
										: company.sharePointLink ? [company.sharePointLink, "SharePoint"]
											: company.dropBoxLink ? [company.dropBoxLink, "DropBox"]
												: [undefined, "Not Connected"];
									return <>
										{((!company?.isParent && company['owners'][0] === user._id) || (company?.isParent && company['owners'][0] === user._id) || (company['servicedBy'] === user._id)) ? <>
											{company.gDriveLink && <>
												<button className={`${company['syncRequired'] ? 'bg4 bg3-hover ' : 'bg3 bg4-hover '} c1 td250 br50px px1rem py0-5rem fw600 cp ttc bsbb tac df jcc aic fs0-75rem`} onClick={() => syncDrive()}>
													<GoogleDrive className="pen fds w2rem" />
													&nbsp;&nbsp;&nbsp;
													Sync Data room
												</button>
												{company['folderId'] &&
													<button className="bg3 bg4-hover c1 td250 br50px px1rem py0-5rem fw600 cp ttc bsbb tac df jcc aic h100 fs0-75rem" onClick={() => openWindow(`https://drive.google.com/drive/folders/${company['folderId']}`)}>
														<GoogleDrive className="pen fds w2rem" />
														&nbsp;&nbsp;&nbsp;
														View Data Room
													</button>
												}
											</>}
											{company.dropBoxLink && <>
												<button className={`${company['syncRequired'] ? 'bg4 bg3-hover ' : 'bg3 bg4-hover '} c1 td250 br50px px1rem py0-5rem fw600 cp ttc bsbb tac df jcc aic fs13px`} onClick={() => syncDropbox()}>
													<Dropbox className="pen fds w2rem" />
													&nbsp;&nbsp;&nbsp;
													Sync Data room
												</button>
												{company['dropBoxFolderMetadata'] &&
													<button className="bg3 bg4-hover c1 td250 br50px px1rem py0-5rem fw600 cp ttc bsbb tac df jcc aic h100 fs13px" onClick={() => openWindow(`https://dropbox.com/home/${company['dropBoxFolderMetadata']['path_display']}`)}>
														<Dropbox className="pen fds w2rem" />
														&nbsp;&nbsp;&nbsp;
														View Data Room
													</button>
												}
											</>}
											{company.sharePointLink && <>
												<button className={`${company['syncRequired'] ? 'bg4 bg3-hover ' : 'bg3 bg4-hover '} c1 td250 br50px px1rem py0-5rem fw600 cp ttc bsbb tac df jcc aic fs0-75rem`} onClick={() => syncSharePoint()}>
													<SharePoint className="pen fds w2rem" />
													&nbsp;&nbsp;&nbsp;
													Sync Data room
												</button>
												{company['sharePointLink'] &&
													<button className="bg3 bg4-hover c1 td250 br50px px1rem py0-5rem fw600 cp ttc bsbb tac df jcc aic h100 fs0-75rem" onClick={() => openWindow(`${company['sharePointFolderDriveItem']['rootFolder']['webUrl']}`)}>
														<SharePoint className="pen fds w2rem" />
														&nbsp;&nbsp;&nbsp;
														View Data Room
													</button>
												}
											</>}
											{syncLink == undefined && (
												tab == 'repository' && <>
													<button onClick={() => dispatch(toggleModal({ open: 'connectDataroom' }))} className={`${!company['uploadedFile'] && 'op20 pen '} fs13px bg3 bg4-hover c1 td250 br50px px1rem py0-5rem fw600 cp ttc bsbb tac df jcc aic`}>Connect Data Room</button>
													<div></div>
												</>

											)}
										</> : <><div></div> <div></div></>}
										{tab == 'repository' && (
											<>
												<div></div>
												<div></div>
												<div className="pr df jce aifs">
													<div className="menuHover pr h100">
														<button onMouseEnter={() => setFAMenuVisible(true)} className="w200px btn-filing-assistant bg3 bg4-hover c1 td250 br50px px1rem py0-5rem h100 fw600 cp ttc bsbb tac df jcc aic fs13px">
															<span className="tooltip-area" style={{ top: '0px', left: '0px' }}>
																<i className="icon pr">
																	<img
																		className="h3rem"
																		src={require(`../../../../images/info-icon.svg`)}
																		alt="info-icon"
																	/>
																	<div className="overlay-box withGirl">
																		<p>I haven't been able to</p>
																		<p> automatically allocate some</p>
																		<p>files, can you help me out?</p>
																	</div>
																</i>
															</span>
															Filing Assistant
														</button>

														{isFAMenuVisible && (
															<div className="miw100 menu op0 bsbb bw0px bbw5px bss bo3 zi1 bg1 shadow td250 pen p0-5rem pa t100 r0 df fdc">
																<label onClick={onAccurateFileName} className="bsbb wsn w100 td250 c4-hover p0-5rem ass cp">
																	<span>Import Files</span>
																</label>
																<label onClick={onAccurateFolderName} className="bsbb w100 wsn td250 c4-hover p0-5rem ass cp">
																	<span>Import Folders</span>
																</label>
																{
																	company['subscription'] && company['subscription'].toLowerCase() === "pro" ? <p onClick={onClickFileReportHistory} className="bsbb w100 td250 c4-hover p0-5rem ass cp wsn">
																		Filing Assistant Manager
																	</p> : null
																}

																<p onClick={onClickViewUnallocatedFiles} className="bsbb w100 td250 c4-hover p0-5rem ass cp wsn">
																	View Unallocated Files
																</p>

																<p onClick={() => checkDuplicatedFiles()} className="bsbb w100 td250 c4-hover p0-5rem ass cp wsn">
																	Deduper
																</p>

																<p onClick={onClickDeduperReportHistory} className="bsbb w100 td250 c4-hover p0-5rem ass cp wsn">
																	Deduper Reports
																</p>
															</div>
														)}
													</div>
												</div>
											</>
										)}
									</>
								})()
							}
						</div>
					}
					{tab !== 'investor-lead' && ((!company?.isParent && company['owners'][0] === user._id) || (company?.isParent && company['owners'][0] === user._id) || (company['servicedBy'] === user._id)) && <>
						<div className="dg gtcr2-md gg0-5rem">
							{company['lastSyncBy'] && company.modules?.[tab]?.uploadFiles &&
								<div className="df aic fs14px c15">
									{company['syncRequired'] && <span className="c4">* Sync is Required -  </span>}
									Last synced by - {company?.['lastSyncBy']?.['profile'] && <img className={`ass mx0-5rem br100 w2rem h2rem`} src={company?.['lastSyncBy']?.['profile']} />} {company?.['lastSyncBy']?.['firstName']},
									{moment(company['lastSyned']).format('lll')}
								</div>
							}
						</div>
					</>}
					<div className="df aic jcc">
						<ul className="list-file-manger" style={{ marginBottom: "0px" }}>
							<li className="bg1 fw400 c2 upload-file">Uploaded Directly</li>
							<li className="bg1 fw400 c2 prepopulated-file">Companies House</li>
							<li className="bg1 fw400 c2 filing-assistant-file">Filing Assistant</li>
							<li className="bg1 fw400 c2 sharepoint-file">Sharepoint</li>
							<li className="bg1 fw400 c2 dropbox-file">Dropbox</li>
							<li className="bg1 fw400 c2 gdrive-file">Drive</li>
						</ul>
					</div>

					{company?.modules[tab] && getSectionHTML(company.modules[tab])}
					{tab == 'repository' && (permissionLevel == "ALL" || permissionLevel == "DASHBOARD & REPORT") &&
						<div className="py2rem df jcc">
							<div>
								<button className={`${(company?.['modules']?.[tab]?.['stats']?.['completionPercent'] == 0) && 'op20 pen '} shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttc bsbb tac df jcc aic`} onClick={(e) => toggleReport(e)}>
									{tab.endsWith("-report") ? "Close" : "View"}{' Report'}
								</button>
							</div>
						</div>
					}
					{tab == 'business-audit' &&
						<div className="py2rem df jcc">
							<button onClick={() => enableAIEnhancedReporting()} className={`${(company?.['modules']?.[tab]?.['stats']?.['completionPercent'] == 0) && 'op20 pen '} h100 mt0-5rem mxa shadow bg3 bg4-hover c1 td250 br5px px1rem fs0-75rem py0-5rem fw600 cp ttc bsbb tac df jcc aic`}>
								<svg className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path className="fa-primary" d="M339.8 211.8l-128 128C206.3 345.3 199.2 348 192 348s-14.34-2.719-19.81-8.188l-64-64c-10.91-10.94-10.91-28.69 0-39.63c10.94-10.94 28.69-10.94 39.63 0L192 280.4l108.2-108.2c10.94-10.94 28.69-10.94 39.63 0C350.7 183.1 350.7 200.9 339.8 211.8z" /><path className="fa-secondary" d="M384 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V96C448 60.65 419.3 32 384 32zM339.8 211.8l-128 128C206.3 345.3 199.2 348 192 348s-14.34-2.719-19.81-8.188l-64-64c-10.91-10.94-10.91-28.69 0-39.63c10.94-10.94 28.69-10.94 39.63 0L192 280.4l108.2-108.2c10.94-10.94 28.69-10.94 39.63 0C350.7 183.1 350.7 200.9 339.8 211.8z" /></svg>
								AI Enhanced Report
							</button>
						</div>
					}
					{(tab == 'warranty-disclosure' || tab == 'software-development') &&
						<div className="py3rem df jcc">
							<button className={`${(company?.['modules']?.[tab]?.['stats']?.['completionPercent'] == 0) && 'op20 pen '} shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttc bsbb tac df jcc aic`} onClick={() => toggleReport()}>
								{tab.endsWith("-report") ? "Close" : "View"}
								{tab == 'warranty-disclosure'
									? ' Disclosure Schedule'
									: ' Report'
								}
							</button>
						</div>
					}
				</div>

				{company?.modules[tab] && <SidebarIndex
					setIsWorkflowReport={setIsWorkflowReport}
					visibleSection={visibleSection}
					setTab={setTab}
					profileClass={profileClass}
					advisors={advisors}
					permissionLevel={permissionLevel}
					company={company}
					setCompany={setCompany}
					tab={tab}
					sanitiseTab={sanitiseTab}
					toggleReport={toggleReport}
					openKPI={openKPI}
					calculateStats={calculateStats}
					calculateSubFolderStats={calculateSubFolderStats}
					getCompletenessAndRiskHTML={getCompletenessAndRiskHTML}
					setSection={setSection}
					getViewableURL={getViewableURL}
					twoMinuteTestAIReport={twoMinuteTestAIReport}
					isTwoMinuteTesHomePage={isTwoMinuteTesHomePage}
					getFitInvestorFirms={getFitInvestorFirms}
					setOpenTabs={setOpenTabs}
					openTabs={openTabs}
					sortSections={sortSections}
				/>
				}
			</div>

			{/* File Report History */}
			<FileReportHistory
				modalType={'fileReportHistory'}
				reportHistories={reportHistories}
				setFileReport={setFileReport}
				setCloneImportResult={setCloneImportResult}
				setCurrentScore={setCurrentScore}
				company={company}
				profileClass={profileClass}
			/>

			{/* Unallocated Files */}
			<UnallocatedFilesModal
				modalType={'unallocatedFiles'}
				tab={tab}
				loading={loading}
				setLoading={setLoading}
				company={company}
				setUnallocatedFiles={setUnallocatedFiles}
				onClickUnallocatedBinAuditLog={onClickUnallocatedBinAuditLog}
				unallocatedFiles={unallocatedFiles}
				handleEditDispatch={handleEditDispatch}
				moveUnallocatedFile={moveUnallocatedFile}
				setDeleteUnallocatedFile={setDeleteUnallocatedFile}
				targetSection={targetSection}
			/>

			{/* Manage Files */}
			<ManageFilesModal
				modalType={'manageFiles'}
				reloadModule={reloadModule}
				section={section}
				setSection={setSection}
				company={company}
				getViewableURL={getViewableURL}
				handleEditDispatch={handleEditDispatch}
				setDeleteFileQuestionId={setDeleteFileQuestionId}
				setDeleteFile={setDeleteFile}
				tab={tab}
				targetSection={targetSection}
			/>

			{/* Duplication Check Waiting  */}
			<DeduperReportWaiting
				modalType={'checkingDuplication'}
				company={company}
				onClickDeduperAuditLog={onClickDeduperAuditLog}
			/>


			{/* File Deduplication Summary */}
			<DeduperReport
				modalType={'duplicationCheckSummary'}
				company={company}
				dupCheck={dupCheck}
				modal={modal}
				profileClass={profileClass}
				onClickDeduperAuditLog={onClickDeduperAuditLog}
				getViewableURL={getViewableURL}
				handleEditDispatch={handleEditDispatch}
				setRefresh={setRefresh}
				reloadModule={reloadModule}
				moveDeduperFile={moveDeduperFile}
				tab={tab}
			/>

			{/* Allocating Files Waiting  */}
			<Modal open={modal['open'] == 'allocatingFiles'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic" >
				<>
					<div className="pr oya bg1 shadow w100 pl3rem pr3rem pb5rem pt0rem bsbb df fdc m1rem maw1000px br5px pr">
						<div className="AnimateWiz tac">
							<img className="h15rem mla mra" src={require(`../../../../images/wizard.gif`)} alt="animWiz" />
						</div>
						<h2 className="fs1-5rem-sm w100 tac mb2rem c15">Performing Filing assistant magic!</h2>
						<div className="tac">
							<p className="c27 mb2rem">This may take a few seconds</p>
							<p className="c27">We’re now summoning the power of AI to allocating files to appropriate sections! <br></br>Please stay with us during this magical incantation.</p>
						</div>
					</div>
					<div style={{ position: 'relative' }}>
						<img src="https://repository-diligentsia.s3.ap-northeast-1.amazonaws.com/development/Group 1000002103-1733923364928.png" alt="messageLadie" className='girlImage1' />
					</div>
					<svg className="pa cp" onClick={() => dispatch(toggleModal({ open: '' }))} style={{ left: '50%', bottom: '80px', transform: 'translateX(-50%)' }} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect opacity="0.8" x="4" y="4" width="52" height="52" rx="26" fill="#151313" />
						<path d="M48.5626 11.4375C44.8913 7.76665 40.214 5.26685 35.1221 4.2542C30.0302 3.24156 24.7523 3.76155 19.9559 5.74843C15.1595 7.73531 11.06 11.0998 8.17569 15.4166C5.29142 19.7333 3.75195 24.8084 3.75195 30C3.75195 35.1917 5.29142 40.2667 8.17569 44.5835C11.06 48.9002 15.1595 52.2647 19.9559 54.2516C24.7523 56.2385 30.0302 56.7585 35.1221 55.7458C40.214 54.7332 44.8913 52.2334 48.5626 48.5625C51.0004 46.1249 52.9342 43.231 54.2535 40.0461C55.5729 36.8611 56.252 33.4474 56.252 30C56.252 26.5526 55.5729 23.1389 54.2535 19.954C52.9342 16.769 51.0004 13.8751 48.5626 11.4375ZM39.3751 36.6375C39.5624 36.8129 39.7117 37.0249 39.8138 37.2603C39.9159 37.4958 39.9686 37.7496 39.9686 38.0063C39.9686 38.2629 39.9159 38.5168 39.8138 38.7522C39.7117 38.9876 39.5624 39.1996 39.3751 39.375C39.1999 39.5488 38.9921 39.6863 38.7636 39.7796C38.5352 39.8729 38.2906 39.9202 38.0438 39.9188C37.5519 39.9167 37.0805 39.7214 36.7313 39.375L30.0001 32.6438L23.3626 39.375C23.0133 39.7214 22.5419 39.9167 22.0501 39.9188C21.8033 39.9202 21.5587 39.8729 21.3302 39.7796C21.1018 39.6863 20.894 39.5488 20.7188 39.375C20.3696 39.0237 20.1736 38.5485 20.1736 38.0531C20.1736 37.5578 20.3696 37.0826 20.7188 36.7313L27.3563 30L20.7188 23.3625C20.4116 23.0038 20.2511 22.5424 20.2694 22.0705C20.2876 21.5986 20.4832 21.151 20.8171 20.8171C21.1511 20.4832 21.5987 20.2875 22.0706 20.2693C22.5425 20.2511 23.0039 20.4116 23.3626 20.7188L30.0001 27.3563L36.6376 20.7188C36.8111 20.539 37.0184 20.3952 37.2476 20.2956C37.4768 20.196 37.7233 20.1424 37.9732 20.1381C38.223 20.1337 38.4713 20.1786 38.7038 20.2702C38.9363 20.3618 39.1484 20.4983 39.3282 20.6719C39.5079 20.8455 39.6517 21.0528 39.7514 21.2819C39.851 21.5111 39.9045 21.7576 39.9089 22.0075C39.9132 22.2573 39.8683 22.5056 39.7767 22.7381C39.6851 22.9706 39.5486 23.1828 39.3751 23.3625L32.6438 30L39.3751 36.6375Z" fill="white" />
					</svg>
				</>
			</Modal>

			{/* Connect Dataroom */}
			<Modal open={modal['open'] == 'connectDataroom'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic" >
				<div className="mah90 oya bg1 shadow w100 p3rem bsbb df fdc m1rem maw900px br5px pr">
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<img className="h2rem pa t0 l0 m1rem" src={require(`../../../../images/logo.svg`)} alt="TODO" />
					<h1 className="text-gradient fs3rem fw600 tac mb2rem">Connect Data Room</h1>
					<div className="dg gtcr3-md gg1rem mb2rem">
						<a className="bg1 br5px bss bo6 bw2px p1rem shadow" onClick={() => dispatch(toggleModal({ open: '' }))} href={company?.gDriveURL}>
							<div className="ma mb1rem df jcc aic">
								<GoogleDrive className="maw100 mr0-5rem h2rem" />
								<h2 className="fs1-5rem dib">Google Drive</h2>
							</div>
							<p className="w100 tac fw600 fs1-25rem">
								Connect Now
								<svg className="f3 ml0-5rem w1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M256 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L370.8 256l-137.4-137.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C272.4 444.9 264.2 448 256 448z" />
									<path className="fa-secondary" d="M338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l32 32L338.8 288z" />
								</svg>
							</p>
						</a>
						<a className="bg1 br5px bss bo6 bw2px p1rem shadow" onClick={() => dispatch(toggleModal({ open: '' }))} href={company?.dropboxURL}>
							<div className="ma mb1rem df jcc aic">
								<Dropbox className="maw100 mr0-5rem h2rem" />
								<h2 className="fs1-5rem dib">Dropbox</h2>
							</div>
							<p className="w100 tac fw600 fs1-25rem">
								Connect Now
								<svg className="f3 ml0-5rem w1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M256 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L370.8 256l-137.4-137.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C272.4 444.9 264.2 448 256 448z" />
									<path className="fa-secondary" d="M338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l32 32L338.8 288z" />
								</svg>
							</p>
						</a>
						<a className="bg1 br5px bss bo6 bw2px p1rem shadow" onClick={() => dispatch(toggleModal({ open: '' }))} href={company?.sharePointAuthURL}>
							<div className="ma mb1rem df jcc aic">
								<SharePoint className="maw100 mr0-5rem h2rem" />
								<h2 className="fs1-5rem dib">SharePoint</h2>
							</div>
							<p className="w100 tac fw600 fs1-25rem">
								Connect Now
								<svg className="f3 ml0-5rem w1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M256 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L370.8 256l-137.4-137.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C272.4 444.9 264.2 448 256 448z" />
									<path className="fa-secondary" d="M338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l32 32L338.8 288z" />
								</svg>
							</p>
						</a>
						{[<IDeals className="maw100 mr0-5rem h2rem" />,
						<SeedLegals className="maw100 mr0-5rem h2rem" />,
						<Datasite className="maw100 mr0-5rem h2rem" />].map((logo, idx) => (
							<div key={idx} className="bg1 br5px bss bo6 bw2px p1rem shadow op20 pen">
								<div className="ma mb1rem df jcc aic">
									{logo}
								</div>
								<p className="w100 tac fw600 fs0-75rem">Coming Soon</p>
							</div>
						))}
					</div>
					<h2 className="c4 fs1-5rem fs2rem-sm ttc w100 tac mb1rem">What's This?</h2>
					{["This links and mirrors your Diligentsia Data Repository and your existing cloud storage provider.*",
						"Our automated API will create a Diligentsia Corporate Folder within your chosen cloud provider's storage.*",
						"At your command (not ours), the two are synced.",
						"Diligentsia does NOT access your other folders at any time",
						"Diligentsia does NOT send your data outside our platform - only you can do so.",
					].map((bulletPoint, idx) => (
						<div key={idx} className="mb0-5rem df aifs">
							<svg className="w2rem ha f3 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
								<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
							</svg>
							<span className="w100">{bulletPoint}</span>
						</div>
					))}
					<span className="mt1rem fs1rem">*You must already have an account with that provider (we cannot access files already on the provider's platform and we do not collect personal data from the provider)</span>
				</div>
			</Modal>

			{/* Pro Add Section */}
			<UserDefinedSectionModal
				onEdit={onEdit}
				onDelete={onDelete}
				modalType={'proaddsection'}
				editSectionName={editSectionName}
				additionalSections={additionalSections}
				isSectionNameDuplicate={isSectionNameDuplicate}
				onSaveAdditionalSections={onSaveAdditionalSections}
			/>

			{/* Delete File */}
			<DeleteFileConfirmModal
				modalType="deleteFile"
				deleteFile={deleteFile}
				onConfirm={() => confirmDeleteFile(modal['from'])}
				onCancel={() => dispatch(toggleModal({ open: modal['from'] }))}
			/>

			{/* Delete Unallocated File */}
			<DeleteFileConfirmModal
				modalType="deleteUnallocatedFile"
				deleteFile={deleteUnallocatedFile?.file ?? {}}
				onConfirm={confirmDeleteUnallocatedFile}
				onCancel={() => dispatch(toggleModal({ open: 'unallocatedFiles' }))}
			/>

			{/* Audit Log */}
			<AuditLogModal
				getViewableURL={getViewableURL}
				profileClass={profileClass}
				modalType={"auditLog"}
				logList={logList}
				logModule={logModule}
			/>

			{/* Deduper Report Audit Log */}
			<Modal open={modal['open'] == 'deduperReportAuditLog'} onClose={() => dispatch(toggleModal({ open: 'duplicationCheckSummary' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic" >
				<div className="mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw800px pr pt5rem br5px pr">
					<span className="pa t0 l0 m1rem ttc c7 fs1-5rem fw500">{company.subscription}</span>
					<img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: 'duplicationCheckSummary' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h1 className="text-gradient fs1-5rem fw600 tac">{company['cName']} Audit Log</h1>
					<h2 className="c4 fs0-75rem ttc w100 tac mb1rem">See the history of Company Deduper Report</h2>
					<div className="ma oya dg gg0-5rem mb2rem w100">
						{logList.length > 0
							? getDeduperReportLogsHTML(logList)
							: <h2 className="c4 fs1-50rem ttc w100 tac mb1rem">No Logs Found!!</h2>
						}
					</div>
					<div className="df w100 fdr jcc ">
						<button onClick={() => dispatch(toggleModal({ open: 'duplicationCheckSummary' }))} className="shadow bg3 bg4-hover c1 td250 br5px p1rem fw600 cp miw20 ttc bsbb tac mb1rem df jcc aic">Go Back</button>
					</div>
				</div>
			</Modal>

			{/* Unallocated Bin Audit Log */}
			<Modal open={modal['open'] == 'unallocatedBinAuditLog'} onClose={() => dispatch(toggleModal({ open: 'unallocatedFiles' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic" >
				<div className="mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw800px pr pt5rem br5px pr">
					<span className="pa t0 l0 m1rem ttc c7 fs1-5rem fw500">{company.subscription}</span>
					<img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: 'unallocatedFiles' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h1 className="text-gradient fs1-5rem fw600 tac">{company['cName']} Audit Log</h1>
					<h2 className="c4 fs0-75rem ttc w100 tac mb1rem">See the history of Company Unallocated Bin</h2>
					<div className="ma oya dg gg0-5rem mb2rem w100">
						{logList.length > 0
							? getUnallocatedBinLogsHTML(logList)
							: <h2 className="c4 fs1-50rem ttc w100 tac mb1rem">No Logs Found!!</h2>
						}
					</div>
					<div className="df w100 fdr jcc ">
						{/* <button onClick={() => dispatch(toggleModal({ open: '' }))} className="shadow bg3 bg4-hover c1 td250 br5px p1rem fw600 cp miw20 ttc bsbb tac mb1rem df jcc aic mr1rem">OK</button> */}
						<button onClick={() => dispatch(toggleModal({ open: 'unallocatedFiles' }))} className="shadow bg3 bg4-hover c1 td250 br5px p1rem fw600 cp miw20 ttc bsbb tac mb1rem df jcc aic">Go Back</button>
					</div>
				</div>
			</Modal>

			{/* File Report Audit Log */}
			<Modal open={modal['open'] == 'fileReportAuditLog'} onClose={() => dispatch(toggleModal({ open: 'importSummary' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic" >
				<div className="mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw800px pr pt5rem br5px pr">
					<span className="pa t0 l0 m1rem ttc c7 fs1-5rem fw500">{company.subscription}</span>
					<img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: 'importSummary' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h1 className="text-gradient fs1-5rem fw600 tac">{company['cName']} Audit Log</h1>
					<h2 className="c4 fs0-75rem ttc w100 tac mb1rem">See the history of Company File Import</h2>
					<div className="ma oya dg gg0-5rem mb2rem w100">
						{logList.length > 0
							? getFileReportLogsHTML(logList)
							: <h2 className="c4 fs1-50rem ttc w100 tac mb1rem">No Logs Found!!</h2>
						}
					</div>
					<div className="df w100 fdr jcc ">
						<button onClick={() => dispatch(toggleModal({ open: 'importSummary' }))} className="shadow bg3 bg4-hover c1 td250 br5px p1rem fw600 cp miw20 ttc bsbb tac mb1rem df jcc aic">Go Back</button>
					</div>
				</div>
			</Modal>

			{/* Data Repository */}
			<WelcomToDashboardModal
				modalType={'dataRepository'}
				dispatch={dispatch}
				company={company}
				user={user}
			/>

			{/* Repository Completed */}
			<Modal open={modal['open'] == 'repositoryCompleted'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic" >
				<div className="mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw800px pr pt5rem br5px pr">
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<img className="h2rem pa t0 l0 m1rem" src={require(`../../../../images/logo.svg`)} alt="TODO" />
					<h1 className="text-gradient fw600 pb1rem">Congratulations!</h1>
					<div className="tac df fdc jcc aic tac">
						<p>Hello, {user.firstName}! 🎉</p>
						<p>Look at you, unstoppable force! 🚀 You've made your first pass at filling your Data Repository and have emerged victorious! Now, it's time to step into your next adventure:</p>
						<p>Unleash the magic of connectivity! Sync your Repository with your preferred provider Data Room. This will create an unbreakable bond between your treasures, keeping them safe and accessible anytime, anywhere. 🌐</p>
						{company.gDriveLink && <button className="shadow my1rem mxa bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttc bsbb tac df jcc aic" onClick={() => { dispatch(toggleModal({ open: '' })); syncDrive() }}>
							<GoogleDrive className="pen fds w2rem mr1rem" />
							👉 Sync Now
						</button>}

						<p>Excited to see the fruits of your labor? View your Repository Report and bask in the glory of your progress. Every detail counts in this journey, and you've made a remarkable headway! 📈</p>
						<button onClick={e => { dispatch(toggleModal({ open: '' })); toggleReport(e); }} className="shadow my1rem mxa bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttc bsbb tac df jcc aic">👉 View Report</button>
						<p>Feeling ready for the next level? It's time to take on the Quick Business Audit questionnaire! Upon its completion, Diligentsia’s unaudited certification awaits. This prized possession will symbolize your company's readiness for any challenge ahead. 💼</p>
						{(() => {
							const [syncLink, driveName] = company.gDriveLink ? [company.gDriveLink, "Google Drive"]
								: company.sharePointLink ? [company.sharePointLink, "SharePoint"]
									: company.dropBoxLink ? [company.dropBoxLink, "DropBox"]
										: [undefined, "Not Connected"];
							return <>
								{company.gDriveLink && <div className="df my1rem">
									<button className={`${company['syncRequired'] ? 'bg4 bg3-hover ' : 'bg3 bg4-hover '} shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttc bsbb tac df jcc aic h100`} onClick={() => syncDrive()}>
										<GoogleDrive className="pen fds w2rem" />
										&nbsp;&nbsp;&nbsp;
										Sync Data room
									</button>
									{company['folderId'] &&
										<button className="ml1rem shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttc bsbb tac df jcc aic h100" onClick={() => openWindow(`https://drive.google.com/drive/folders/${company['folderId']}`)}>
											<GoogleDrive className="pen fds w2rem" />
											&nbsp;&nbsp;&nbsp;
											View Data Room
										</button>
									}
								</div>}
								{company.dropBoxLink && <div className="df my1rem">
									<button className={`${company['syncRequired'] ? 'bg4 bg3-hover ' : 'bg3 bg4-hover '} shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttc bsbb tac df jcc aic h100`} onClick={() => syncDropbox()}>
										<Dropbox className="pen fds w2rem" />
										&nbsp;&nbsp;&nbsp;
										Sync Data room
									</button>
									{company['dropBoxFolderMetadata'] &&
										<button className="ml1rem shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttc bsbb tac df jcc aic h100" onClick={() => openWindow(`https://dropbox.com/home/${company['dropBoxFolderMetadata']['path_display']}`)}>
											<Dropbox className="pen fds w2rem" />
											&nbsp;&nbsp;&nbsp;
											View Data Room
										</button>
									}
								</div>}
								{company.sharePointLink && <div className="df my1rem">
									<button className={`${company['syncRequired'] ? 'bg4 bg3-hover ' : 'bg3 bg4-hover '} shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttc bsbb tac df jcc aic h100`} onClick={() => syncSharePoint()}>
										<SharePoint className="pen fds w2rem" />
										&nbsp;&nbsp;&nbsp;
										Sync Data room
									</button>
									{company['sharePointLink'] &&
										<button className="ml1rem shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttc bsbb tac df jcc aic h100" onClick={() => openWindow(`${company['sharePointFolderDriveItem']['rootFolder']['webUrl']}`)}>
											<SharePoint className="pen fds w2rem" />
											&nbsp;&nbsp;&nbsp;
											View Data Room
										</button>
									}
								</div>}
								{syncLink == undefined && (
									tab == 'repository' && <button onClick={() => dispatch(toggleModal({ open: 'connectDataroom' }))} className={`${!company['uploadedFile'] && 'op20 pen '} my1rem shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttc bsbb tac df jcc aic`}>Connect Data Room</button>
								)}
							</>
						})()}
						<p>Remember, every adventure you undertake strengthens your fortress, builds your credibility, and takes your business one step closer to greatness. Keep conquering, {user['firstName']}! 👍</p>
					</div>
				</div>
			</Modal>

			{/* Created DataRoom */}
			<Modal open={modal['open'] == 'createdDataRoom'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic" >
				<div className="mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw600px pr pt5rem br5px pr">
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<img className="h2rem pa t0 l0 m1rem" src={require(`../../../../images/logo.svg`)} alt="TODO" />
					<div className="tac my1rem">
						<p className="mb0-5rem">Good news!  Your Cloud Data<br />Room has now been created.<br />You can now sync.​</p>
					</div>
					<div className="df jcc">
						<button className="w60 shadow bg4 bg3-hover c1 td250 br5px px1rem py0-5rem mr1rem fw600 cp ttc bsbb df jcc" onClick={() => { window.location.href = `/company/${companyId}#repository` }}>Let’s go!​</button>
					</div>
				</div>
			</Modal>

			{/* Accurate file names? */}
			<Modal id="accurateFileNamesModal" open={modal['open'] == 'accurateFileNames'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic" >
				<div className="poppins-font-style cookies-modal mah100 bg1 shadow w100 df fdc m1rem pr br10px pr freecompany-modal" style={{ outline: 'none', maxWidth: '750px', borderRadius: '20px', }}>
					<div className="df fww w100 bsbb">
						<div className="left-text w60 py3rem pt5rem bsbb pl3rem">
							<h2 className="c15 fs1-5rem mb0-5rem">Accurate file names?</h2>
							<p className="c15 fs1rem">Hi, I’m Clarence, your AI Filing Assistant, Please note that we do not interrogate your file contents, but allocate to our Data Repository on the basis of each file’s name.  Filenames that are insufficiently informative or which contain special characters will be placed in an “unallocated bin” for your subsequent manual allocation.</p>
						</div>
						<div className="right-img w40 mb2rem mt2rem pl1rem pr0-5rem bsbb">
							<img className="w100" src={require(`../../../../images/modal-right-img.png`)} alt="TODO" />
						</div>
					</div>
					<div className="df jcc mb4rem" for={"company_fileupload" + company['_id']} style={{ marginTop: '-48px' }}>
						<button className="w30 shadow bg4 bg3-hover c1 td250 br30px px1rem py0-5rem  fw600 cp ttc bsbb df jcc mt1rem">Next</button>
						<input ref={fileImportRef} onChange={handleFileChange} className="op0 pa cp h4rem" id={"company_fileupload" + company['_id']} type="file" multiple />
					</div>
					<svg className="pa cp" onClick={() => dispatch(toggleModal({ open: '' }))} style={{ left: '50%', bottom: '-80px', transform: 'translateX(-50%)' }} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect opacity="0.8" x="4" y="4" width="52" height="52" rx="26" fill="#151313" />
						<path d="M48.5626 11.4375C44.8913 7.76665 40.214 5.26685 35.1221 4.2542C30.0302 3.24156 24.7523 3.76155 19.9559 5.74843C15.1595 7.73531 11.06 11.0998 8.17569 15.4166C5.29142 19.7333 3.75195 24.8084 3.75195 30C3.75195 35.1917 5.29142 40.2667 8.17569 44.5835C11.06 48.9002 15.1595 52.2647 19.9559 54.2516C24.7523 56.2385 30.0302 56.7585 35.1221 55.7458C40.214 54.7332 44.8913 52.2334 48.5626 48.5625C51.0004 46.1249 52.9342 43.231 54.2535 40.0461C55.5729 36.8611 56.252 33.4474 56.252 30C56.252 26.5526 55.5729 23.1389 54.2535 19.954C52.9342 16.769 51.0004 13.8751 48.5626 11.4375ZM39.3751 36.6375C39.5624 36.8129 39.7117 37.0249 39.8138 37.2603C39.9159 37.4958 39.9686 37.7496 39.9686 38.0063C39.9686 38.2629 39.9159 38.5168 39.8138 38.7522C39.7117 38.9876 39.5624 39.1996 39.3751 39.375C39.1999 39.5488 38.9921 39.6863 38.7636 39.7796C38.5352 39.8729 38.2906 39.9202 38.0438 39.9188C37.5519 39.9167 37.0805 39.7214 36.7313 39.375L30.0001 32.6438L23.3626 39.375C23.0133 39.7214 22.5419 39.9167 22.0501 39.9188C21.8033 39.9202 21.5587 39.8729 21.3302 39.7796C21.1018 39.6863 20.894 39.5488 20.7188 39.375C20.3696 39.0237 20.1736 38.5485 20.1736 38.0531C20.1736 37.5578 20.3696 37.0826 20.7188 36.7313L27.3563 30L20.7188 23.3625C20.4116 23.0038 20.2511 22.5424 20.2694 22.0705C20.2876 21.5986 20.4832 21.151 20.8171 20.8171C21.1511 20.4832 21.5987 20.2875 22.0706 20.2693C22.5425 20.2511 23.0039 20.4116 23.3626 20.7188L30.0001 27.3563L36.6376 20.7188C36.8111 20.539 37.0184 20.3952 37.2476 20.2956C37.4768 20.196 37.7233 20.1424 37.9732 20.1381C38.223 20.1337 38.4713 20.1786 38.7038 20.2702C38.9363 20.3618 39.1484 20.4983 39.3282 20.6719C39.5079 20.8455 39.6517 21.0528 39.7514 21.2819C39.851 21.5111 39.9045 21.7576 39.9089 22.0075C39.9132 22.2573 39.8683 22.5056 39.7767 22.7381C39.6851 22.9706 39.5486 23.1828 39.3751 23.3625L32.6438 30L39.3751 36.6375Z" fill="white" />
					</svg>
				</div>
			</Modal>

			{/* Accurate folder names? */}
			<Modal id="accurateFolderNamesModal" open={modal['open'] == 'accurateFolderNames'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic" >
				<div className="poppins-font-style cookies-modal mah100 bg1 shadow w100 df fdc m1rem pr br10px pr freecompany-modal" style={{ outline: 'none', maxWidth: '750px', borderRadius: '20px', }}>
					<div className="df fww w100 bsbb">
						<div className="left-text w60 py3rem pt5rem bsbb pl3rem">
							<h2 className="c15 fs1-5rem mb0-5rem">Accurate folder names?</h2>
							<p className="c15 fs1rem">Please note that we do not interrogate your folder contents, but allocate to our Data Repository on the basis of each folders’s name.  Foldersnames that are insufficiently informative or which contain special characters will be placed in an “unallocated bin” for your subsequent manual allocation.</p>
						</div>
						<div className="right-img w40 mb2rem mt2rem pl1rem pr0-5rem bsbb">
							<img className="w100" src={require(`../../../../images/modal-right-img.png`)} alt="TODO" />
						</div>
					</div>
					<div className="df jcc mb4rem" for={"company_folderupload" + company['_id']} style={{ marginTop: '-48px' }}>
						<button className="w30 shadow bg4 bg3-hover c1 td250 br30px px1rem py0-5rem  fw600 cp ttc bsbb df jcc mt1rem">Next</button>
						<input onChange={handleFileChange} directory="" webkitdirectory="" mozdirectory="" className="op0 pa cp h4rem" id={"company_folderupload" + company['_id']} type="file" multiple />
					</div>
					<svg className="pa cp" onClick={() => dispatch(toggleModal({ open: '' }))} style={{ left: '50%', bottom: '-80px', transform: 'translateX(-50%)' }} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect opacity="0.8" x="4" y="4" width="52" height="52" rx="26" fill="#151313" />
						<path d="M48.5626 11.4375C44.8913 7.76665 40.214 5.26685 35.1221 4.2542C30.0302 3.24156 24.7523 3.76155 19.9559 5.74843C15.1595 7.73531 11.06 11.0998 8.17569 15.4166C5.29142 19.7333 3.75195 24.8084 3.75195 30C3.75195 35.1917 5.29142 40.2667 8.17569 44.5835C11.06 48.9002 15.1595 52.2647 19.9559 54.2516C24.7523 56.2385 30.0302 56.7585 35.1221 55.7458C40.214 54.7332 44.8913 52.2334 48.5626 48.5625C51.0004 46.1249 52.9342 43.231 54.2535 40.0461C55.5729 36.8611 56.252 33.4474 56.252 30C56.252 26.5526 55.5729 23.1389 54.2535 19.954C52.9342 16.769 51.0004 13.8751 48.5626 11.4375ZM39.3751 36.6375C39.5624 36.8129 39.7117 37.0249 39.8138 37.2603C39.9159 37.4958 39.9686 37.7496 39.9686 38.0063C39.9686 38.2629 39.9159 38.5168 39.8138 38.7522C39.7117 38.9876 39.5624 39.1996 39.3751 39.375C39.1999 39.5488 38.9921 39.6863 38.7636 39.7796C38.5352 39.8729 38.2906 39.9202 38.0438 39.9188C37.5519 39.9167 37.0805 39.7214 36.7313 39.375L30.0001 32.6438L23.3626 39.375C23.0133 39.7214 22.5419 39.9167 22.0501 39.9188C21.8033 39.9202 21.5587 39.8729 21.3302 39.7796C21.1018 39.6863 20.894 39.5488 20.7188 39.375C20.3696 39.0237 20.1736 38.5485 20.1736 38.0531C20.1736 37.5578 20.3696 37.0826 20.7188 36.7313L27.3563 30L20.7188 23.3625C20.4116 23.0038 20.2511 22.5424 20.2694 22.0705C20.2876 21.5986 20.4832 21.151 20.8171 20.8171C21.1511 20.4832 21.5987 20.2875 22.0706 20.2693C22.5425 20.2511 23.0039 20.4116 23.3626 20.7188L30.0001 27.3563L36.6376 20.7188C36.8111 20.539 37.0184 20.3952 37.2476 20.2956C37.4768 20.196 37.7233 20.1424 37.9732 20.1381C38.223 20.1337 38.4713 20.1786 38.7038 20.2702C38.9363 20.3618 39.1484 20.4983 39.3282 20.6719C39.5079 20.8455 39.6517 21.0528 39.7514 21.2819C39.851 21.5111 39.9045 21.7576 39.9089 22.0075C39.9132 22.2573 39.8683 22.5056 39.7767 22.7381C39.6851 22.9706 39.5486 23.1828 39.3751 23.3625L32.6438 30L39.3751 36.6375Z" fill="white" />
					</svg>
				</div>
			</Modal>
		</>
	);
}