import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import AnimatedPage from '../../../components/AnimatedPage';
import Button from '../../../components/common/Button';
import ProfileImageUploader from '../../../components/Form/ProfileImageUploader';
import moment from 'moment';
import ProfileForm from '../../../components/Form/ProfileForm';
import TwoFactorAuthentication from '../../../components/Form/TwoFactoreAuthentication';

const Profile = () => {

    const userData = useSelector((state) => state.user);
    const [profileClass, setProfileClass] = useState("client-profile");
    const formRef = useRef();

    // const handleSave = () => { formRef.current.handleSubmit() };

    return (
        <>
            <AnimatedPage>
                <div className="container py2rem df fdc">
                    <div className='df aic jcsb'><h1 className='c15'>Profile</h1></div>

                    {/* Profile Image Section */}
                    <ProfileImageUploader profileClass={profileClass} Button={Button} />

                    <div className='df jcsb aic lg-fdc'>
                        {/* Profile Form */}
                        <ProfileForm
                            Button={Button}
                            userData={userData}
                            ref={formRef} />

                        {/* Two Factor authentication */}
                        <TwoFactorAuthentication Button={Button} userData={userData} />

                    </div>
                </div>
            </AnimatedPage>
        </>
    )
}

export default Profile