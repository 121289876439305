import { createAction, createSlice } from "@reduxjs/toolkit";

export default function getFilesAllocationUnallocationReducer() {
    const fileAllocateAndUnAllocate = createSlice({
        name: "FILES_ACTIONS",
        initialState: {
            unallocatedFiles: [],
            deduperReports: {},
            fileManageSection: {}
        },
        reducers: {
            SET_UNALLOCATE_FILES: (state, action) => {
                state.unallocatedFiles = action.payload
            },
            SET_DEDUPER_FILES: (state, action) => {
                state.deduperReports = action.payload
            },
            SET_FILES_MANAGE_SECTION: (state, action) => {
                state.fileManageSection = action.payload
            }
        }
    });

    return fileAllocateAndUnAllocate.reducer;
}

export const setUnallocatedFiles = createAction("FILES_ACTIONS/SET_UNALLOCATE_FILES");
export const setDeduperFiles = createAction("FILES_ACTIONS/SET_DEDUPER_FILES");
export const setFileManageSection = createAction("FILES_ACTIONS/SET_FILES_MANAGE_SECTION");


