import React, { useState, useEffect, useRef, createRef } from 'react';

import AnimatedPage from '../../../components/AnimatedPage';
import Banner from '../../../components/landingPage/homePage/Banner';
import SmoothScrollingButton from '../../../components/SmoothScrollingButton';
import KeyFeatures from '../../../components/landingPage/homePage/KeyFeatures';
import ContentCard from '../../../components/landingPage/homePage/contentCard';
import VideoSection from '../../../components/landingPage/homePage/VideoSection';
import ReviewSection from '../../../components/landingPage/homePage/ReviewSection';
import PricingSection from '../../../components/landingPage/homePage/PricingSection';
import AboutUsCrousel from '../../../components/landingPage/homePage/AboutUsCrousel';
import NotSureSection from '../../../components/landingPage/homePage/NotSureSection';
import RegisterSection from '../../../components/landingPage/homePage/RegisterSection';
import HowItsWorkSection from '../../../components/landingPage/homePage/HowItsWorkSection';
import KeyFeaturesModules from '../../../components/landingPage/homePage/KeyFeaturesModules';
import ContentCompaniesCard from '../../../components/landingPage/homePage/contentCompaniesCard';
import RegisterTwoMinuteTest from '../../../components/landingPage/homePage/RegisterTwoMinuteTest';
import { FREE_PLAN, LITE_PLAN, FOOTER_PRO_PLAN, PRO_PLUS_PLAN } from '../../../constants/pricingPlane';

import Dropbox from "../../../components/svgs/Dropbox";
import SharePoint from "../../../components/svgs/SharePoint";
import GoogleDrive from "../../../components/svgs/GoogleDrive";
// import NewKeyFeatureModule from '../../../components/landingPage/homePage/NewKeyFeatureModule';
import UpdatedKeyFeatureModule from '../../../components/landingPage/homePage/updatedKeyFeatureModule';
import { UPDATED_COMPANY_KEY_FEATURE_MODULES } from '../../../staticData';

//Ref Array for fade  and scale animation 
//Note: used this into all pages
export const COMPONENT_REF = ['banner-title', 'life-cycle-title', 'clinet-review-title', 'how-its-work-title', 'work-block-scaling', 'key-features', 'video-section-title', "register-form-title", "not-sure", 'pricing-title', 'pricing-section']


// Main Banner Section data 
const MAIN_BANNER_DATA = {
    //Verticle text slider content
    SLIDER_TEXT_CONTANT: [
        { str: "Grow faster?", className: 'item-a' },
        { str: "Raise investment more quickly?", className: 'item-b' },
        { str: "Exit for a higher valuation?", className: 'item-c' }
    ],
    bannerTitle: <div className={`fw500 fs3rem c15 tslp mt2rem`} style={{ fontSize: '70px', lineHeight: '90px' }}>
        Grow <span className='c4'>value.</span> <br /> Simply.
    </div>,
    bannersubTitle: 'Companies',
    bannerDescription: <span className="poppins-font-style xlw-80">We help you get  better organised. Quickly. For investment. For growth. For exit. We have a database of 700 UK investors - angels, networks, Vcs, PEs. <br></br><br></br>
        If you could increase the value of your business by 10%, how much would this be worth to you?
    </span>,
    //Banner Image
    bannerImage: require('../../../../images/landingpage/companyMainBanner.webp'),
    videoButton: require('../../../../images/videoButton.png'),
    videoLink: "https://www.loom.com/embed/677ae4be4ca444119428dd420a89385e?sid=fe630645-d275-49ae-86f1-80554ca1bc39",
    posterImage: "https://repository-diligentsia.s3.ap-northeast-1.amazonaws.com/development/thumbpaul-1731073132725.webp",
    //Contact right information
    contactInfo: {
        user: 'Meet Paul,',
        designation: 'a company CEO'
    },
    //Active menu
    activeMenu: "menu-bar-0",
    //descriptions info
    description: {
        headingOne: <><span className="c4">Automated low-cost diligence platform</span> assisted by AI that allows companies,
            their advisors and investors to more easily pick their winners through their
            investment, growth and exit lifecycle.</>,
        headingTwo: `Due diligence should be a reusable asset not an overhead.
            Diligentsia delivers a standardised methodology for fast
            qualification, reduced transaction times, less cost and less
            risk for everyone.`,
        isTestBtn: true,
    },
    // gurantee: {
    //     title: 'Could Diligentsia’s platform deliver to your company:',
    //     point1: 'Up to 10% + increase in valuation?'
    // },
    gurantee: {
        point1: 'Raising money and want to connect with investors?',
        point2: 'Want to turbocharge your growth?',
        point3: 'Want to become exit ready?',
    },

    // cubeStyle: { top: '110px', left: "-23px" }
    cubeStyle: { top: '86px', left: "-68px", width: '50%' },
    // animationImage: require("../../../../images/landingpage/90-startup.png"),
};

// Review Section data
// const REVIEW_CONTENT_DATA = {
//     title: <>Hear from a happy
//         <span className="c4">
//             {` client`}
//         </span>
//     </>,
//     description: {
//         desc1: ` By self-completing the Diligentsia modules, we have not only saved tens of thousands of pounds of retainers in
//         initial preparation, but later on, saved substantial legal fees through having already created draft warranty
//         disclosures and directors’ questionnaires – substantially shortening the transaction timetable. Delivering the data
//         room along with a comprehensive diligence pack on a  plate at the outset Impressed our investor. Diligentsia’s module
//         costs were immaterial in the transaction and dwarfed by the savings.`,
//         desc2: 'Being matched with investors and obtaining qualified leads, along with introductions to named individuals was a bonus.',
//         clientName: 'Ted Bechman ACA,',
//         designation: 'Financial Advisor'
//     },
//     clientImage: require("../../../../images/landingpage/clinet-image.png"),
//     imageStyle: { width: "100%" }
// };

// How its work data
const HOW_ITS_WORK_DATA = {
    IMAGES_ARRAY: [
        {
            title1: 'Register',
            icon: <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <path d="M8.96204 74.0005H47.1957C40.4123 70.3005 35.7872 63.2088 35.7872 54.8837C35.7872 42.8586 45.654 32.9919 57.6791 32.9919H57.9874V8.01673C57.9874 3.70003 54.2874 0 49.9707 0H8.96204C4.64534 0 0.945312 3.70003 0.945312 8.01673V65.9838C0.945312 70.3005 4.337 74.0005 8.96204 74.0005ZM16.0538 12.3334H42.5706C44.4206 12.3334 45.654 13.8751 45.654 15.4168C45.654 17.2668 44.4206 18.5001 42.5706 18.5001H16.0538C14.2037 18.5001 12.9704 17.2668 12.9704 15.4168C12.9704 13.8751 14.5121 12.3334 16.0538 12.3334ZM16.0538 26.8252H33.3206C35.1706 26.8252 36.4039 28.3669 36.4039 29.9086C36.4039 31.7586 35.1706 32.9919 33.3206 32.9919H16.0538C14.2037 32.9919 12.9704 31.7586 12.9704 29.9086C12.9704 28.0585 14.5121 26.8252 16.0538 26.8252Z" fill="#4A67FF" />
                <path d="M57.6821 39.4668C49.0488 39.4668 41.957 46.5585 41.957 55.1919C41.957 63.8253 49.0488 70.917 57.6821 70.917C66.3155 70.917 73.4073 63.8253 73.4073 55.1919C73.4073 46.5585 66.3155 39.4668 57.6821 39.4668ZM65.3905 53.6502L57.6821 61.0503C57.0655 61.667 56.4488 61.9753 55.5238 61.9753C54.5988 61.9753 53.9821 61.667 53.3654 61.0503L49.6654 57.0419C48.4321 55.8086 48.4321 53.9586 49.9738 52.7252C51.2071 51.4919 53.0571 51.4919 54.2905 52.7252L55.8321 54.5752L61.3822 49.3335C62.6155 48.1002 64.4655 48.1002 65.6989 49.3335C66.6239 50.2585 66.6239 52.4169 65.3905 53.6502Z" fill="#4A67FF" />
            </svg>,
            desc: <>Have a no-commitment look around and use our <b>FREE</b> modules (No credit card required).</>
        },
        {
            title1: 'Complete',
            icon: <svg style={{ width: '90px', height: '90px' }} width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M41.5093 34.919C43.0004 42.3341 58.6499 50.891 60.9739 51.6701C63.3113 50.8776 78.9205 42.3476 80.452 34.919C80.6937 35.6578 80.6132 11.0886 80.5997 10.7662C80.6003 10.5897 80.566 10.4147 80.4987 10.2514C80.4314 10.0882 80.3325 9.93986 80.2076 9.815C80.0828 9.69014 79.9344 9.59121 79.7712 9.52392C79.6079 9.45663 79.433 9.4223 79.2564 9.42291C72.8353 9.28862 66.656 8.81841 61.659 6.18556C61.45 6.0603 61.2109 5.99414 60.9672 5.99414C60.7235 5.99414 60.4844 6.0603 60.2754 6.18556C55.2916 8.80496 49.0989 9.28862 42.678 9.42291C42.5014 9.42229 42.3265 9.45662 42.1632 9.5239C42 9.59119 41.8516 9.69012 41.7268 9.81498C41.6019 9.93984 41.503 10.0882 41.4357 10.2514C41.3684 10.4147 41.3341 10.5897 41.3347 10.7662C41.4421 11.1289 41.1063 35.5906 41.5093 34.919ZM51.6513 23.877C51.7633 23.5359 51.9735 23.2354 52.2554 23.0132C52.5374 22.791 52.8786 22.6569 53.2364 22.6277L57.5619 22.2246L59.2813 18.2215C59.4279 17.8972 59.6649 17.6221 59.9639 17.4291C60.263 17.2361 60.6113 17.1334 60.9672 17.1334C61.3231 17.1334 61.6714 17.2361 61.9705 17.4291C62.2695 17.6221 62.5065 17.8972 62.6531 18.2215L64.3725 22.2246L68.698 22.6277C69.056 22.657 69.3974 22.7913 69.6795 23.0137C69.9615 23.2362 70.1716 23.537 70.2835 23.8783C70.3954 24.2197 70.404 24.5865 70.3084 24.9327C70.2127 25.279 70.017 25.5893 69.7457 25.8248L66.468 28.6994L67.4352 32.9309C67.5149 33.281 67.4902 33.6468 67.3642 33.983C67.2382 34.3192 67.0164 34.6112 66.7263 34.8227C66.4361 35.0342 66.0904 35.1561 65.7317 35.1732C65.373 35.1903 65.0172 35.1019 64.7083 34.919L60.9739 32.6891C60.3291 32.9846 56.9977 35.3219 56.2858 35.1742C56.0113 35.1709 55.7409 35.1066 55.4944 34.9859C55.2478 34.8652 55.0311 34.6912 54.8601 34.4765C54.6891 34.2618 54.568 34.0117 54.5055 33.7443C54.4431 33.477 54.4409 33.1992 54.4991 32.9309L55.4663 28.6994L52.1886 25.8248C51.9183 25.5883 51.7233 25.2777 51.6278 24.9315C51.5323 24.5852 51.5405 24.2186 51.6513 23.877Z" fill="#4A67FF" />
                <path d="M12.4772 57.0585C12.3995 56.8989 12.291 56.7562 12.1581 56.6385C12.0252 56.5208 11.8703 56.4304 11.7025 56.3726C11.5346 56.3147 11.357 56.2905 11.1798 56.3013C11.0025 56.3122 10.8292 56.3578 10.6696 56.4357L6.1339 58.6484C5.81179 58.8055 5.56529 59.0842 5.44862 59.4231C5.33195 59.7619 5.35465 60.1333 5.51174 60.4554L14.6761 79.2463C14.754 79.4059 14.8625 79.5486 14.9955 79.6662C15.1285 79.7839 15.2834 79.8741 15.4514 79.9319C15.6193 79.9896 15.797 80.0137 15.9742 80.0027C16.1514 79.9918 16.3248 79.946 16.4843 79.868L21.0081 77.6565C21.3298 77.4993 21.576 77.2208 21.6925 76.8822C21.809 76.5436 21.7864 76.1726 21.6296 75.8506L12.4772 57.0585Z" fill="#4A67FF" />
                <path d="M74.7305 54.4748L59.5444 61.7083C58.8264 63.1532 57.6105 64.29 56.1206 64.9093C54.6307 65.5286 52.9673 65.5886 51.4366 65.0783L33.9099 59.0574C33.5635 58.9305 33.2807 58.6728 33.1222 58.3397C32.9637 58.0066 32.9421 57.6246 33.0621 57.2758C33.182 56.9269 33.434 56.639 33.7638 56.4739C34.0937 56.3087 34.4751 56.2795 34.8263 56.3924L52.3249 62.4133C53.2919 62.7328 54.3459 62.6581 55.2582 62.2054C56.1705 61.7527 56.8674 60.9585 57.1978 59.9951C57.5282 59.0318 57.4654 57.977 57.023 57.0597C56.5806 56.1423 55.7944 55.4365 54.8348 55.0952L36.321 48.6936C34.6897 48.1433 32.9077 48.2547 31.3577 49.0038L15.4805 56.8014L23.5036 73.2566L28.89 70.5775C30.4866 69.8151 32.3172 69.7039 33.9944 70.2673L46.5014 74.5679C48.1349 75.1281 49.9233 75.0218 51.4789 74.2718L78.4108 61.2853C78.9152 61.0394 79.3596 60.686 79.7128 60.25C80.066 59.814 80.3195 59.3058 80.4553 58.7614C80.6267 58.0229 80.5796 57.2504 80.3196 56.5383C80.0597 55.8261 79.5982 55.2049 78.9913 54.7505C78.3844 54.2961 77.6585 54.0281 76.9019 53.9791C76.1454 53.9302 75.3909 54.1024 74.7305 54.4748Z" fill="#4A67FF" />
            </svg>,
            desc: 'Upgrade to Diligentsia Lite or Diligentsia Pro to complete additional due diligence modules.'
        },
        {
            title1: 'Enhance',
            icon: <svg width="50" height="58" viewBox="0 0 50 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M34.6154 9.66667V11.6H30.7692V9.66667C30.7692 6.47686 28.1738 3.86667 25 3.86667C21.8262 3.86667 19.2308 6.47686 19.2308 9.66667V11.6H15.3846V9.66667C15.3846 4.33112 19.6928 0 25 0C30.3072 0 34.6154 4.33112 34.6154 9.66667ZM50 13.5333V50.2667C50 54.5393 46.5576 58 42.3077 58H7.69231C3.44238 58 0 54.5393 0 50.2667V13.5333C0 12.4704 0.86576 11.6 1.92308 11.6H15.3846V15.4667C15.3846 16.5296 16.2504 17.4 17.3077 17.4C18.365 17.4 19.2308 16.5296 19.2308 15.4667V11.6H30.7692V15.4667C30.7692 16.5296 31.635 17.4 32.6923 17.4C33.7496 17.4 34.6154 16.5296 34.6154 15.4667V11.6H48.0769C49.1342 11.6 50 12.4704 50 13.5333Z" fill="#5C76FF" />
                <g clip-path="url(#clip0_1906_801)">
                    <path d="M40.1284 31.4031C38.964 30.2429 37.0819 30.2429 35.9175 31.4031L27.0444 40.2761L24.0882 37.3199C22.9237 36.1597 21.0417 36.1597 19.8772 37.3199C18.7128 38.4843 18.7128 40.3664 19.8772 41.5308L24.939 46.5968C26.1034 47.7613 27.9854 47.7613 29.1499 46.5968L40.1327 35.6183C41.2929 34.4539 41.2929 32.5675 40.1284 31.4031Z" fill="white" />
                </g>
                <g clip-path="url(#clip1_1906_801)">
                    <path d="M9.87158 40.5969C11.036 41.7571 12.9181 41.7571 14.0825 40.5969L22.9556 31.7239L25.9118 34.6801C27.0763 35.8403 28.9583 35.8403 30.1228 34.6801C31.2872 33.5157 31.2872 31.6336 30.1228 30.4692L25.061 25.4032C23.8966 24.2387 22.0146 24.2387 20.8501 25.4032L9.86729 36.3817C8.70713 37.5461 8.70713 39.4325 9.87158 40.5969Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_1906_801">
                        <rect width="22" height="22" fill="white" transform="translate(19 28)" />
                    </clipPath>
                    <clipPath id="clip1_1906_801">
                        <rect width="22" height="22" fill="white" transform="matrix(-1 0 0 -1 31 44)" />
                    </clipPath>
                </defs>
            </svg>,
            desc: <>Pro? Enhance value on your investment, growth or exit journey with bespoked additional modules from <b>Diligentsia</b> <i><span className='c4'>AppStore.</span></i></>
        },
        {
            title1: 'Help?',
            icon: <svg style={{ width: '70px', height: '70px' }} width="96" height="96" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M51.1876 50.0494H14.8143C12.9342 50.0494 11.4043 51.5794 11.4043 53.4594C11.4043 55.3395 12.9342 56.8694 14.8143 56.8694H51.1876C53.0677 56.8694 54.5976 55.3395 54.5976 53.4594C54.5976 51.5794 53.0677 50.0494 51.1876 50.0494Z" fill="#F9E109" />
                <path d="M56.8707 15.9498C54.3632 15.9498 52.324 17.989 52.324 20.4964C52.324 22.181 53.2561 23.6381 54.6223 24.4247C51.9921 30.6536 47.8887 34.4751 44.0535 34.1295C39.7888 33.7818 36.3105 28.7395 34.3918 20.3032C36.8516 19.6802 38.6839 17.4637 38.6839 14.8131C38.6839 11.6782 36.1355 9.12976 33.0005 9.12976C29.8656 9.12976 27.3172 11.6782 27.3172 14.8131C27.3172 17.4638 29.1495 19.6803 31.6092 20.3032C29.6906 28.7395 26.2123 33.7818 21.9476 34.1295C18.1284 34.4751 14.0068 30.6536 11.3789 24.4247C12.7451 23.6381 13.6772 22.1809 13.6772 20.4964C13.6772 17.989 11.638 15.9498 9.13054 15.9498C6.62321 15.9498 4.58398 17.989 4.58398 20.4964C4.58398 22.8289 6.35714 24.734 8.62137 24.993L12.9999 47.7764H53.0014L57.3798 24.993C59.6442 24.734 61.4173 22.8289 61.4173 20.4964C61.4173 17.989 59.3781 15.9498 56.8707 15.9498Z" fill="#FCBC04" />
            </svg>,
            desc: 'Not inclined? Too busy? Your lawyers/accountants can help cost-effectively via Diligentsia Pro PLUS.'
        },
        {
            title1: 'Share',
            image: <img src={require('../../../../images/landingpage/work-certificate.png')} />,
            desc: 'Get Diligentsia certification, create your due diligence pack, share with advisors, investors and prospective purchasers.'
        },
        {
            title1: 'Funding?',
            image: <img src={require('../../../../images/landingpage/work-certificate-1.png')} />,
            desc: 'Gold certified? Match your requirements against our 700-UK investor database for curated introductions with “Fit” score.'
        }
    ],
    title: 'How it works?',
    description: 'Rapidly increase the value of your business!'
};

const LandingHomeCompanies = () => {
    const [showAll, setShowAll] = useState(false);
    const [isPlay, setIsPlay] = useState(false);
    const [hasHover, setHasHover] = useState(false)
    const refs = useRef(COMPONENT_REF.map(() => createRef()));
    const [isAnimationVisible, setIsAnimationVisible] = useState(false)

    ///Observer so appllying fadeup animation
    useEffect(() => {
        const observers = refs.current.map((ref, index) =>
            new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting) {
                        setIsAnimationVisible(prev => ({ ...prev, [index]: true }));
                    }
                },
                { threshold: 0.5 }
            )
        );

        refs.current.forEach((ref, index) => {
            if (ref.current) {
                observers[index].observe(ref.current);
            }
        });

        return () => {
            refs.current.forEach((ref, index) => {
                if (ref.current) {
                    observers[index].unobserve(ref.current);
                }
            });
        };
    }, []);

    const toggleShowAll = () => setShowAll((prev) => !prev);

    // Video for diligentisa Help 
    function playVideo(e) {
        let video = document.getElementById("video");
        if (video.paused == true) {
            setIsPlay(true);
            video.play();
        }
        e.target.controls = true;
        if (e?.target?.nextElementSibling) e.target.nextElementSibling.remove();
    };

    return (
        <div className="poppins-font-style comapnies-page-font *">
            <AnimatedPage>
                <SmoothScrollingButton />
                {/* Main Banner */}
                {/* <MainBanner data={MAIN_BANNER_DATA} isAnimationVisible={isAnimationVisible} refs={refs}
                    calImage={require('../../../../images/landingpage/calculation10-1.gif')}
                    hasTo={true} /> */}
                <Banner
                    data={MAIN_BANNER_DATA}
                    isAnimationVisible={isAnimationVisible}
                    refs={refs}
                    // calImage={require('../../../../images/landingpage/calculation10-1.gif')}
                    hasTo={true}
                />

                {/* Company Lifecycle  Block*/}
                {/* <AboutUs isAnimationVisible={isAnimationVisible} refs={refs} /> */}
                {/* <AboutUsCrousel crouselClass="companies-crousel" isAnimationVisible={isAnimationVisible} refs={refs} data={ABOUT_US_CONTENT} /> */}

                {/* Clinet review section */}
                {/* <ReviewSection data={REVIEW_CONTENT_DATA} refs={refs} isAnimationVisible={isAnimationVisible} /> */}

                {/* Features section */}
                {/* <KeyFeatures
                    subTitle='Reusable diligence - building your business stronger'
                    data={KEY_FEATURE}
                    refs={refs}
                    isAnimationVisible={isAnimationVisible}
                    setHasHover={setHasHover}
                    showAll={showAll}
                    hasHover={hasHover}
                    toggleShowAll={toggleShowAll}
                    len={10}
                /> */}
                {/* <NewKeyFeatureModule
                    subTitle='Building your business stronger'
                    data={KEY_FEATURE_MODULES}
                    refs={refs}
                    isAnimationVisible={isAnimationVisible}
                    setHasHover={setHasHover}
                    hasHover={hasHover}
                /> */}

                <UpdatedKeyFeatureModule
                    key={`${new Date().getTime()}`}
                    data={UPDATED_COMPANY_KEY_FEATURE_MODULES}
                    subTitle='We help you grow value throughout your company’s lifecycle'
                    refs={refs}
                    isAnimationVisible={isAnimationVisible}
                    setHasHover={setHasHover}
                    hasHover={hasHover}
                />

                {/* Hows its work section*/}
                <HowItsWorkSection data={HOW_ITS_WORK_DATA} refs={refs} isAnimationVisible={isAnimationVisible} />

                {/* Regsiter form section */}
                {/* <RegisterSection registerationType='User' refs={refs} isAnimationVisible={isAnimationVisible} /> */}
                <RegisterTwoMinuteTest registerationType='User' refs={refs} isAnimationVisible={isAnimationVisible} />

                {/* Not Sure Yet? section */}
                {/* <NotSureSection refs={refs} isAnimationVisible={isAnimationVisible} /> */}

                {/* Pricing Section */}
                {/* <PricingSection pricingTitle="Companies" data={PRICING_CONSTANT} refs={refs} isAnimationVisible={isAnimationVisible} /> */}

                {/* Video Section */}
                {/* <VideoSection data={VIDEO_SECTION} refs={refs} isAnimationVisible={isAnimationVisible} playVideo={playVideo} isPlay={isPlay} /> */}

            </AnimatedPage>

        </div>
    )
}

export default LandingHomeCompanies;








