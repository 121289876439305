import moment from "moment";
import html2pdf from "html2pdf.js";
import ReactDOMServer from "react-dom/server";
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import 'react-circular-progressbar/dist/styles.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

export default function AllReportSidebar({ company, modules, tab, setTab, showReport, handleContent }) {

	const getSidebarHTML = (module, showInTooltip = false) => {
		let array = [];
		if (module !== '') {
			if (module && module.sections && module?.['expansionPack']) array = Object.values(module['sections'][0]['subFolders']);
			else array = module?.sections;
		}

		const style = showInTooltip ? {} : { width: "calc((100% - 4rem) / 5)" };
		const jsx = module && module.sections && (
			<div id="sidebar" style={style} className="h100vh w100 bsbb dg gg1rem br5px bg5 ps t0 sidebar-report all-sidebar-report">
				{!showInTooltip &&
					<div className="df jcc aic fdc p0-5rem">
						<h3 className="c3 fs1rem ttu w100 tac">Diligence Pack</h3>
						<button onClick={() => setTab("")} className={`shadow mt1rem mxa bg3 bg4-hover c1 td250 br5px px1rem fs0-75rem py0-5rem fw600 cp ttu bsbb tac close-reports`}>
							Close Report
						</button>
						<hr style={{ border: "none", height: "1px", display: "block", width: "100%", marginTop: "18px", backgroundColor: "#002d63" }} />
						{company != null && <>
							<button onClick={() => downloadPDF()} className="h100 mt1rem mb0-5rem mxa shadow bg3 bg4-hover c1 td250 br5px px1rem fs0-75rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
								<svg className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path className="fa-primary" d="M424.1 320.1l-88 88C334.4 411.5 328.5 416 320 416s-14.4-4.467-16.97-7.031l-88-88c-9.375-9.375-9.375-24.56 0-33.94s24.56-9.375 33.94 0L296 334.1V184C296 170.8 306.8 160 320 160s24 10.75 24 24v150.1l47.03-47.03c9.375-9.375 24.56-9.375 33.94 0S434.3 311.6 424.1 320.1z" /><path className="fa-secondary" d="M537.6 226.6C541.7 215.9 544 204.2 544 192c0-53-43-96-96-96c-19.69 0-38.09 6-53.31 16.2C367 64.2 315.3 32 256 32C167.6 32 96 103.6 96 192c0 2.703 .1094 5.445 .2031 8.133C40.2 219.8 0 273.2 0 336C0 415.5 64.5 480 144 480H512c70.69 0 128-57.3 128-128C640 290.1 596 238.4 537.6 226.6zM424.1 320.1l-88 88C334.4 411.5 328.5 416 320 416s-14.4-4.467-16.97-7.031l-88-88c-9.375-9.375-9.375-24.56 0-33.94s24.56-9.375 33.94 0L296 334.1V184C296 170.8 306.8 160 320 160s24 10.75 24 24v150.1l47.03-47.03c9.375-9.375 24.56-9.375 33.94 0S434.3 311.6 424.1 320.1z" /></svg>
								Download PDF
							</button>
						</>}
					</div>
				}
				<hr style={{ border: "none", height: "1px", display: "block", width: "100%", backgroundColor: "#002d63" }} />
				<div className="mah100 mya oya df fdc siedbar-dropdwon">
					{Object.values(modules).map((value, idx) => {
						if (showReport?.includes('directors-questionnaire') && value?.type == showReport) {
							return <Accordion expanded={value['type'] == showReport}>
								<AccordionSummary onClick={() => handleContent(value['type'])} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
									<Typography>{company?.['modules']?.[value['type']]?.heading}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<div className="mah100 mya oya df fdc jcsb" >
										{array.length > 0 && array.map(section => {
											// TODO: Calculate Stats for Expansion Packs
											if (!section.stats) section.stats = module['sections'][0]['stats'];
											const completeness = section.stats.completionPercent;

											let riskColour;
											if (module.type === "repository" || section.stats.riskPercent < 38) riskColour = 7;
											else if (section.stats.riskPercent > 62) riskColour = 9;
											else riskColour = 8;

											let hexColour = riskColour === 8 ? "#ddb64044" : riskColour === 9 ? "#dd404044" : "#40dda144";
											let tooltip = ReactDOMServer.renderToStaticMarkup(<p className="tac maw200px">{`This section is ${completeness}% complete` + (module['type'] !== "repository" ? ` with ${section.stats.completionPercent === 0 ? "no risk score" : `a risk score of ${section.stats.riskPercent}%`}.` : "")}</p>);
											if (section['disabled']) tooltip = ReactDOMServer.renderToStaticMarkup(<p className="tac maw200px">{`Please request admin to access ${section['sectionNo']}`}</p>);

											let colorChange = false;
											if (module['type'] == "warranty-disclosure") {
												section?.questions.map(question => {
													if (question['answer']['radioOption'] == 'Disclosure' || question['answer']['radioOption'] == 'Not Sure') {
														colorChange = true;
														return;
													}
												})
											}

											return <>
												<button data-tooltip={tooltip} style={{ background: `linear-gradient(90deg, ${colorChange ? "#f9922a" : hexColour} 0%, ${colorChange ? "#f9922a" : hexColour} ${completeness}%, #f0f0f8 ${completeness}%)` }} data-scroll-to={section['sectionNo']} className={`c2 c3-hover bg5 w100 df td250 px1rem pl0-5rem py0-5rem cp pr`}>
													<span className={(showInTooltip ? 'fs0-5rem ' : 'fs0-75rem ') + "fw500 asc pen uss ass tal"}>{section['sectionName']} </span>
												</button>
											</>
										})}
									</div>
								</AccordionDetails>
							</Accordion>
						}
						if (value?.['heading'] && !value['type'].includes('directors-questionnaire'))
							return <Accordion expanded={value['type'] == showReport}>
								<AccordionSummary onClick={() => handleContent(value['type'])} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
									<Typography>{company?.['modules']?.[value['type']]?.heading}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<div className="mah100 mya oya df fdc jcsb">
										{array.length > 0 && array.map(section => {
											// TODO: Calculate Stats for Expansion Packs
											if (!section.stats) section.stats = module['sections'][0]['stats'];
											const completeness = section.stats.completionPercent;

											let riskColour;
											if (module.type === "repository" || section.stats.riskPercent < 38) riskColour = 7;
											else if (section.stats.riskPercent > 62) riskColour = 9;
											else riskColour = 8;

											let hexColour = riskColour === 8 ? "#ddb64044" : riskColour === 9 ? "#dd404044" : "#40dda144";
											let tooltip = ReactDOMServer.renderToStaticMarkup(<p className="tac maw200px">{`This section is ${completeness}% complete` + (module['type'] !== "repository" ? ` with ${section.stats.completionPercent === 0 ? "no risk score" : `a risk score of ${section.stats.riskPercent}%`}.` : "")}</p>);
											if (section['disabled']) tooltip = ReactDOMServer.renderToStaticMarkup(<p className="tac maw200px">{`Please request admin to access ${section['sectionNo']}`}</p>);

											let colorChange = false;
											if (module['type'] == "warranty-disclosure") {
												section?.questions.map(question => {
													if (question['answer']['radioOption'] == 'Disclosure' || question['answer']['radioOption'] == 'Not Sure') {
														colorChange = true;
														return;
													}
												})
											}

											return <>
												<button data-tooltip={tooltip} style={{ background: `linear-gradient(90deg, ${colorChange ? "#f9922a" : hexColour} 0%, ${colorChange ? "#f9922a" : hexColour} ${completeness}%, #f0f0f8 ${completeness}%)` }} data-scroll-to={section['sectionNo']} className={`c2 c3-hover bg5 w100 df td250 px1rem pl0-5rem py0-5rem cp pr`}>
													<span className={(showInTooltip ? 'fs0-5rem ' : 'fs0-75rem ') + "fw500 asc pen uss ass tal"}>{section['sectionName']} </span>
												</button>
											</>
										})}
									</div>
								</AccordionDetails>
							</Accordion>
					})}
				</div>
				{/* {!showInTooltip && (
					<div className="mta cp df jcc py1rem px0-5rem aic bss bw0px btw2px bo6">
						<img className="br100 mr0-5rem w3rem h3rem" src="https://this-person-does-not-exist.com/img/avatar-gen11548c471947e6a4777a2f945b3e6d79.jpg" />
						<span className="fw500 fs1-2rem">Contact Lawyer</span>
					</div>
				)} */}
			</div >
		)
		return showInTooltip ? ReactDOMServer.renderToStaticMarkup(jsx) : jsx;
	};

	const downloadPDF = async () => {
		try {
			const wrapper = document.getElementById("pdf");
			wrapper.classList.remove("gg2rem");
			for (const page of wrapper.children)
				page.classList.remove("shadow");
			for (const hideEl of wrapper.querySelectorAll(".hideOnDownload"))
				hideEl.classList.add("op0");
			const filename = [company.cName, company.subscription, tab].join(" - ");
			const opt = { filename, jsPDF: { unit: 'in', format: 'a4', orientation: 'p' } };
			await html2pdf().set(opt).from(wrapper).save();
			wrapper.classList.add("gg2rem");
			for (const page of wrapper.children)
				page.classList.add("shadow");
			for (const hideEl of wrapper.querySelectorAll(".hideOnDownload"))
				hideEl.classList.remove("op0");
		} catch (e) { console.error(e); }
	};

	const newReport = showReport ? showReport : `two-minute-test-${company['testType']}`

	return (
		<>
			{getSidebarHTML(modules[newReport])}
		</>
	);
}