import { createSlice, createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "../config";

let moduleContent = {
	'kpi': {
		heading: 'KPI',
		aiEnhanced: true,
		uploadFiles: false,
		subheading: 'Key Performance Indicators'
	}, 'repository': {
		aiEnhanced: false,
		uploadFiles: true,
		heading: 'Repository',
		subheading: 'Securely store all your important documents here'
	}, 'business-audit-investment': {
		aiEnhanced: true,
		uploadFiles: false,
		heading: 'Business Audit',
		subheading: 'Get expert recommendations on how to reduce risk'
	}, 'business-audit-growth': {
		aiEnhanced: true,
		uploadFiles: false,
		heading: 'Business Audit',
		subheading: 'Get expert recommendations on how to reduce risk'
	}, 'business-audit-exit': {
		aiEnhanced: true,
		uploadFiles: false,
		heading: 'Business Audit',
		subheading: 'Get expert recommendations on how to reduce risk'
	}, 'directors-questionnaire': {
		aiEnhanced: false,
		uploadFiles: true,
		heading: 'Directors Questionnaire',
		subheading: ''
	}, 'two-minute-test-exit': {
		aiEnhanced: true,
		uploadFiles: false,
		heading: 'Exit Ready Test',
		subheading: 'See How Exit Ready You Are'
	}, 'two-minute-test-growth': {
		aiEnhanced: true,
		uploadFiles: false,
		heading: 'Growth Ready Test',
		subheading: 'See How Growth Ready You Are'
	}, 'two-minute-test-investment': {
		aiEnhanced: true,
		uploadFiles: false,
		heading: 'Investment Ready Test',
		subheading: 'How Investment Ready Are You'
	}, 'esg': {
		aiEnhanced: false,
		uploadFiles: true,
		heading: 'Environmental, Social & Governmental',
		subheading: 'Get expert recommendations on how to reduce risk'
	}, 'software-development': {
		aiEnhanced: false,
		uploadFiles: true,
		heading: 'Software Development',
		subheading: 'Get expert recommendations on how to reduce risk'
	}, 'warranty-disclosure': {
		aiEnhanced: false,
		uploadFiles: true,
		heading: 'Warranty Disclosure Schedule',
		subheading: 'Get expert recommendations on how to reduce risk'
	}, 'unaudited-certification': {
		sections: [],
		heading: 'Certification',
		subheading: 'This is your current certification',
	}, 'investor-lead': {
		uploadFiles: true,
		heading: 'Investor Lead Introduction',
		subheading: 'Securely store all your important documents here'
	}
}

export const fetchCompanyData = createAsyncThunk(
	"company/fetchCompanyData",
	async (data, { rejectWithValue }) => {
		try {
			const { moduleName, companyId, selectedCompany } = data;
			let baseUrl = `/company/get/${companyId}`
			if (moduleName) {
				baseUrl += `?moduleName=${moduleName}`
			}
			// Replace with your API endpoint
			const response = await getRequest(baseUrl);


			if (response['code'] == 200) {
				response['body']['modules']['kpi'] = { type: "kpi", sections: [] };
				for (let module of Object.values(response['body']['modules'])) {
					let content = module['type'].includes('directors-questionnaire') ? moduleContent['directors-questionnaire'] : moduleContent[module['type']]
					if (content) {
						module['heading'] = module['director'] ? `${content['heading']} - ${module['director']['name']} (${module['director']['designation']})` : content['heading'];
						module['aiEnhanced'] = content['aiEnhanced'];
						module['subheading'] = content['subHeading'];
						module['uploadFiles'] = content['uploadFiles'];
					}
				}
				response['body']['modules']['unaudited-certification'] = moduleContent['unaudited-certification'];
				if (!moduleName && selectedCompany?.['modules']) {
					response['body']['modules'] = selectedCompany['modules']
				}
				return response['body']
			};


			return response.body; // Returning the API response
		} catch (error) {
			console.log(error, "error")
			return rejectWithValue(error.response.data || "Something went wrong");
		}
	}
);

export default function getCompanyReducer(company = {}) {
	const companySlice = createSlice({
		name: "company",
		initialState: {
			company,
			selectedCompany: {}
		},
		reducers: {
			update: (state, action) => {
				return { ...state, ...action.payload };
			},
			selectedCompany: (state, action) => {
				return {
					...state,
					selectedCompany: action.payload
				};
			},
			setFirstVisit: (state, action) => {
				return {
					...state,
					selectedCompany: {
						...state.selectedCompany,
						firstVisitRepository: action.payload
					}
				};
			},
		},
		extraReducers: (builder) => {
			builder
				.addCase(fetchCompanyData.pending, (state) => {
					state.loading = true;
					state.error = null;
				})
				.addCase(fetchCompanyData.fulfilled, (state, action) => {
					state.loading = false;
					state.selectedCompany = action.payload;
				})
				.addCase(fetchCompanyData.rejected, (state, action) => {
					state.loading = false;
					state.error = action.payload;
				});
		},
	});

	return companySlice.reducer;
}

export const updateCompany = createAction("company/update");
export const setCompany = createAction("company/selectedCompany");
export const setFirstVisit = createAction("company/setFirstVisit");