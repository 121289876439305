import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const SyncAnimate = ({ text }) => {
    const { syncing } = useSelector((state) => state.appData);
    const [dots, setDots] = useState(1);

    useEffect(() => {
        if (!syncing) {
            setDots(0); // Reset to 1 dot when syncing stops
            return;
        }

        const interval = setInterval(() => {
            setDots(prevDots => (prevDots === 3 ? 1 : prevDots + 1));
        }, 500);

        return () => clearInterval(interval); // Clear interval when component unmounts or syncing changes
    }, [syncing]);

    return <div className="df aife c15 fs13px">{text} <p style={{ minWidth: '12px' }}>{syncing ? '.'.repeat(dots) : ''}</p></div>;
};

export default SyncAnimate;