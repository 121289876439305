import { useState, useEffect } from "react";

import AnimatedPage from "../../../components/AnimatedPage";
import HeadingSubheadingContent from "../../../components/HeadingSubheadingContent";
import { getRequest, postRequest, showMessage } from '../../../config';
import moment from "moment";

export default function AiDeduperHistoryComponent(props) {

	const { onBack } = props;
	const [deduperTestLogs, setDeduperTestLogs] = useState([]);
	const [selectedLog, setSelectedLog] = useState();

	const fetch = async () => {
		let res = await getRequest(`/aiTest/deduperTestLogs`);
		if (res?.code === 200) {
			setDeduperTestLogs(res['body']);
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	const getAiResponseString = (aiResp) => {
		if (!aiResp || aiResp.length == 0)
			return "";

		let stringResult = "";
		for (const dup of aiResp) {
			if (Array.isArray(dup)) {
				stringResult += "<p>- " + dup.join(", ") + "</p>";
				// stringResult += "<br>";
			}

		}

		return stringResult;
	}

	const selectCurrentDeduperLog = async () => {
		let result = confirm("Do you want to update this instruction as default?");
		if (result === false) {
			return;
		}
		if (!selectedLog) return;
		if (selectedLog.isSelected) return;
		let res = await postRequest(`/aiTest/selectDeduper`, { deDuperId: selectedLog['_id'] });
		if (!res || res['code'] != 200) return;

		let currentLogs = deduperTestLogs;
		for (const duperTestLog of currentLogs) {
			duperTestLog.isSelected = false;
			if (duperTestLog['_id'] == selectedLog['_id'])
				duperTestLog.isSelected = true;
		}
		setDeduperTestLogs(currentLogs);
		showMessage("Instruction is selected as default");
	}

	return (
		<AnimatedPage>
			<div className="container py5rem df fdc">
				<HeadingSubheadingContent wrapperClass="mb2rem tac" heading="Admin Panel - Ai Deduper Test History" />
				<span onClick={() => { selectedLog ? setSelectedLog(null) : onBack() }} className="mra shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
					<svg className="pen f1 h2rem mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
						<path className="fa-primary pen" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z" />
						<path className="fa-secondary pen" d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l-32-32l32-32H416C433.7 224 447.1 238.3 447.1 256z" />
					</svg>
					Back to Menu
				</span>
				{
					!selectedLog ? deduperTestLogs.map((deduperTestLog, logIndex) => {
						console.log("deduperTestLog; ", deduperTestLog);
						return <div className={`shadow mt1rem py1rem px2rem br5px cp bss bw2px bo5 ${deduperTestLog.isSelected ? "bg7" : "bg1"}`} onClick={() => { setSelectedLog(deduperTestLog); }}>
							<p className="usn pen fs1-5rem">
								Tested by {deduperTestLog?.['tester']?.['profile'] && <img className="ass mx0-5rem br100 w2rem h2rem" src={deduperTestLog?.['tester']?.['profile']} />} {deduperTestLog?.['tester']?.['firstName']},&nbsp;&nbsp;
								{moment(deduperTestLog['createdAt']).format('lll')}<br /><br />
								<b>FileNames:</b> {deduperTestLog.fileNames} <br /><br />
								<b>Instructions:</b> {deduperTestLog.instructions.substr(0, 100) + "  ... "}
							</p>
						</div>
					}) :
						<div className="fs1rem bss bw2px bo5 br10px p1rem jcsb">
							<div className="sectionWrapper jcsb px2rem py0-5rem my0-5rem bss bw2px bo5 br10px">
								<div className="df jcsb">
									<span className="fs1rem ">
										<b>Company Name :</b> {selectedLog.companyName} <br /> <br />
										<b>Company Description :</b> <p>{selectedLog.companyDescription}</p> <br /> <br />
										<b>FileNames :</b> {selectedLog.fileNames} <br /> <br />
										<b>Instructions :</b> <p>{selectedLog.instructions}</p> <br /> <br />
										<b>AI Response:</b>
										<div>
											{selectedLog.response && selectedLog.response.length > 0 && selectedLog.response.map(resp => {
												let respString = "";
												if (Array.isArray(resp)) {
													respString = "- " + resp.join(", ");
												}
												return <p>
													{respString}
												</p>
											})}
										</div>
									</span>
								</div>
							</div>
							{
								<div className="w100 df jcc aic">
									<button onClick={() => selectCurrentDeduperLog()} className={`c6 br5px px1rem py0-5rem fw600 fs1-25rem bsbb tac df jcc aic ${selectedLog.isSelected ? "bg7" : "cp bg4"}`}>
										{selectedLog.isSelected ? 'This instruction is currently used' : 'Use this instruction'}
									</button>
								</div>
							}
						</div>
				}
			</div>
		</AnimatedPage>

	);
}
