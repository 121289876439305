import React, { useState } from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
import { Divider } from '@mui/material'
import ModalWrapper from '../../common/Modal/ModalWrapper'
import { toggleModal } from '../../../reducers/modalReducer'
import FileManager from "../../../../images/dataRepo/deduper.svg"
import Delete from "../../../../images/dataRepo/delete.svg"
import Copy from "../../../../images/dataRepo/copy.svg"
import Downloads from "../../../../images/dataRepo/downloads.svg"
import Send from "../../../../images/dataRepo/send.svg"
import { AppMode } from '../../../env';
import { postRequest } from '../../../config';
import { setDeduperFiles } from '../../../reducers/filesReducer';
import Button from '../../common/Button';

const DeduperReport = ({
    moveDeduperFile,
    modalType,
    profileClass,
    dupCheck,
    modal,
    onClickDeduperAuditLog,
    tab,
    company,
    getViewableURL,
    handleEditDispatch,
    reloadModule
}) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const removeDuplicate = (key, index) => {
        if (!dupCheck?.deduperResult?.[key]) return;
        const updatedDeduperResult = { ...dupCheck.deduperResult };
        updatedDeduperResult[key] = [...updatedDeduperResult[key]];
        updatedDeduperResult[key].splice(index, 1);

        if (updatedDeduperResult[key].length === 0) {
            delete updatedDeduperResult[key];
        }

        dispatch(setDeduperFiles({ ...dupCheck, deduperResult: updatedDeduperResult }));
    };

    const deleteDeduperFile = async (key, questionId, file, index) => {
        let directorId = undefined;
        if (tab.includes('directors-questionnaire')) {
            const dqs = tab.split('_');
            if (dqs.length > 1)
                directorId = dqs[1];
        }
        let res = {}
        if (tab.includes('directors-questionnaire')) {
            res = await postRequest('/repository/deleteFile', { file, questionId, companyId: company._id, directorId });
        } else {
            res = await postRequest('/repository/deleteFile', { file, questionId, companyId: company._id });
        }

        if (!res || res['code'] != 200) return;
        if (tab) await reloadModule(tab);
        removeDuplicate(key, index);
    };

    const handleConfirm = async () => {
        setLoading(true)
        let i = 0
        while (i < dupCheck.selectedDeuper.length) {
            const currentData = dupCheck.selectedDeuper[i]
            await moveDeduperFile(dupCheck._id, currentData.curQno, currentData.file, currentData.actionType === "move" ? true : false, currentData.qNo, currentData.name, currentData.key, currentData.index)
            i++;
        }
        setLoading(false)
        dispatch(toggleModal({ open: '' }))
    }

    const getDuplicationCheckEntryHTML = key => {
        const files = dupCheck.deduperResult[key];
        let module = company.modules[tab];
        if (module) module = JSON.parse(JSON.stringify(module));

        if (Array.isArray(files)) {

            const filteredData = files.filter(f => (f != null && f != undefined))

            return <>
                {
                    filteredData.map((checkFile, index) => {
                        let file = checkFile.file;
                        let fileColor = file.originator == 'filing-assistant' ? 'filing-assistant-file' : file.originator == 'sharepoint' ? 'sharepoint-file' : file.originator == 'dropbox' ? 'dropbox-file' : file.originator == 'gdrive' ? 'gdrive-file' : file.bucket == `repository-diligentsia${AppMode === "production" ? "-prod" : ""}` ? "upload-file" : 'prepopulated-file';
                        const url = file.location;

                        return <React.Fragment key={`getDuplicationCheckEntryHTML-${index}${checkFile.file.key}`}>
                            <tr>
                                <td className={"mx1rem fs1rem fw600 bg1 w40"}>
                                    <div
                                        onClick={() => getViewableURL(url)}
                                        className={`mr1rem pl2rem cp tdu fs12rem pr bg44 fw500 ${fileColor} fileManageFileName w100 tal`}
                                    >
                                        {file.originalname}
                                    </div>
                                </td>

                                <td className="frs-td mx1rem fs1rem fw400 bg1 w50">
                                    {tab == 'repository' && (
                                        <>
                                            <div className="mr1rem w100">
                                                <div className="">
                                                    <div
                                                        className="c2 tal fw400"
                                                    >
                                                        {checkFile.qNo && checkFile.qNo} {checkFile.name && checkFile.name}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </td>

                                <td className={"mx1rem fs1rem fw600 df aic w100 jcc w10"}
                                    style={{
                                        minHeight: "54px"
                                    }}
                                >
                                    {tab == 'repository' && (
                                        <>
                                            <Send
                                                data-tooltip={"Cut"}
                                                className="mr1rem cp"
                                                onClick={() =>
                                                    handleEditDispatch({ key: key, fileName: file.title, from: "deduper", actionType: "move", fileData: file, index })
                                                }
                                            />
                                            <Copy
                                                data-tooltip={"Copy"}
                                                className="mr1rem cp"
                                                onClick={() =>
                                                    handleEditDispatch({ key: key, fileName: file.title, from: "deduper", actionType: "copy", fileData: file, index })
                                                }
                                            />
                                        </>
                                    )}

                                    <Link className="df" target="_blank" to={url}>
                                        <Downloads data-tooltip={"Download"} className={`f3 cp mr1rem ${tab == 'repository' ? '' : 'mla'}`} />
                                    </Link>

                                    <Delete
                                        className="cp"
                                        data-tooltip={"Delete"}
                                        onClick={() => deleteDeduperFile(key, checkFile.questionId, file, index)}
                                    />

                                </td>
                            </tr>
                            {
                                filteredData.length === (index + 1) ? <Divider style={{ marginTop: "2rem", marginBottom: "2rem" }} /> : null
                            }
                        </React.Fragment>
                    })
                }


            </>
        }
    };

    return (
        <>
            <ModalWrapper
                modalType={modalType}
                modalCardClasses={"maw1000px mah80"}
                girlComponent={<div style={{ position: 'relative' }}>
                    <img src={require("../../../../images/dataRepo/deduper.png")} alt="deduper" className='animateRight' />
                </div>}
            >
                <div className='df jcsb pr'>
                    <div className='df jcfs aic'>
                        <h2 className="c15 fw600 tal df aic mb0-5rem fs1-25em">
                            <FileManager className="mr1rem" /> Deduper Report
                        </h2>
                    </div>
                    <button
                        onClick={onClickDeduperAuditLog}
                        className='cp tac c1 bg5 c15 br20px px1-5rem py0-5rem fw500'>
                        Log
                    </button>
                </div>

                <h3 className="w100 fw400 fs13px c37 df jcfs aic mt1-5rem">
                    Checked by: &nbsp;
                    <p className={`w2rem h2rem jcc aic dib wsn mr0-5rem`}>
                        {dupCheck?.['checkBy']?.['profile']
                            ? <img
                                src={dupCheck?.['checkBy']?.['profile']}
                                className={`w2rem h2rem df jcc aic br100 ${profileClass} mr0-5rem`}
                                data-tooltip={dupCheck?.['checkBy']?.['firstName'] ? (dupCheck?.['checkBy']?.['firstName'] + " " + (dupCheck?.['checkBy']?.['lastName'] ? dupCheck?.['checkBy']?.['lastName'] : "")) : "?"}
                            />
                            : <img
                                src={require("../../../../images/profile-photo.png")}
                                className={`w2rem h2rem df jcc aic br100 ${profileClass} mr0-5rem`}
                                data-tooltip={dupCheck?.['checkBy']?.['firstName'] ? (dupCheck?.['checkBy']?.['firstName'] + " " + (dupCheck?.['checkBy']?.['lastName'] ? dupCheck?.['checkBy']?.['lastName'] : "")) : "?"}
                            />
                        }
                    </p>
                    <p
                        className={`df jcc ttc aic br100 fw600 c36`}
                    >
                        {`${dupCheck?.['checkBy']?.['firstName'] ? `${dupCheck?.['checkBy']?.['firstName']} ${dupCheck?.['checkBy']?.['lastName'] ? dupCheck?.['checkBy']?.['lastName'] : ""}` : ""}`}
                    </p>
                    <span className='c36'>
                        &nbsp;{moment(dupCheck?.['checkAt']).format('lll')}
                    </span>
                </h3>

                <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />

                <div className="dg gg0-5rem mb2rem fs1rem ">

                    <table className="fis bcc">
                        <thead className='db'>
                            <tr>
                                <th className="w50 tal fs14px fw400 c37">File Name</th>
                                <th className="w40 tal fs14px fw400 c37">Section</th>
                                <th className="w10 fs14px fw400 c37">Actions</th>
                            </tr>
                        </thead>
                        <tbody className='mah300px oya db w100 oxh lightScrollbar'>
                            {dupCheck.deduperResult && Object.keys(dupCheck.deduperResult).map(getDuplicationCheckEntryHTML)}
                        </tbody>
                    </table>

                    {(!dupCheck.deduperResult || Object.keys(dupCheck.deduperResult).length == 0) && (
                        <div className='df fdc jcc aic m2rem'>
                            <img src={require("../../../../images/dataRepo/empty-inbox.svg")} alt={"fileManagement"} />
                            <p className="fs1rem fw600 mt1rem tac c15 op80">No files found</p>
                        </div>
                    )}
                </div>

                <div className="df w100 fdr jcc ">
                    {modal['from'] == 'deduperHistory' && (
                        <button onClick={() => dispatch(toggleModal({ open: 'deduperReportHistory' }))} className="shadow bg3 bg4-hover c1 td250 br5px p1rem fw600 cp miw20 ttu bsbb tac mb1rem df jcc aic mr1rem">Back to List</button>
                    )}

                    {
                        dupCheck.selectedDeuper && dupCheck.selectedDeuper.length ? <Button
                            loading={loading}
                            onClick={handleConfirm}
                            text={"Confirm Allocation"}
                            btnType='warning'
                            customClassName="maw300px"
                        /> : null
                    }

                </div>


            </ModalWrapper>
        </>
    )
}

export default DeduperReport