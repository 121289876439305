import React from 'react'
import ModalWrapper from '../../common/Modal/ModalWrapper'
import { toggleModal } from '../../../reducers/modalReducer';
import Button from '../../common/Button';

const FirstSetupDataRepositoryModal = ({ modalType, dispatch }) => {
    return (
        <ModalWrapper
            modalType={modalType}
            modalCardClasses={"maw1000px FirstSetupData bg38"}
        >

            <div className="df fdc">
                <p className="c15 fs1-25rem fw400">First time?</p>
                <h1 className="c15 fs1-5rem fw600 mb2rem">3 Ways to populate our Data Repository</h1>
            </div>
            <div className="w100 dg gtcr3 gg1rem">
                <img
                    className='w100'
                    src={require('../../../../images/landingpage/first-time-repository-1.png')}
                    alt='first-time-repository'
                />
                <img
                    className='w100'
                    src={require('../../../../images/landingpage/first-time-repository-2.png')}
                    alt='first-time-repository-1'
                />
                <img
                    className='w100'
                    src={require('../../../../images/landingpage/first-time-repository-3.png')}
                    alt='first-time-repository-3'
                />
            </div>

            <div
                className='mt2rem w100 fs1rem df jcc'
            >
                <Button
                    btnType="warning"
                    customClassName="w40"
                    text={"Let’s Go!"}
                    onClick={() => dispatch(toggleModal({ open: '' }))}
                />
            </div>

        </ModalWrapper>
    )
}

export default FirstSetupDataRepositoryModal