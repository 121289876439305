import React from 'react'
import Cropper from "react-cropper";
import { useDispatch, useSelector } from 'react-redux';
import { postRequest, showMessage } from '../../../config';
import { toggleModal } from '../../../reducers/modalReducer';
import { updateUser } from '../../../reducers/userReducer';
import ModalWrapper from '../../common/Modal/ModalWrapper';

const ProfileImageUploader = ({ profileClass, Button }) => {

    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    const cropper = React.createRef(null);
    const [loading, setLoading] = React.useState(false);
    const [userProfile, setUserProfile] = React.useState("");

    const handleProfile = async (e) => {
        let profile = e.target.files[0];
        if (!profile) return
        if (profile && profile.size > 5 * 1024 * 1024)
            return showMessage("Photo Size must be less than 5MB");
        profile = await URL.createObjectURL(profile);
        setUserProfile(profile);
    };

    const updateProfile = async () => {
        console.log(cropper.current && userProfile, "cropper.current && userProfile")
        let attachment = [];
        if (cropper.current && userProfile) {
            setLoading(true)
            let imageToUpload = cropper?.current?.getCroppedCanvas()?.toDataURL();
            let res = await fetch(imageToUpload);
            let blob = await res.blob();
            const coverFile = new File([blob], "profile image", {
                type: "image/png",
            });
            attachment.push(coverFile);
            const response = await postRequest(`/user/updateProfile`, { attachment });
            if (response || response["code"] == 200) {
                dispatch(toggleModal({ open: "" }));
                dispatch(updateUser({ ...user, profile: imageToUpload }));
                setUserProfile("");
            }
            setLoading(false);
            showMessage(response.message);
        }
        else {
            showMessage("Please upload image first")
        }
    };

    const handleClose = () => {
        dispatch(toggleModal({ open: "" }))
        setUserProfile("")
        dispatch(updateUser(user))
    }

    return (
        <>
            <div className="form-group mt3rem">
                <h2 className='c15 fs1rem mb1rem'>Profile Picture</h2>
                <div className="img-upload-area pr">
                    <div className="img-box pr shadow">
                        <img src={user["profile"] || require("../../../../images/profile-photo.jpeg")} alt="Profile"
                            className={`profile-img w7rem h7rem ${profileClass}`} />
                        <div className="input-file">
                            <span
                                className="add-btn bg5"
                                onClick={() => { dispatch(toggleModal({ open: "uploadProfile" })) }}>
                                <svg viewBox="0 0 487 487" className="zi3">
                                    <g>
                                        <path
                                            d="M308.1,277.95c0,35.7-28.9,64.6-64.6,64.6s-64.6-28.9-64.6-64.6s28.9-64.6,64.6-64.6S308.1,242.25,308.1,277.95z
													M440.3,116.05c25.8,0,46.7,20.9,46.7,46.7v122.4v103.8c0,27.5-22.3,49.8-49.8,49.8H49.8c-27.5,0-49.8-22.3-49.8-49.8v-103.9
													v-122.3l0,0c0-25.8,20.9-46.7,46.7-46.7h93.4l4.4-18.6c6.7-28.8,32.4-49.2,62-49.2h74.1c29.6,0,55.3,20.4,62,49.2l4.3,18.6H440.3z
													M97.4,183.45c0-12.9-10.5-23.4-23.4-23.4c-13,0-23.5,10.5-23.5,23.4s10.5,23.4,23.4,23.4C86.9,206.95,97.4,196.45,97.4,183.45z
													M358.7,277.95c0-63.6-51.6-115.2-115.2-115.2s-115.2,51.6-115.2,115.2s51.6,115.2,115.2,115.2S358.7,341.55,358.7,277.95z"
                                        />
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Upload Profile */}
            <ModalWrapper modalType={"uploadProfile"} onCloseHandler={handleClose}>
                <h2 className={`c15 ${userProfile == "" ? "tac" : "tal"} lh1 fw600 mb2rem`}> Upload Profile Picture </h2>
                {userProfile == ""
                    ? (<label for="profile" className="w60 bsbb bg5 br5px tac bsd bo3 bw2px cp pr db upload-pic-area mxa px1rem py4rem">
                        <input className="" onChange={handleProfile} onDrop={handleProfile} id="profile" type="file" accept="image/jpeg, image/jpg, image/png" />
                        <div className='df fdc aic jcc'>
                            <img src={require('../../../../images/upLoading.svg')} alt='upLoading' />
                            <span className='w60 mxa c15 op60 tac mt1rem'>Upload or drag and drop (max 5MB)</span>
                        </div>
                    </label>
                    ) : (
                        <div className="img-box pr maw300px mxa mah300px ">
                            <Cropper style={{ maxHeight: "300px" }} ref={cropper} guides={false} aspectRatio={1 / 1} className="cropper-settings" src={userProfile} />
                        </div>
                    )}
                <div className='df jcc w100'>
                    {
                        userProfile !== "" && <>
                            <button type='button' className='c3 bg44 bo3 bss bw1px w20rem mr1rem br50px mt2rem pr cp px2-5rem sm-px16px db fs14px sm-fs12px fw400 tac'>
                                Change Picture
                                <input className="op0 w100 h100 pa l0 t0 cp" onChange={handleProfile} onDrop={handleProfile} id="profile1" type="file" accept="image/jpeg, image/jpg, image/png" />
                            </button>
                        </>
                    }
                    <Button btnType='warning' text={"Save"} onClick={() => { if (!loading) updateProfile() }} loading={loading} customClassName='mt2rem w20rem' />
                </div>
            </ModalWrapper>

            {/* <Modal
                open={modal["open"] == "uploadProfile"}
                onClose={handleClose}
                className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic"
            >
                <div className="mah90 oya bg1 shadow w100 p3rem bsbb df fdc m1rem maw600px br5px pr">
                    <button
                        className="close-popup"
                        onClick={handleClose}
                    >
                        <svg
                            style={{ position: "relative", top: "4px" }}
                            className="pen fds h1rem f1 mr0-5rem"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                        >
                            <path
                                className="pen fa-primary"
                                d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                            />
                        </svg>
                    </button>

                    <h1 className="text-gradient fs2rem tac lh1 fw600 mb2rem">
                        Upload Profile Picture
                    </h1>
                    {userProfile == "" ? (
                        <label
                            for="profile"
                            className="w100 bsbb bg5 br5px tac p1rem bsd bo6 bw2px cp pr upload-pic-area"
                        >
                            <span className="pen fs1rem fw500 tac">Upload picture</span>
                            <br />
                            <div className="">
                                <input
                                    className=""
                                    onChange={(e) => handleProfile(e)}
                                    id="profile"
                                    type="file"
                                    accept="image/jpeg, image/jpg, image/png"
                                />
                            </div>
                            <label>Max file size 5MB</label>
                        </label>
                    ) : (
                        <div className="img-box pr">
                            <Cropper
                                style={{ maxHeight: "500px" }}
                                ref={cropper}
                                guides={false}
                                aspectRatio={1 / 1}
                                className="cropper-settings"
                                src={userProfile}
                            />
                        </div>
                    )}
                    <div className="df jcse mt1rem">
                        <Button
                            btnType={"warning"}
                            onClick={handleClose}
                            text={"Cancel"}
                            customClassName={"mr1rem"}
                        />
                        <Button
                            onClick={() => { if (!loading) updateProfile() }}
                            text={Object.keys(userProfile).length == 0 ? "Upload" : "Crop & Upload"}
                        />
                    </div>
                </div>
            </Modal> */}
        </>
    )
}

export default ProfileImageUploader