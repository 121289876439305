import React, { useMemo, useState, useEffect } from 'react'
import Modal from '@mui/material/Modal';
import { postRequest, showMessage } from '../../../config';

const CreateEditSection = ({ modal, dispatch, toggleModal, sectionList, selectedData }) => {

    // Hooks and vars
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        sectionNo: '',
        sectionName: ''
    })

    const allSections = useMemo(() => {
        return sectionList.map((section) => ({ label: section.sectionName, value: section._id }))
    }, [sectionList])

    useEffect(() => {
        if (selectedData) {
            setData({
                sectionName: selectedData.sectionName
            })
        }
    }, [selectedData])


    const handleClose = () => {
        dispatch(toggleModal({ open: '' }))
    }

    // Handle Submit
    const handleSubmit = async () => {
        if (loading) return
        setLoading(true)
        try {
            const endpoint = selectedData?.title.toLowerCase().trim().includes('edit') ? '/admin/update/section' : '/admin/create/section'
            let response = await postRequest(endpoint, data);
            if (response['code'] == 200) {
                handleClose()
                showMessage(response['message']);
            }
        } catch (err) {
            console.log(err, "error")

        } finally {
            setLoading(false)

        }
    }


    const findDefaultValue = () => {
        const selectedSection = allSections.filter((cat) => cat.value === data?.categoryId)
        if (selectedSection.length) {
            return selectedSection[0]
        }
        return null
    }

    return (
        <>
            <Modal
                open={modal['open'] == '123addEditSection'}
                onClose={handleClose}
                className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic"
            >
                <div className="mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw800px pr pt5rem br5px pr">
                    <img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
                    <button className="close-popup" onClick={handleClose}>
                        <svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                            <path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                        </svg>
                    </button>
                    <h1 className="text-gradient fs1-5rem fw600 tac">{selectedData && selectedData?.title}</h1>

                    <div className="row mt1rem">
                        <div className={`w50 p1rem`}>
                            <label className="" for="empty">Section Name</label>
                            <input
                                id="sectionName"
                                type="text"
                                name='sectionName'
                                placeholder="Add category name. e.g. cybersecurity"
                                className="pl1rem shadow w100 br5px p1rem"
                                onChange={(e) => setData((prev) => ({ ...prev, [e.target.name]: e.target.value }))}
                                value={data.sectionName}
                            />
                        </div>

                        <div className={`w50 p1rem`}>
                            <label className="" for="empty">Section Number</label>
                            <input
                                id="sectionNo"
                                type="text"
                                name='sectionNo'
                                placeholder="Add category name. e.g. cybersecurity"
                                className="pl1rem shadow w100 br5px p1rem"
                                onChange={(e) => setData((prev) => ({ ...prev, [e.target.name]: e.target.value }))}
                                value={data.sectionNo}
                            />
                        </div>

                    </div>


                    <div className="df jcse">
                        <span
                            onClick={() => {
                                if (loading) return
                                dispatch(toggleModal({ open: "" }))
                            }}
                            className="shadow bg4 bg4-hover c1 td250 br5px px2rem py0-5rem cp ttu mr1rem bsbb tac df jcc aic mt1rem"
                        >
                            Cancel
                        </span>
                        <span
                            onClick={handleSubmit}
                            className="shadow bg3 bg4-hover c1 td250 br5px px2rem py0-5rem cp ttu mr1rem bsbb tac df jcc aic mt1rem"
                        >
                            Submit
                        </span>
                    </div>
                </div >
            </Modal >
        </>
    )
}

export default CreateEditSection