import Modal from '@mui/material/Modal';
import React, { useState } from "react";
import { HashLink } from 'react-router-hash-link';
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// typewtiter import 
import { toggleModal } from "../../../reducers/modalReducer";
import { LANDING_PAGE, TEST_PAGE } from "../../../constants/routes";


// Menu list
const PAGE_MENU_LIST = [
    { title: 'Companies', link: LANDING_PAGE },
    { title: 'Accountants', link: `${LANDING_PAGE}-accountants` },
    { title: 'Lawyers', link: `${LANDING_PAGE}-lawyers` },
    { title: 'VCs', link: `${LANDING_PAGE}-vcs` },
    { title: 'PEs', link: `${LANDING_PAGE}-pes` },
    { title: 'Angel Networks', link: `${LANDING_PAGE}-angel-networks` },
    { title: 'Angels', link: `${LANDING_PAGE}-angels` }
]

const Banner = ({ data, isAnimationVisible, refs, calImage, }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const modal = useSelector((state) => state.modal);

    const [isPlay, setIsPlay] = useState(false);

    function playVideo(e) {
        let video = document.getElementById("videoPaul");
        if (video.paused == true) {
            setIsPlay(true);
            video.play();
        }
        e.target.controls = true;
        if (e?.target?.nextElementSibling) e.target.nextElementSibling.remove();
    };

    return (
        <div
            className="bg1 bpr brnr banner-bg"
            style={{
                backgroundImage: `url(${require('../../../../images/landingpage/form-background-3.png')})`,
                backgroundPosition: 'right top',
                backgroundSize: '100% 56%'
            }}
        >
            <div className="main-banner-container">
                <div className="pt1rem df fdc">
                    <div className="row df pr" style={{ flexWrap: "nowrap" }}>
                        {/* Left side block content block */}
                        <div className="col-sm df jcc fdc gg2rem" style={{ width: "70%", paddingRight: "100px" }}>
                            <div ref={refs?.current?.[0]} className={`fs1-75rem c15 tslp ${isAnimationVisible[0] ? 'op1 tty0' : 'op0 ttx20'}`} style={{ lineHeight: '50px' }}>
                                {/* Welcome to Diligentsia */}
                                {data?.['bannersubTitle']}
                            </div>
                            <div className='fw500 c15 mih12rem '>
                                <span className="c15 fs3-5rem" style={{ lineHeight: '70px' }}>
                                    {data?.['bannerTitle']}
                                </span>
                            </div>
                            {data?.['bannerDescription'] &&
                                <span className="fs1rem c15 tslp w100">
                                    {data['bannerDescription']}
                                </span>
                            }
                        </div>
                        {/* Right side image block */}
                        <div className="col-sm mt2rem df jce pr right-img-area" style={{ width: '30%', height: '400px' }}>
                            <div className="pr">
                                <img src={require('../../../../images/landingpage/animation-cube.png')} className='ar pa zi0 cubes-img' style={{ ...data?.cubeStyle }} />
                                <img src={data?.bannerImage} className={`zi1 pr person-img`} style={{ ...data?.imageStyle, height: '100%' }} />
                                {
                                    data?.videoButton ? <img src={data?.videoButton} onClick={() => dispatch(toggleModal({ open: "videoCompanies" }))} className={`zi1 pa cp pillIcon`} style={{ left: "54%", bottom: "33px", width: "172px !important", height: "50px" }} /> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Navbar part */}
            <div className={`bg1 pr pt1rem w100 df jcc fdc aic tic automated-sec`}>
                <div className={`br br35px bg18 df jcsa aic zi1 w60 p0-5rem mb4rem menu-bottom`} style={{ marginTop: "-10px", backgroundColor: '#6775b995', backdropFilter: 'blur(5px)' }}>
                    {PAGE_MENU_LIST.map((obj, index) => <div onClick={() => navigate(obj?.link)} key={obj?.title} className={`cp c1 ${data?.activeMenu === `${"menu-bar"}-${index}` ? "fs1-25rem-screen4 fw700 fs1rem-screen1" : 'fs1-125rem-screen4 fw400 fs1rem-screen1'}`} style={{ lineHeight: "33px" }}>{obj?.title}</div>)}
                </div>
            </div>

            {/* Calculator part */}
            {
                calImage && <div className="pr bg1 pb3rem bsbb calcluter-sec">
                    <div className="bg1 p4rem w100 df br45px bsbb" style={{ background: "radial-gradient(70% 70% at 24.51% 50.12%, #F5F7FF 0%, #E8ECFF 100%)" }}>

                        <div className='w30 df jcc'>
                            <img src={calImage} className="w90 ofcon" />
                        </div>

                        <div className='w70 df jcc fdc pl2rem pr2rem'>
                            {data?.gurantee?.point1 &&
                                <div className="fw400 fs1-75rem c15" style={{ lineHeight: '40px', }}>
                                    {data?.gurantee?.point1}
                                </div>
                            }
                            {data?.gurantee?.point2 &&
                                <div className="fw400 fs1-75rem c15" style={{ lineHeight: '40px', }}>
                                    {data?.gurantee?.point2}
                                </div>
                            }
                            {data?.gurantee?.point3 &&
                                <div className="fw400 fs1-75rem c15" style={{ lineHeight: '40px', }}>
                                    {data?.gurantee?.point3}
                                </div>
                            }
                            <div className='fw400 df jcs'>
                                {data?.['description']?.['isTestBtn'] &&
                                    <Link to={TEST_PAGE} className="bsbb jcc w400px bg1 tal df fdc aic fw500 mt1rem pt1rem px1rem c1 bg4 br19px cp arrowBtnAnimation secend-sec-btn td250">
                                        <>FREE 2-Minute Test
                                            <span className='fw400 c21 df fdc' style={{ marginTop: '-2px', fontSize: '13px', lineHeight: '15px', fontStyle: 'italic' }}>No registration or credit card required</span>
                                            <i className="icon">
                                                <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.39612 10.336H15.818L11.43 14.7241C10.1696 15.9845 12.0602 17.875 13.3206 16.6147L17.3169 12.6121L19.9847 9.94019C20.5017 9.42008 20.5017 8.58008 19.9847 8.05996L13.3206 1.38812C13.0671 1.12758 12.7181 0.981397 12.3545 0.984421C11.1542 0.984572 10.5652 2.4465 11.43 3.2788L15.8284 7.66688H1.32711C-0.521408 7.75859 -0.383387 10.4281 1.39612 10.336Z" fill="#FC7785" />
                                                </svg>
                                            </i>
                                        </>
                                    </Link>
                                }
                                {data?.['description']?.['isInvestorBtn'] &&
                                    <HashLink to="#investor-lookup" style={{ width: '333px', paddingLeft: '30px' }} className='bsbb jcc w400px bg1 tal df fdc aic fw500 mt1rem pt1rem px1rem c1 bg4 br19px cp arrowBtnAnimation secend-sec-btn td250'>
                                        <>Investor Database Check
                                            <i className="icon">
                                                <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.39612 10.336H15.818L11.43 14.7241C10.1696 15.9845 12.0602 17.875 13.3206 16.6147L17.3169 12.6121L19.9847 9.94019C20.5017 9.42008 20.5017 8.58008 19.9847 8.05996L13.3206 1.38812C13.0671 1.12758 12.7181 0.981397 12.3545 0.984421C11.1542 0.984572 10.5652 2.4465 11.43 3.2788L15.8284 7.66688H1.32711C-0.521408 7.75859 -0.383387 10.4281 1.39612 10.336Z" fill="#FC7785" />
                                                </svg>
                                            </i>
                                        </>
                                    </HashLink>
                                }
                            </div>
                        </div>
                    </div>

                </div >
            }


            {/* Story Video Modal */}
            <Modal open={modal['open'] == 'videoCompanies'} onClose={() => dispatch(toggleModal({ open: '' }))} className='backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic'>
                <div className='bg1 shadow w100 p2-5rem bsbb df fdc m1rem maw900px br5px pr'>
                    <button className='close-popup' onClick={() => dispatch(toggleModal({ open: '' }))} >
                        <svg style={{ position: 'relative', top: '4px' }} className='pen fds h1rem f1 mr0-5rem' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' >
                            <path className='pen fa-primary' d='M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z' />
                        </svg>
                    </button>
                    {/* <div className="pr cp">
                        <video
                            id="videoPaul"
                            onClick={(e) => setIsPlay(true)}
                            className="bg1 bsbb pr bss bw5px bo5 w100 "
                            controls="true"
                            poster={data.posterImage}>
                            <source src={data.videoLink} type="video/mp4"></source>
                        </video>
                        <svg onClick={(e) => playVideo(e)} className={`${isPlay && 'dn'}  pa center`} style={{ width: '80px', height: '80px' }} width="146" height="146" viewBox="0 0 146 146" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M73.1295 0.384033C33.2267 0.384033 0.767578 32.8463 0.767578 72.7459C0.767578 112.646 33.2267 145.108 73.1295 145.108C113.032 145.108 145.491 112.646 145.491 72.7459C145.491 32.8463 113.032 0.384033 73.1295 0.384033ZM101.896 75.2811L59.6854 102.417C59.1908 102.738 58.6195 102.897 58.0542 102.897C57.5595 102.897 57.0589 102.773 56.6115 102.529C55.6397 101.999 55.039 100.986 55.039 99.8816V45.6102C55.039 44.5061 55.6397 43.4931 56.6115 42.9631C57.5654 42.439 58.7608 42.4712 59.6854 43.075L101.896 70.2107C102.756 70.7642 103.28 71.7213 103.28 72.7459C103.28 73.7706 102.756 74.7274 101.896 75.2811Z" fill="white" />
                        </svg>
                    </div> */}
                    <div style={{ position: 'relative', paddingBottom: '56.25%', height: '0' }}>
                        <iframe src={data.videoLink + "&output=embed"} frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}></iframe>
                    </div>
                </div>
            </Modal >

        </div >
    )

}
export default Banner

