import React from 'react'
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { resetTabModal, toggleModal } from '../../../reducers/modalReducer';

const RepositoryAlert = ({ setTabName }) => {

    let modal = useSelector(state => state.modal);
    const dispatch = useDispatch()
    const { id } = useParams();

    const handleClose = () => {
        setTabName('')
        dispatch(toggleModal({ open: '' }))
        dispatch(resetTabModal(true))
    }

    return (
        <>
            <Modal open={modal['open'] == 'repositoryAlert'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic" >
                <div className="mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw600px pr pt5rem br5px pr">

                    <img className="h2rem pa t0 l0 m1rem" src={require(`../../../../images/logo.svg`)} alt="TODO" />
                    <div className="tac my1rem">
                        <p className="mb0-5rem">The files allocation process is currently running in the background. Please access this module after some time.</p>
                    </div>
                    <div className="df jcc">
                        <Link to={`/company/${id}`}>
                            <button type='button' onClick={handleClose} className="w100 shadow bg4 bg3-hover c1 td250 br5px px1rem py0-5rem mr1rem fw600 cp ttu bsbb df jcc">Go Back</button>
                        </Link>
                    </div>
                </div>
            </Modal>

        </>
    )
}

export default RepositoryAlert