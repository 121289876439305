import React, { useEffect, useMemo, useState } from 'react'
import { getRequest, postRequest, showMessage } from '../../../config';
import EnterCode from '../../modals/enterCode';
import GoogleValidator from '../../modals/googleValidator';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../../reducers/modalReducer';

const TwoFactorAuthentication = ({ userData, Button }) => {

    const dispatch = useDispatch()
    const { smsEnabled, twoFaEnabled, emailEnabled } = useSelector((state) => state.loginType);
    const [focussed, setFocused] = useState(null)
    const [loading, setLoading] = useState(false);
    const [verify, setVerify] = useState(null);
    const [counter, setCounter] = useState(0);
    const [qr, setQr] = useState(null)
    const [authenticationOptions, setAuthenticationOptions] = useState([
        {
            name: 'SMS',
            action: 'smsEnabled',
            icon: require('../../../../images/profilePage/sms_auth.svg'),
            selected: false
        },
        {
            name: 'Google',
            action: 'twoFaEnabled',
            icon: require('../../../../images/profilePage/google_auth.svg'),
            selected: false
        },
        {
            name: 'Email',
            action: 'emailEnabled',
            icon: require('../../../../images/profilePage/email_auth.svg'),
            selected: false
        }
    ])

    const buttonText = useMemo(() => {
        return counter > 0 ? `Resend in ${counter}s` : 'Resend Code';
    }, [counter]);

    const isButtonDisabled = useMemo(() => {
        return counter > 0 || loading;
    }, [counter, loading]);

    useEffect(() => {
        let timer;
        if (counter > 0) {
            timer = setTimeout(() => setCounter(counter - 1), 1000);
        }
        return () => clearTimeout(timer);
    }, [counter]);

    const handleVerification = async () => {
        try {
            if (!userData.phone) {
                return showMessage('Please enter a phone number')
            }
            setLoading(true)
            const response = await postRequest("/user/sendSMS", { phone: userData.phone });
            if (!response) return;
            setCounter(60);
            setVerify("Phone");
            dispatch(toggleModal({ open: "smsEnabled" }));
            showMessage(response["message"]);
        } catch (e) {
            showMessage(e["message"]);
        } finally {
            setLoading(false)
        }
    }

    const verifySMSCode = async (e) => {
        const authKey = e.target.value;
        if (authKey.length < 6) return;
        setLoading(true)
        try {
            const response = await postRequest("/user/enableSMS", { authKey });
            showMessage(response["message"]);
            dispatch(toggleModal({ open: "" }));
        } catch (e) {
            showMessage(e["message"]);
        } finally {
            setLoading(false)
        }
    };

    const verifyAuthenticatorCode = async (e) => {
        const tokenCode = e.target.value;
        if (tokenCode.length < 6) return;
        const response = await postRequest("/user/verify2Factor", { tokenCode });
        if (!response) return;
        showMessage(response["message"]);
        dispatch(toggleModal({ open: "" }));
    };

    useEffect(() => {
        if (userData) {
            const updatedOptions = authenticationOptions.map((option) => {
                if (option.name === 'SMS') {
                    return { ...option, selected: userData.smsEnabled };
                } else if (option.name === 'Google') {
                    return { ...option, selected: userData.twoFaEnabled };
                } else if (option.name === 'Email') {
                    return { ...option, selected: userData.emailEnabled };
                }
                return option;
            });
            setAuthenticationOptions(updatedOptions);
        }
    }, [userData])

    const enableAuthenticator = async () => {
        const res = await getRequest("/user/enable2Factor");
        if (!res || res["code"] != 200) {
            return showMessage(res["message"]);
        }

        const { qrData } = res.body;
        dispatch(toggleModal({ open: "authenticator" }));
        setVerify("Qr");
        setQr(qrData);
    };

    const handleAuthenticate = (action) => {
        setFocused(action)
        if (action === "twoFaEnabled" && !userData["twoFaEnabled"]) enableAuthenticator();
        else if (action === "smsEnabled" && !userData["smsEnabled"]) handleVerification();
    };

    const handleSubmit = async () => {

    }

    return (
        <div className='w50 pl3rem lg-w100 lg-px1rem lg-my2rem'>
            <div className='bg38 px3rem br20px py4rem sm1rem df fdc aic'>
                <h3 className='w100 tac c15 lh1-5rem'>Two Factor Authentication</h3>
                <p className='w100 tac c15 fs14px'>Choose alternative authentication methods</p>

                <div className='dg gtcr1-md gtcr3a-xl gg1rem mt2rem lg-w100'>
                    {authenticationOptions.map((option) => {
                        return (
                            <div
                                key={option.name}
                                className={`bg1 px2rem py0-5rem br30px df fdc jcc aic pr cp ${option.action === focussed && 'bs1px brss bo3'}`}
                                style={{ minHeight: '230px' }}
                                onClick={() => handleAuthenticate(option.action)}
                            >
                                {
                                    option.selected ? <div className='pa twofaSelect'>
                                        <div className='pr df aic jcfe'>
                                            <p className='bg47 px1rem py0-25rem br20px c1 fs12px'>Activated</p>
                                            <img className='pa r0' src={require('../../../../images/profilePage/green_tick.svg')} alt={`green_tick`} />
                                        </div>
                                    </div> : null
                                }
                                <img src={option.icon} alt={`${option.name}_authIcon`} />
                                <div className='tac c15'>
                                    <h3 className='fw800 tac'>{option.name}</h3>
                                    Authentication
                                </div>
                            </div>
                        )
                    })
                    }

                </div>
                <div className='df jcc w100'>
                    <Button
                        btnType='warning'
                        text={"Save"}
                        onClick={handleSubmit}
                        customClassName='mt2rem w20rem'
                    />
                </div>
            </div>

            <EnterCode
                modalType={"smsEnabled"}
                authMethod={verify}
                codeEntered={verifySMSCode}
                smsEnabled={smsEnabled}
                twoFaEnabled={twoFaEnabled}
                emailEnabled={emailEnabled}
                resendCode={handleVerification}
                loading={loading}
                buttonText={buttonText}
                isButtonDisabled={isButtonDisabled}
                counter={counter}
            />

            <GoogleValidator
                modalType={"authenticator"}
                authMethod={verify}
                twoFaEnabled={twoFaEnabled}
                codeEntered={verifyAuthenticatorCode}
                loading={loading}
                qr={qr}
            />

        </div>
    )
}

export default TwoFactorAuthentication