import moment from "moment";
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

import 'react-circular-progressbar/dist/styles.css';

export default function QualifiedLeads({ profileClass, user, promptScore, qualifiedLeads, investors, saveInvestorMember }) {
    const navigate = useNavigate()
    const dispatch = useDispatch();

    let modal = useSelector(state => state.modal);

    useEffect(() => { }, []);

    const handleInvestorMember = (leadId, member) => {
        if (typeof (member) == 'object') saveInvestorMember(leadId, member['_id']);
    };

    return (
        <div className="dropdown-company-dahboard leads-page poppins-font-style *">
            {(qualifiedLeads && qualifiedLeads.length == 0) && <h2 className="w100 mt2rem c15 fs2rem lh2 tac fw500">No leads found!</h2>}
            <div className="df jcsb pt2rem pl6rem pr7rem aife">
                <div className="df bg1 br50px mr0-5rem bw2px bss pr1rem p0-5rem bsbb aic mr1rem cp add-btn-dashboard">
                    <span className='bg1 bsbb mr0-5rem br100 w40px h40px tac df jcc aic'>
                        <svg className='h30px w30px' width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_2661_6467)">
                                <path d="M19 0C8.52298 0 0 8.52298 0 19C0 29.477 8.52298 38 19 38C29.477 38 38 29.477 38 19C38 8.52298 29.477 0 19 0Z" fill="#4A67FF" />
                                <g clip-path="url(#clip1_2661_6467)">
                                    <path d="M28.8086 23.712C28.0825 23.1474 27.1029 22.6642 25.8948 22.2732L21.9391 12.9046L24.1946 11.7509C24.6656 11.51 24.7113 10.8532 24.2785 10.5495L19.4036 7.12769C19.037 6.8705 18.5257 7.01712 18.3516 7.43036L12.1028 22.2734C10.8953 22.6646 9.91617 23.1479 9.19033 23.7124C8.03865 24.6081 7.79688 25.5185 7.79688 26.1245C7.79688 27.5363 9.0462 28.8124 11.3147 29.7176C15.5984 31.4269 22.3985 31.4279 26.6846 29.7176C28.9531 28.8124 30.2024 27.5363 30.2024 26.1245C30.2024 25.5183 29.9606 24.6077 28.8086 23.712ZM25.4044 24.7267C25.2605 25.1791 24.2021 25.8442 22.3277 26.2325V24.2886C23.3095 24.1069 24.1835 23.8442 24.8917 23.5126L25.4044 24.7267ZM19.3094 8.77956L22.5157 11.0302L21.3908 11.6056C21.2333 11.2338 20.8175 11.0794 20.4678 11.2272C20.11 11.3782 19.9425 11.7907 20.0936 12.1484C20.2504 12.5198 24.3441 22.2155 24.3441 22.2155C23.1126 22.8046 21.1043 23.1714 18.9997 23.1714C16.894 23.1714 14.8847 22.8043 13.6533 22.2146L19.3094 8.77956ZM20.9215 24.4853V26.4488C19.6907 26.5789 18.311 26.5792 17.0778 26.4488V24.4853C18.3331 24.6084 19.6657 24.6085 20.9215 24.4853ZM13.107 23.5123C13.8154 23.8441 14.6895 24.1069 15.6717 24.2886V26.2325C13.7972 25.8442 12.7389 25.1791 12.5949 24.7267C12.9304 23.9323 12.812 24.2131 13.107 23.5123ZM26.1634 28.4116C22.2122 29.9882 15.7872 29.9882 11.8359 28.4116C10.1873 27.7537 9.20308 26.8988 9.20308 26.1245C9.20308 25.4321 10.0087 24.682 11.3454 24.0708L11.2271 24.351C11.1906 24.4376 11.1717 24.5306 11.1717 24.6246C11.1717 26.2821 13.4385 27.3399 16.2311 27.7541C18.1721 28.002 19.7522 28.0429 21.7681 27.7541C24.5607 27.3399 26.8275 26.2821 26.8275 24.6246C26.8275 24.5306 26.8087 24.4376 26.7721 24.351L26.6539 24.071C27.9906 24.6819 28.7962 25.432 28.7962 26.1245C28.7962 26.8987 27.812 27.7537 26.1634 28.4116Z" fill="white" />
                                    <path d="M9.23477 14.4275L9.30161 14.9619C9.34502 15.3091 9.63713 15.5716 9.987 15.5777C10.349 15.5817 10.6394 15.3225 10.6934 14.9863L10.8034 14.3017C10.9572 13.345 11.6097 12.5221 12.5057 12.1545C13.0576 11.9286 13.1013 11.1986 12.6177 10.901L12.1114 10.5894C11.4399 10.1763 10.9715 9.49476 10.8261 8.7198L10.6903 7.99537C10.627 7.65746 10.3288 7.41657 9.98503 7.42196C9.64135 7.4289 9.35313 7.68333 9.30354 8.02349L9.22188 8.58349C9.09668 9.44198 8.59284 10.1812 7.83963 10.6117L7.35364 10.8893C6.83968 11.183 6.89742 11.9455 7.45217 12.1568C8.42288 12.5267 9.10592 13.3967 9.23477 14.4275ZM10.0092 10.4284C10.2552 10.8231 10.5662 11.1767 10.9303 11.4722C10.5668 11.7668 10.2542 12.1201 10.0059 12.5152C9.75427 12.1194 9.43717 11.7674 9.06711 11.4749C9.44111 11.1793 9.75858 10.8254 10.0092 10.4284Z" fill="white" />
                                    <path d="M30.6578 16.5219L30.1182 16.1981C29.4418 15.7924 28.9706 15.115 28.8252 14.3398L28.6903 13.6203C28.6269 13.2825 28.3272 13.0412 27.985 13.047C27.6413 13.0539 27.3531 13.3083 27.3035 13.6485L27.2157 14.2505C27.094 15.0851 26.6066 15.8083 25.8787 16.2344L25.3942 16.5181C24.8883 16.8142 24.9467 17.5664 25.4937 17.7798C26.4385 18.1486 27.1036 19.0027 27.2294 20.0091L27.3016 20.587C27.3455 20.9382 27.6439 21.2029 27.9994 21.2029C28.3439 21.2029 28.6386 20.9527 28.6934 20.6114L28.8039 19.9239C28.957 18.9714 29.6085 18.1572 30.5043 17.7989L30.5573 17.7777C31.0959 17.5621 31.1558 16.8207 30.6578 16.5219ZM28.0151 18.1285C27.7688 17.7378 27.4585 17.3897 27.096 17.0994C27.4628 16.8055 27.774 16.4546 28.0194 16.0616C28.2677 16.4532 28.5808 16.8029 28.9474 17.094C28.581 17.3849 28.2657 17.7353 28.0151 18.1285Z" fill="white" />
                                </g>
                            </g>
                            <defs>
                                <clipPath id="clip0_2661_6467">
                                    <rect width="38" height="38" fill="white" />
                                </clipPath>
                                <clipPath id="clip1_2661_6467">
                                    <rect width="24" height="24" fill="white" transform="translate(7 7)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                    <span className='c15'>Set up Wizard</span>
                </div>
                <div className={`progress-area-report progress-fit-report mb2rem`}>
                    <div className='progress-area' style={{ display: 'flex', position: 'relative', justifyContent: 'end', alignItems: 'center' }}>
                        <div className='progress-box'>
                            <div className="progress-bar" style={{ width: 80, height: 80, backgroundColor: '#FBFBFB', borderRadius: '50%' }}>
                                <CircularProgressbar
                                    className={`${promptScore > 67 ? 'green' : promptScore > 33 ? 'orange' : 'red'}`}
                                    value={promptScore || 0}
                                    text={`${promptScore || 0}%`}
                                    strokeWidth={18}
                                    styles={buildStyles({
                                        strokeLinecap: 'round',
                                        strokeWidth: '5px',
                                        textSize: '18px',
                                        pathTransitionDuration: 0.5,
                                        trailColor: '#d6d6d6',
                                        backgroundColor: '#fc7785',
                                        dominantBaseline: 'ideographic',
                                    })}
                                />
                            </div>
                            <span className="tooltip-area cp pr" style={{ top: '83px', left: '-186px' }}>
                                <i className="icon">
                                    <svg classNaw2rem me="h2rem" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_2661_6508)">
                                            <path d="M9 18.1133C13.9628 18.1133 18 14.076 18 9.11328C18 4.15052 13.9628 0.113281 9 0.113281C4.03724 0.113281 0 4.15052 0 9.11328C0 14.076 4.03724 18.1133 9 18.1133ZM9 1.91328C12.97 1.91328 16.2 5.14326 16.2 9.11328C16.2 13.0833 12.97 16.3133 9 16.3133C5.02998 16.3133 1.8 13.0833 1.8 9.11328C1.8 5.14326 5.02998 1.91328 9 1.91328Z" fill="#FC7785" />
                                            <path d="M8.69665 11.6919C8.50359 11.6919 8.34746 11.5299 8.34746 11.3297C8.34746 10.8559 8.41225 10.4466 8.54117 10.1025C8.63575 9.84312 8.78929 9.5817 9.00049 9.31758C9.15597 9.12538 9.43454 8.84446 9.8375 8.47618C10.2405 8.10723 10.5022 7.81355 10.6233 7.59446C10.7445 7.37538 10.8041 7.13613 10.8041 6.87672C10.8041 6.40696 10.6272 5.995 10.2741 5.63949C9.92107 5.28398 9.48767 5.10656 8.97458 5.10656C8.47898 5.10656 8.06501 5.26785 7.73331 5.58976C7.49102 5.82497 7.30897 6.15696 7.18847 6.58573C7.10555 6.88008 6.82892 7.06825 6.53545 7.03196C6.16359 6.98627 5.9187 6.60454 6.02495 6.23156C6.18821 5.65831 6.4616 5.19661 6.84447 4.84581C7.37635 4.35723 8.07991 4.11328 8.95514 4.11328C9.88156 4.11328 10.6207 4.37471 11.1727 4.89823C11.7247 5.42175 12 6.05414 12 6.79608C12 7.22551 11.9028 7.62135 11.7091 7.9829C11.5154 8.34514 11.1358 8.78532 10.5715 9.30414C10.1925 9.65293 9.9444 9.91032 9.82778 10.0756C9.71117 10.241 9.62501 10.4312 9.5693 10.6455C9.52783 10.8048 9.49933 11.0387 9.48378 11.3458C9.47406 11.5394 9.31923 11.6912 9.13265 11.6912H8.69665V11.6919ZM8.28268 13.4258C8.28268 13.0461 8.57939 12.7383 8.94542 12.7383C9.31145 12.7383 9.60817 13.0461 9.60817 13.4258C9.60817 13.8055 9.31145 14.1133 8.94542 14.1133C8.57939 14.1133 8.28268 13.8055 8.28268 13.4258Z" fill="#FC7785" stroke="#FC7785" stroke-width="0.5" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_2661_6508">
                                                <rect width="18" height="18" fill="white" transform="translate(0 0.113281)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <div className="overlay-box" style={{ left: 'auto', top: '0', right: '22px', zIndex: '9', width: '400px' }}>
                                        <p className="fs0-75rem">“The Prompt Response Score  is a score provided to each  applicant company to  indicate the promptness of  any initial response from an  investor – whether positive or  negative“. Any initial  response that takes more  than 10 days is marked  negatively</p>
                                    </div>
                                </i>
                            </span>
                            <span style={{ marginBottom: '0', fontSize: '13px' }} className="text-center-area c15">Prompt Response Score</span>
                        </div>

                    </div>
                </div>
            </div>
            <div className="table-permission">
                <table id="table" className="table-userPermissions table-clientPermissions table-leades">
                    <thead className="ps t0">
                        <tr>
                            <th className="index-table ttu fw500 compnay-name" style={{ textAlign: 'center' }}>Company Name</th>
                            <th className="index-table ttu fw500 team-member" style={{ textAlign: 'center' }}>Company Member</th>
                            <th className="index-table ttu fw500 email-date" style={{ textAlign: 'center' }}>Email Contacted<br />Date</th>
                            <th className="index-table ttu fw500 fit-score" style={{ textAlign: 'center' }}>Fit<br />Score</th>
                            <th className="index-table ttu fw500 fit-report" style={{ textAlign: 'center' }}>Fit<br />Report</th>
                            <th className="index-table ttu fw500 allocated-member" style={{ textAlign: 'center' }}>Allocated<br />Member</th>
                            <th className="index-table ttu fw500 accepted-rejected" style={{ textAlign: 'center' }}>Accepted/<br />Rejected? </th>
                            <th className="index-table ttu fw500 email-respond" style={{ textAlign: 'center' }}>Response <br />Email Date</th>
                            <th className="index-table ttu fw500 archive" style={{ textAlign: 'center' }}>Archive?</th>
                        </tr>
                    </thead>
                    <tbody>
                        {qualifiedLeads && qualifiedLeads.length > 0
                            ? qualifiedLeads.map(lead => (
                                <tr>
                                    <td className="c15 compnay-name">{lead['cName']}</td>
                                    <td className="c15 df jcc team-member mt0-5rem">
                                        {lead['contactProfile']
                                            ? <img className={`w2rem h2rem df jcc aic br100 client-profile ${profileClass}`} style={{ margin: 0 }} src={lead['contactProfile']} data-tooltip={lead?.['contactFirstName'] && (lead?.['contactFirstName'] + " " + lead?.['contactLastName'])} />
                                            : <span className={`w2rem h2rem df jcc aic br100 client-profile`} data-tooltip={lead?.['contactFirstName'] ? (lead?.['contactFirstName'] + " " + lead?.['contactLastName']) : "Register to show your name here"}>
                                                {lead?.['contactFirstName'][0] + lead?.['contactLastName'][0]}
                                            </span>
                                        }
                                        <p className="fs1rem ml0-5rem">{lead['contactFirstName']} {lead?.['contactLastName']}</p>
                                    </td>
                                    <td className="c15 email-date">{lead['dateContacted'] != 'Nil' ? moment(lead['dateContacted']).format('lll') : <>-</>}</td>
                                    <td className="c15 fit-score">
                                        <div className="df jcc">
                                            {lead['fitScorePercentage'] >= 70
                                                ? <li className="" style={{ listStyle: 'none' }}><i className={`fa fa-circle fs0-75rem green`}></i></li>
                                                : lead['fitScorePercentage'] >= 30
                                                    ? <li className="" style={{ listStyle: 'none' }}><i className={`fa fa-circle fs0-75rem orange`}></i></li>
                                                    : <li style={{ listStyle: 'none' }}><i className={`fa fa-circle fs0-75rem red`}></i></li>
                                            }
                                            <p className="fs0-75rem ml0-5rem">{`${lead['fitScorePercentage']}%`}</p>
                                        </div>
                                    </td>
                                    <td className="c15 df jcc fit-report">
                                        <Link className={`w2rem h2rem df jcc aic br100 bg3 bg4-hover c1 bw2px bo7 bss`} style={{ position: 'relative', bottom: '0' }} to={`/company/${lead['clientId']}#fit-report:${user?.['companyId']?.['_id']}`}>R</Link>
                                    </td>
                                    <td className="c15 allocated-member">
                                        <Select
                                            className='w200px'
                                            placeholder='Select an investor'
                                            name='investor-dropdown'
                                            id='investor-dropdown'
                                            labelId='investor-multiple-checkbox-label'
                                            onChange={(e) => handleInvestorMember(lead['_id'], e['target']['value'])}
                                            renderValue={selected => typeof (selected) != 'string' ? `${selected['firstName']} ${selected['lastName']} (${selected['title'] || selected['job']})` : selected}
                                            value={lead['investorMember'] || 'Select an investor'}
                                        >
                                            {!lead['investorMember'] && (
                                                <MenuItem value='Select an investor' className='multi-dropdown-list'>
                                                    <ListItemText primary='Select an investor' />
                                                </MenuItem>
                                            )}
                                            {investors.map((option, idx) => (
                                                <MenuItem key={option['_id']} value={option} className='multi-dropdown-list'>
                                                    <Checkbox checked={lead?.['investorMember']?.['_id'] == option['_id']} />
                                                    <ListItemText primary={`${option['firstName']} ${option['lastName']} (${option['title'] || option['job']})`} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </td>
                                    {lead['leadStatus'] == 'Accepted'
                                        ? <td className="accepted-rejected green">Accepted</td>
                                        : lead['leadStatus'] == 'Rejected'
                                            ? <td className="accepted-rejected red">Rejected</td>
                                            : <td className="accepted-rejected">-</td>
                                    }
                                    <td className="c15 email-respond">{lead['dateLeadStatus'] != 'Nil' ? moment(lead['dateLeadStatus']).format('lll') : <>-</>}</td>
                                    <td className="c15 archive">{lead['isArchived'] ? 'Yes' : 'No'}</td>
                                </tr>
                            ))
                            : <tr>
                                <td className="c15 compnay-name">-</td>
                                <td className="c15 df jcc team-member"><span className={`w2rem h2rem df jcc aic br100 mr0-5rem client-profile`} data-tooltip="??">?</span></td>
                                <td className="c15 email-date">-</td>
                                <td className="c15 fit-score">0</td>
                                <td className="c15 df jcc fit-report"><Link data-tooltip="Fit Report" className={`w2rem h2rem df jcc aic br100 bg3 bg4-hover c1 bw2px bo7 bss`} style={{ position: 'relative', bottom: '0' }} to="#">R</Link></td>
                                <td className="c15 allocated-member">-</td>
                                <td className="c15 accepted-rejected">-</td>
                                <td className="c15 email-respond">-</td>
                                <td className="c15 archive">No</td>
                            </tr>
                        }
                    </tbody>

                </table>
            </div>
        </div>
    )
}