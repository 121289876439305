import React from 'react'
import ModalWrapper from '../../common/Modal/ModalWrapper'
import { AddCircle } from '@mui/icons-material';
import Button from '../../common/Button';

const UserDefinedSectionModal = ({ modalType, editSectionName, additionalSections, isSectionNameDuplicate, onSaveAdditionalSections, onEdit, onDelete }) => {
    return (
        <>
            <ModalWrapper
                modalType={modalType}
            >
                <div className="df fdr jcc aic">
                    <div className="pr df filing-assistant-pos jcfs aife">
                        <div className="additional-sections-info pr">
                            <span className="tooltip-area" style={{ top: '-28px', left: '-24px' }}>
                                <i className="icon">
                                    <img className="h3rem" src={require(`../../../../images/info-icon.svg`)} alt="info" />
                                    <div className="overlay-box left" style={{
                                        left: "34px",
                                        top: "100%"
                                    }}>
                                        <p className='c15'>Creating a new user-defined section here will add it to the standard Index used in the Data Repository and reporting, and will be replicated in your cloud data room (Dropbox/Drive/Sharepoint).</p>
                                    </div>
                                </i>
                            </span>
                        </div>
                    </div>
                    <h3 className="c15 fs18px fw600 tac mb2rem">Add/Edit Sections</h3>
                </div>
                <div className="df fdc jcc" >
                    {additionalSections.map((item, index) => {
                        return (
                            <div keys={`addUserDefinedSection-${index}`} className="pr mb1rem df fdr">
                                <div className="w100 df aic">
                                    <input
                                        id={`userSection-${index}`}
                                        disabled={item[2]}
                                        value={item[1]}
                                        onChange={(e) => { editSectionName(e.target.value, index) }}
                                        type="text"
                                        placeholder="Add your additional user-defined sections here..."
                                        style={{ paddingLeft: "2rem" }}
                                        className={`w100 br10px userSection p0-5rem ${isSectionNameDuplicate.indexes.includes(item[0]) && "error"} bs1px brss bo42`}
                                    />
                                    {item[2] === false ? <AddCircle color='primary' onClick={() => onEdit(index)} className='pa' style={{ right: "10px " }} /> : <button className="ml0-5rem shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttu bsbb tac df jcc aic" onClick={() => onDelete(index)}>Delete</button>}
                                </div>
                            </div>
                        );
                    })}
                    {isSectionNameDuplicate.indexes.length ? <p className="errorMessage w100 mb1rem">
                        {isSectionNameDuplicate.indexes.map((item, index) => (<span> Sections {item}{(isSectionNameDuplicate.indexes.length - 1) === index ? '' : ', '} </span>))} have matching names. Please change one of them.
                    </p> : null}

                    <div className="pr mb1rem df jcc aic">
                        <Button
                            customClassName='mt2rem w20rem'
                            btnType='warning'
                            text={"Save Section"}
                            onClick={onSaveAdditionalSections}
                            disabled={isSectionNameDuplicate.indexes.length}
                        />
                        {/* <button
                            disabled={isSectionNameDuplicate.indexes.length}
                            className="ml0-5rem shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttu bsbb tac df jcc aic"
                            onClick={onSaveAdditionalSections}>Save Section</button> */}
                    </div>
                </div>

            </ModalWrapper>
        </>
    )
}

export default UserDefinedSectionModal