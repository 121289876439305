import React from 'react'

const FileUploadRepository = ({ percent, gifFile, from, to, fileName, filesUploaded, totalFiles }) => {
    return (
        <div
            id="fileUploadRepository"
            className="td500 zi4 pen df jcc aic fdc"
            style={{ padding: "1rem" }}
        >
            {gifFile}

            <div className='df jcsb' style={{ width: "97%" }}>
                <p className='fs12px c43'> {from}</p>
                <p className='fs12px c43'> {to}</p>
            </div>

            <div className="maw70 w700px df fdc jcc aic mt0rem">
                <h1 className='c15 fs24px w100 tac fw500'> Uploading Files </h1>
                <div className="w100 df jcc aic mt1rem">
                    <progress color="green" className="maw90 w90 mr1rem" id="upload-progress-bar" value={percent} max="100"></progress>
                </div>
                {
                    fileName ? <div className='df aic jcc'>
                        <span className='c15 fw500 fs13px my0-5rem'> {fileName} | {filesUploaded} / {totalFiles}</span>
                    </div> : null
                }

                <p className='c15 fs1rem w100 tac fw400 my2rem'>Almost there</p>
            </div>

        </div>
    )
}

export default FileUploadRepository