import React from 'react'

const InvestorLeadMatching = () => {
    return (
        <>

            <div id="investor-lookup-landingPage" className="pr bg1 br20px px5rem py2rem xlpx1rem">
                <div className={`tslp pb2rem op1 tty0`}>
                    <p className="fw500 tac c15 fs1-75rem">
                        Investor & Applicant Curated Lead Matching
                    </p>
                </div>
                <div className='investor-box df fww aic'>
                    <div className='left-certificate-area df fww w70 pt3rem pb1rem'>
                        <div className='certificate-box tac w20 pr mr1rem'>
                            <img className='pa round-box' src={require('../../../../images/landingpage/ball-certficate.png')} alt='' />
                            <h3 className='fs1rem c1 mb1rem'>Gold Certified</h3>
                            <img className='w6rem' src={require('../../../../images/landingpage/diligentsia-certification.png')} alt='' />
                        </div>
                        <div className='certificate-box tac w20 pr'>
                            <img className='pa round-box' src={require('../../../../images/landingpage/ball-certficate.png')} alt='' />
                            <h3 className='fs0-75rem c15 mb0-5rem'>Applicant <br /> Company Pack</h3>
                            <img className='w6rem' src={require('../../../../images/landingpage/form-blur.png')} alt='' />
                        </div>
                        <div className='certificate-box tac w35 pr mr1rem'>
                            <img className='pa round-box' src={require('../../../../images/landingpage/ball-certficate.png')} alt='' />
                            <h3 className='fs1rem c15 mb1rem'>“Fit” Report</h3>
                            <img className='w170px' src={require('../../../../images/landingpage/fit-report-blur.png')} alt='' />
                        </div>
                        <div className='certificate-box tac w20 pr'>
                            <img className='pa round-box' src={require('../../../../images/landingpage/ball-certficate.png')} alt='' />
                            <h3 className='fs1rem c15 mb1rem'>Investor Pack</h3>
                            <img className='w6rem' src={require('../../../../images/landingpage/investor-form.png')} alt='' />
                        </div>
                    </div>
                    <div className='w30 list-right-area'>
                        <div className='bg1 br15px shadow p2rem benefits-box'>
                            <h3 className='fs1-25rem c15'>Benefits For Investor</h3>
                            <div className="pt1rem">
                                <ul className="custom-list">
                                    <li style={{ paddingTop: '4px', paddingBottom: '4px' }} className="c14 fs12px fw400 df aic">
                                        FREE leads generation
                                    </li>
                                    <li style={{ paddingTop: '4px', paddingBottom: '4px' }} className="c14 fs12px fw400 df aic">
                                        Only for companies with gold certification
                                    </li>
                                    <li style={{ paddingTop: '4px', paddingBottom: '4px' }} className="c14 fs12px fw400 df aic">
                                        Matched to your investment criteria
                                    </li>
                                    <li style={{ paddingTop: '4px', paddingBottom: '4px' }} className="c14 fs12px fw400 df aic">
                                        Diligentsia Pack supplied with the lead:
                                    </li>
                                </ul>
                                <p className='c14 fs12px fw400 df aic ml2rem'>- Data Repository Report</p>
                                <p className='c14 fs12px fw400 df aic ml2rem'>- 20-section Business Audit</p>
                                <p className='c14 fs12px fw400 df aic ml2rem'>- Directors’ Questionnaires</p>
                                <p className='c14 fs12px fw400 df aic ml2rem'>- Draft Warranty Disclosures</p>
                            </div>
                        </div>
                    </div>
                    <div className="w100 investor-lookup-full-img" style={{ display: 'none' }}>
                        <img className='w100' src={require('../../../../images/landingpage/application-moda-img.png')} alt='' />
                    </div>
                </div>
                <div className="pr tac mt2rem">
                    <p className="fw400 fs1rem w100 tac c15 mb1rem" style={{ lineHeight: '24px', fontSize: '0.96rem' }}>
                        Have we already added your fund/firm and contact details to our database from publicly available information?<br /> You can check here (and later update/amend)
                    </p>
                </div>
            </div>

        </>
    );
}

export default InvestorLeadMatching